import React, { useState, useEffect } from "react";
import { Tooltip, IconButton } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  sql,
  globalFilter,
  addToHistory,
  openSaveFileDialog,
} from "../apis/utility";
import {
  CancelTwoTone,
  FileDownload,
  ThumbDownAlt,
  MoodBad,
  Link,
  Done,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { usePapaParse } from "react-papaparse";

const MyCellRenderer = (props) => {
  if (props.field === "ae")
    return (
      <span>
        {props.value > 0 &&
        props.row.serious <= 0 &&
        props.row.severe <= 0 &&
        props.row.possible <= 0 &&
        props.row.probable <= 0 ? (
          <Done />
        ) : null}
        &nbsp;
        {props.row.serious > 0 ? (
          <Tooltip title="Serious">
            <ThumbDownAlt sx={{ fontSize: 18, color: "error.main" }} />
          </Tooltip>
        ) : null}
        &nbsp;
        {props.row.severe > 0 ? (
          <Tooltip title="Severe">
            <MoodBad sx={{ fontSize: 18, color: "error.main" }} />
          </Tooltip>
        ) : props.row.moderate > 0 ? (
          <Tooltip title="Moderate">
            <MoodBad sx={{ fontSize: 18, color: "warning.main" }} />
          </Tooltip>
        ) : null}
        &nbsp;
        {props.row.probable > 0 ? (
          <Tooltip title="Probable">
            <Link sx={{ fontSize: 22, color: "warning.main" }} />
          </Tooltip>
        ) : props.row.possible > 0 ? (
          <Tooltip title="Possible">
            <Link sx={{ fontSize: 22, color: "info.main" }} />
          </Tooltip>
        ) : null}
        {props.row.died > 0 ? (
          <Tooltip title="Died">
            <CancelTwoTone sx={{ fontSize: 22, color: "error.main" }} />
          </Tooltip>
        ) : null}
      </span>
    );
  else if (["dm", "ds", "vd", "lb", "vs", "cm", "mh"].includes(props.field))
    return <span>{props.value > 0 ? <Done /> : null}</span>;
  else return <span>{props.value}</span>;
};

export default function SubjectTable(props) {
  const location = useLocation(),
    { jsonToCSV } = usePapaParse();

  addToHistory({ title: "Subject Table", url: location });

  const [columnDefs, setColumnDefs] = useState(null),
    [rows, setRows] = useState(null),
    [subtitle, setSubtitle] = useState(null);

  useEffect(() => {
    // console.log('props', props)
    const selectedSubjects = props.selectedSubjects.map(
      (sub) => '"' + sub + '"'
    );
    const where =
        selectedSubjects.length > 0
          ? `WHERE SUBJID IN (${selectedSubjects.join(",")})`
          : "",
      subtitle = globalFilter(props.selectedOptions);
    setSubtitle(subtitle);
    sql(
      props.studyDatabase,
      `SELECT SUBJID as id,
                sex , age, armcd,
                ae, dm,  ds, lb, vd, vs, cm, mh,
                ethnic, race,
                serious, moderate, severe, possible, probable, died, ongoing, dsdecod
                  FROM subjects ${where}`
    ).then((res) => {
      if (res.message === "success" && res.data.length > 0) {
        setRows(res.data);
        // console.log('res.data', res.data, 'where', where, 'props.studyDatabase', props.studyDatabase)
        const keys = Object.keys(res.data[0]);
        const columnDefs = [];
        keys.forEach((key, index) => {
          const row = {
            field: key,
            headerName: key,
            renderCell: MyCellRenderer,
            width: 50,
          };
          if (key === "id") {
            row.width = 75;
          } else if (["ae"].includes(key)) {
            row.width = 100;
          } else if (["ethnic"].includes(key)) {
            row.width = 150;
          } else if (["dsdecod"].includes(key)) {
            row.width = 260;
          } else if (["armcd"].includes(key)) {
            row.width = 100;
          } else if (["race"].includes(key)) {
            row.width = 260;
          }
          if (key.startsWith("n_")) {
            row.width = 60;
          }
          if (
            [
              "id",
              "ae",
              "dm",
              "ds",
              "lb",
              "vd",
              "cm",
              "mh",
              "vs",
              "sex",
              "age",
              "race",
              "ethnic",
              "armcd",
              "dsdecod",
            ].includes(key)
          ) {
            if (
              !["ae", "id", "ethnic", "race", "armcd", "dsdecod"].includes(key)
            )
              row.width = 10;
            columnDefs.push(row);
          } else if (props.detail) columnDefs.push(row);
        });
        setColumnDefs(columnDefs);
      }
    });
    // eslint-disable-next-line
  }, [props.selectedSubjects, props.selectedOptions, props.studyDatabase]);

  return (
    <div
      style={{
        height: props.height ? props.height : props.screenHeight * 0.8,
        width: "100%",
      }}
    >
      <h4>
        <Tooltip title="Download table">
          <IconButton
            color="primary"
            onClick={() => {
              openSaveFileDialog(jsonToCSV(rows), "subjects", "text/csv");
            }}
          >
            <FileDownload />
          </IconButton>
        </Tooltip>
        Subjects ({props.study})<br />
        <span style={{ fontSize: 12 }}>{subtitle}</span>
      </h4>
      {rows && columnDefs && (
        <DataGridPro
          rows={rows}
          columns={columnDefs}
          rowHeight={33}
          rowsPerPageOptions={[10, 25, 50, 100]}
          density={props.detail ? "comfortable" : "compact"}
          onRowClick={(e, row) => {
            window.open(
              window.location.protocol +
                "//" +
                window.location.host +
                "/#/patientprofile/" +
                props.studyDatabase +
                "/" +
                e.id
            );
          }}
          sx={{ fontWeight: "fontSize=5", fontSize: "0.7em" }}
        />
      )}
    </div>
  );
}
