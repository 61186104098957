import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Tooltip, Chip, } from '@mui/material';
export const LookBelow = (props) => {
    // console.log('props', props)
    const handleClick = () => {
        window.scrollBy(0, 600);
    }
    const { label, tooltip, mt, ml, mr, mb } = props;
    return (
        <Tooltip title={tooltip}>
            <Chip clickable={true}
                onClick={(e) => {
                    handleClick()
                }}
                color="primary" size="large" icon={<ArrowDownwardIcon />} sx={{ mt: mt, ml: ml, mr: mr, mb: mb }} label={label} />
        </Tooltip>
    )
}