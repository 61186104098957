import * as React from 'react';
import { Fab, Tooltip, Modal, Box, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useLocalstorageState } from "rooks";

export default function Save(props) {
    const [, setUserSelections1] = useLocalstorageState('userSelections1', {}),
        [, setUserSelections2] = useLocalstorageState('userSelections2', {}),
        [, setUserSelections3] = useLocalstorageState('userSelections3', {}),
        [, setUserSelections4] = useLocalstorageState('userSelections4', {}),
        [, setUserSelections5] = useLocalstorageState('userSelections5', {}),
        [, setUserSelections6] = useLocalstorageState('userSelections6', {}),
        [, setUserSelections7] = useLocalstorageState('userSelections7', {}),
        [, setUserSelections8] = useLocalstorageState('userSelections8', {}),
        [, setUserSelections9] = useLocalstorageState('userSelections9', {}),
        [openModal, setOpenModal] = React.useState(false),
        handleClose = () => { setOpenModal(false) },
        handleOpen = () => { setOpenModal(true) },
        save = (slot) => {
            const selections = {
                study: props.study,
                selectedOptions: props.selectedOptions,
                studyDatabase: props.studyDatabase
            };
            switch (slot) {
                case 1: setUserSelections1(selections)
                    break;
                case 2: setUserSelections2(selections)
                    break;
                case 3: setUserSelections3(selections)
                    break;
                case 4: setUserSelections4(selections)
                    break;
                case 5: setUserSelections5(selections)
                    break;
                case 6: setUserSelections6(selections)
                    break;
                case 7: setUserSelections7(selections)
                    break;
                case 8: setUserSelections8(selections)
                    break;
                case 9: setUserSelections9(selections)
                    break;
                default:
            }
            handleClose();
        },
        modalStyle = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        };

    return (<>
        <Tooltip title="Save Global Filter (to local storage)">
            <Fab size="small"
                onClick={handleOpen}
                color={Object.keys(props.selectedOptions).length > 0 ? "secondary" : "primary"}
                sx={{ margin: 1 }}>
                <SaveIcon />
            </Fab>
        </Tooltip>
        <Modal open={openModal} onClose={handleClose}>
            <Box sx={modalStyle} >
                <Button onClick={() => save(1)}>Save Slot 1</Button>
                <Button onClick={() => save(2)}>Save Slot 2</Button>
                <Button onClick={() => save(3)}>Save Slot 3</Button>
                <Button onClick={() => save(4)}>Save Slot 4</Button>
                <Button onClick={() => save(5)}>Save Slot 5</Button>
                <Button onClick={() => save(6)}>Save Slot 6</Button>
                <Button onClick={() => save(7)}>Save Slot 7</Button>
                <Button onClick={() => save(8)}>Save Slot 8</Button>
                <Button onClick={() => save(9)}>Save Slot 9</Button>
            </Box>
        </Modal>
    </>)
}