import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton, Box } from '@mui/material';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';

export default function PreferencesIconLink() {
    const navigate = useNavigate()
    return (
        <Box sx={{ flexGrow: 0, p: 1 }}>
            <Tooltip title="Preferences">
                <IconButton onClick={() => navigate('/preferences')} sx={{ p: 0 }}>
                    <SettingsInputComponentIcon sx={{ color: 'text.primary', fontSize: 24, fontWeight: 'medium' }} />
                </IconButton>
            </Tooltip>
        </Box >)
}