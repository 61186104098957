import * as React from 'react';
import { Link } from "react-router-dom";
import { Grid } from '@mui/material';
import logo from '../../assets/images/both_logo_2_hres.png';

export default function About() {

  return (<>
    <Grid sx={{ mt: 2 }} container spacing={2}>
      <Grid xs={4}>
        <h2>Who are we?</h2>
        <a href="mailto:info@both-analytics.com"><b>Dr. Ayad Abdul-Ahad</b></a> (CEO)<p />
        <a href="mailto:info@both-analytics.com"><b>Marcus Irsfield</b></a> (CFO)<p />
        <a href="mailto:robert.snijder@both-analytics.com"><b>Robert Snijder</b></a> (Statistician)<p />
        <a href="mailto:philip.mason@info@both-analytics.com"><b>Philip Mason</b></a> (Developer)
      </Grid >
      <Grid xs={4}>
        <p />
        <a href="https://both-analytics.com/index.php/both_home.html" target='_blank' rel="noreferrer">
          <img src={logo} alt="BoTh Analytics" />
        </a>
        <p />
        <nav>
          Return to <Link to="/">Home</Link> page.
        </nav>
      </Grid>
    </Grid >
  </>
  )
}