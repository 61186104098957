import { Modal, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { sql } from '../../apis/utility';
import parse from 'html-react-parser';
import Markdown from 'markdown-to-jsx';

export const InfoModal = (props) => {
    const { setOpenInfoModal, openInfoModal, screenHeight, screenWidth } = props,
        location = useLocation(),
        handleClose = () => setOpenInfoModal(false),
        style = {
            position: 'absolute',
            top: '40%',
            left: '40%',
            transform: 'translate(-40%, -40%)',
            width: screenWidth * 0.75,
            // height: screenHeight * 0.3,
            maxheight: screenHeight * 0.3,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflow: 'auto'
        },
        [html, setHtml] = useState(null),
        [markdown, setMarkdown] = useState(null)

    useEffect(() => {
        const pathname = location.pathname.replace(/\//g, "%2F")
        sql('global', `SELECT * FROM screeninfo WHERE screen = '${pathname}'`)
            .then(res => {
                if (res && res.message === 'success' && res.data.length > 0) {
                    setHtml(res.data[0].html)
                    setMarkdown(res.data[0].markdown)
                }
                else {
                    setHtml('No data was retrieved for this screen. Please add a description for this screen in the screeninfo SQL table.')
                    setMarkdown('No data was retrieved for this screen. Please add a description using **html** or **markdown** for this screen in the **screeninfo** SQL table.')
                }
                // console.log('res', res)
            })
    }, [openInfoModal, location.pathname])

    return (
        <Modal open={openInfoModal} onClose={handleClose}        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Info about screen - {location.pathname.substr(1)}
                </Typography>
                {/* <Typography id="modal-description" variant="body2" component="p"> */}
                {html ? parse(html) : markdown ?
                    <Markdown>{markdown}</Markdown> : null}
                {/* </Typography> */}
            </Box>
        </Modal>
    );
};