import React, { useState, useEffect } from "react";
import { sql, globalFilter, getSubjects } from "../../apis/utility";
// import { saeReports, saeReports2, ciomsReports1 } from "../../apis/reports";
import {
  Stack,
  Chip,
  Container,
  Box,
  Typography,
  Tooltip,
  Grid,
  // IconButton,
  // Badge,
} from "@mui/material";
import HeatMap from "@uiw/react-heat-map";
// import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
// import MailIcon from "@mui/icons-material/Mail";
import { Donut } from "./Donut";
import { DataGridPro } from "@mui/x-data-grid-pro";

export default function Home(props) {
  const heatmapWidthFactor = 10,
    [subjectsWithAes, setSubjectsWithAes] = useState(null),
    [totalAes, setTotalAes] = useState(null),
    [seriousAes, setSeriousAes] = useState(null),
    [notSeriousAes, setNotSeriousAes] = useState(null),
    [notRelatedAes, setNotRelatedAes] = useState(null),
    [possibleAes, setPossibleAes] = useState(null),
    [probableAes, setProbableAes] = useState(null),
    [sqlStatement, setSqlStatement] = useState(null),
    [subtitle, setSubtitle] = useState(null),
    [visits, setVisits] = useState(null),
    [minVisitDate, setMinVisitDate] = useState(null),
    [maxVisitDate, setMaxVisitDate] = useState(null),
    [rowColDefs, setRowColDefs] = useState(null),
    [rowCounts, setRowCounts] = useState(null),
    gridHover = { backgroundColor: "#333333" },
    {
      studyDatabase,
      selectedOptions,
      varToTable,
      setSelectedSubjects,
      selectedSubjects,
      screenWidth,
      // seriousResults,
      screenHeight,
      study,
      setSelectedOptions,
      // showCm,
      // showMh,
    } = props,
    dateOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    panelColors = {
      0: "#FFFFFF",
      2: "#D6E0CC",
      4: "#ADC299",
      10: "#85A366",
      20: "#5C8533",
      30: "#336600",
    },
    // panelColors = {
    //   0: "#f4decd",
    //   2: "#e4b293",
    //   4: "#d48462",
    //   10: "#c2533a",
    //   20: "#ad001d",
    //   30: "#000",
    // },
    ValueRenderer = (props) => {
      const { value } = props;
      console.log("props", props);
      return value;
      // return value.toLocaleString();
    };

  useEffect(() => {
    sql(
      studyDatabase,
      `SELECT vddate AS date, count(*) as count FROM vd GROUP BY date `
    ).then((res) => {
      if (res.message === "success") {
        // console.log('res', res)
        const realdates = res.data.map((row) => new Date(row.date)),
          maxDate = new Date(Math.max.apply(null, realdates)),
          minDate = new Date(Math.min.apply(null, realdates));
        // console.log(realdates, minDate, maxDate)
        setVisits(res.data);
        setMinVisitDate(minDate);
        setMaxVisitDate(maxDate);
      }
    });
  }, [selectedOptions, studyDatabase]);

  useEffect(() => {
    if (props.rowCounts === null) return;
    const keys = Object.keys(props.rowCounts).filter(
        (item) => item.length === 2
      ),
      cd = keys.map((k) => {
        return {
          field: k,
          headerName: k,
          width: 60,
        };
      });
    setRowColDefs(cd);
    const row0 = { ...props.rowCounts, id: 1 };
    setRowCounts([row0]);
    // console.log(props);
  }, [props.rowCounts]);

  useEffect(() => {
    if (studyDatabase && selectedOptions && varToTable)
      getSubjects(studyDatabase, selectedOptions, varToTable).then((res) => {
        // console.log('GenerateSelects - res', res)
        setSelectedSubjects(res);
      });
    // eslint-disable-next-line
  }, [selectedOptions, studyDatabase]);

  useEffect(() => {
    if (selectedOptions) {
      const tempSubtitle = globalFilter(selectedOptions);
      // console.log(' selectedOptions',  selectedOptions, 'tempSubtitle', tempSubtitle)
      setSubtitle(tempSubtitle);
    }
  }, [selectedOptions]);

  useEffect(() => {
    const whereClause =
        "where SUBJID in (" +
        selectedSubjects.map((item) => `"${item}"`).join(",") +
        ")",
      tempSqlStatement = `SELECT *
            FROM (
                    SELECT count(DISTINCT SUBJID) AS subjectsWithAEs,
                            count( * ) AS totalAEs
                    FROM aevd ${whereClause}
                )
                CROSS JOIN
                (
                    SELECT count(DISTINCT SUBJID) AS totalSubjects
                    FROM vd ${whereClause}
                )
                CROSS JOIN
                (
                    SELECT count(CASE WHEN aeser = "Y" THEN 1 END) AS serious,
                            count(CASE WHEN aeser != "Y" THEN 1 END) AS notSerious,
                            count(CASE WHEN aerel = "NOT RELATED" OR aerel = "NOT SUSPECTED" THEN 1 END) AS notRelated,
                            count(CASE WHEN aerel = "POSSIBLE" THEN 1 END) AS possible,
                            count(CASE WHEN aerel = "PROBABLE" or aerel = "SUSPECTED" THEN 1 END) AS probable
                    FROM ae ${whereClause}
                );`;
    // console.log('tempSqlStatement', tempSqlStatement)
    setSqlStatement(tempSqlStatement);
  }, [selectedSubjects]);

  useEffect(() => {
    // console.log('Home - props', props, 'sqlStatement', sqlStatement)
    if (sqlStatement) {
      sql(studyDatabase, sqlStatement).then((res) => {
        // console.log('res', res)
        if (res.data.length > 0) {
          const pctSubjectsWithAEs =
            (res.data[0].subjectsWithAEs / res.data[0].totalSubjects) * 100;
          setSubjectsWithAes(
            res.data[0].subjectsWithAEs +
              " of " +
              res.data[0].totalSubjects +
              " (" +
              pctSubjectsWithAEs.toFixed(1) +
              "%)"
          );
          setTotalAes(res.data[0].totalAEs);
          setSeriousAes(res.data[0].serious);
          setNotSeriousAes(res.data[0].notSerious);
          setNotRelatedAes(res.data[0].notRelated);
          setPossibleAes(res.data[0].possible);
          setProbableAes(res.data[0].probable);
        }
      });
    }
  }, [sqlStatement, studyDatabase]);

  document.title = "Xploratum";
  // console.log('screenWidth', screenWidth, 'screenHeight', screenHeight)

  return (
    <Container sx={{ backgroundColor: "primary.light", mt: 2 }}>
      <Box>
        <br />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Box sx={{ gridHover }}>
              <Typography sx={{ fontWeight: "bold" }}>{study}</Typography>
              <Typography sx={{ fontWeight: "bold", color: "#bbbbbb" }}>
                SQLite Database used - {studyDatabase}
              </Typography>
              <Typography sx={{ textDecoration: "underline" }}>
                {subtitle}
              </Typography>
              <br />
              <Stack direction="column" spacing={1}>
                <Chip
                  label={subjectsWithAes + " subjects have an AE"}
                  sx={{
                    backgroundColor: "#f4decd",
                    "&:hover": { backgroundColor: "primary.light" },
                  }}
                  onClick={() => {
                    setSelectedOptions({
                      AESER: [
                        { value: "N", label: "N" },
                        { value: "Y", label: "Y" },
                      ],
                    });
                  }}
                />
                <Chip
                  label={totalAes + " AEs in total"}
                  sx={{
                    backgroundColor: "#d48462",
                    "&:hover": { backgroundColor: "primary.light" },
                  }}
                  onClick={() => {
                    setSelectedOptions({
                      AESER: [
                        { value: "N", label: "N" },
                        { value: "Y", label: "Y" },
                      ],
                    });
                  }}
                />
                <Chip
                  label={notSeriousAes + " non-serious AEs"}
                  sx={{
                    backgroundColor: "#e4b293",
                    "&:hover": { backgroundColor: "primary.light" },
                  }}
                  onClick={() => {
                    setSelectedOptions({ AESER: [{ value: "N", label: "N" }] });
                  }}
                />
                <Chip
                  label={notRelatedAes + " un-related AEs"}
                  sx={{
                    backgroundColor: "#e4b293",
                    "&:hover": { backgroundColor: "primary.light" },
                  }}
                  onClick={() => {
                    setSelectedOptions({
                      AEREL: [{ value: "NOT RELATED", label: "NOT RELATED" }],
                    });
                  }}
                />
                <Chip
                  label={possibleAes + " possibly related  AEs"}
                  sx={{
                    backgroundColor: "#d48462",
                    "&:hover": { backgroundColor: "primary.light" },
                  }}
                  onClick={() => {
                    setSelectedOptions({
                      AEREL: [{ value: "POSSIBLE", label: "POSSIBLE" }],
                    });
                  }}
                />
                <Chip
                  label={probableAes + " probably related  AEs"}
                  sx={{
                    backgroundColor: "#ac011d",
                    color: "white",
                    "&:hover": { backgroundColor: "primary.light" },
                  }}
                  onClick={() => {
                    setSelectedOptions({
                      AEREL: [{ value: "PROBABLE", label: "PROBABLE" }],
                    });
                  }}
                />
                <Chip
                  label={seriousAes + " serious AEs"}
                  sx={{
                    backgroundColor: "#111111",
                    color: "white",
                    "&:hover": { backgroundColor: "primary.light" },
                  }}
                  onClick={() => {
                    setSelectedOptions({ AESER: [{ value: "Y", label: "Y" }] });
                  }}
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ gridHover }}>
              {visits && (
                <>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Visits made by study subjects
                  </Typography>
                  <Typography sx={{ fontWeight: "bold", color: "#bbbbbb" }}>
                    {minVisitDate.toLocaleDateString("en-US", dateOptions)} to{" "}
                    {maxVisitDate.toLocaleDateString("en-US", dateOptions)}
                  </Typography>
                  <HeatMap
                    value={visits}
                    width={screenWidth * heatmapWidthFactor}
                    height={screenHeight * 0.3}
                    // screenHeight={screenHeight * .2}
                    style={{ color: "#ad001d" }}
                    startDate={minVisitDate}
                    endDate={maxVisitDate}
                    panelColors={panelColors}
                    rectSize={Math.round(screenWidth * 0.0175)}
                    legendCellSize={22}
                    rectRender={(props, data) => {
                      return (
                        <Tooltip
                          key={props.key}
                          placement="top"
                          title={`${data.count || 0} on ${data.date}`}
                        >
                          <rect {...props} />
                        </Tooltip>
                      );
                    }}
                    legendRender={(props, data) => {
                      // console.log('props', props, 'data', data)
                      // if (!data.count) return <rect {...props} />;
                      return (
                        <Tooltip
                          key={props.key}
                          placement="top"
                          title={`${
                            Object.keys(panelColors)[props.key - 1] || ""
                          }${props.key === 0 ? "" : " - "}${
                            Object.keys(panelColors)[props.key]
                          }`}
                        >
                          <rect {...props} />
                        </Tooltip>
                      );
                    }}
                  />
                </>
              )}
            </Box>
            {rowCounts && rowColDefs ? (
              <Box sx={{ height: 100 }}>
                <b>Records in each SDTM table</b>
                <DataGridPro
                  rows={rowCounts}
                  columns={rowColDefs}
                  rowHeight={33}
                  sx={{ fontSize: "0.7em" }}
                  density="compact"
                  renderCell={ValueRenderer}
                  hideFooter={true}
                />
              </Box>
            ) : null}
          </Grid>

          <Donut
            study={study}
            studyDatabase={studyDatabase}
            selectedOptions={selectedOptions}
            screenHeight={screenHeight}
            screenWidth={screenWidth}
            selectedSubjects={selectedSubjects}
          />
          {/* <Grid container spacing={0} sx={{ mt: 1 }}>
            <Grid item xs={4}>
              <Typography sx={{ mt: 1, fontWeight: "bold", color: "#bbbbbb" }}>
                Serious AE Report examples:
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {seriousResults && seriousResults.data.length > 0 && (
                <Tooltip title="Serious AE Report Example 1">
                  <IconButton
                    sx={{
                      mt: 1,
                      mb: 1,
                      "&:hover": { backgroundColor: "primary.light" },
                    }}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      saeReports(seriousResults, studyDatabase, study, {
                        cm: showCm,
                        mh: showMh,
                      });
                    }}
                  >
                    <Badge color="primary">
                      <HealthAndSafetyIcon color="action" />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={1}>
              {seriousResults && seriousResults.data.length > 0 && (
                <Tooltip title="Serious AE Report Example 2">
                  <IconButton
                    sx={{
                      mt: 1,
                      mb: 1,
                      "&:hover": { backgroundColor: "primary.light" },
                    }}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      saeReports2(seriousResults, studyDatabase, study, {
                        cm: showCm,
                        mh: showMh,
                      });
                    }}
                  >
                    <Badge color="primary">
                      <HealthAndSafetyIcon color="action" />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={1}>
              {seriousResults && seriousResults.data.length > 0 && (
                <Tooltip title="CIOMS Example">
                  <IconButton
                    sx={{
                      mt: 1,
                      mb: 1,
                      "&:hover": { backgroundColor: "primary.light" },
                    }}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      ciomsReports1(seriousResults, studyDatabase, study, {
                        cm: showCm,
                        mh: showMh,
                      });
                    }}
                  >
                    <Badge color="primary">
                      <MailIcon color="action" />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid> */}
          <Grid sx={{ mb: 5 }}>&nbsp;</Grid>
        </Grid>
      </Box>
    </Container>
  );
}
