import React, { useState, useEffect } from "react";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting'
import { sql, dataToBarOptions, globalFilter, addToHistory } from "../../apis/utility";
import { Tooltip, Fab, Snackbar } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SubjectTable from "../SubjectTable";
import { useLocation } from "react-router-dom";
import { LookBelow } from "../layout/LookBelow";
HC_exporting(Highcharts)

export default function AEDrilldown(props) {
    const location = useLocation();
    addToHistory({ title: "AE Drill", url: location })
    const
        contrastingColor = props.theme.palette.mode === 'dark' ? 'white' : 'black',
        [showSubjects, setShowSubjects] = useState(false),
        [chartOptions, setChartOptions] = useState(null),
        [newOptions, setNewOptions] = useState(null),
        [data, setData] = useState(null),
        [caption, setCaption] = useState(null),
        [drillVars, setDrillVars] = useState([]),
        drillDownPath = props.drillDownPath,
        [drillDownLevel, setDrillDownLevel] = useState(0),
        [whereClause, setWhereClause] = useState(1),
        [snackBarOpen, setSnackBarOpen] = useState(false),
        [subjectsDrilledTo, setSubjectsDrilledTo] = useState([]),
        whereSubjects = props.selectedSubjects.length > 0
            ? 'where SUBJID in ("' + props.selectedSubjects.join('","') + '")'
            : '',
        sql1 = `select * from (select count(distinct SUBJID) as subjectsWithAEs, count(*) as totalAEs from aevd ${whereSubjects}) ` +
            `cross join (select count(distinct SUBJID) as totalSubjects from vd ${whereSubjects})`,
        drillUp = () => {
            setDrillVars(drillVars.slice(0, drillDownLevel - 1));
            setDrillDownLevel(drillDownLevel - 1);
            setShowSubjects(false);
        },
        top = () => {
            setDrillVars(drillVars.slice(0, 0));
            setDrillDownLevel(0);
            setShowSubjects(false);
        }

    useEffect(() => {
        if (props.studyDatabase) sql(props.studyDatabase, sql1)
            .then((res) => {
                const classVar = drillDownPath[drillDownLevel];
                let tempWhereClause = whereSubjects
                    ? whereSubjects
                    : 'where 1'
                for (let i = 0; i < drillDownLevel; i++) {
                    tempWhereClause += ' and ' + drillDownPath[i] + '="' + drillVars[i] + '"'
                }
                setWhereClause(tempWhereClause);
                const pctSubjectsWithAEs = res.data[0].subjectsWithAEs / res.data[0].totalSubjects * 100;
                setCaption(`<b>Total Subjects: ${res.data[0].totalSubjects}, ` +
                    `Subjects with AEs: ${res.data[0].subjectsWithAEs} (${pctSubjectsWithAEs.toFixed(1)}%) , ` +
                    `Total AEs: ${res.data[0].totalAEs}</b>`);
                sql(props.studyDatabase, `SELECT ${classVar}, count(distinct SUBJID) as numberOfSubjects, count(*) as numberOfAEs ` +
                    `FROM ae ${tempWhereClause} group by ${classVar}`)
                    .then((res) => {
                        if (res.message === "success") {
                            const barOptions = dataToBarOptions(res.data, classVar, ['numberOfAEs', 'numberOfSubjects'], contrastingColor);
                            setNewOptions(barOptions);
                            setData(res.data);
                        }
                    });
            })
    }, [drillDownPath, drillVars, drillDownLevel, sql1, whereClause, whereSubjects, contrastingColor, props.studyDatabase])

    const whatHaveIDrilledDownTo = () => {
        const tempWhereClause = []
        for (let i = 0; i < drillDownLevel; i++) {
            const catVar = drillDownPath[i],
                drillDownLevelLabel = catVar in props.variableLabels.ae
                    ? props.variableLabels.ae[catVar]
                    : catVar
            tempWhereClause.push(drillDownLevelLabel + '="' + drillVars[i] + '"')
        }
        return tempWhereClause.join(' and ')
    }

    useEffect(() => {
        let subtitle = globalFilter(props.selectedOptions)
        const catVar = drillDownPath[drillDownLevel],
            drillDownLevelLabel = 'ae' in props.variableLabels && catVar in props.variableLabels.ae
                ? props.variableLabels.ae[catVar]
                : catVar,
            commonOptions = {
                chart: {
                    type: 'bar',
                    height: props.screenHeight * 0.75,
                    zoomType: 'xy',
                    backgroundColor: 'transparent',
                },
                xAxis: { labels: { style: { color: contrastingColor } } },
                legend: { itemStyle: { color: contrastingColor } },
                title: { text: `Adverse Events by ${drillDownLevelLabel} (${props.study})`, style: { color: contrastingColor } },
                subtitle: {
                    text: caption + '<br/>' + subtitle + '<br/>' + whatHaveIDrilledDownTo(), style: { color: contrastingColor }
                },
                plotOptions: {
                    column: { colorByPoint: true },
                    bar: {
                        dataLabels: { enabled: true, style: { color: contrastingColor } },
                        point: {
                            events: {
                                click: function (e) {
                                    // console.log('clicked', e.point.category);
                                    if (drillDownLevel < drillDownPath.length - 1) { // drilling down to next level
                                        setDrillDownLevel(drillDownLevel + 1);
                                        setDrillVars([...drillVars, e.point.category]);
                                    } else { // drilling down beyond last level to subjects with AE
                                        setSnackBarOpen(true)
                                        setShowSubjects(true);
                                        const bottomWhereClause = whereClause + ' and ' +
                                            drillDownPath.slice(-1)[0] + '="' + e.point.category + '"'
                                        sql(props.studyDatabase, `select distinct SUBJID from ae ${bottomWhereClause}`)
                                            .then((res) => {
                                                if (res.message === "success") {
                                                    setSubjectsDrilledTo(res.data.map(d => d.SUBJID));
                                                }
                                            })
                                    }
                                }
                            }
                        }
                    }
                },
                colors: ['#f4decd', '#e4b293'],
                tooltip: {
                    formatter: function () {
                        return '<b>' + this.series.name + ', Body System = ' + this.point.category + '</b><br/>' +
                            'Number of AEs or Subjects: ' + Highcharts.numberFormat(Math.abs(this.point.y), 0);
                    }
                },
                credits: { enabled: false }
            }
        const updatedOptions = { ...commonOptions, ...newOptions }
        // console.log('updatedOptions', updatedOptions)
        setChartOptions(updatedOptions)
        // eslint-disable-next-line
    }, [data, caption, drillDownLevel, drillVars, newOptions, drillDownPath, props.study, subjectsDrilledTo, whereClause, contrastingColor])

    return (<>
        {drillDownLevel > 0
            ? <span style={{ position: "fixed", bottom: '100px', right: '40px' }}>
                <Tooltip title="Level we have drilled down to">
                    <Fab size="small" variant='extended' color="info" sx={{ margin: 1 }} onClick={drillUp}>
                        Level {drillDownLevel}
                    </Fab>
                </Tooltip>
                <Tooltip title="Drill up">
                    <Fab size="small" color="info" sx={{ margin: 1 }} onClick={drillUp}>
                        <ArrowDropUpIcon />
                    </Fab>
                </Tooltip>
                <Tooltip title="Go to top level">
                    <Fab size="small" color="info" sx={{ margin: 1 }} onClick={top}>
                        <ArrowUpwardIcon />
                    </Fab>
                </Tooltip>
            </span>
            : null}
        {chartOptions &&
            newOptions &&
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />}
        {showSubjects &&
            <LookBelow label="Subject Table" tooltip="Click to scroll to subject table appears below" mt={2} ml={4} mr={0} mb={0} />
        }
        {showSubjects &&
            <SubjectTable
                screenHeight={props.screenHeight}
                selectedSubjects={subjectsDrilledTo}
                study={props.study}
                studyDatabase={props.studyDatabase}
                selectedOptions={props.selectedOptions} />}
        <Snackbar message={"Can't drill any lower"}
            open={snackBarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackBarOpen(false)} />
    </>
    );
}