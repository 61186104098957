import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton, Box } from "@mui/material";
import FormatAlignJustify from "@mui/icons-material/FormatAlignJustify";
import { getHeight } from "../../apis/utility";

export default function EcrfLink() {
  const navigate = useNavigate(),
    screenHeight = getHeight() + "px";
  return (
    <Box sx={{ flexGrow: 1, p: 1, minHeight: { screenHeight } }}>
      <Tooltip title="Study Documents">
        <IconButton onClick={() => navigate("/ecrf")} sx={{ p: 0 }}>
          <FormatAlignJustify
            sx={{ color: "text.primary", fontSize: 24, fontWeight: "medium" }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
