import React, { useEffect, useState } from 'react';
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Widgets from 'fusioncharts/fusioncharts.widgets';
import Charts from "fusioncharts/fusioncharts.charts";
ReactFC.fcRoot(FusionCharts, Widgets, Charts);

export const ViewSpark = (props) => {
    const
        spark = (data) => {
            return (
                {
                    chart: {
                        theme: "gammel",
                        showclosevalue: "0",
                        showopenvalue: "0",
                        setadaptiveymin: "1",
                        chartTopMargin:10,
                        chartBottomMargin:10
                    },
                    dataset: [
                        {
                            data: data.map((item) => {
                                return {
                                    value: item.value,
                                    tooltext: item.label + ': ' + item.value
                                }
                            })
                        }
                    ]
                }

            )
        },
        [test, setTest] = useState([])

    console.log('spark', spark(test))
    useEffect(() => {
        const temp = []
        temp.push([{ label: "January", value: "420000" }, { label: "February", value: "810000" }, { label: "March", value: "720000" }, { label: "April", value: "550000" }, { label: "May", value: "910000" }, { label: "June", value: "510000" }, { label: "July", value: "680000" }, { label: "August", value: "620000" }, { label: "September", value: "610000" }, { label: "October", value: "490000" }, { label: "November", value: "900000" }, { label: "December", value: "730000" }])
        temp.push([{ label: "January", value: "999" }, { label: "February", value: "810000" }, { label: "March", value: "720000" }, { label: "April", value: "550000" }, { label: "May", value: "910000" }, { label: "June", value: "510000" }, { label: "July", value: "680000" }, { label: "August", value: "620000" }, { label: "September", value: "610000" }, { label: "October", value: "490000" }, { label: "November", value: "900000" }, { label: "December", value: "730000" }])
        console.log('temp', temp)
        setTest(temp)
    }, [])

    return (<>
        {
            test.length > 0 && test.map((item, index) => {
                return <ReactFC
                    key={index}
                    type="sparkline"
                    width="200"
                    height="40"
                    dataFormat="JSON"
                    dataSource={spark(item)}
                />
            })

        }
    </>
    )
};