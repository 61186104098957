import React, { useState, useEffect } from "react";
import { sql, globalFilter, addToHistory } from "../../apis/utility";
import { useLocation } from "react-router-dom";
import SubjectTable from "../SubjectTable";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import AnnotationsModule from "highcharts/modules/annotations"
import { LookBelow } from "../layout/LookBelow";
AnnotationsModule(Highcharts)

export default function LBHysLaw(props) {
    const location = useLocation();
    addToHistory({ title: "Hys Law", url: location })
    const
        whereSubjects = props.selectedSubjects && props.selectedSubjects.length > 0
            ? 'and SUBJID in ("' + props.selectedSubjects.join('","') + '")'
            : '',
        [chartOptions, setChartOptions] = useState(null),
        contrastingColor = props.theme.palette.mode === 'dark' ? 'white' : 'black',
        [subjectsDrilledTo, setSubjectsDrilledTo] = useState(null),
        [levels, setLevels] = useState(null),
        sqlStatement = `SELECT SUBJID,
                                lbdy,
                                max(CASE WHEN lbtestcd = 'ALT' THEN value END) AS alt,
                                max(CASE WHEN lbtestcd = 'AST' THEN value END) AS ast,
                                max(CASE WHEN lbtestcd = 'BILI' or lbtestcd = 'TBILI' THEN value END) AS tbili
                        FROM (
                                    SELECT SUBJID,
                                        lbtestcd,
                                        lbdy,
                                        max(lbstresn) AS value
                                    FROM lb
                                    WHERE LBTESTCD IN ('ALT', 'AST', 'TBILI', 'BILI') 
                                    ${whereSubjects}
                                    GROUP BY SUBJID,
                                            lbdy,
                                            lbtestcd
                                )
                        GROUP BY SUBJID,
                                lbdy;`,
        [subtitle, setSubtitle] = useState(null)

    useEffect(() => {
        sql(props.studyDatabase, `SELECT DISTINCT LBTESTCD,
                    max(LBSTNRLO) AS LBSTNRLO,
                    max(LBSTNRHI) AS LBSTNRHI,
                    max(LBSTRESN) AS MAX,
                    max(LBSTRESU) as UNIT
            FROM lb
            WHERE LBTESTCD IN ('AST', 'ALT', 'TBILI', 'BILI') 
            ${whereSubjects}
            GROUP BY LBTESTCD;
            `)
            .then((res) => {
                if (res.message === "success") {
                    const tempLevels = []
                    res.data.forEach((row) => {
                        if (row.LBSTNRHI) {
                            tempLevels[row.LBTESTCD==='BILI' ? 'TBILI' : row.LBTESTCD] = {
                                low: row.LBSTNRLO,
                                high: row.LBSTNRHI,
                                max: row.MAX,
                                unit: row.UNIT
                            }
                        }
                    })
                    setLevels(tempLevels)
                }
            })
    }, [whereSubjects, props.studyDatabase]);

    useEffect(() => {
        setSubtitle(globalFilter(props.selectedOptions))
        if (levels) sql(props.studyDatabase, sqlStatement)
            .then((res) => {
                if (res.message === "success") {
                    const
                        // ulnAST=levels['AST'].high,
                        ulnALT = Number(levels['ALT'].high),
                        ulnTbili = Number(levels['TBILI'].high),
                        altData = res.data.map((item) => {
                            return [Number(item.alt) / ulnALT, Number(item.tbili) / ulnTbili, item.SUBJID]
                        }),
                        astData = res.data.map((item) => {
                            return [Number(item.ast) / ulnALT, Number(item.tbili) / ulnTbili]
                        }),
                        series = [
                            {
                                id: 'alt',
                                name: 'ALT vs TBili',
                                color: '#ff0000',
                                data: altData
                            },
                            {
                                id: 'ast',
                                name: 'AST vs. TBili',
                                color: '#00ff00',
                                data: astData
                            }
                        ],

                        // maxALT = levels['ALT'].max,
                        // maxAST = levels['AST'].max,
                        // maxALTAST = Math.max(maxALT, maxAST),
                        // maxTbili = levels['TBILI'].max,
                        altUnit = levels['ALT'].unit,
                        // astUnit = levels['AST'].unit,
                        tbiliUnit = levels['TBILI'].unit,
                        xLines = [],
                        yLines = []
                    // if (maxALTAST > ulnALT) xLines.push({
                    //     color: 'orange',
                    //     dashStyle: 'longdashdot',
                    //     value: ulnALT,
                    //     width: 2,
                    //     label: { style: { color: 'orange' }, text: "ULN", verticalAlign: "bottom" }
                    // })
                    // if (maxALTAST > 2 * ulnALT) xLines.push({
                    //     color: 'red',
                    //     dashStyle: 'longdashdot',
                    //     value: ulnALT * 2,
                    //     width: 2,
                    //     label: { style: { color: 'red' }, text: "2*ULN", verticalAlign: "bottom" }
                    // })
                    // if (maxALTAST > 3 * ulnALT)
                    xLines.push({
                        color: contrastingColor,
                        dashStyle: 'longdashdot',
                        value: 3,
                        width: 2,
                        label: { style: { color: contrastingColor }, text: "3 * ULN", verticalAlign: "top" }
                    })
                    // if (maxTbili > ulnTbili) yLines.push({
                    //     color: 'orange',
                    //     dashStyle: 'longdashdot',
                    //     value: ulnTbili,
                    //     width: 2,
                    //     label: { style: { color: 'orange' }, text: "ULN", align: "left" }
                    // })
                    // if (maxTbili > 2 * ulnTbili) 
                    yLines.push({
                        color: contrastingColor,
                        dashStyle: 'longdashdot',
                        value: 2,
                        width: 2,
                        label: { style: { color: contrastingColor }, text: "2 * ULN", align: "left" }
                    })
                    // if (maxTbili > 3 * ulnTbili) yLines.push({
                    //     color: 'orange',
                    //     dashStyle: 'longdashdot',
                    //     value: ulnTbili * 3,
                    //     width: 4,
                    //     label: { style: { color: 'red' }, text: "3*ULN", align: "left" }
                    // })
                    // console.log('altData', altData)
                    setChartOptions({
                        chart: {
                            "type": 'scatter',
                            "height": props.screenHeight * 0.75,
                            "zoomType": 'xy',
                            "backgroundColor": 'transparent',
                        },
                        tooltip: {
                            formatter: function () {
                                const x = this.point.series.name.split(' ')[0], y = this.point.series.name.split(' ')[2]
                                return x + ' = <b>' + this.x +
                                    '</b><br/>' + y + ' = <b>' + this.y + '</b>';
                            }
                        },
                        xAxis: {
                            min: 0.1,
                            max: 100,
                            type: 'logarithmic',
                            plotLines: xLines,
                            labels: { style: { color: contrastingColor } },
                            title: { text: 'ALT & AST (' + altUnit + ')', style: { color: contrastingColor } },
                        },
                        yAxis: {
                            min: 0.1,
                            max: 100,
                            type: 'logarithmic',
                            plotLines: yLines,
                            labels: { style: { color: contrastingColor } },
                            title: { text: 'TBILI (' + tbiliUnit + ')', style: { color: contrastingColor } },
                        },
                        series: series,
                        legend: { itemStyle: { color: contrastingColor } },
                        title: { text: `Hys Law (${props.study})`, style: { color: contrastingColor } },
                        subtitle: { text: subtitle, style: { color: contrastingColor } },
                        annotations: [{
                            labels: [{
                                point: {
                                    x: 40,
                                    y: 40,
                                    xAxis: 0,
                                    yAxis: 0
                                },
                                text: "Hy's Law Range",
                            },
                            {
                                point: {
                                    x: 40,
                                    y: 0.2,
                                    xAxis: 0,
                                    yAxis: 0
                                },
                                text: "Temple's Corollary Range"
                            },
                            {
                                point: {
                                    x: 0.2,
                                    y: 40,
                                    xAxis: 0,
                                    yAxis: 0
                                },
                                text: "Hyperbillirubinemia"
                            }],
                            labelOptions: {
                                borderRadius: 5,
                                backgroundColor: 'rgba(252, 255, 197, 0.7)',
                                borderWidth: 1,
                                borderColor: '#AAA'
                            }
                        }],
                        plotOptions: {
                            column: { colorByPoint: true },
                            scatter: {
                                dataLabels: { enabled: false, style: { color: contrastingColor } },
                                point: {
                                    events: {
                                        click: function (e) {
                                            const index = e.point.index,
                                                SUBJID = altData[index][2]
                                            // console.log('SUBJID', SUBJID, ' e.point', e.point);
                                            setSubjectsDrilledTo([SUBJID]);
                                        }
                                    }
                                }
                            }
                        },
                        credits: { enabled: false }
                    })
                }
            })
        // eslint-disable-next-line
    }, [props.selectedSubjects, props.selectedOptions, subtitle, sqlStatement, levels, contrastingColor])
    // console.log('chartOptions', chartOptions)

    return (<>
        {chartOptions
            ? <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
            : "loading"}
        {subjectsDrilledTo &&
            <LookBelow />
        }
        {subjectsDrilledTo &&
            <SubjectTable
                screenHeight={props.screenHeight}
                selectedSubjects={subjectsDrilledTo}
                study={props.study}
                studyDatabase={props.studyDatabase}
                selectedOptions={props.selectedOptions} />}
    </>)
}