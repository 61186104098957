import React from "react";
import {
  AppBar,
  Toolbar,
  Tooltip,
  Container,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MainMenu from "./MainMenu";
// import VDMenu from './VDMenu';
// import DMMenu from './DMMenu';
// import DSMenu from './DSMenu';
// import AEMenu from './AEMenu';
// import VSMenu from './VSMenu';
// import LBMenu from './LBMenu';
import Save from "./Save";
import Load from "./Load";
import Clear from "./Clear";
import { Filter } from "./Filter";
import HomeIconLink from "./HomeIconLink";
import EcrfLink from "./EcrfLink";
import SubjectIconLink from "./SubjectIconLink";
import SlidingDrawer from "./SlidingDrawer";
import DrawerContents from "./DrawContents";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import PreferencesIconLink from "./PreferencesIconLink";
import logo from "../../assets/images/logo.png";
import InfoIcon from "@mui/icons-material/Info";
import CreateIcon from "@mui/icons-material/Create";
import VisibilityIcon from "@mui/icons-material/Visibility";
import OverviewLink from "./OverviewLink";
import QueryIconLink from "./QueryIconLink";

export const TopMenu = (props) => {
  const {
      userName,
      userType,
      setOpenInfoModal,
      setOpenMakeNotesModal,
      setOpenViewNotesModal,
    } = props,
    location = useLocation(),
    navigate = useNavigate(),
    infoAboutCurrentScreen = () => {
      console.log(location.pathname);
      setOpenInfoModal(true);
    },
    makeNote = () => {
      setOpenMakeNotesModal(true);
    },
    viewNote = () => {
      setOpenViewNotesModal(true);
    };

  return (
    <AppBar
      sx={{
        width: "100%",
        // position: "fixed",
      }}
      position="sticky"
      color="info"
      enableColorOnDark
    >
      <Container maxWidth={false}>
        <Toolbar variant="dense" disableGutters>
          <MainMenu listOfStudies={props.listOfStudies} />
          <Tooltip
            title={
              userName
                ? `You're logged in as ${userName} the ${userType}`
                : `You're not logged in`
            }
          >
            <img src={logo} width="125" alt="logo" />
          </Tooltip>
          <Divider
            orientation="vertical"
            sx={{ flexGrow: 0.1 }}
            flexItem
          ></Divider>
          <OverviewLink />
          <HomeIconLink />
          <EcrfLink />
          <Divider
            orientation="vertical"
            sx={{ flexGrow: 1 }}
            flexItem
          ></Divider>
          <Tooltip title="Make a note for this screen">
            <IconButton
              onClick={() => {
                makeNote();
              }}
            >
              <CreateIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="View notes for this screen">
            <IconButton
              onClick={() => {
                viewNote();
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <SubjectIconLink />
          <QueryIconLink />
          <PreferencesIconLink />
          <Tooltip title="Swith between dark and light mode">
            <IconButton sx={{ ml: 1 }} onClick={props.setTheme}>
              {props.theme.palette.mode === "dark" ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Info about this screen">
            <IconButton
              onClick={() => {
                infoAboutCurrentScreen(location);
              }}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" sx={{ flexGrow: 0.1 }}></Divider>
          <Save
            study={props.study}
            studyDatabase={props.studyDatabase}
            selectedOptions={props.selectedOptions}
          />
          <Load
            setSelectedOptions={props.setSelectedOptions}
            setStudy={props.setStudy}
            studyDatabase={props.studyDatabase}
            setStudyDatabase={props.setStudyDatabase}
            optionsForSelection={props.optionsForSelection}
            varToTable={props.varToTable}
            setSelectedSubjects={props.setSelectedSubjects}
            selectedOptions={props.selectedOptions}
            // selectedSubjects={props.selectedSubjects}
          />
          <Clear
            setSelectedOptions={props.setSelectedOptions}
            studyDatabase={props.studyDatabase}
            optionsForSelection={props.optionsForSelection}
            varToTable={props.varToTable}
            setSelectedSubjects={props.setSelectedSubjects}
            selectedOptions={props.selectedOptions}
          />
          <Filter
            selectedOptions={props.selectedOptions}
            studyDatabase={props.studyDatabase}
            setSelectedOptions={props.setSelectedOptions}
            setSelectedSubjects={props.setSelectedSubjects}
            optionsForSelection={props.optionsForSelection}
            varToTable={props.varToTable}
          />
          <Divider
            orientation="vertical"
            sx={{ ml: 1, mr: 1, flexGrow: 1 }}
            flexItem
          ></Divider>
          <Tooltip title="Adverse Events">
            <Button
              variant="contained"
              sx={{
                p: 1,
                bgcolor: "red",
                fontSize: 10,
                mr: 1,
                "&:hover": { color: "yellow" },
              }}
              disabled={props.showAe !== "Y"}
              onClick={() => {
                navigate("/aeboth");
              }}
            >
              Adverse Events
            </Button>
          </Tooltip>
          <Tooltip title="Labs">
            <Button
              variant="contained"
              sx={{
                p: 1,
                bgcolor: "blue",
                fontSize: 10,
                mr: 1,
                "&:hover": { color: "yellow" },
              }}
              disabled={props.showLb !== "Y"}
              onClick={() => {
                navigate("/lbboxplot");
              }}
            >
              Labs
            </Button>
          </Tooltip>
          <Tooltip title="Vital Signs">
            <Button
              variant="contained"
              sx={{
                p: 1,
                bgcolor: "orange",
                fontSize: 10,
                mr: 1,
                "&:hover": { color: "yellow" },
              }}
              disabled={props.showVs !== "Y"}
              onClick={() => {
                navigate("/vsboxplot");
              }}
            >
              Vital Signs
            </Button>
          </Tooltip>
          <Tooltip title="Disposition">
            <Button
              variant="contained"
              sx={{
                p: 1,
                bgcolor: "aqua",
                color: "black",
                fontSize: 10,
                mr: 1,
                "&:hover": { color: "yellow" },
              }}
              disabled={props.showDs !== "Y"}
              onClick={() => {
                navigate("/dssankey");
              }}
            >
              Disposition
            </Button>
          </Tooltip>
          <Tooltip title="Demography">
            <Button
              variant="contained"
              sx={{
                p: 1,
                bgcolor: "green",
                fontSize: 10,
                mr: 1,
                "&:hover": { color: "yellow" },
              }}
              disabled={props.showDm !== "Y"}
              onClick={() => {
                navigate("/dminfo");
              }}
            >
              Demography
            </Button>
          </Tooltip>
          {/* <VDMenu theme={theme} links={links} listOfTables={props.listOfTables} /> 
          <DMMenu theme={theme} links={links} listOfTables={props.listOfTables} />
          <DSMenu theme={theme} links={links} listOfTables={props.listOfTables} />
          <AEMenu theme={theme} links={links} listOfTables={props.listOfTables} />
          <VSMenu theme={theme} links={links} listOfTables={props.listOfTables} />
          <LBMenu theme={theme} links={links} listOfTables={props.listOfTables} /> */}
          <SlidingDrawer
            anchor="right"
            title="Options"
            contents={
              <DrawerContents
                study={props.study}
                setStudy={props.setStudy}
                setShowAe={props.setShowAe}
                setShowCm={props.setShowCm}
                setShowDm={props.setShowDm}
                setShowLb={props.setShowLb}
                setShowMh={props.setShowMh}
                setShowVs={props.setShowVs}
                setShowDs={props.setShowDs}
                setShowVd={props.setShowVd}
                setDrillDownPath={props.setDrillDownPath}
                listOfTables={props.listOfTables}
                setStudyDatabase={props.setStudyDatabase}
                studyDatabase={props.studyDatabase}
                selectedOptions={props.selectedOptions}
                setSelectedOptions={props.setSelectedOptions}
                optionsForSelection={props.optionsForSelection}
                varToTable={props.varToTable}
                selectedSubjects={props.selectedSubjects}
                setSelectedSubjects={props.setSelectedSubjects}
                listOfStudies={props.listOfStudies}
                screenHeight={props.screenHeight}
                setScreenHeight={props.setScreenHeight}
                screenWidth={props.screenWidth}
                setScreenWidth={props.setScreenWidth}
                normalRanges={props.normalRanges}
                setNormalRanges={props.setNormalRanges}
                alerts={props.alerts}
                alertResults={props.alertResults}
                setAlertResults={props.setAlertResults}
                variableLabels={props.variableLabels}
                setAlerts={props.setAlerts}
                drillDownPath={props.drillDownPath}
                labCategories={props.labCategories}
              />
            }
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
