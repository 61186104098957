import React, { useState, useEffect } from "react";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Tooltip, Fab, Snackbar, Grid, FormControl, InputLabel, Select, MenuItem, } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SubjectTable from "../SubjectTable";
import { dataToStreamOptions, globalFilter, makeLBBOTArray, addToHistory, round } from "../../apis/utility";
import addStreamgraphModule from 'highcharts/modules/streamgraph';
import addSeriesLabel from 'highcharts/modules/series-label';
import addAccessibility from 'highcharts/modules/accessibility';
import { useLocation } from "react-router-dom";
import { LookBelow } from "../layout/LookBelow";

export default function LBBoth(props) {
    const location = useLocation();
    addToHistory({ title: "LB BOTh", url: location })
    addStreamgraphModule(Highcharts);
    addSeriesLabel(Highcharts);
    addAccessibility(Highcharts);

    const
        contrastingColor = props.theme.palette.mode === 'dark' ? 'white' : 'black',
        [showSubjects, setShowSubjects] = useState(false),
        [dataForChart, setDataForChart] = useState(null),
        [drillVars, setDrillVars] = useState([]),
        [subjectsDrilledTo,] = useState([]),
        [, setWhereClause] = useState(1),
        [snackBarOpen, setSnackBarOpen] = useState(false),
        [newOptions, setNewOptions] = useState(null),
        [chartOptions, setChartOptions] = useState(null),
        [minX, setMinX] = useState(null),
        [maxX, setMaxX] = useState(null),
        [, setMinY] = useState(null),
        [maxY, setMaxY] = useState(null),
        [arms, setArms] = useState([]),
        [drillDownLevel, setDrillDownLevel] = useState(0), // define the drilldown level here
        [drillDownPath,] = useState(['category', 'lbtestcd']), // define the drilldown path here
        [classVar, setClassVar] = useState('category'),
        whereSubjects = props.selectedSubjects.length > 0
            ? 'where SUBJID in ("' + props.selectedSubjects.join('","') + '")'
            : '',
        drillUp = () => {
            setDrillVars(drillVars.slice(0, drillDownLevel - 1));
            setDrillDownLevel(drillDownLevel - 1);
            setClassVar(drillDownPath[drillDownLevel - 1]);
            setShowSubjects(false);
        },
        top = () => {
            setDrillVars(drillVars.slice(0, 0));
            setDrillDownLevel(0);
            setClassVar(drillDownPath[0]);
            setShowSubjects(false);
        },
        whatHaveIDrilledDownTo = () => {
            const tempWhereClause = []
            for (let i = 0; i < drillDownLevel; i++) {
                tempWhereClause.push(drillDownPath[i] + '="' + drillVars[i] + '"')
            }
            return tempWhereClause.join(' and ')
        },
        [selectedArm, setSelectedArm] = useState(null),
        [upperSelectedArm, setUpperSelectedArm] = useState('PCBO')

    useEffect(() => {
        if (props.studyDatabase) makeLBBOTArray(props.studyDatabase, classVar, whereSubjects, drillDownLevel, drillDownPath, drillVars)
            .then((res) => {
                // console.log('res: ', res);
                if (res && res.min) setMinX(res.min)
                else setMinX(0)
                if (res && res.max) setMaxX(res.max);
                setWhereClause(res.tempWhereClause);
                const orderedArms = Object.keys(res.values),
                    position = orderedArms.indexOf(upperSelectedArm),
                    to = 0
                // console.log('position: ', position)
                orderedArms.splice(to, 0, orderedArms.splice(position, 1)[0]); // put the default one at the top of the list
                // setArms(orderedArms)
                setArms(orderedArms);
                if (!('PCBO' in orderedArms)) setUpperSelectedArm(orderedArms[0])
                setSelectedArm(orderedArms[1])
                // setDataForChart(res.values[upperSelectedArm]);
                setDataForChart(res.values);
            })
        // eslint-disable-next-line
    }, [classVar, whereSubjects, props.studyDatabase, drillDownLevel])

    useEffect(() => {
        if (dataForChart) {
            // console.log('dataForChart', dataForChart);
            let streamOptions = {}
            Object.keys(dataForChart).forEach(arm => {
                // console.log('arm', arm);
                streamOptions[arm] = dataToStreamOptions(dataForChart[arm], classVar, minX, maxX,
                    {
                        MILD: '#99ccff', MODERATE: '#ffb84d', SEVERE: '#ff4d4d',
                        T1: '#99ccff', T2: '#DC9492', T3: '#ffb84d', T4: '#ff4d4d', T5: '#000000'
                    }, // specify colors to use for values of category variable
                    {
                        MILD: 2, MODERATE: 1, SEVERE: 0,
                        T1: 4, T2: 3, T3: 2, T4: 1, T5: 0
                    }, // specify index to use for values of category variable, 0 is top subgroup
                    false, // specify whether to invert values or not
                    props.theme)
            })
            // console.log('streamOptions', streamOptions);
            setNewOptions(streamOptions);
            // work out the min and max values for the y axis
            const tempMin = {}, tempMax = {}
            Object.keys(streamOptions).forEach(arm => {
                const armObject = streamOptions[arm]
                // console.log('armObject', armObject)
                const values = armObject.series.map((item) => item.data.map((obj) => obj.y))
                // add values for each day
                const numberOfCategories = values.length,
                    numberOfCategoryValues = values[0].length,
                    totalsByCategory = []
                for (let i = 0; i < numberOfCategories; i++) {
                    for (let j = 0; j < numberOfCategoryValues; j++) {
                        totalsByCategory[j] = totalsByCategory[j] ? totalsByCategory[j] + values[i][j] || 0 : values[i][j] || 0
                    }
                }
                // console.log('arm - totalsByCategory', totalsByCategory)
                // const merged = Array.prototype.concat.apply([], values).map((item) => item || 0)
                // console.log('arm', arm, 'values', values, 'merged', merged)
                tempMin[arm] = Math.ceil(Math.min(...totalsByCategory) * 10) / 10
                tempMax[arm] = Math.ceil(Math.max(...totalsByCategory) * 10) / 10
            })
            // console.log('tempMin', tempMin, 'tempMax', tempMax)
            setMinY(tempMin)
            setMaxY(tempMax)
        }
        // eslint-disable-next-line
    }, [dataForChart, classVar, minX, maxX])

    useEffect(() => {
        const subtitle = globalFilter(props.selectedOptions),
            commonOptions = {
                chart: {
                    type: 'column',
                    marginBottom: 25,
                    height: props.screenHeight * 0.375,
                    zoomType: 'xy',
                    backgroundColor: 'transparent',
                    "legendPosition": "top-right",
                    "legendAllowDrag": "1"
                },
                // colors: ['#ffc2b3', '#e6e6ff'],
                title: { text: `Adverse Events (${props.study})`, style: { color: contrastingColor } },
                subtitle: { text: subtitle + '<br/>' + whatHaveIDrilledDownTo(), style: { color: contrastingColor } },
                plotOptions: {
                    column: {
                        groupPadding: 0,
                        pointPadding: 0,
                    },
                    series: {
                        stacking: 'normal',
                        label: {
                            minFontSize: 5,
                            maxFontSize: 15,
                            style: {
                                color: contrastingColor
                            }
                        },
                        accessibility: {
                            exposeAsGroupOnly: true
                        },
                        point: {
                            events: {
                                click: function (e) {
                                    // console.log('e', e, 'e.point.name', e.point.name,
                                    //     'drillDownLevel', drillDownLevel, 'drillDownPath', drillDownPath,
                                    //     'drillVars', drillVars)
                                    setShowSubjects(true);
                                    if (drillDownLevel < drillDownPath.length) {
                                        setClassVar(drillDownPath[drillDownLevel + 1]);
                                        setDrillDownLevel(drillDownLevel + 1);
                                        setDrillVars([...drillVars, e.point.name]);
                                    }
                                    // const enhancedWhereClause = whereClause + ' and ' +
                                    //     drillDownPath.slice(-1)[0] + '="' + this.options.name + '"'
                                    // console.log('enhancedWhereClause', enhancedWhereClause)
                                    // sql(props.studyDatabase, `select distinct SUBJID from lb ${enhancedWhereClause}`)
                                    //     .then((res) => {
                                    //         if (res.message === "success") {
                                    //             const subjectData = res.data.map(d => d.SUBJID)
                                    //             setSubjectsDrilledTo(subjectData);
                                    //         }
                                    //     })
                                    // }
                                }
                            }
                        }
                    }
                },
                yAxis: {
                    visible: true,
                    startOnTick: false,
                    endOnTick: false,
                    title: { text: "Burden of Toxicity", style: { color: contrastingColor } },
                    min: 0,
                    max: maxY && maxY[upperSelectedArm] && maxY[selectedArm] ? Math.max(maxY[upperSelectedArm], maxY[selectedArm]) : undefined,
                    labels: { style: { color: contrastingColor } }
                },
                legend: {
                    enabled: true,
                    floating: true,
                },
                tooltip: {
                    formatter: function () {
                        return '<b>' + this.series.name + ',</b><br/> ' +
                            'Day = ' + this.point.category + '<br/>' +
                            'Weighted % of Patients with Lab AEs: ' + Highcharts.numberFormat(round(this.point.y, 1), 1);
                    }
                },
                credits: { enabled: false }
            },
            updatedOptions = {},
            upperColors = { MILD: '#ccffcc', MODERATE: '#00FF00', SEVERE: '#009933' },
            lowerColors = { MILD: '#ccebff', MODERATE: '#0099ff', SEVERE: '#0000ff' }

        // console.log('newOptions', newOptions);
        if (newOptions) {
            Object.keys(newOptions).forEach(arm => {
                updatedOptions[arm] = { ...commonOptions, ...newOptions[arm] }
                updatedOptions[arm].title = { text: `Labs Burden of Toxicity (${arm}) (${props.study})`, style: { color: contrastingColor } }
                if (arm !== upperSelectedArm) {
                    updatedOptions[arm].series.forEach(series => { series.color = lowerColors[series.name] })
                    const titlemods = {
                        verticalAlign: 'bottom',
                        y: -10,
                        floating: true
                    }
                    const chartmods = {
                        marginBottom: 75,
                        "legendPosition": "bottom",
                    },
                        xAxismods = {
                            title: { text: null },
                            enabled: false,
                            visible: false,
                            labels: { enabled: false }
                        },
                        yAxismods = { reversed: true, min: 0, max: Math.max(maxY[upperSelectedArm], maxY[selectedArm]) }
                    updatedOptions[arm].title = { ...updatedOptions[arm].title, ...titlemods }
                    updatedOptions[arm].chart = { ...updatedOptions[arm].chart, ...chartmods }
                    updatedOptions[arm].xAxis = { ...updatedOptions[arm].xAxis, ...xAxismods }
                    updatedOptions[arm].yAxis = { ...updatedOptions[arm].yAxis, ...yAxismods }
                }
                else {
                    updatedOptions[arm].series.forEach(series => { series.color = upperColors[series.name] })
                }
            })
            setChartOptions(updatedOptions)
            // console.log("chartOptions", updatedOptions);
        }
        // eslint-disable-next-line
    }, [newOptions, selectedArm, upperSelectedArm, contrastingColor])

    // console.log('classVar', classVar, 'drillVars', drillVars, 'drillDownLevel', drillDownLevel, 'drillDownPath', drillDownPath)

    return (<>
        {chartOptions && chartOptions[upperSelectedArm] &&
            <Grid container spacing={3}>
                <Grid item xs={12} key={'upper'}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions[upperSelectedArm]}
                        immutable={true}
                    />
                </Grid>
            </Grid>}
        {chartOptions && chartOptions[selectedArm] &&
            <Grid container spacing={3}>
                <Grid item xs={12} key={'lower'}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions[selectedArm]}
                        immutable={true}
                    />
                </Grid>
            </Grid>}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="upper-arm-label">Upper Arm</InputLabel>
            {arms && arms.length > 0 && upperSelectedArm && <Select
                labelId="upper-arm-label"
                id="upper-arm-select"
                value={upperSelectedArm ? upperSelectedArm : ''}
                label="Upper Arm"
                onChange={(event) => { setUpperSelectedArm(event.target.value); }}
            >
                {arms && arms.length > 0 && arms.map((arm, i) => <MenuItem key={'up' + i} value={arm}>{arm}</MenuItem>)}
            </Select>}
        </FormControl>
        {arms && arms.length > 1 &&
            <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                <InputLabel id="lower-arm-label">Lower Arm</InputLabel>
                {arms && arms.length > 0 && selectedArm && <Select
                    labelId="lower-arm-label"
                    id="lower-arm-select"
                    value={selectedArm ? selectedArm : ''}
                    label="Lower Arm"
                    onChange={(event) => { setSelectedArm(event.target.value); }}
                >
                    {arms && arms.length > 0 && arms.map((arm, i) => <MenuItem key={'low' + i} value={arm}>{arm}</MenuItem>)}
                </Select>}
            </FormControl>}
        {
            drillDownLevel > 0
                ? <span style={{ position: "fixed", bottom: '100px', right: '40px' }}>
                    <Tooltip title="Level we have drilled down to">
                        <Fab size="small" variant='extended' color="info" sx={{ margin: 1 }} onClick={drillUp}>
                            Level {drillDownLevel}
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Drill up">
                        <Fab size="small" color="info" sx={{ margin: 1 }} onClick={drillUp}>
                            <ArrowDropUpIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Go to top level">
                        <Fab size="small" color="info" sx={{ margin: 1 }} onClick={top}>
                            <ArrowUpwardIcon />
                        </Fab>
                    </Tooltip>
                </span>
                : null
        }
        {showSubjects &&
            <LookBelow label="Subject Table" tooltip="Click to scroll to subject table appears below" mt={2} ml={4} mr={0} mb={0} />
        }
        {
            showSubjects &&
            <SubjectTable selectedSubjects={subjectsDrilledTo}
                study={props.study}
                studyDatabase={props.studyDatabase}
                selectedOptions={props.selectedOptions}
                screenHeight={props.screenHeight} />
        }
        <Snackbar message={"Can't drill any lower"} open={snackBarOpen} onClose={() => setSnackBarOpen(false)} />
    </>
    );
}