import React, { useState, useEffect } from "react";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { sql, globalFilter, addToHistory } from "../../apis/utility";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

export function TestTable(props) {
    const location = useLocation();
    addToHistory({ title: "Test Table", url: location })
    const [vsData, setVsData] = useState([]),
        [subtitle, setSubtitle] = useState(null),
        [columns, setColumns] = useState(null)

    useEffect(() => {
        const where = props.selectedSubjects.length > 0
            ? ' SUBJID in ("' + props.selectedSubjects.join('","') + '")'
            : ''
        setSubtitle(globalFilter(props.selectedOptions))
        sql(props.studyDatabase, `SELECT SUBJID as id,
                    ae, dm, ds, lb, vd, vs,
                    serious as a1, moderate as a2, severe as a3, possible as a4, ae as a5, dm  as a6, ds as a7, lb as a8, vd as a9, vs as a10,
                    serious, moderate, severe, possible, probable
                    FROM subjects ${where}`)
            .then((res) => {
                // console.log('res', res)
                if (res.message === "success") {
                    const tempData = res.data.map((row, id) => { return { ...row, id: id } })
                    setVsData(tempData)
                }
            })
    }, [props.studyDatabase, props.selectedOptions, props.selectedSubjects])

    useEffect(() => {
        const getValues = (data, variable) => {
            return data.map((row) => row[variable]).filter((value) => Number.isFinite(value))
        },
            renderProgress = (cellValues, variable) => {
                const { value } = cellValues,
                    flex = (value - min[variable]) / (max[variable] - min[variable])
                return <Box sx={{ flex: { flex }, backgroundColor: 'info.main', color: 'white' }} >{value}</Box >

            }
        const pulseValues = getValues(vsData, 'PULSE'),
            opulseValues = getValues(vsData, 'OPULSE'),
            sysbpValues = getValues(vsData, 'SYSBP'),
            osysbpValues = getValues(vsData, 'OSYSBP'),
            diabpValues = getValues(vsData, 'DIABP'),
            odiabpValues = getValues(vsData, 'ODIABP'),
            min = [],
            max = []
        min['PULSE'] = Math.min(...pulseValues)
        max['PULSE'] = Math.max(...pulseValues)
        min['OPULSE'] = Math.min(...opulseValues)
        max['OPULSE'] = Math.max(...opulseValues)
        min['SYSBP'] = Math.min(...sysbpValues)
        max['SYSBP'] = Math.max(...sysbpValues)
        min['OSYSBP'] = Math.min(...osysbpValues)
        max['OSYSBP'] = Math.max(...osysbpValues)
        min['DIABP'] = Math.min(...diabpValues)
        max['DIABP'] = Math.max(...diabpValues)
        min['ODIABP'] = Math.min(...odiabpValues)
        max['ODIABP'] = Math.max(...odiabpValues)
        const cols = [
            { width: 80, headerName: 'Subject', field: 'SUBJID' },
            { width: 50, headerName: 'Day', field: 'day' },
            { width: 90, headerName: 'Visit', field: 'VISIT' },
            { width: 140, headerName: 'Date', field: 'VSDTC' },
            {
                width: 105, headerName: 'Pulse', field: 'PULSE',
                renderCell: (cellValues) => { return renderProgress(cellValues, 'PULSE') }
            },
            {
                width: 105, headerName: 'Orth. Pulse', field: 'OPULSE',
                renderCell: (cellValues) => { return renderProgress(cellValues, 'OPULSE') }
            },
            {
                width: 105, headerName: 'Systolic BP', field: 'SYSBP',
                renderCell: (cellValues) => { return renderProgress(cellValues, 'SYSBP') }
            },
            {
                width: 105, headerName: 'Ortho. Systolic BP', field: 'OSYSBP',
                renderCell: (cellValues) => { return renderProgress(cellValues, 'DIABP') }
            },
            {
                width: 105, headerName: 'Diastolic BP', field: 'DIABP',
                renderCell: (cellValues) => { return renderProgress(cellValues, 'DIABP') }
            },
            {
                width: 105, headerName: 'Ortho. Diastolic BP', field: 'ODIABP',
                renderCell: (cellValues) => { return renderProgress(cellValues, 'DIABP') }
            }
        ]
        setColumns(cols)
    }, [vsData])
    return (<>
        <h4>Vital Signs<br />
            <span style={{ fontSize: 12 }}>{subtitle}</span></h4>
        {vsData && columns
            ? <div style={{ height: 650, width: "100%" }}>
                <DataGridPro
                    rows={vsData}
                    columns={columns}
                    density={"compact"}
                    rowHeight={30}
                    sx={{ fontSize: '0.7em' }}
                />
            </div>
            : "Loading..."
        }
    </>
    );
}