import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  // Divider,
  TextField,
  Box,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { addToHistory, sql, openSaveFileDialog } from "../../apis/utility";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { usePapaParse } from "react-papaparse";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export const Query = (props) => {
  const location = useLocation(),
    // serverUrl = window.location.host.startsWith("localhost")
    //   ? "http://localhost:3001"
    //   : "http://134.122.110.66,
    [sqlQuery, setSqlQuery] = useState(""),
    [columns, setColumns] = useState(null),
    [resultMessage, setResultMessage] = useState(null),
    [result, setResult] = useState(null),
    { jsonToCSV } = usePapaParse(),
    runQuery = () => {
      setResultMessage(null);
      setResult(null);
      sql(props.studyDatabase, sqlQuery).then((res) => {
        console.log("res", res);
        setResultMessage(res.message);
        if (res.data.length > 0) {
          const tempData = res.data.map((row, id) => {
            return { ...row, id: id };
          });
          // console.log('tempData', tempData);
          setResult(tempData);

          const keys = Object.keys(res.data[0]),
            columns = [];
          keys.forEach((key, index) => {
            const headerName = key;
            columns.push({
              headerName: headerName,
              field: key,
              renderCell: (cellValues) => {
                const { value, field } = cellValues;
                if (field === "SUBJID") {
                  const target = `${window.location.protocol}//${window.location.host}/#/patientprofile/${props.studyDatabase}/${value}`;
                  return (
                    <Button
                      onClick={() => {
                        window.open(target);
                      }}
                    >
                      {value}
                    </Button>
                  );
                } else if (field.toUpperCase() === "SEX") {
                  const color = value === "F" ? "red" : "blue";
                  return (
                    <b>
                      <span style={{ color: color }}>{value}</span>
                    </b>
                  );
                } else if (
                  [
                    "PKSET",
                    "PDSET",
                    "EXPOSED",
                    "RANDOMIZED",
                    "SAF",
                    "DSCPFL",
                    "DSADFL",
                    "AESER",
                    "AETE",
                    "CMONGO",
                    "LBBLFL",
                    "LBFAST",
                    "ANALYSIS",
                    "MHONGO",
                    "VSBLFL",
                    "VSDRVFL",
                  ].includes(field.toUpperCase())
                ) {
                  const color = ["Y", "YES"].includes(value.toUpperCase())
                    ? "green"
                    : "red";
                  return (
                    <b>
                      <span style={{ color: color }}>{value}</span>
                    </b>
                  );
                } else if (["AESEV"].includes(field.toUpperCase())) {
                  const color = ["MILD"].includes(value.toUpperCase())
                    ? "green"
                    : ["MODERATE"].includes(value.toUpperCase())
                    ? "orange"
                    : "red";
                  return (
                    <b>
                      <span style={{ color: color }}>{value}</span>
                    </b>
                  );
                } else if (["AEREL"].includes(field.toUpperCase())) {
                  const color = ["NOT RELATED"].includes(value.toUpperCase())
                    ? "green"
                    : ["POSSIBLE"].includes(value.toUpperCase())
                    ? "orange"
                    : "red";
                  return (
                    <b>
                      <span style={{ color: color }}>{value}</span>
                    </b>
                  );
                } else if (
                  ["LBNRIND", "LBLNRIND"].includes(field.toUpperCase())
                ) {
                  const color = ["NORMAL"].includes(value.toUpperCase())
                    ? "green"
                    : ["LOW"].includes(value.toUpperCase())
                    ? "blue"
                    : ["HIGH"].includes(value.toUpperCase())
                    ? "red"
                    : "black";
                  return (
                    <b>
                      <span style={{ color: color }}>{value}</span>
                    </b>
                  );
                }
                return value;
              },
            });
          });
          setColumns(columns);
        }
      });
    },
    exportData = () => {
      openSaveFileDialog(jsonToCSV(result), "customQuery.csv", "text/csv");
    };

  addToHistory({ title: "Sql", url: location });

  useEffect(() => {
    console.log("result", result, "columns", columns, "props", props);
  }, [columns, result, props]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ mt: 1, mb: 1 }}>
            {result && result.length > 0 ? (
              <Tooltip title="Download table">
                <IconButton color="primary" onClick={exportData}>
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            {`Query for ${props.study} (${props.studyDatabase})`}
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <TextField
            label="Enter a SQL Query"
            value={sqlQuery}
            onChange={(event) => setSqlQuery(event.target.value)}
            fullWidth
            multiline={true}
            maxRows={40}
          />
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            color="primary"
            sx={{ m: 2 }}
            onClick={runQuery}
          >
            Run
          </Button>
          {resultMessage && (
            <Box
              sx={{
                fontWeight: 700,
                color: resultMessage === "success" ? "green" : "red",
                mb: 1,
                ml: 2,
              }}
            >{`${resultMessage}`}</Box>
          )}
        </Grid>
      </Grid>
      {/* <Divider /> */}
      {result && result.length > 0 ? (
        <Box sx={{ height: props.screenHeight - 300, width: "100%" }}>
          <DataGridPro
            rows={result}
            columns={columns}
            rowHeight={30}
            density={"compact"}
            sx={{ fontSize: "0.7em" }}
          />
        </Box>
      ) : (
        <Typography sx={{ mt: 3, color: "blue" }}>
          Table of results will appear here
        </Typography>
      )}
    </>
  );
};
