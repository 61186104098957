import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { sql } from "../../apis/utility";
// import { saeReports, saeReports2, ciomsReports1 } from "../../apis/reports";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Tooltip,
} from "@mui/material";

export default function Overview(props) {
  const [rowsAE, setRowsAE] = useState(null),
    [rowsDM, setRowsDM] = useState(null),
    { selectedOptions, listOfStudies } = props,
    studyDatabase = "global",
    navigate = useNavigate(),
    handleClick = (
      id,
      filename,
      drillDownPath,
      ae,
      cm,
      dm,
      ds,
      lb,
      mh,
      vd,
      vs
    ) => {
      console.log(id, filename, drillDownPath, ae, cm, dm, ds, lb, mh, vd, vs);
      if (props.hasOwnProperty("setStudy")) {
        console.log(props);
        props.setStudy(id);
        props.setStudyDatabase(filename);
        // eslint-disable-next-line
        props.setDrillDownPath(eval(drillDownPath));
        props.setShowAe(ae);
        props.setShowCm(cm);
        props.setShowDm(dm);
        props.setShowDs(ds);
        props.setShowLb(lb);
        props.setShowMh(mh);
        props.setShowVd(vd);
        props.setShowVs(vs);
        navigate("/home");
      }
    };
  // console.log(props);

  useEffect(() => {
    // get AE combined summary info
    sql(studyDatabase, `SELECT * from summary_ae `).then((res) => {
      if (res.message === "success") {
        setRowsAE(res.data);
      }
    });
    // get DM combined summary info
    sql(studyDatabase, `SELECT * from summary_dm `).then((res) => {
      if (res.message === "success") {
        // console.log("rowsDM - res", res);
        setRowsDM(res.data);
      }
    });
  }, [selectedOptions, studyDatabase]);

  document.title = "Xploratum";
  // console.log('screenWidth', screenWidth, 'screenHeight', screenHeight)

  return (
    <Container sx={{ backgroundColor: "primary.light", mt: 2 }}>
      {rowsAE && rowsDM && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead
              style={{ fontWeight: "800", backgroundColor: "rgba(0,0,0,.9)" }}
            >
              <TableRow>
                <TableCell style={{ color: "white" }}>
                  Study (Database)
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: "white" }}>Subjects</span>
                  <br />
                  <span style={{ color: "lightgreen" }}>Male</span>
                  <br />
                  <span style={{ color: "lightgreen" }}>Female</span>
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: "lightgreen" }}>Arms</span>
                  <br /> <span style={{ color: "lightgreen" }}>PK set</span>
                  <br /> <span style={{ color: "lightgreen" }}>PD set</span>
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: "red" }}>Total AEs</span>
                  <br />
                  <span style={{ color: "red" }}>Not Recovered</span>
                  <br />
                  <span style={{ color: "red" }}>Recovered</span>
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: "red" }}>Severe</span>
                  <br />
                  <span style={{ color: "red" }}>Moderate</span>
                  <br />
                  <span style={{ color: "red" }}>Mild</span>
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: "red" }}>Probable</span>
                  <br />
                  <span style={{ color: "red" }}>Possible</span>
                  <br />
                  <span style={{ color: "red" }}>Not related</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ backgroundColor: "lightgray" }}>
              {rowsAE.map((rowAE) => {
                const thisAE = listOfStudies.filter(
                  (st) => st.filename === rowAE.database
                )[0];
                const rowDM = rowsDM.filter(
                  (st) => st.database === thisAE.filename
                )[0];
                // console.log("thisAE", thisAE, "rowDM", rowDM, "rowAE", rowAE);
                return (
                  <TableRow
                    key={rowAE.database}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Tooltip title={rowAE.database}>
                        <Button
                          // href="#/home"
                          onClick={() => {
                            console.log("clicked info was - ", thisAE);
                            handleClick(
                              thisAE.STUDYID,
                              thisAE.filename,
                              thisAE.drillDownPath,
                              thisAE.ae,
                              thisAE.cm,
                              thisAE.dm,
                              thisAE.ds,
                              thisAE.lb,
                              thisAE.mh,
                              thisAE.vd,
                              thisAE.vs
                            );
                          }}
                        >
                          {thisAE.STUDYID}
                        </Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                      <span style={{ color: "black" }}>{rowDM.subjects}</span>
                      <br />
                      <span style={{ color: "green" }}>{rowDM.sex_m}</span>
                      <br />
                      <span style={{ color: "green" }}>{rowDM.sex_f}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span style={{ color: "green" }}>{rowDM.arms}</span>
                      <br />
                      <span style={{ color: "green" }}>{rowDM.pkset}</span>
                      <br />
                      <span style={{ color: "green" }}>{rowDM.pdset}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span style={{ color: "red" }}>{rowAE.count}</span>
                      <br />
                      <span style={{ color: "red" }}>
                        {rowAE.not_recovered}
                      </span>
                      <br />
                      <span style={{ color: "red" }}>{rowAE.recovered}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span style={{ color: "red" }}>{rowAE.severe}</span>
                      <br />
                      <span style={{ color: "red" }}>{rowAE.moderate}</span>
                      <br />
                      <span style={{ color: "red" }}>{rowAE.mild}</span>
                    </TableCell>
                    <TableCell align="right">
                      <span style={{ color: "red" }}>{rowAE.probable}</span>
                      <br />
                      <span style={{ color: "red" }}>{rowAE.possible}</span>
                      <br />
                      <span style={{ color: "red" }}>{rowAE.not_related}</span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
}
