import React, { useState, useEffect } from "react";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { dir, getafile } from '../../apis/utility'
import { Typography } from "@mui/material";
import { saveAs } from "file-saver";

export const Files = (props) => {
    const [fileDirContents, setFileDirContents] = useState(null),
        [dataDirContents, setDataDirContents] = useState(null),
        [selected, setSelected] = React.useState([]),
        handleSelect = (event, nodeId) => {
            // console.log('event', event, 'nodeId', nodeId)
            setSelected(nodeId);
            if (nodeId.length > 0) {
                const folder = nodeId.split('-')[0],
                    index = nodeId.split('-')[1]
                // console.log('folder', folder, 'index', index)
                if (index) {
                    getafile(folder, dirContents[folder][index])
                        .then((response) => {
                            // console.log('response', response)
                            saveAs(response, dirContents[folder][index])
                        })
                }
            }
        },
        dirContents = {}
    dirContents.files = fileDirContents
    dirContents.data = dataDirContents

    useEffect(() => {
        dir('files')
            .then(res => {
                // console.log('res', res)
                setFileDirContents(res)
            })
        dir('data')
            .then(res => {
                // console.log('res', res)
                setDataDirContents(res)
            })
    }, [])

    return (<>
        <Typography variant="h6" gutterBottom>
            Download Files
        </Typography>
        <Typography variant="subtitle1" sx={{ opacity: 0.5 }} gutterBottom>
            We can place files into a number of directories so they can be downloaded from here.
        </Typography>
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            selected={selected}
            onNodeSelect={handleSelect}
            sx={{ height: 0.7 * props.screenHeight, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        >
            <TreeItem nodeId="dir1" key="dir1" label="Files">
                {fileDirContents && fileDirContents.map((item, index) => {
                    return (
                        <TreeItem nodeId={'files-' + index} key={'files-' + index} label={item}>
                        </TreeItem>
                    )
                })}
            </TreeItem>
            <TreeItem nodeId="dir2" key="dir2" label="Data">
                {dataDirContents && dataDirContents.map((item, index) => {
                    return (
                        <TreeItem nodeId={'data-' + index} key={'data-' + index} label={item}>
                        </TreeItem>
                    )
                })}
            </TreeItem>
        </TreeView>
    </>
    );
};
