import React, { useState, useEffect } from "react";
import { sql, globalFilter, addToHistory } from "../../apis/utility";
import { useLocation } from "react-router-dom";
import SubjectTable from "../SubjectTable";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import excelexport from "fusioncharts/fusioncharts.excelexport";
import Charts from "fusioncharts/fusioncharts.charts";
// import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel';
import { LookBelow } from "../layout/LookBelow";
ReactFC.fcRoot(FusionCharts, Charts, GammelTheme, excelexport);

export default function DSSankey(props) {
    const location = useLocation();
    addToHistory({ title: "DS Sankey", url: location })
    const
        whereSubjects = props.selectedSubjects.length > 0
            ? 'where SUBJID in ("' + props.selectedSubjects.join('","') + '")'
            : '',
        [subjectsDrilledTo, setSubjectsDrilledTo] = useState(null),
        sqlStatement1 = `SELECT count(CASE WHEN substr(visit,1,9) = 'SCREENING' THEN 1 END) AS screened
                        FROM vd
                        ${whereSubjects}`,
        sqlStatement2 = `SELECT count(CASE WHEN exposed = 'Yes' THEN 1 END) AS exposed,
                                count(CASE WHEN randomized = 'Yes' THEN 1 END) AS randomized,
                                count(CASE WHEN saf = 'Yes' THEN 1 END) AS safety
                        FROM dm
                        ${whereSubjects}`,
        sqlStatement3 = `SELECT armcd,
                                count( * ) AS total
                        FROM dm
                        ${whereSubjects}
                        GROUP BY armcd;`,
        sqlStatement4 = `SELECT dsdecod,
                        armcd,
                        count( * ) as total
                FROM (
                            SELECT SUBJID,
                                dsdecod
                            FROM ds
                            ${whereSubjects}
                        )
                        LEFT JOIN
                        (
                            SELECT SUBJID AS dmsub,
                                armcd
                            FROM dm
                            ${whereSubjects}
                        )
                        ON SUBJID = dmsub
                GROUP BY armcd,
                        dsdecod
                        ;`,
        [subtitle, setSubtitle] = useState(null),
        [links1, setLinks1] = useState(null),
        [links2, setLinks2] = useState(null),
        [links3, setLinks3] = useState(null),
        [links4, setLinks4] = useState(null),
        [nodes1, setNodes1] = useState(null),
        [nodes2, setNodes2] = useState(null),
        [nodes3, setNodes3] = useState(null),
        [nodes4, setNodes4] = useState(null),
        [nodes, setNodes] = useState(null),
        [links, setLinks] = useState(null)

    useEffect(() => {
        sql(props.studyDatabase, sqlStatement1)
            .then((res) => {
                if (res.message === "success") {
                    // console.log('res.data 1', res.data)
                    setNodes1(Object.keys(res.data[0]).map((key) => { return { label: key } }))
                    setLinks1(Object.keys(res.data[0]).map((key) => { return { from: 'Screening', to: key, value: res.data[0][key] } }))
                }
            })
    }, [props.studyDatabase, sqlStatement1])
    useEffect(() => {
        sql(props.studyDatabase, sqlStatement2)
            .then((res) => {
                if (res.message === "success") {
                    // console.log('res.data 2', res.data)
                    const exposed = res.data[0].exposed,
                        randomized = res.data[0].randomized,
                        safety = res.data[0].safety
                    setNodes2(Object.keys(res.data[0]).map((key) => { return { label: key } }))
                    setLinks2([{ from: 'screened', to: 'randomized', value: randomized },
                    { from: 'randomized', to: 'safety', value: safety },
                    { from: 'safety', to: 'exposed', value: exposed }])
                }
            })
    }, [props.studyDatabase, sqlStatement2])
    useEffect(() => {
        sql(props.studyDatabase, sqlStatement3)
            .then((res) => {
                if (res.message === "success") {
                    // console.log('res.data 3', res.data)
                    setNodes3(res.data.map((item) => { return { label: item.ARMCD } }))
                    setLinks3(res.data.map((item) => { return { from: 'exposed', to: item.ARMCD, value: item.total } }))
                }
            })
    }, [props.studyDatabase, sqlStatement3])
    useEffect(() => {
        sql(props.studyDatabase, sqlStatement4)
            .then((res) => {
                if (res.message === "success") {
                    // console.log('res.data 4', res.data)
                    const labels1 = res.data.map((item) => { return { label: item.armcd } }),
                        labels2 = res.data.map((item) => { return { label: item.dsdecod } })
                    setNodes4([...labels1, ...labels2])
                    setLinks4(res.data.map((item) => { return { from: item.armcd, to: item.dsdecod, value: item.total } }))
                }
            })
    }, [props.studyDatabase, sqlStatement4])

    useEffect(() => {
        if (nodes2 && nodes3 && nodes4) {
            setSubtitle(globalFilter(props.selectedOptions))
            setNodes([{ label: 'Screening' }, ...nodes1, ...nodes2, ...nodes3, ...nodes4])
            setLinks([...links1, ...links2, ...links3, ...links4])
        }
    }, [nodes1, nodes2, nodes3, nodes4, links1, links2, links3, links4, props.selectedOptions])

    return (<>
        {nodes && links
            ? <ReactFC
                type="sankey"
                width="100%"
                height={props.screenHeight * 0.75}
                dataFormat="JSON"
                dataSource={
                    {
                        "chart": {
                            caption: "Disposition Summary (" + props.study + ")",
                            subcaption: subtitle,
                            // yaxisname: "Age",
                            // xaxisname: "Number of Subjects",
                            // theme: "gammel",
                            legendPosition: "bottom",
                            showLegend: "0",
                            "bgColor": "EEEEEE,CCCCCC",
                            "bgratio": "60,40",
                            "bgAlpha": "70,80",
                            "bgAngle": "180",
                            "exportEnabled": "1"
                        },
                        nodes: nodes,
                        "links": links
                    }
                }
                events={{
                    dataPlotClick: function (e) {
                        // console.log(e.data)
                        let sqlStatement = ''
                        if (e.data.targetLinks[0] === 'exposed') sqlStatement = `select distinct SUBJID from dm where armcd='${e.data.label}'`
                        else sqlStatement = `select distinct SUBJID from ds where dsdecod='${e.data.label}'`
                        sql(props.studyDatabase, sqlStatement)
                            .then((res) => {
                                // console.log('res', res)
                                if (res.message === "success") {
                                    setSubjectsDrilledTo(res.data.map(d => d.SUBJID));
                                }
                            })
                    }
                }
                }
            />
            : "loading"}
        {subjectsDrilledTo &&
            <LookBelow label="Subject Table" tooltip="Click to scroll to subject table appears below" mt={2} ml={4} mr={0} mb={0} />
        }
        {subjectsDrilledTo &&
            <SubjectTable
                screenHeight={props.screenHeight}
                selectedSubjects={subjectsDrilledTo}
                study={props.study}
                studyDatabase={props.studyDatabase}
                selectedOptions={props.selectedOptions} />}
    </>)
}