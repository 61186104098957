import * as React from "react";
import {
  Paper,
  Tooltip,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// Icons
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PeopleIcon from "@mui/icons-material/People";
import StreamIcon from "@mui/icons-material/Stream";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TableViewIcon from "@mui/icons-material/TableView";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import InfoIcon from "@mui/icons-material/Info";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import MedicationLiquidIcon from "@mui/icons-material/MedicationLiquid";
import MasksIcon from "@mui/icons-material/Masks";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AirIcon from "@mui/icons-material/Air";
import BarChartIcon from "@mui/icons-material/BarChart";
import HomeIcon from "@mui/icons-material/Home";

export const History = (props) => {
  const navigate = useNavigate(),
    [value, setValue] = React.useState(0),
    history = JSON.parse(sessionStorage.getItem("history")) || [],
    sx = {
      fontSize: 24,
      fontWeight: "lighter",
      minWidth: "20px",
      maxWidth: "30px",
      "&:hover": { fontSize: 30, color: "black" },
    },
    fontSize = "small";

  return (
    <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          // flex: 1,
          justifyContent: "flex-start",
          "&.MuiBottomNavigation-root": {
            backgroundColor: "lightgray",
            height: "30px",
          },
        }}
      >
        {history.length > 0 &&
          history.map((item, index) => {
            return (
              <Tooltip key={index} title={item.title}>
                <BottomNavigationAction
                  onClick={() => navigate(item.url)}
                  sx={{
                    backgroundColor: "lightgray",
                    minHeight: "30px",
                    minWidth: "25px",
                  }}
                  icon={
                    item.url.pathname === "/subjects" ? (
                      <PeopleIcon
                        fontSize={fontSize}
                        sx={{ color: "black", ...sx }}
                      />
                    ) : item.url.pathname === "/reporting" ? (
                      <InfoIcon
                        fontSize={fontSize}
                        sx={{ color: "black", ...sx }}
                      />
                    ) : item.url.pathname === "/view/ae" ? (
                      <TableViewIcon
                        fontSize={fontSize}
                        sx={{ color: "red", ...sx }}
                      />
                    ) : item.url.pathname === "/aeboth" ? (
                      <CoronavirusIcon
                        fontSize={fontSize}
                        sx={{ color: "red", ...sx }}
                      />
                    ) : item.url.pathname === "/aestreamgraph" ? (
                      <StreamIcon
                        fontSize={fontSize}
                        sx={{ color: "red", ...sx }}
                      />
                    ) : item.url.pathname === "/aesunburst" ? (
                      <WbSunnyIcon
                        fontSize={fontSize}
                        sx={{ color: "red", ...sx }}
                      />
                    ) : item.url.pathname === "/mhsunburst" ? (
                      <WbSunnyIcon
                        fontSize={fontSize}
                        sx={{ color: "purple", ...sx }}
                      />
                    ) : item.url.pathname === "/ae" ? (
                      <AlignHorizontalCenterIcon
                        fontSize={fontSize}
                        sx={{ color: "red", ...sx }}
                      />
                    ) : item.url.pathname === "/drill" ? (
                      <FileDownloadIcon
                        fontSize={fontSize}
                        sx={{ color: "red", ...sx }}
                      />
                    ) : item.url.pathname === "/view/vd" ? (
                      <TableViewIcon
                        fontSize={fontSize}
                        sx={{ color: "brown", ...sx }}
                      />
                    ) : item.url.pathname === "/vdinfo" ? (
                      <BarChartIcon
                        fontSize={fontSize}
                        sx={{ color: "brown", ...sx }}
                      />
                    ) : item.url.pathname === "/view/dm" ? (
                      <TableViewIcon
                        fontSize={fontSize}
                        sx={{ color: "green", ...sx }}
                      />
                    ) : item.url.pathname === "/dminfo" ? (
                      <AccessibilityNewIcon
                        fontSize={fontSize}
                        sx={{ color: "green", ...sx }}
                      />
                    ) : item.url.pathname === "/view/ds" ? (
                      <TableViewIcon
                        fontSize={fontSize}
                        sx={{ color: "aqua", ...sx }}
                      />
                    ) : item.url.pathname === "/dssankey" ? (
                      <AirIcon
                        fontSize={fontSize}
                        sx={{ color: "aqua", ...sx }}
                      />
                    ) : item.url.pathname === "/dsinfo" ? (
                      <BarChartIcon
                        fontSize={fontSize}
                        sx={{ color: "aqua", ...sx }}
                      />
                    ) : item.url.pathname === "/view/lb" ? (
                      <TableViewIcon
                        fontSize={fontSize}
                        sx={{ color: "blue", ...sx }}
                      />
                    ) : item.url.pathname === "/lbboxplot" ? (
                      <CandlestickChartIcon
                        fontSize={fontSize}
                        sx={{ color: "blue", ...sx }}
                      />
                    ) : item.url.pathname === "/lbhyslaw" ? (
                      <MasksIcon
                        fontSize={fontSize}
                        sx={{ color: "blue", ...sx }}
                      />
                    ) : item.url.pathname === "/lbboth" ? (
                      <CoronavirusIcon
                        fontSize={fontSize}
                        sx={{ color: "blue", ...sx }}
                      />
                    ) : item.url.pathname === "/hyslaw" ? (
                      <MasksIcon
                        fontSize={fontSize}
                        sx={{ color: "blue", ...sx }}
                      />
                    ) : item.url.pathname === "/lbtable" ? (
                      <MedicationLiquidIcon
                        fontSize={fontSize}
                        sx={{ color: "blue", ...sx }}
                      />
                    ) : item.url.pathname === "/view/vs" ? (
                      <TableViewIcon
                        fontSize={fontSize}
                        sx={{ color: "orange", ...sx }}
                      />
                    ) : item.url.pathname === "/vsbp" ? (
                      <MedicationLiquidIcon
                        fontSize={fontSize}
                        sx={{ color: "orange", ...sx }}
                      />
                    ) : item.url.pathname === "/vsboxplot" ? (
                      <CandlestickChartIcon
                        fontSize={fontSize}
                        sx={{ color: "orange", ...sx }}
                      />
                    ) : item.url.pathname === "/vslisting" ? (
                      <FormatListNumberedIcon
                        fontSize={fontSize}
                        sx={{ color: "orange", ...sx }}
                      />
                    ) : item.url.pathname.startsWith("/view/") ? (
                      <TableViewIcon
                        fontSize={fontSize}
                        sx={{ color: "white", ...sx }}
                      />
                    ) : item.url.pathname === "/" ? (
                      <HomeIcon
                        fontSize={fontSize}
                        sx={{ color: "black", ...sx }}
                      />
                    ) : (
                      <QuestionMarkIcon
                        fontSize={fontSize}
                        sx={{ color: "white", ...sx }}
                      />
                    )
                  }
                />
              </Tooltip>
            );
          })}
      </BottomNavigation>
    </Paper>
  );
};
