import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { IconButton } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

//todo: add support for icons for right,top,bottom

export default function SlidingDrawer(props) {
  const anchor = props.anchor,
    [state, setState] = React.useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    }),
    toggleDrawer =
      (anchor, open) =>
        (event) => {
          if (event.type === 'keydown' &&
            (event.key === 'Tab' ||
              event.key === 'Shift')
          ) { return; }
          setState({ ...state, [anchor]: open });
        }

  return (
    <div>
      <React.Fragment key={anchor}>
        <IconButton
          anchor={'right'}
          onClick={toggleDrawer(anchor, true)}
          title={props.title}>
          <KeyboardDoubleArrowLeftIcon sx={{ color: 'text.primary', fontSize: 24, fontWeight: 'bold' }} />
        </IconButton>
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          PaperProps={{
            sx: { width: "50%" },
          }}
        >
          {props.contents}
        </Drawer>
      </React.Fragment>
    </div>
  );
}