import React, { useState, useEffect } from "react";
import { sql, globalFilter, addToHistory } from "../../apis/utility";
import { useLocation } from "react-router-dom";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import excelexport from "fusioncharts/fusioncharts.excelexport";
import Charts from "fusioncharts/fusioncharts.charts";
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
ReactFC.fcRoot(FusionCharts, Charts, PowerCharts, excelexport);

export default function VSPulse(props) {
    const parms = {
        title: "Pulse",
        testCodeValues: ["PULSE"],
        normalRangeKey: "VSPULSE",
        yAxisMaxValue: "120",
        yAxisMinValue: "20",
    },
        location = useLocation(),
        whereSubjects = props.selectedSubjects.length > 0
            ? 'and SUBJID in ("' + props.selectedSubjects.join('","') + '")'
            : '',
        whereValues = parms.testCodeValues.map((item) => `'${item}'`).join(','),
        sqlStatement = `select VSDY, VSSTRESN, VSTESTCD from vs where VSTESTCD in (${whereValues}) ${whereSubjects}`,
        [subtitle, setSubtitle] = useState(null),
        [dataSource, setDataSource] = useState(null),
        { low, high } = props.normalRanges[parms.normalRangeKey]
    addToHistory({ title: parms.title, url: location })

    useEffect(() => {
        setSubtitle(globalFilter(props.selectedOptions))
        sql(props.studyDatabase, sqlStatement)
            .then((res) => {
                if (res.message === "success") {
                    const categoryValues = [...new Set(res.data
                        .filter((row) => (parms.testCodeValues).includes(row.VSTESTCD))
                        .map((row) => Number(row.VSDY)))]
                        .sort((a, b) => a - b),
                        categories = [{
                            category:
                                categoryValues.map((item) => { return { label: item.toString() } })
                        }],
                        series = parms.testCodeValues.map((testCode, index) => {
                            return {
                                seriesname: testCode,
                                data:
                                    categories[0].category.map((category) => {
                                        const data1 = res.data
                                            .filter((row) => row.VSTESTCD === testCode && row.VSDY === category.label)
                                        if (data1.length > 2) { return { value: data1.map((item) => Number(item.VSSTRESN)).join(',') } }
                                        else {
                                            const values = data1.map((item) => Number(item.VSSTRESN)).join(',');
                                            return { "value": values + ',' + values + ',' + values }
                                        }
                                    })
                            }
                        })
                    // console.log('series', series)
                    const dataSource = {
                        chart: {
                            "caption": `${parms.title} (${props.study})`,
                            "subcaption": subtitle,
                            "yaxisname": parms.title,
                            "xaxisname": "Day",
                            "yAxisMaxValue": parms.yAxisMaxValue,
                            "yAxisMinValue": parms.yAxisMinValue,
                            "bgColor": "EEEEEE,CCCCCC",
                            "bgratio": "60,40",
                            "bgAlpha": "70,80",
                            "bgAngle": "180",
                            "plotspacepercent": "25",
                            "numVisiblePlot": "222",
                            "showalloutliers": "1",
                            "exportEnabled": "1",
                            "upperBoxColor": "#ffe6e6",
                            "lowerBoxColor": "#ccffcc",
                        },
                        categories: categories,
                        dataset: parms.testCodeValues.map((value, index) => series[index]),
                        "trendlines": [{
                            "line": [{
                                "isTrendZone": "1",
                                "startvalue": parms.yAxisMinValue,
                                "endValue": low,
                                "color": "#0000ff",
                                "valueOnRight": "1",
                                "alpha": "20",
                                "tooltext": `Low ${parms.title}`,
                                "displayvalue": "Low"
                            }, {
                                "isTrendZone": "1",
                                "startvalue": high,
                                "endValue": parms.yAxisMaxValue,
                                "color": "#ff0000",
                                "valueOnRight": "1",
                                "alpha": "20",
                                "tooltext": `High ${parms.title}`,
                                "displayvalue": "High"
                            }]
                        }]
                    }
                    setDataSource(dataSource)
                }
            });
        // eslint-disable-next-line
    }, [props.selectedSubjects, props.study, props.selectedOptions, props.studyDatabase, subtitle, sqlStatement, high, low])
    // console.log(dataSource)
    return (<>
        {dataSource
            ? <ReactFC
                type="boxandwhisker2d"
                width="100%"
                height={props.screenHeight * 0.8}
                dataFormat="JSON"
                dataSource={dataSource}
            />
            : "loading"}
    </>)
}