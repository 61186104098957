import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Chip,
  Box,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Typography,
} from "@mui/material";
import SubjectTable from "../SubjectTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GenerateSelects } from "../forms/GenerateSelects";
import ShowAlerts from "../pages/ShowAlerts";

function DrawerContents(props) {
  const { listOfStudies, listOfTables } = props,
    [expanded, setExpanded] = useState("panel0"),
    navigate = useNavigate(),
    handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    },
    handleClick = (
      e,
      id,
      filename,
      drillDownPath,
      ae,
      cm,
      dm,
      ds,
      lb,
      mh,
      vd,
      vs
    ) => {
      // console.log('e', e, 'id', id, 'filename', filename, 'ae', ae);
      if (props.hasOwnProperty("setStudy")) {
        props.setStudy(id);
        props.setStudyDatabase(filename);
        // eslint-disable-next-line
        props.setDrillDownPath(eval(drillDownPath));
        props.setShowAe(ae);
        props.setShowCm(cm);
        props.setShowDm(dm);
        props.setShowDs(ds);
        props.setShowLb(lb);
        props.setShowMh(mh);
        props.setShowVd(vd);
        props.setShowVs(vs);
        navigate("/home");
      }
    };
  // console.log('props', props)

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "primary.light",
        }}
      >
        <Accordion
          expanded={expanded === "panel0"}
          onChange={handleChange("panel0")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel0a-header">
            <Typography>Select Study</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" spacing={1}>
              {listOfStudies &&
                listOfStudies.map((thisStudy, index) => {
                  return (
                    <Chip
                      label={thisStudy.STUDYID}
                      variant={
                        thisStudy.STUDYID === props.study
                          ? "filled"
                          : "outlined"
                      }
                      color="success"
                      clickable={true}
                      onClick={(e) => {
                        handleClick(
                          e,
                          thisStudy.STUDYID,
                          thisStudy.filename,
                          thisStudy.drillDownPath,
                          thisStudy.ae,
                          thisStudy.cm,
                          thisStudy.dm,
                          thisStudy.ds,
                          thisStudy.mh,
                          thisStudy.lb,
                          thisStudy.vd,
                          thisStudy.vs
                        );
                      }}
                      key={index}
                    />
                  );
                })}
            </Stack>
          </AccordionDetails>
        </Accordion>
        {props.study ? (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel1a-header"
            >
              <Typography>
                {props.study
                  ? `Lab Alerts for ${props.study}`
                  : "Select a study first (above)"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ShowAlerts
                studyDatabase={props.studyDatabase}
                selectedSubjects={props.selectedSubjects}
                alerts={props.alerts}
                alertResults={props.alertResults}
                setAlertResults={props.setAlertResults}
                labCategories={props.labCategories}
                screenHeight={props.screenHeight}
              />
            </AccordionDetails>
          </Accordion>
        ) : null}
        {props.study ? (
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2a-header"
            >
              <Typography>
                {props.study
                  ? `View a table from database for ${props.study}`
                  : "Select a study first (above)"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {listOfTables &&
                listOfTables.map((thisTable, index) => {
                  return (
                    <React.Fragment key={"tab" + index}>
                      <button
                        onClick={() => {
                          navigate(`/view/${thisTable}`);
                        }}
                      >
                        {thisTable}
                      </button>
                      &nbsp;
                    </React.Fragment>
                  );
                })}
            </AccordionDetails>
          </Accordion>
        ) : null}
        {props.study ? (
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel3a-header"
            >
              <Typography>
                {props.study
                  ? `Subject Overview for ${props.study}`
                  : "Select a study first (above)"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SubjectTable
                selectedSubjects={props.selectedSubjects}
                study={props.study}
                selectedOptions={props.selectedOptions}
                studyDatabase={props.studyDatabase}
                screenHeight={400}
              />
            </AccordionDetails>
          </Accordion>
        ) : null}
        {props.study ? (
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel4a-header"
            >
              <Typography>Global Filter Builder</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GenerateSelects
                selectedOptions={props.selectedOptions}
                studyDatabase={props.studyDatabase}
                setSelectedOptions={props.setSelectedOptions}
                setSelectedSubjects={props.setSelectedSubjects}
                optionsForSelection={props.optionsForSelection}
                varToTable={props.varToTable}
              />
            </AccordionDetails>
          </Accordion>
        ) : null}
      </Box>
    </div>
  );
}
export default DrawerContents;
