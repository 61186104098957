import {
  Document,
  Packer,
  Paragraph,
  UnderlineType,
  AlignmentType,
  WidthType,
  TextRun,
  HeadingLevel,
  Header,
  Footer,
  Table,
  TableCell,
  TableRow,
  PageNumber,
} from "docx";
import { saveAs } from "file-saver";
import { sql } from "./utility";
// import fs from "fs";

const unchecked = "\u2610",
  checked = "\u2612";

export const createHeading = (text) => {
  return new Paragraph({
    text: text,
    heading: HeadingLevel.HEADING_1,
    thematicBreak: true,
  });
};

export const createSubHeading = (text) => {
  return new Paragraph({
    text: text,
    heading: HeadingLevel.HEADING_2,
  });
};

export const cell = (text, columnSpan, optionalStyle, bold, lineBreak) => {
  const cellContent = {
    children: [paragraph(text, optionalStyle, bold, lineBreak)],
  };
  if (columnSpan > 0) cellContent.columnSpan = columnSpan;
  return new TableCell(cellContent);
};
export const paragraph = (text, optionalStyle, bold, lineBreak) => {
  const useStyle = optionalStyle ? optionalStyle : {},
    useBold = bold ? bold : false,
    children = [];
  text.forEach((t, i) => {
    children.push(
      new TextRun({
        text: t,
        bold: useBold,
        ...useStyle,
        break: lineBreak && i > 0 ? true : false,
      })
    );
  });
  return new Paragraph({
    children: children,
  });
};

export const aeRow = (col1, col2, col3, col4, col5, col6, col7, col8, col9) => {
  const children = [];
  children.push(cell([col1]) || "");
  children.push(cell([col2]) || "");
  children.push(cell([col3]) || "");
  children.push(cell([col4]) || "");
  children.push(cell([col5]) || "");
  children.push(cell([col6]) || "");
  children.push(cell([col7]) || "");
  children.push(cell([col8]) || "");
  children.push(cell([col9]) || "");
  return new TableRow({
    children: children,
  });
};

export const mhRow = (aeOnset, condition, onset, lastOccurence) => {
  console.log(aeOnset, condition, onset, lastOccurence);
  const children = [];
  children.push(cell([condition], 3) || "");
  children.push(cell([onset], 2) || "");
  const conditionAtOnset = aeOnset <= onset ? "Y" : "N";
  children.push(cell([conditionAtOnset], 2) || "");
  if (conditionAtOnset === "N") children.push(cell([lastOccurence]) || "");
  return new TableRow({
    children: children,
  });
};

export const cmRow = (
  CMTRT,
  CMINDC,
  CMDOSU,
  CMDOSFRQ,
  CMROUTE,
  CMSTDTC,
  CMENDTC
) => {
  const children = [];
  const treatment = CMINDC ? CMTRT + " (" + CMINDC + ")" : CMTRT;
  children.push(cell([treatment]) || "");
  children.push(cell([CMDOSU]) || "");
  children.push(cell([CMDOSFRQ]) || "");
  children.push(cell([CMROUTE]) || "");
  children.push(cell([CMSTDTC]) || "");
  children.push(cell([CMENDTC]) || "");
  children.push(cell([""]));
  children.push(cell([""]));
  return new TableRow({
    children: children,
  });
};

export const saeReports = async (
  seriousResults,
  studyDatabase,
  study,
  show
) => {
  console.log(
    "seriousResults",
    seriousResults,
    "studyDatabase",
    studyDatabase,
    "study",
    study,
    "show",
    show
  );
  if (seriousResults.message === "success") {
    console.log("seriousResults", seriousResults);
    seriousResults.data.forEach((row) => {
      console.log("row", row);
      saeReport(row.SUBJID, studyDatabase, study, show);
    });
  }
};

export const saeReport = async (subjid, studyDatabase, study, show) => {
  console.log("subjid", subjid, "studyDatabase", studyDatabase, "study", study);
  const { aeInfo, dmInfo, dsInfo, mhInfo, cmInfo, vdInfo, vsInfo } =
    await getSAEData(subjid, studyDatabase, show);
  console.log(
    "aeInfo",
    aeInfo,
    "dmInfo",
    dmInfo,
    "dsInfo",
    dsInfo,
    "mhInfo",
    mhInfo,
    "cmInfo",
    cmInfo,
    "vdInfo",
    vdInfo,
    "vsInfo",
    vsInfo
  );

  const courier = { font: "Courier New", size: "7pt" },
    table1 = new Table({
      columnWidths: [4505, 4505],
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
              children: [
                paragraph([unchecked + " Death"], courier, true),
                paragraph([checked + " Other SAE"], courier, true),
              ],
            }),
            new TableCell({
              width: {
                size: 4505,
                type: WidthType.DXA,
              },
              children: [
                paragraph([unchecked + " Discontinued due to AE"], courier),
                paragraph([unchecked + " Other Event of Interest "], courier),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                paragraph(["Protocol Number: ", dmInfo.STUDYID], courier),
                paragraph(
                  ["Subject Number (Site - ID): ", dmInfo.SUBJID],
                  courier
                ),
              ],
            }),
            new TableCell({
              children: [
                paragraph(["Age (at study entry): ", dmInfo.AGE], courier),
                paragraph(["Sex: ", dmInfo.SEX], courier),
              ],
              borders: { left: { size: 1, color: "ffffff" } },
            }),
          ],
        }),
        new TableRow({
          children: [
            cell(
              ["Height (cm; at study entry or first exam): ", vsInfo.height],
              null,
              courier
            ),
            cell(
              ["Weight (kg; at study entry or first exam): ", vsInfo.weight],
              null,
              courier
            ),
          ],
        }),
        new TableRow({
          children: [
            cell(
              ["Race/Ethnicity: ", dmInfo.RACE + " / " + dmInfo.ETHNIC],
              courier
            ),
            cell(["Country (site location): ", dmInfo.SITE], courier),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                paragraph(
                  ["Initial Assigned Treatment Group: ", dmInfo.ARMCD],
                  courier
                ),
              ],
              columnSpan: 2,
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                paragraph(
                  ["Start/Stop dates (Study Days) of study drug: "],
                  courier
                ),
                paragraph(["\tFirst Day: ", vdInfo.first], courier),
                paragraph(["\tLast Day: ", vdInfo.last], courier),
              ],
              columnSpan: 2,
            }),
          ],
        }),
      ],
    }),
    aeRows = aeInfo.map((ae) => {
      return aeRow(
        ae.AETERM,
        ae.AEPT,
        ae.AESTDTC,
        ae.AESTDTC,
        ae.AEENDTC,
        ae.AEENDTC,
        ae.AESEV,
        ae.AEREL,
        ae.AEOUT
      );
    }),
    table2 = new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                paragraph(["Event(s) Requiring a Narrative "], courier),
              ],
              columnSpan: 9,
            }),
          ],
        }),
        new TableRow({
          children: [
            cell(["Reported Term"]),
            cell(["MedDRA Preferred Term"]),
            cell(["Day of Onset"], 2),
            cell(["Day of Offset"], 2),
            cell(["Investigator Assessment of Severity"]),
            cell(["Investigator Assessment of Relationship to Study Drug"]),
            cell(["Outcome"]),
          ],
        }),
        new TableRow({
          children: [
            cell([""]),
            cell([""]),
            cell(["Date"]),
            cell(["Study Day"]),
            cell(["Date"]),
            cell(["Study Day"]),
            cell([""]),
            cell([""]),
            cell([""]),
          ],
        }),
        ...aeRows,
      ],
    }),
    sex = dmInfo.SEX ? "male" : "female",
    lowerCaseAllWordsExceptFirstLetters = (string) =>
      string.replaceAll(
        /\S*/g,
        (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
      ),
    aeWords =
      aeInfo &&
      aeInfo.map((ae) => {
        return paragraph([
          `- A ${ae.AEREL.toLowerCase()}, ${ae.AESEV.toLowerCase()}, ` +
            `${lowerCaseAllWordsExceptFirstLetters(
              ae.AEPT
            )} started on ${ae.AESTDTC.toLowerCase()} and ended on ${ae.AEENDTC.toLowerCase()}. The patient ${ae.AEOUT.toLowerCase()}.`,
        ]);
      }),
    mhWords =
      mhInfo && mhInfo.length > 0
        ? mhInfo.map((mh) => {
            return paragraph([
              `- ${mh.MHDECOD} started ${mh.mhstdtc}${
                mh.mhendat ? " and ended " + mh.mhendat : ""
              }. It is ${mh.MHONGO === "N" ? "not " : ""}ongoing.`,
            ]);
          })
        : [paragraph(["No medical history"])],
    cmWords =
      cmInfo &&
      cmInfo.map((cm) => {
        return paragraph([
          `- ${cm.CMTRT} - ${cm.CMDOSE} ${cm.CMDOSU} (${cm.CMROUTE}, ${
            cm.CMDOSFRQ
          }) started ${cm.CMSTDTC}${
            cm.CMENDTC ? " and ended " + cm.CMENDTC : ""
          }. It is ${cm.MHONGO === "N" ? "not " : ""}ongoing. ${cm.CMINDC}`,
        ]);
      }),
    doc = new Document({
      creator: "Xploratum",
      title: "Sample Xploratum SAE Report",
      description: "Sample Serious Adverse Event Report produced by Xploratum",
      styles: {
        default: {
          heading1: {
            run: {
              font: "Calibri",
              size: 52,
              bold: true,
              color: "000000",
              underline: {
                type: UnderlineType.SINGLE,
                color: "000000",
              },
            },
            paragraph: {
              alignment: AlignmentType.CENTER,
              spacing: { line: 340 },
            },
          },
          heading2: {
            run: {
              font: "Calibri",
              size: 26,
              bold: true,
            },
            paragraph: {
              spacing: { line: 340 },
            },
          },
          heading3: {
            run: {
              font: "Calibri",
              size: 26,
              bold: true,
            },
            paragraph: {
              spacing: { line: 276 },
            },
          },
          heading4: {
            run: {
              font: "Calibri",
              size: 26,
              bold: true,
            },
            paragraph: {
              alignment: AlignmentType.JUSTIFIED,
            },
          },
        },
        paragraphStyles: [
          {
            id: "Normal",
            name: "Normal",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Helvetica",
              size: "7pt",
            },
          },
          {
            id: "Table",
            name: "Table1",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Helvetica",
              size: 12,
            },
          },
        ],
      },
      sections: [
        {
          headers: {
            default: new Header({
              children: [
                new Paragraph(`Study drug: ` + study),
                new Paragraph("Subject ID: " + subjid),
                // new ImageRun({
                //     data: fs.readFileSync("/static/media/logo.6440a26285b2b046c203.png"),
                //     transformation: {
                //         width: 100,
                //         height: 100,
                //     },
                // }),
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [new Paragraph("Footer text")],
            }),
          },
          properties: {},
          children: [
            createHeading("Serious Adverse Event Report"),
            createSubHeading("Confidential"),
            table1,
            paragraph([""]),
            table2,
            paragraph([""]),
            paragraph([
              `Subject ${subjid} was a ${
                dmInfo.AGE
              } year old ${lowerCaseAllWordsExceptFirstLetters(
                dmInfo.ETHNIC
              )} ${lowerCaseAllWordsExceptFirstLetters(dmInfo.RACE)} ` +
                `${sex}. This subject entered the study on ${vdInfo.first}.`,
            ]),
            paragraph([""]),
            paragraph(["Medical History:"]),
            ...mhWords,
            paragraph([""]),
            paragraph(["Concomitant Medication:"]),
            ...cmWords,
            paragraph([""]),
            paragraph([
              "The patient experienced the following adverse events:",
            ]),
            ...aeWords,
            paragraph([""]),
            // paragraph('The patient died on April 8, 2011 (day 51). The mortality report stated that the cause of death was a carcinoma of bladder.'),
            // paragraph(""),
            paragraph([
              "Pharmacovigilance Causality Statement: ",
              "Y / Y / N (Serious/Unlisted/Causality)",
            ]),
            paragraph([""]),
            paragraph(["Sponsor's interpretation and comment: ", ""]),
          ],
        },
      ],
    });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, "SAE Report, " + study + ", Subject " + subjid + ".docx");
    console.log("Word document created successfully");
  });
};

export const saeReports2 = async (
  seriousResults,
  studyDatabase,
  study,
  show
) => {
  console.log(
    "studyDatabase",
    studyDatabase,
    "study",
    study,
    "seriousResults",
    seriousResults,
    "show",
    show
  );
  if (seriousResults.message === "success") {
    seriousResults.data.forEach((row) => {
      console.log("row", row);
      saeReport2(row.SUBJID, studyDatabase, study, show);
    });
  }
};

export const saeReport2 = async (subjid, studyDatabase, study, show) => {
  console.log("subjid", subjid, "studyDatabase", studyDatabase, "study", study);
  const { aeInfo, dmInfo, dsInfo, mhInfo, cmInfo, vdInfo, vsInfo } =
    await getSAEData(subjid, studyDatabase, show);
  console.log(
    "aeInfo",
    aeInfo,
    "dmInfo",
    dmInfo,
    "dsInfo",
    dsInfo,
    "mhInfo",
    mhInfo,
    "cmInfo",
    cmInfo,
    "vdInfo",
    vdInfo,
    "vsInfo",
    vsInfo
  );

  let aeOnset = aeInfo[0].AESTDTC;
  const aeRows = aeInfo.map((ae) => {
      aeOnset = Math.min(aeOnset, ae.AESTDTC);
      return aeRow(
        ae.AEPT,
        ae.AESTDTC,
        ae.AESTDTC,
        ae.AEOUT,
        ae.AEENDTC,
        ae.AEENDTC,
        ae.AESEV,
        "N"
      );
    }),
    mhRows = mhInfo.map((mh) => {
      console.log("mh", mh);
      return mhRow(aeOnset, mh.MHDECOD, mh.mhstdtc, mh.mhendat);
    }),
    cmRows = cmInfo.map((cm) => {
      return cmRow(
        cm.CMTRT,
        cm.CMINDC,
        cm.CMDOSU,
        cm.CMDOSFRQ,
        cm.CMROUTE,
        cm.CMSTDTC,
        cm.CMENDTC
      );
    }),
    male = dmInfo.SEX === "M" ? checked : unchecked,
    female = dmInfo.SEX === "F" ? checked : unchecked,
    table1 = new Table({
      rows: [
        new TableRow({
          children: [cell(["(1) Study Details"], 8, null, true)],
        }),
        new TableRow({
          children: [
            cell(["Sponsor Study Code: ", dmInfo.STUDYID], 3),
            cell(["Site Name/Identifier: ", dmInfo.SITE], 3),
            cell(
              ["Country: ", "COUNTRY" in dmInfo ? dmInfo.COUNTRY : "Unknown"],
              2
            ),
          ],
        }),
        new TableRow({
          children: [
            cell(
              [
                "Initial report " +
                  unchecked +
                  "\t\tFollow-up report " +
                  unchecked,
              ],
              8
            ),
          ],
        }),
        new TableRow({
          children: [cell(["(2) Subject Details"], 8, null, true)],
        }),
        new TableRow({
          children: [
            cell(["Subject Number: ", dmInfo.SUBJID], 2),
            cell(
              [
                "Randomisation Number: ",
                "RANDNUM" in dmInfo ? dmInfo.RANDNUM : "Unknown",
              ],
              2
            ),
            cell(["Date of Birth: ", dmInfo.BRTHDTC], 2),
            cell(["Weight: ", vsInfo.weight], 1),
            cell(["Male " + male + "\tFemale " + female], 1),
          ],
        }),
        new TableRow({
          children: [cell(["(3) Event Details"], 8, null, true)],
        }),
        new TableRow({
          children: [
            cell(["Event"], 1, null, true),
            cell(["Start date"], 1, null, true),
            cell(["Start time"], 1, null, true),
            cell(["Outcome"], 1, null, true),
            cell(["End date"], 1, null, true),
            cell(["End time"], 1, null, true),
            cell(["Maximum intensity"], 1, null, true),
            cell(["Withdrawal"], 1, null, true),
          ],
        }),
        new TableRow({
          children: [
            cell(["Diagnosis Only (if known) Otherwise Sign/Symptom"], 1),
            cell(["DD/MMM/YY"], 1),
            cell(["Hr : Min", "00:00-23:59"], 1, null, null, true),
            cell(
              [
                "1=Recovered/Resolved",
                "2=Recovering/Resolving",
                "3=Not recovered/Not resolved",
                "4=Recovered/Resolved with sequelae",
                "5=Fatal",
              ],
              1,
              null,
              null,
              true
            ),
            cell(["DD/MMM/YY"], 1),
            cell(["Hr : Min", "00:00-23:59"], 1, null, null, true),
            cell(
              ["1=Mild", "2=Moderate", "3=Severe", "X=Not applicable"],
              1,
              null,
              null,
              true
            ),
            cell(
              [
                "Did the subject withdraw from study as a result of this AE?",
                "Y=Yes / N=No",
                null,
                null,
                true,
              ],
              1
            ),
          ],
        }),
        ...aeRows,
        new TableRow({
          children: [
            cell(
              [
                "(4) Seriousness (specify how this event reaches the status of Serious)",
              ],
              8,
              null,
              true
            ),
          ],
        }),
        new TableRow({
          children: [
            cell(
              [
                unchecked + " Results in death (1)",
                unchecked + " Is life threatening",
                unchecked +
                  " Requires hospitalisation or prolongation of existing hospitalisation (2)",
              ],
              4,
              null,
              null,
              true
            ),
            cell(
              [
                unchecked + " Results in disability/incapacity",
                unchecked + " Congenital anomaly/birth defect",
                unchecked + " Other (specify)",
              ],
              4,
              null,
              null,
              true
            ),
          ],
        }),
        new TableRow({
          children: [
            cell(
              [
                "(1) Date of Death (DD-MMM-YYYY):",
                "Autopsy performed:	Yes	" +
                  unchecked +
                  " (attach report)\tNo " +
                  unchecked,
                "Probable cause of death:",
              ],
              4,
              null,
              null,
              true
            ),
            cell(
              [
                "(2) Date of hospitalisation (DD-MMM-YYYY):",
                "Date of discharge (DD-MMM-YYYY):",
              ],
              5,
              null,
              null,
              true
            ),
          ],
        }),
        new TableRow({
          children: [
            cell(
              [
                "(5) Possible causes of SAE other than Investigational Product(s)",
              ],
              8,
              null,
              true
            ),
          ],
        }),
        new TableRow({
          children: [
            cell(
              [
                unchecked + " Disease under study",
                unchecked + " Medical condition(s) specify",
                unchecked + "Lack of efficacy",
              ],
              3,
              null,
              null,
              true
            ),
            cell(
              [
                unchecked + " Withdrawal of IMP",
                unchecked + " Concomitant medication(s) specify",
              ],
              2,
              null,
              null,
              true
            ),
            cell(
              [
                unchecked + " Activity related to the study (e.g. procedures)",
                unchecked + " Other (specify)",
              ],
              3,
              null,
              null,
              true
            ),
          ],
        }),
        new TableRow({
          children: [
            cell(["(6) Investigational Product Details"], 8, null, true),
          ],
        }),
        new TableRow({
          children: [
            cell(["Investigational Product(s)"], 1, null, true),
            cell(["Route"], 1, null, true),
            cell(["Daily Frequency"], 1, null, true),
            cell(["Total Daily Dose"], 1, null, true),
            cell(
              [
                "Duration of therapy",
                "(DD-MMM-YYYY)",
                "(Add 24 hour clock if relevant)",
              ],
              1,
              null,
              true,
              true
            ),
            cell(["Hr : Min", "00:00-23:59"], 1, null, true, true),
            cell(["Causality assessment"], 1, null, true),
            cell(
              ["Action taken with IMP*", "Y=Yes / N=No", null, true, true],
              1
            ),
          ],
        }),
        new TableRow({
          children: [
            cell(
              [
                "Trade name of generic name and formulation, strength (e.g., tab 5 mg)",
              ],
              1
            ),
            cell(["(e.g., oral, i.v., topical, inhaled)"], 1),
            cell(["(e.g., qd, bid)"]),
            cell(["Specify units"]),
            cell(["Started"], 1),
            cell(["Stopped"]),
            cell(
              [
                "Is there a reasonable possibility that the AE may have been caused by the IMP?",
                "Y=Yes / N=No",
              ],
              1
            ),
            cell(["1, 2, 3, 4, 5, X"], 1),
          ],
        }),
        new TableRow({
          children: [
            cell(
              [
                "*	1 = IMP withdrawn, 2 = Dose reduced, 3 = Dose increased, 4 = Dose not changed, 5 = Dose interrupted, X = Not applicable",
              ],
              8
            ),
          ],
        }),
        new TableRow({
          children: [cell(["(7) RELEVANT Medical Conditions"], 8, null, true)],
        }),
        new TableRow({
          children: [
            cell(
              [
                "Specify any RELEVANT past or current medical disorders, allergies, surgery etc.",
              ],
              3,
              null,
              true,
              true
            ),
            cell(["Date of Onset", "(DD-MMM-YYYY)"], 2, null, true, true),
            cell(
              ["Condition present at time of onset SAE?", "Y = Yes / N = No"],
              2,
              null,
              true,
              true
            ),
            cell(
              ["If NO, Date of last occurrence", "(DD-MMM-YYYY)"],
              1,
              null,
              true,
              true
            ),
          ],
        }),
        ...mhRows,
        new TableRow({
          children: [
            cell(
              [
                "(8) Concomitant Medications (include details of any medications that may have contributed to the event, but exclude medications used to treat the event)",
              ],
              8,
              null,
              true
            ),
          ],
        }),
        new TableRow({
          children: [
            cell(["Drug name"]),
            cell(["Dose"]),
            cell(["Frequency", "(e.g. qd, bid)"], 1, null, null, true),
            cell(
              ["Route", "(e.g. oral, i.v., topical, inhaled)"],
              1,
              null,
              null,
              true
            ),
            cell(["Start date", "(DD-MMM-YYYY)"], 1, null, null, true),
            cell(["Stop date", "(DD-MMM-YYYY)"], 1, null, null, true),
            cell(
              [
                "Causality assessment",
                "Is there a reasonable possibility that the AE may have been caused by the concomitant medication?",
                "Y=Yes / N=No",
              ],
              1,
              null,
              null,
              true
            ),
            cell(["Reason for medication"]),
          ],
        }),
        ...cmRows,
        // new TableRow({
        //     children: [
        //         cell([''], 1),
        //         cell([''], 1),
        //         cell([''], 1),
        //         cell([''], 1),
        //         cell([''], 1),
        //         cell([''], 1),
        //         cell([''], 1),
        //         cell([''], 1),
        //     ],
        // }),
        new TableRow({
          children: [
            cell(
              [
                "(9) Details of Relevant Clinical/Laboratory Assessments (include details of any other tests/procedures which were carried out to diagnose or confirm the SAE)",
              ],
              8,
              null,
              true
            ),
          ],
        }),
        new TableRow({ children: [cell([""], 8)] }),
        new TableRow({
          children: [
            cell(["(10) Narrative Comments or Remarks"], 8, null, true),
          ],
        }),
        new TableRow({ children: [cell([""], 8)] }),
      ],
    }),
    doc = new Document({
      creator: "Xploratum",
      title: "Sample Xploratum SAE Report",
      description: "Sample Serious Adverse Event Report produced by Xploratum",
      styles: {
        default: {
          heading1: {
            run: {
              font: "Calibri",
              size: 52,
              bold: true,
              color: "000000",
              underline: {
                type: UnderlineType.SINGLE,
                color: "000000",
              },
            },
            paragraph: {
              alignment: AlignmentType.CENTER,
              spacing: { line: 340 },
            },
          },
          heading2: {
            run: {
              font: "Calibri",
              size: 26,
              bold: true,
            },
            paragraph: {
              spacing: { line: 340 },
            },
          },
          heading3: {
            run: {
              font: "Calibri",
              size: 26,
              bold: true,
            },
            paragraph: {
              spacing: { line: 276 },
            },
          },
          heading4: {
            run: {
              font: "Calibri",
              size: 26,
              bold: true,
            },
            paragraph: {
              alignment: AlignmentType.JUSTIFIED,
            },
          },
        },
        paragraphStyles: [
          {
            id: "Normal",
            name: "Normal",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Helvetica",
              size: "7pt",
            },
          },
          {
            id: "Table",
            name: "Table1",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Helvetica",
              size: 12,
            },
          },
        ],
      },
      sections: [
        {
          headers: {
            default: new Header({
              children: [new Paragraph(`SERIOUS ADVERSE EVENT (SAE)`)],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph(
                  "Study ID: " +
                    dmInfo.STUDYID +
                    "  |  Subject ID: " +
                    dmInfo.SUBJID
                ),
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun(
                      "Date: " +
                        new Date().toLocaleDateString() +
                        "  |  Time: " +
                        new Date().toLocaleTimeString()
                    ),
                    new TextRun({
                      children: [
                        "Page ",
                        PageNumber.CURRENT,
                        " of ",
                        PageNumber.TOTAL_PAGES,
                      ],
                    }),
                  ],
                }),
              ],
            }),
          },
          properties: {
            page: {
              margin: {
                top: 100,
                right: 100,
                bottom: 100,
                left: 100,
              },
            },
          },
          children: [
            // createHeading('Serious Adverse Event Report'),
            // createSubHeading('Confidential'),
            table1,
            paragraph([""]),
            paragraph([
              "Investigator’s Signature\t\tInvestigator’s Name (print)\t\tDate (DD-MMM-YYYY)",
            ]),
          ],
        },
      ],
    });
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, "SAE Report, " + study + ", Subject " + subjid + ".docx");
    console.log("Word document created successfully");
  });
};

export const ciomsReports1 = async (
  seriousResults,
  studyDatabase,
  study,
  show
) => {
  console.log(
    "seriousResults",
    seriousResults,
    "studyDatabase",
    studyDatabase,
    "study",
    study,
    "show",
    show
  );
  if (seriousResults.message === "success") {
    seriousResults.data.forEach((row) => {
      console.log("row", row);
      ciomsReport1(row.SUBJID, studyDatabase, study, show);
    });
  }
};

export const getSAEData = async (subjid, studyDatabase, show) => {
  let aeInfo, dmInfo, dsInfo, mhInfo, cmInfo, vdInfo, vsInfo, res;
  res = await sql(
    studyDatabase,
    `SELECT aeterm,aept,AESTDTC,AEENDTC,aesev,aerel,aeout FROM ae WHERE aeser='Y' and subjid = '${subjid}' ORDER BY AESTDTC`
  );
  if (res.message === "success") {
    aeInfo = res.data;
  }
  res = await sql(
    studyDatabase,
    `SELECT studyid,subjid,age,sex,race,ethnic,armcd,site, country FROM dm WHERE subjid = '${subjid}'`
  );
  if (res.message === "success") {
    dmInfo = res.data[0];
  }
  res = await sql(
    studyDatabase,
    `SELECT dsdecod,dsldt FROM ds WHERE subjid = '${subjid}'`
  );
  if (res.message === "success") {
    dsInfo = res.data[0];
  }
  res = await sql(
    studyDatabase,
    `SELECT subjid, mhdecod, mhongo, CASE WHEN length(mhendat) = 4 THEN mhendat || '-01-01' WHEN length(mhendat) = 7 THEN mhendat || '-01' ELSE mhendat END AS mhendat,  CASE WHEN length(mhstdtc) = 4 THEN mhstdtc || '-01-01' WHEN length(mhstdtc) = 7 THEN mhstdtc || '-01' ELSE mhstdtc END AS mhstdtc, mhdtc FROM mh where subjid = '${subjid}'`
  );
  if (res.message === "success") {
    mhInfo = res.data;
  }
  res = await sql(
    studyDatabase,
    `SELECT subjid, cmtrt, cmindc, cmdose, cmdosu, cmdosfrq, cmroute, cmstdtc, cmongo, cmendtc FROM cm WHERE subjid = '${subjid}'`
  );
  if (res.message === "success") {
    cmInfo = res.data;
  }
  res = await sql(
    studyDatabase,
    `SELECT max(start) AS first, max(vddate) AS last FROM (SELECT CASE WHEN visit in ('DAY 1','BASELINE','CYCLE 01 DAY 01') THEN vddate ELSE NULL END AS start, vddate FROM vd WHERE subjid = '${subjid}')`
  );
  if (res.message === "success") {
    vdInfo = res.data[0];
  }
  res = await sql(
    studyDatabase,
    `SELECT max(height) AS height, max(weight) AS weight FROM (SELECT CASE (vstestcd) WHEN 'HEIGHT' THEN vsstresn ELSE NULL END AS height, CASE (vstestcd) WHEN 'WEIGHT' THEN vsstresn ELSE NULL END AS weight FROM vs WHERE vstestcd IN ('HEIGHT', 'WEIGHT') AND substr(visit,1,9) = 'SCREENING' AND subjid = '${subjid}')`
  );
  if (res.message === "success") {
    vsInfo = res.data[0];
  }
  return { aeInfo, dmInfo, dsInfo, mhInfo, cmInfo, vdInfo, vsInfo };
};

export const ciomsReport1 = async (subjid, studyDatabase, study, show) => {
  const { aeInfo, dmInfo, dsInfo, mhInfo, cmInfo, vdInfo, vsInfo } =
    await getSAEData(subjid, studyDatabase, show);
  console.log(
    "subjid",
    subjid,
    "studyDatabase",
    studyDatabase,
    "study",
    study,
    "aeInfo",
    aeInfo,
    "dmInfo",
    dmInfo,
    "dsInfo",
    dsInfo,
    "mhInfo",
    mhInfo,
    "cmInfo",
    cmInfo,
    "vdInfo",
    vdInfo,
    "vsInfo",
    vsInfo
  );

  const w = (size, type) => {
      return { size: size, type: type ? WidthType[type] : WidthType.DXA };
    },
    table1 = new Table({
      width: w(100, "AUTO"),
      rows: [
        new TableRow({
          children: [
            new TableCell({
              rowSpan: 3,
              width: w(6000),
              children: [
                new Paragraph({
                  children: [new TextRun("SUSPECT ADVERSE REACTION REPORT")],
                }),
                new Paragraph({
                  children: [new TextRun("MFR CONTROL No. : 20220001(0)")],
                }),
              ],
            }),
            new TableCell({
              columnSpan: 13,
              width: w(800),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 13,
              width: w(800),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: w(800),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
            new TableCell({
              width: w(400),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
            new TableCell({
              width: w(400),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
            new TableCell({
              width: w(400),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
            new TableCell({
              width: w(400),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
            new TableCell({
              width: w(400),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
            new TableCell({
              width: w(400),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
            new TableCell({
              width: w(400),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
            new TableCell({
              width: w(400),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
            new TableCell({
              width: w(400),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
            new TableCell({
              width: w(400),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
            new TableCell({
              width: w(400),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
            new TableCell({
              width: w(800),
              children: [new Paragraph({ children: [new TextRun("")] })],
            }),
          ],
        }),
      ],
    }),
    table2 = new Table({
      width: { size: 100, type: WidthType.AUTO },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: w(2000),
              rowSpan: 2,
              children: [paragraph(["1. PATIENT INITIALS"])],
            }),
            new TableCell({
              width: w(1500),
              rowSpan: 2,
              children: [paragraph(["1a. COUNTRY"])],
            }),
            new TableCell({
              width: w(2500),
              columnSpan: 3,
              children: [paragraph(["2. DATE OF BIRTH"])],
            }),
            new TableCell({
              width: w(777),
              rowSpan: 2,
              children: [paragraph(["2a. AGE"])],
            }),
            new TableCell({
              width: w(777),
              rowSpan: 2,
              children: [paragraph(["3. SEX"])],
            }),
            new TableCell({
              width: w(2500),
              columnSpan: 3,
              children: [paragraph(["4-6. REACTION ONSET"])],
            }),
            new TableCell({
              rowSpan: 3,
              children: [
                paragraph([
                  " 8-12. CHECK ALL. APPROPRIATE TO ADVERSE REACTION",
                ]),
                paragraph(["", unchecked + " PATIENT DIED"], null, null, true),
                paragraph(
                  [
                    "",
                    unchecked +
                      " INVOLVED OR PROLONGED INPATIENT HOSPITALIZAITON",
                  ],
                  null,
                  null,
                  true
                ),
                paragraph(
                  [
                    "",
                    unchecked +
                      " INVOLVED OERSISTENCE OR SIGNIFICANT DISABILITY OR INCAPACITY",
                  ],
                  null,
                  null,
                  true
                ),
                paragraph(
                  ["", unchecked + " CONGENITAL ANOMALY OR BIRTH DEFECT"],
                  null,
                  null,
                  true
                ),
                paragraph(
                  ["", unchecked + " OTHER MEDICALLY IMPORTANT EVENT"],
                  null,
                  null,
                  true
                ),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({ children: [paragraph(["DA"])] }),
            new TableCell({ children: [paragraph(["MO"])] }),
            new TableCell({ children: [paragraph(["YR"])] }),
            new TableCell({ children: [paragraph(["DA"])] }),
            new TableCell({ children: [paragraph(["MO"])] }),
            new TableCell({ children: [paragraph(["YR"])] }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 10,
              children: [
                new Paragraph({
                  children: [
                    new TextRun(
                      "7+13. DESCRIBE REACTION(S) (include relevant test/lab/data)"
                    ),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    }),
    // Suspect drugs
    table3 = new Table({
      width: { size: 100, type: WidthType.AUTO },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [
                paragraph([
                  "14. SUSPECT DRUG(S) (include generic name)\tSubject Unblinded: ",
                ]),
              ],
            }),
            // cell([" "]),
            new TableCell({
              width: w(3000),
              rowSpan: 2,
              children: [
                paragraph(["20. DID EVENT ABATE AFTER STOPPING DRUG?"]),
                paragraph(
                  [
                    "",
                    unchecked +
                      " YES\t" +
                      unchecked +
                      " NO\t" +
                      unchecked +
                      " NA",
                  ],
                  null,
                  null,
                  true
                ),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: w(4500),
              children: [paragraph(["15. DAILY DOSE"])],
            }),
            new TableCell({
              width: w(4500),
              children: [paragraph(["16. ROUTE OF ADMINISTRATION"])],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [paragraph(["17. INDICATION FOR USE"])],
            }),
            // cell([" "]),
            new TableCell({
              width: w(3000),
              rowSpan: 2,
              children: [
                paragraph(["21. DID EVENT REAPPEAR AFTER REINTRODUCTION?"]),
                paragraph(
                  [
                    "",
                    unchecked +
                      " YES\t" +
                      unchecked +
                      " NO\t" +
                      unchecked +
                      " NA",
                  ],
                  null,
                  null,
                  true
                ),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: w(4500),
              children: [paragraph(["18. THERAPY DATES (From/To)"])],
            }),
            new TableCell({
              width: w(4500),
              children: [paragraph(["19. THERAPY DURATION"])],
            }),
          ],
        }),
      ],
    }),
    // concomitant drugs
    table4 = new Table({
      width: { size: 100, type: WidthType.AUTO },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: w(12000),
              children: [
                paragraph([
                  "22. CONCOMITANT DRUGS AND DATES OF ADMINISTRATION (Exclude those used to treat event)",
                ]),
                paragraph(["", ""], null, null, true),
                paragraph(["", ""], null, null, true),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: w(12000),
              children: [
                paragraph(["23. OTHER RELEVANT HISTORY"]),
                paragraph(["", ""], null, null, true),
                paragraph(["", ""], null, null, true),
              ],
            }),
          ],
        }),
      ],
    }),
    // manufacturer
    table5 = new Table({
      width: { size: 100, type: WidthType.AUTO },
      rows: [
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [paragraph(["IV. MANUFACTURER"])],
            }),
            new TableCell({
              width: w(6000),
              rowSpan: 5,
              children: [paragraph(["25b. REPORTER DETAILS"])],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [paragraph(["24a. NAME AND ADDRESS OF MANUFACTURER"])],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: w(3000),
              children: [
                paragraph(["EDURACT No: "]),
                paragraph(["Study No.: "]),
              ],
            }),
            new TableCell({
              width: w(3000),
              children: [paragraph(["24b MFR CONTROL NO."])],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [paragraph(["24c. DATE RECEIVED BY MFR."])],
            }),
            new TableCell({
              children: [
                paragraph(["24d. REPORT SOURCE"]),
                paragraph([
                  checked +
                    " STUDY\t" +
                    unchecked +
                    " LITERATURE\t" +
                    unchecked +
                    " AUTHORITY\t" +
                    unchecked +
                    " HEALTH PROFESSIONAL\t" +
                    unchecked +
                    " OTHER",
                ]),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({ children: [paragraph(["DATE OF THIS REPORT"])] }),
            new TableCell({
              children: [
                paragraph(["25a. REPORT TYPE"]),
                paragraph([
                  checked +
                    " INITIAL\t" +
                    unchecked +
                    " FOLLOW UP\t" +
                    unchecked +
                    " FINAL",
                ]),
              ],
            }),
          ],
        }),
      ],
    }),
    doc = new Document({
      creator: "Xploratum",
      title: "Sample Xploratum CIOMS Report",
      description: "Sample CIOMS Report produced by Xploratum",
      styles: {
        default: {
          heading1: {
            run: {
              font: "Helvetica",
              size: 32, // half points
              bold: true,
              alignment: AlignmentType.LEFT,
              // color: "000000",
              // underline: {
              //     type: UnderlineType.SINGLE,
              //     color: "000000",
              // },
            },
            paragraph: {
              alignment: AlignmentType.CENTER,
              spacing: { line: 340 },
            },
          },
        },
        paragraphStyles: [
          {
            id: "Normal",
            name: "Normal",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              font: "Helvetica",
              size: "7pt",
            },
          },
        ],
      },
      sections: [
        {
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun(
                      "Date: " +
                        new Date().toLocaleDateString() +
                        "  |  Time: " +
                        new Date().toLocaleTimeString()
                    ),
                    new TextRun({
                      children: [
                        "Page ",
                        PageNumber.CURRENT,
                        " of ",
                        PageNumber.TOTAL_PAGES,
                      ],
                    }),
                  ],
                }),
                // new Paragraph(`Small Pharma Ltd.`),
                // new Paragraph('6-8 Bonhill Street,'),
                // new Paragraph('London, EC2A 4BX, U.K.')
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph(
                  "Study ID: " +
                    dmInfo.STUDYID +
                    "  |  Subject ID: " +
                    dmInfo.SUBJID
                ),
              ],
            }),
          },
          properties: {
            page: {
              margin: {
                top: 100,
                right: 100,
                bottom: 100,
                left: 100,
              },
            },
          },
          children: [
            paragraph([""]),
            table1,
            paragraph(["I. REACTION INFORMATION"]),
            table2,
            paragraph(["II. SUSPECT DRUG(S) INFORMATION"]),
            table3,
            paragraph(["III. CONCOMITANT DRUGS AND HISTORY"]),
            table4,
            paragraph([""]),
            table5,
            new Paragraph({
              pageBreakBefore: true,
              children: [new TextRun("")],
            }),
            createHeading("Continuation Sheet for CIOMS report"),
            createSubHeading(
              "Describe Reaction(s) (Include relevant test/lab data (Cont...)"
            ),

            createHeading("Suspect Drugs (Cont...)"),
            createSubHeading("Product Reaction Level"),

            createHeading("Causality"),
            createSubHeading("Causality Assessment"),
            createSubHeading("Labelling"),

            createHeading("Clinical Trial Identification (Cont...)"),

            createHeading("Additional Information (Cont...)"),
            createSubHeading("Laboratory Data"),
          ],
        },
      ],
    });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, "CIOMS Report, " + study + ", Subject " + subjid + ".docx");
    console.log("Word document created successfully");
  });
};
