import React from "react";
import { HotColumn, HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import { TickCross, Heatmap, ProgressBarRenderer, PatientProfileRenderer, Colormap } from '../apis/utility'

// register Handsontable's modules
registerAllModules();
export const HandsOnTable = (props) => {
    let { data, columns, colHeaders, renderOptions, height, autoRowSize, autoColumnSize, colors } = props;
    if (!renderOptions) renderOptions = {}
    const min = {}, max = {}
    Object.keys(renderOptions || {}).forEach(key => {
        if (['heatmap', 'progressbar'].includes(key)) {
            const item = renderOptions[key];
            item.forEach(variable => {
                if (columns.includes(variable)) {
                    const values = data ? data.map((row) => row[variable]) : []
                    min[variable] = Math.min(...values)
                    max[variable] = Math.max(...values)
                }
            })
        }
    })
    return (
        data && renderOptions && columns
            ? <div>
                <HotTable
                    data={data}
                    colHeaders={colHeaders ? colHeaders : columns ? columns : true}
                    rowHeaders={false}
                    height={height ? height : '100'}
                    dropdownMenu={true}
                    hiddenColumns={{ indicators: true }}
                    contextMenu={true}
                    multiColumnSorting={true}
                    filters={true}
                    autoRowSize={autoRowSize || true}
                    autoColumnSize={autoColumnSize || true}
                    licenseKey="non-commercial-and-evaluation"
                >
                    {
                        columns
                            ? columns.map((column, index) => {
                                return (<HotColumn
                                    data={column}
                                    key={index}>
                                    {'tickcross' in renderOptions && renderOptions.tickcross.includes(column)
                                        ? <TickCross hot-renderer />
                                        : 'colormap' in renderOptions && renderOptions.colormap.includes(column)
                                            ? <Colormap hot-renderer
                                                colors={colors} />
                                            : 'heatmap' in renderOptions && renderOptions.heatmap.includes(column)
                                                ? <Heatmap hot-renderer
                                                    min={min[column]}
                                                    max={max[column]}
                                                    rMax={1}
                                                    gMax={-1}
                                                    bMax={0}
                                                    opacity={0.5} />
                                                : 'progressbar' in renderOptions && renderOptions.progressbar.includes(column)
                                                    ? <ProgressBarRenderer hot-renderer
                                                        min={min[column]}
                                                        max={max[column]}
                                                        cellWidth={100}
                                                        color='rgba(0,0,255,0.25)' />
                                                    : 'patientprofile' in renderOptions && renderOptions.patientprofile.includes(column)
                                                        ? <PatientProfileRenderer hot-renderer />
                                                        : null}
                                </HotColumn>)
                            })
                            : null}
                </HotTable >
            </div >
            : null

    );
}