import React, { useState } from "react";
import axios from 'axios';
import { Button } from '@mui/material';

const Rdemo = () => {
    const [data, setData] = useState(null)

    const runcode = (code) => {
        axios.get('http://localhost:3001/r/' + code)
            .then((response) => {
                console.log(response);
                if (response.statusText === 'OK') {
                    setData(response.data);
                }
            });
    },
        openUrl = (url) => {
            window.open(url, '_blank');
        }

    return (
        <div >
            <h1>R demo</h1>

            <Button variant="contained" color="primary" onClick={() => openUrl('http://localhost:3001/r-pdf/plot1')}>
                plot1
            </Button>
            <p />
            <Button variant="contained" color="primary" onClick={() => openUrl('http://localhost:3001/r-pdf/plot2')}>
                plot2
            </Button>
            <p />
            <Button variant="contained" color="primary" onClick={() => {
                runcode('test1')
            }}>
                R which returns a string
            </Button>
            <p />
            {data && <pre>{data}</pre>}
            <Button variant="contained" color="primary" onClick={() => {
                runcode('test2')
            }}>
                R connecting to sqlite
            </Button>
        </div >
    );
}
export default Rdemo;