import React, { useState, useEffect } from "react";
import { Grid, CircularProgress } from "@mui/material";
import Select from "react-select";
import { sql, globalFilter, addToHistory, getStats } from "../../apis/utility";
import { useLocation } from "react-router-dom";
// import ReactFC from "react-fusioncharts";
// import FusionCharts from "fusioncharts";
// import excelexport from "fusioncharts/fusioncharts.excelexport";
// import Charts from "fusioncharts/fusioncharts.charts";
// import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
// ReactFC.fcRoot(FusionCharts, Charts, PowerCharts, excelexport);
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartMore from "highcharts/highcharts-more";
import AnnotationsModule from "highcharts/modules/annotations";

HighchartMore(Highcharts);
AnnotationsModule(Highcharts);

export default function BoxPlot(props) {
  const parms = props.parms,
    [codeOptions, setCodeOptions] = useState(parms.codeOptions),
    [selectedOption, setSelectedOption] = useState(parms.dmOptions[0]),
    [selectedWidth, setSelectedWidth] = useState(parms.widthOptions[2]),
    [selectedCodes, setSelectedCodes] = useState(null),
    [xCategoryValues, setXCategoryValues] = useState([
      parms.codeOptions[0].value,
    ]),
    changeSelectedOption = (e) => {
      setSelectedOption(e);
    },
    changeWidth = (e) => {
      setSelectedWidth(e);
    },
    changeCodes = (e) => {
      //   console.log("e", e, "codeOptions", codeOptions);
      if (e && e[0] && typeof e === "object") {
        const newXCategoryValues = e.map((e) => e.value);
        setSelectedCodes(e);
        setXCategoryValues(newXCategoryValues);
      } else if (e && e.length === 0) setSelectedCodes(null);
    },
    location = useLocation(),
    [waiting, setWaiting] = useState(false),
    whereSubjects =
      props.selectedSubjects.length > 0
        ? 'and SUBJID in ("' + props.selectedSubjects.join('","') + '")'
        : "",
    [whereValues, setWhereValues] = useState(""),
    [subtitle, setSubtitle] = useState(null),
    [dataSources, setDataSources] = useState(null);
  // { low, high } = props.normalRanges[xCategoryValues[0]]
  // console.log('props', props)
  addToHistory({ title: "Boxplot", url: location });

  useEffect(() => {
    const categoryVariableLabel = parms.categoryVariableLabel
      ? parms.categoryVariableLabel
      : parms.categoryVariable;
    sql(
      props.studyDatabase,
      `select distinct ${parms.categoryVariable}, ${categoryVariableLabel} as label from  ${parms.table}`
    ).then((res) => {
      const newCodeOptions = res.data
        .map((e) => {
          return { value: e[parms.categoryVariable], label: e.label };
        })
        .sort((a, b) => a.value.localeCompare(b.value));
      setCodeOptions(newCodeOptions);
      const newWhereValues = newCodeOptions
        .map((item) => `'${item.value}'`)
        .join(",");
      setWhereValues(newWhereValues);
      changeCodes([newCodeOptions[0]]);
    });
    // eslint-disable-next-line
  }, [props.studyDatabase, parms.categoryVariable, parms.table]);

  useEffect(() => {
    setWaiting(true);
    setSubtitle(globalFilter(props.selectedOptions));
    const sqlStatement = `SELECT *, ${parms.categoryVariable} || ' (' || ${selectedOption.value} || ')' as combinedCategory FROM 
                        (SELECT SUBJID as ${parms.table}, ${parms.xVariable}, ${parms.yVariable}, ${parms.categoryVariable} 
                        FROM ${parms.table} WHERE ${parms.categoryVariable} in (${whereValues}) ${whereSubjects})
                        LEFT JOIN
                        (SELECT SUBJID as dm, ${selectedOption.value} from dm WHERE 1 ${whereSubjects})
                        ON ${parms.table}=dm
                        `;
    sql(props.studyDatabase, sqlStatement).then((res) => {
      if (res.message === "success") {
        // console.log('res', res)
        const dmVariableValues = res.data
          .map((row) => row[selectedOption.value])
          .filter((v, i, a) => a.indexOf(v) === i);
        // console.log('dmVariableValues', dmVariableValues)

        // make categories for x-axis
        const currentXCategoryValues = [
            ...new Set(
              res.data
                .filter((row) =>
                  xCategoryValues.includes(row[parms.categoryVariable])
                )
                .map((row) => Number(row[parms.xVariable]))
            ),
          ].sort((a, b) => a - b),
          xCategories = [
            {
              category: currentXCategoryValues.map((item) => {
                return { label: item.toString() };
              }),
            },
          ];
        // console.log(
        //   "currentXCategoryValues",
        //   currentXCategoryValues,
        //   "xCategoryValues",
        //   xCategoryValues,
        //   "props",
        //   props
        // );
        // make a collection of parameters for each dm value
        const tempDataSources = {};
        dmVariableValues.forEach((dmVariableValue) => {
          // make series which determines what is actually plotted
          const series = xCategoryValues.map((testCode) => {
            return {
              name: testCode,
              data: xCategories[0].category.map((category) => {
                const data1 = res.data.filter(
                  (row) =>
                    row[parms.categoryVariable] === testCode &&
                    row[parms.xVariable] === category.label &&
                    row[selectedOption.value] === dmVariableValue
                );
                // console.log("data1", data1);
                const values = data1.map((item) =>
                    Number(item[parms.yVariable])
                  ),
                  boxValues = getStats(values);
                // console.log("boxValues", boxValues);
                return boxValues;
                // if (data1.length > 2) {
                //   return {
                //     value: data1
                //       .map((item) => Number(item[parms.yVariable]))
                //       .join(","),
                //   };
                // } else {
                //   const values = data1
                //     .map((item) => Number(item[parms.yVariable]))
                //     .join(",");
                //   return { value: values + "," + values + "," + values };
                // }
              }),
            };
          });
          // console.log('res.data', res.data)
          //   console.log("props", props, "xCategoryValues[0]", xCategoryValues[0]);
          const { low, high } =
            xCategoryValues[0] && xCategoryValues[0] in props.normalRanges
              ? props.normalRanges[xCategoryValues[0]]
              : { low: null, high: null };
          const selectedData = res.data.filter((row) =>
              xCategoryValues.includes(row[parms.categoryVariable])
            ),
            values = selectedData.map((item) => Number(item[parms.yVariable])),
            convert = (n) => {
              var order =
                n > 0
                  ? Math.floor(Math.log(n) / Math.LN10 + 0.000000001)
                  : null;

              return order ? Math.pow(10, order - 1) : 0;
            },
            max = Math.max(...values),
            min = Math.min(...values),
            roundUp = convert(max),
            roundDown = convert(min),
            yAxisMaxValue = Math.ceil(Math.max(...values) / roundUp) * roundUp,
            yAxisMinValue = roundDown
              ? Math.floor(Math.min(...values) / roundDown) * roundDown
              : 0,
            title = `${xCategoryValues.map((item) => item).join(", ")}`;
          //   console.log(
          //     "min",
          //     min,
          //     "yAxisMaxValue",
          //     yAxisMaxValue,
          //     "yAxisMinValue",
          //     yAxisMinValue,
          //     "xCategories",
          //     xCategories,
          //     "series",
          //     series,
          //     "low",
          //     low,
          //     "high",
          //     high,
          //     "roundUp",
          //     roundUp,
          //     "roundDown",
          //     roundDown
          //   );
          const dataSource = {
            chart: { type: "boxplot" },
            credits: {
              enabled: false,
            },
            title: { text: `${title} (${props.study}) [${dmVariableValue}]` },
            legend: { enabled: true },
            colors: [
              "#058DC7",
              "#50B432",
              "#ED561B",
              "#DDDF00",
              "#24CBE5",
              "#64E572",
              "#FF9655",
              "#FFF263",
              "#6AF9C4",
            ],
            series: series.map((use) => use),
            xAxis: {
              categories: xCategories[0].category.map((item) => item.label),
              title: {
                text: "Day of Treatment",
              },
            },
            yAxis: {
              min: yAxisMinValue,
              max: yAxisMaxValue,
              title: { text: title },
              plotBands: [
                {
                  from: low,
                  to: high,
                  color: "#e6ffe6",
                  label: {
                    text: "Normal",
                    style: {
                      color: "#606060",
                    },
                  },
                },
              ],
            },
          };
          //   const dataSource = {
          //     chart: {
          //       caption: `${title} (${props.study}) [${dmVariableValue}]`,
          //       subcaption: subtitle,
          //       palettecolors: "e4b293,f4decd",
          //       yaxisname: title,
          //       xaxisname: "Day",
          //       yAxisMaxValue: yAxisMaxValue,
          //       yAxisMinValue: yAxisMinValue,
          //       bgColor: "EEEEEE,CCCCCC",
          //       bgratio: "60,40",
          //       bgAlpha: "70,80",
          //       bgAngle: "180",
          //       plotspacepercent: "25",
          //       numVisiblePlot: "222",
          //       showalloutliers: "1",
          //       setAdaptiveYMin: "1",
          //       minimizeTendency: "1",
          //       // showVZeroPlane: "0",
          //       exportEnabled: "1",
          //       // "upperBoxColor": "#ffe6e6",
          //       // "lowerBoxColor": "#ccffcc",
          //     },
          //     categories: xCategories,
          //     dataset: series.map((use) => use),
          //     trendlines: low
          //       ? [
          //           {
          //             line: [
          //               {
          //                 isTrendZone: "1",
          //                 startvalue: low ? 0 : yAxisMinValue,
          //                 endValue: low,
          //                 color: "#0000ff",
          //                 valueOnRight: "1",
          //                 alpha: "20",
          //                 tooltext: `Low ${xCategoryValues[0]} (under ${low})`,
          //                 displayvalue: "Low",
          //               },
          //               {
          //                 isTrendZone: "1",
          //                 startvalue: high,
          //                 endValue: yAxisMaxValue,
          //                 color: "#ff0000",
          //                 valueOnRight: "1",
          //                 alpha: "20",
          //                 tooltext: `High ${xCategoryValues[0]} (over ${high})`,
          //                 displayvalue: "High",
          //               },
          //             ],
          //           },
          //         ]
          //       : null,
          //   };
          tempDataSources[dmVariableValue] = dataSource;
        });
        console.log("tempDataSources", tempDataSources);
        setDataSources(tempDataSources);
        setWaiting(false);
      }
    });
    // eslint-disable-next-line
  }, [
    props.selectedSubjects,
    props.study,
    props.selectedOptions,
    props.studyDatabase,
    xCategoryValues,
    subtitle,
    selectedOption,
    whereValues,
    selectedWidth,
  ]);

  //   console.log(
  //     "selectedCodes",
  //     selectedCodes,
  //     "selectedWidth.value",
  //     selectedWidth.value
  //   );

  return (
    <>
      <Grid container spacing={1}>
        {waiting && (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        )}
        {!waiting && (
          <Grid item xs={4}>
            <Select
              id="dmSelect"
              options={parms.dmOptions}
              value={selectedOption}
              onChange={(e) => changeSelectedOption(e)}
            ></Select>
          </Grid>
        )}
        {!waiting && (
          <Grid item xs={4}>
            <Select
              options={codeOptions}
              isMulti
              id={parms.table + "codeSelect"}
              closeMenuOnSelect={false}
              value={selectedCodes}
              onChange={(e) => changeCodes(e)}
              placeholder="Select test or measurement"
            ></Select>
          </Grid>
        )}
        {!waiting && (
          <Grid item xs={4}>
            <Select
              options={parms.widthOptions}
              value={selectedWidth}
              onChange={(e) => changeWidth(e)}
            ></Select>
          </Grid>
        )}

        {!waiting && (
          <>
            {selectedCodes && dataSources && selectedWidth.value
              ? Object.keys(dataSources).map((key, index) => (
                  <Grid item xs={Number(selectedWidth.value)} key={index}>
                    {/* <ReactFC
                                type="boxandwhisker2d"
                                width="100%"
                                height={props.screenHeight * 0.8}
                                dataFormat="JSON"
                                dataSource={dataSources[key]}
                            /> */}
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={dataSources[key]}
                    />
                  </Grid>
                ))
              : dataSources && selectedWidth.value
              ? "loading"
              : null}
          </>
        )}
      </Grid>
    </>
  );
}
