import React, { useState, useEffect } from "react";
import { sql, globalFilter, addToHistory } from "../../apis/utility";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import SubjectTable from "../SubjectTable";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import excelexport from "fusioncharts/fusioncharts.excelexport";
import Charts from "fusioncharts/fusioncharts.charts";
// import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import GammelTheme from "fusioncharts/themes/fusioncharts.theme.gammel";
import { LookBelow } from "../layout/LookBelow";
ReactFC.fcRoot(FusionCharts, Charts, GammelTheme, excelexport);

export default function DMInfo(props) {
  const location = useLocation();
  addToHistory({ title: "DM Info", url: location });

  const { detail } = props,
    whereSubjects =
      props.selectedSubjects && props.selectedSubjects.length > 0
        ? 'where SUBJID in ("' + props.selectedSubjects.join('","') + '")'
        : "",
    [subjectsDrilledTo, setSubjectsDrilledTo] = useState(null),
    sqlForAge = !detail
      ? "SELECT " +
        "count(CASE WHEN age >= 0 AND age < 10 THEN 1 END) AS [0-10]," +
        "count(CASE WHEN age >= 10 AND age < 20 THEN 1 END) AS [10-20]," +
        "count(CASE WHEN age >= 20 AND age < 30 THEN 1 END) AS [20-30]," +
        "count(CASE WHEN age >= 30 AND age < 40 THEN 1 END) AS [30-40]," +
        "count(CASE WHEN age >= 40 AND age < 50 THEN 1 END) AS [40-50]," +
        "count(CASE WHEN age >= 50 AND age < 60 THEN 1 END) AS [50-60]," +
        "count(CASE WHEN age >= 60 AND age < 70 THEN 1 END) AS [60-70]," +
        "count(CASE WHEN age >= 70 AND age < 80 THEN 1 END) AS [70-80]," +
        "count(CASE WHEN age >= 80 AND age < 90 THEN 1 END) AS [80-90]," +
        "count(CASE WHEN age >= 90 AND age < 100 THEN 1 END) AS [90-100] " +
        `FROM (SELECT CAST (age AS DECIMAL) AS age FROM dm ${whereSubjects});`
      : "SELECT " +
        "count(CASE WHEN age >= 0 AND age < 5 THEN 1 END) AS [0-5]," +
        "count(CASE WHEN age >= 5 AND age < 10 THEN 1 END) AS [5-10]," +
        "count(CASE WHEN age >= 10 AND age < 15 THEN 1 END) AS [10-15]," +
        "count(CASE WHEN age >= 15 AND age < 20 THEN 1 END) AS [15-20]," +
        "count(CASE WHEN age >= 20 AND age < 25 THEN 1 END) AS [20-25]," +
        "count(CASE WHEN age >= 25 AND age < 30 THEN 1 END) AS [25-30]," +
        "count(CASE WHEN age >= 30 AND age < 35 THEN 1 END) AS [30-35]," +
        "count(CASE WHEN age >= 35 AND age < 40 THEN 1 END) AS [35-40]," +
        "count(CASE WHEN age >= 40 AND age < 45 THEN 1 END) AS [40-45]," +
        "count(CASE WHEN age >= 45 AND age < 50 THEN 1 END) AS [45-50]," +
        "count(CASE WHEN age >= 50 AND age < 55 THEN 1 END) AS [50-55]," +
        "count(CASE WHEN age >= 55 AND age < 60 THEN 1 END) AS [55-60]," +
        "count(CASE WHEN age >= 60 AND age < 65 THEN 1 END) AS [60-65]," +
        "count(CASE WHEN age >= 65 AND age < 70 THEN 1 END) AS [65-70]," +
        "count(CASE WHEN age >= 70 AND age < 75 THEN 1 END) AS [70-75]," +
        "count(CASE WHEN age >= 75 AND age < 80 THEN 1 END) AS [75-80]," +
        "count(CASE WHEN age >= 80 AND age < 85 THEN 1 END) AS [80-85]," +
        "count(CASE WHEN age >= 85 AND age < 90 THEN 1 END) AS [85-90]," +
        "count(CASE WHEN age >= 90 AND age < 95 THEN 1 END) AS [90-95]," +
        "count(CASE WHEN age >= 95 AND age < 100 THEN 1 END) AS [95-100] " +
        `FROM (SELECT CAST (age AS DECIMAL) AS age FROM dm ${whereSubjects});`,
    sqlForSex = `select sex, count(*) as n from dm ${whereSubjects} group by sex`,
    sqlForPkpd = `select pkset||','||pdset as PKPD, count(*) as n from dm ${whereSubjects} group by PKPD`,
    sqlForCountry = `select country, count(*) as n from dm ${whereSubjects} group by country`,
    sqlForRace = `select race, count(*) as n from dm ${whereSubjects} group by race`,
    sqlForEthnic = `select ethnic, count(*) as n from dm ${whereSubjects} group by ethnic`,
    sqlForSite = `select site, count(*) as n from dm ${whereSubjects} group by site`,
    sqlForArmcd = `select armcd, count(*) as n from dm ${whereSubjects} group by armcd`,
    [subtitle, setSubtitle] = useState(null),
    [dataAge, setDataAge] = useState(null),
    [dataSex, setDataSex] = useState(null),
    [dataPkpd, setDataPkpd] = useState(null),
    [dataCountry, setDataCountry] = useState(null),
    [dataRace, setDataRace] = useState(null),
    [dataEthnic, setDataEthnic] = useState(null),
    [dataSite, setDataSite] = useState(null),
    [dataArmcd, setDataArmcd] = useState(null);

  useEffect(() => {
    setSubtitle(globalFilter(props.selectedOptions));
    sql(props.studyDatabase, sqlForAge).then((res) => {
      if (res.message === "success") {
        // console.log('res.data', res.data)
        const data = Object.keys(res.data[0]).map((key) => {
          return {
            label: key,
            value: res.data[0][key],
          };
        });
        // console.log('data', data)
        setDataAge({
          chart: {
            caption: "Age in years (" + props.study + ")",
            subcaption: subtitle,
            xaxisname: "Age in years",
            yaxisname: "Number of Subjects",
            palettecolors: "e4b293",
            // theme: "gammel",
            bgColor: "EEEEEE,CCCCCC",
            bgratio: "60,40",
            bgAlpha: "70,80",
            bgAngle: "180",
            exportEnabled: "1",
          },
          data: data,
        });
      }
    });
    sql(props.studyDatabase, sqlForSex).then((res) => {
      if (res.message === "success") {
        const data = res.data.map((item) => {
          return {
            label: item.SEX,
            value: item.n,
          };
        });
        setDataSex({
          chart: {
            caption: "Sex (" + props.study + ")",
            subcaption: subtitle,
            yaxisname: "Sex",
            xaxisname: "Number of Subjects",
            palettecolors: "FF33C3,3368FF",
            bgColor: "EEEEEE,CCCCCC",
            bgratio: "60,40",
            bgAlpha: "70,80",
            bgAngle: "180",
            exportEnabled: "1",
          },
          data: data,
        });
      }
    });
    sql(props.studyDatabase, sqlForPkpd).then((res) => {
      if (res.message === "success") {
        const data = res.data.map((item) => {
          return {
            label: item.PKPD,
            value: item.n,
          };
        });
        setDataPkpd({
          chart: {
            caption: "PK,PD (" + props.study + ")",
            subcaption: subtitle,
            yaxisname: "PK,PD",
            xaxisname: "Number of Subjects",
            palettecolors: "FF33C3,3368FF",
            bgColor: "EEEEEE,CCCCCC",
            bgratio: "60,40",
            bgAlpha: "70,80",
            bgAngle: "180",
            exportEnabled: "1",
          },
          data: data,
        });
      }
    });
    sql(props.studyDatabase, sqlForCountry).then((res) => {
      if (res.message === "success") {
        const data = res.data.map((item) => {
          return {
            label: item.COUNTRY,
            value: item.n,
          };
        });
        setDataCountry({
          chart: {
            caption: "Country (" + props.study + ")",
            subcaption: subtitle,
            yaxisname: "Country",
            xaxisname: "Number of Subjects",
            palettecolors: "FF33C3,3368FF",
            bgColor: "EEEEEE,CCCCCC",
            bgratio: "60,40",
            bgAlpha: "70,80",
            bgAngle: "180",
            exportEnabled: "1",
          },
          data: data,
        });
      }
    });
    sql(props.studyDatabase, sqlForRace).then((res) => {
      if (res.message === "success") {
        const data = res.data.map((item) => {
          return {
            label: item.RACE,
            value: item.n,
          };
        });
        setDataRace({
          chart: {
            caption: "Race (" + props.study + ")",
            subcaption: subtitle,
            yaxisname: "Race",
            xaxisname: "Number of Subjects",
            bgColor: "EEEEEE,CCCCCC",
            bgratio: "60,40",
            bgAlpha: "70,80",
            bgAngle: "180",
            exportEnabled: "1",
          },
          data: data,
        });
      }
    });
    sql(props.studyDatabase, sqlForEthnic).then((res) => {
      if (res.message === "success") {
        const data = res.data.map((item) => {
          return {
            label: item.ETHNIC,
            value: item.n,
          };
        });
        setDataEthnic({
          chart: {
            caption: "Ethnic (" + props.study + ")",
            subcaption: subtitle,
            yaxisname: "Ethnic",
            xaxisname: "Number of Subjects",
            bgColor: "EEEEEE,CCCCCC",
            bgratio: "60,40",
            bgAlpha: "70,80",
            bgAngle: "180",
            exportEnabled: "1",
          },
          data: data,
        });
      }
    });
    sql(props.studyDatabase, sqlForSite).then((res) => {
      if (res.message === "success") {
        const data = res.data.map((item) => {
          return {
            label: item.SITE,
            value: item.n,
          };
        });
        setDataSite({
          chart: {
            caption: "Site (" + props.study + ")",
            subcaption: subtitle,
            yaxisname: "Site",
            xaxisname: "Number of Subjects",
            bgColor: "EEEEEE,CCCCCC",
            bgratio: "60,40",
            bgAlpha: "70,80",
            bgAngle: "180",
            exportEnabled: "1",
          },
          data: data,
        });
      }
    });
    sql(props.studyDatabase, sqlForArmcd).then((res) => {
      if (res.message === "success") {
        const data = res.data.map((item) => {
          return {
            label: item.ARMCD,
            value: item.n,
          };
        });
        setDataArmcd({
          chart: {
            caption: "Arm (" + props.study + ")",
            subcaption: subtitle,
            yaxisname: "Arm",
            xaxisname: "Number of Subjects",
            bgColor: "EEEEEE,CCCCCC",
            bgratio: "60,40",
            bgAlpha: "70,80",
            bgAngle: "180",
            exportEnabled: "1",
          },
          data: data,
        });
      }
    });
  }, [
    props.selectedSubjects,
    props.study,
    props.studyDatabase,
    props.selectedOptions,
    subtitle,
    sqlForAge,
    sqlForSex,
    sqlForArmcd,
    sqlForEthnic,
    sqlForRace,
    sqlForSite,
    sqlForCountry,
    sqlForPkpd,
  ]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {dataAge ? (
            <ReactFC
              type="bar2d"
              width="100%"
              height={detail ? props.screenHeight * 0.55 : "180"}
              dataFormat="JSON"
              dataSource={dataAge}
              events={{
                dataPlotClick: function (e) {
                  // console.log(e.data)
                  const range = e.data.id.split("-");
                  sql(
                    props.studyDatabase,
                    `select distinct SUBJID from dm where age>=${range[0]} and age<${range[1]}`
                  ).then((res) => {
                    // console.log('res', res)
                    if (res.message === "success") {
                      setSubjectsDrilledTo(res.data.map((d) => d.SUBJID));
                    }
                  });
                },
              }}
            />
          ) : (
            "loading"
          )}
        </Grid>
        <Grid item xs={3}>
          {dataSex ? (
            <ReactFC
              type="pie2d"
              width="100%"
              height={detail ? props.screenHeight * 0.275 : "80"}
              dataFormat="JSON"
              dataSource={dataSex}
              events={{
                dataPlotClick: function (e) {
                  sql(
                    props.studyDatabase,
                    `select distinct SUBJID from dm where sex='${e.data.id}'`
                  ).then((res) => {
                    if (res.message === "success") {
                      setSubjectsDrilledTo(res.data.map((d) => d.SUBJID));
                    }
                  });
                },
              }}
            />
          ) : (
            "loading"
          )}
          {dataPkpd ? (
            <ReactFC
              type="pie2d"
              width="100%"
              height={detail ? props.screenHeight * 0.275 : "80"}
              dataFormat="JSON"
              dataSource={dataPkpd}
              events={{
                dataPlotClick: function (e) {
                  const split = e.data.id.split(","),
                    pkset = split[0],
                    pdset = split[1];
                  sql(
                    props.studyDatabase,
                    `select distinct SUBJID from dm where pkset='${pkset}' and pdset='${pdset}'`
                  ).then((res) => {
                    if (res.message === "success") {
                      setSubjectsDrilledTo(res.data.map((d) => d.SUBJID));
                    }
                  });
                },
              }}
            />
          ) : (
            "loading"
          )}
        </Grid>
        <Grid item xs={3}>
          {dataArmcd ? (
            <ReactFC
              type="pie2d"
              width="100%"
              height={detail ? props.screenHeight * 0.275 : "80"}
              dataFormat="JSON"
              dataSource={dataArmcd}
              events={{
                dataPlotClick: function (e) {
                  sql(
                    props.studyDatabase,
                    `select distinct SUBJID from dm where armcd='${e.data.id}'`
                  ).then((res) => {
                    if (res.message === "success") {
                      setSubjectsDrilledTo(res.data.map((d) => d.SUBJID));
                    }
                  });
                },
              }}
            />
          ) : (
            "loading"
          )}
          {dataCountry ? (
            <ReactFC
              type="pie2d"
              width="100%"
              height={detail ? props.screenHeight * 0.275 : "80"}
              dataFormat="JSON"
              dataSource={dataCountry}
              events={{
                dataPlotClick: function (e) {
                  sql(
                    props.studyDatabase,
                    `select distinct SUBJID from dm where country='${e.data.id}'`
                  ).then((res) => {
                    if (res.message === "success") {
                      setSubjectsDrilledTo(res.data.map((d) => d.SUBJID));
                    }
                  });
                },
              }}
            />
          ) : (
            "loading"
          )}{" "}
        </Grid>
        <Grid item xs={4}>
          {dataRace ? (
            <ReactFC
              type="pie2d"
              width="100%"
              height={detail ? props.screenHeight * 0.2 : "80"}
              dataFormat="JSON"
              dataSource={dataRace}
              events={{
                dataPlotClick: function (e) {
                  sql(
                    props.studyDatabase,
                    `select distinct SUBJID from dm where race='${e.data.id}'`
                  ).then((res) => {
                    if (res.message === "success") {
                      setSubjectsDrilledTo(res.data.map((d) => d.SUBJID));
                    }
                  });
                },
              }}
            />
          ) : (
            "loading"
          )}
        </Grid>
        <Grid item xs={4}>
          {dataEthnic ? (
            <ReactFC
              type="pie2d"
              width="100%"
              height={detail ? props.screenHeight * 0.2 : "80"}
              dataFormat="JSON"
              dataSource={dataEthnic}
              events={{
                dataPlotClick: function (e) {
                  sql(
                    props.studyDatabase,
                    `select distinct SUBJID from dm where ethnic='${e.data.id}'`
                  ).then((res) => {
                    if (res.message === "success") {
                      setSubjectsDrilledTo(res.data.map((d) => d.SUBJID));
                    }
                  });
                },
              }}
            />
          ) : (
            "loading"
          )}
        </Grid>
        <Grid item xs={4}>
          {dataSite ? (
            <ReactFC
              type="pie2d"
              width="100%"
              height={detail ? props.screenHeight * 0.2 : "80"}
              dataFormat="JSON"
              dataSource={dataSite}
              events={{
                dataPlotClick: function (e) {
                  sql(
                    props.studyDatabase,
                    `select distinct SUBJID from dm where site='${e.data.id}'`
                  ).then((res) => {
                    if (res.message === "success") {
                      setSubjectsDrilledTo(res.data.map((d) => d.SUBJID));
                    }
                  });
                },
              }}
            />
          ) : (
            "loading"
          )}
        </Grid>
      </Grid>
      {subjectsDrilledTo && (
        <LookBelow
          label="Subject Table"
          tooltip="Click to scroll to subject table appears below"
          mt={2}
          ml={4}
          mr={0}
          mb={0}
        />
      )}
      {subjectsDrilledTo && (
        <SubjectTable
          screenHeight={props.screenHeight}
          selectedSubjects={subjectsDrilledTo}
          study={props.study}
          studyDatabase={props.studyDatabase}
          selectedOptions={props.selectedOptions}
        />
      )}
    </>
  );
}
