import React, { useEffect } from "react";
import { Divider } from "@mui/material";
import Select from 'react-select'
import { getSubjects } from '../../apis/utility';

function GenerateSelectsWithinAnArea(props, area) {
    const { selectedOptions, varToTable, setSelectedSubjects, setSelectedOptions } = props,
        changeSelectedOptions = (e, key) => {
            // console.log('e', e, 'key', key)
            setSelectedOptions({ ...selectedOptions, [key]: e })
        },
        customStyles = {
            option: (provided, state) => ({
                ...provided,
                borderBottom: '2px dotted gray',
                color: state.isSelected ? 'green' : 'black',
                padding: 0,
            })
        }

    // when user changes the selected options, update the selected subjects
    useEffect(() => {
        // console.log('props', props)
        getSubjects(props.studyDatabase, selectedOptions, varToTable)
            .then(res => {
                // console.log('GenerateSelects - res', res)
                setSelectedSubjects(res)
            })
        // eslint-disable-next-line 
    }, [selectedOptions, props.studyDatabase])


    return Object.keys(area.options).map((key, index) => {
        const option = area.options[key];
        const theseSelectedOptions = selectedOptions[key] || null
        return (
            <React.Fragment key={key}>
                <b>{option.label}</b>
                <Select
                    key={key}
                    styles={customStyles}
                    closeMenuOnSelect={false}
                    isMulti
                    options={option.options}
                    value={theseSelectedOptions}
                    color={'black'}
                    onChange={(e) => changeSelectedOptions(e, key)}>
                </Select>
            </React.Fragment>
        )
    })
}
export function GenerateSelects(props) {
    return props.optionsForSelection
        .map((area, index) => {
            return (
                <React.Fragment key={index}>
                    <Divider>
                        <b>{area.label}</b>
                    </Divider>
                    {GenerateSelectsWithinAnArea(props, area)}
                </React.Fragment>
            )
        })
}