import * as React from "react";
import {
  Typography,
  Stack,
  Paper,
  Tooltip,
  IconButton,
  Badge,
  Box,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { addToHistory } from "../../apis/utility";
import { saeReports, saeReports2, ciomsReports1 } from "../../apis/reports";
import { HealthAndSafety, Mail } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
// import pdfmake from "pdfmake/build/pdfmake";
// import { Document, Packer, Paragraph, TextRun } from "docx";
// import { saveAs } from "file-saver";
// import Mail from "@mui/icons-material/Mail";

export const AEReports = (props) => {
  const location = useLocation(),
    {
      studyDatabase,
      // selectedOptions,
      // varToTable,
      // setSelectedSubjects,
      // selectedSubjects,
      // screenWidth,
      seriousResults,
      //   screenHeight,
      study,
      //   setSelectedOptions,
      showCm,
      showMh,
    } = props,
    Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));
  // serverUrl = window.location.host.startsWith("localhost")
  //   ? "http://localhost:3001"
  //   : "https://safetymanagementsystemserver.onrender.com",
  // openUrl = (url) => {
  //   window.open(url, "_blank");
  // },
  // fonts = {
  //   Courier: {
  //     normal: "Courier",
  //     bold: "Courier-Bold",
  //     italics: "Courier-Oblique",
  //     bolditalics: "Courier-BoldOblique",
  //   },
  //   Helvetica: {
  //     normal: "Helvetica",
  //     bold: "Helvetica-Bold",
  //     italics: "Helvetica-Oblique",
  //     bolditalics: "Helvetica-BoldOblique",
  //   },
  //   Roboto: {
  //     normal:
  //       "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
  //     bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
  //     italics:
  //       "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
  //     bolditalics:
  //       "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  //   },
  // },
  // makePdf = () => {
  //   const docDefinition = {
  //     content: [
  //       { text: "Example Report", style: "header" },
  //       "Text would go here.",
  //       { text: "More text", style: "anotherStyle" },
  //       {
  //         text: "Multiple styles applied",
  //         style: ["header", "anotherStyle"],
  //       },
  //     ],
  //     styles: {
  //       header: {
  //         fontSize: 22,
  //         bold: true,
  //       },
  //       anotherStyle: {
  //         italics: true,
  //         alignment: "right",
  //       },
  //     },
  //   };
  //   pdfmake.createPdf(docDefinition, null, fonts).download();
  // },
  // makeDocx = () => {
  //   const docx = new Document({
  //     sections: [
  //       {
  //         properties: {},
  //         children: [
  //           new Paragraph({
  //             children: [
  //               new TextRun("Example Report. "),
  //               new TextRun({
  //                 text: "Text goes here.",
  //                 bold: true,
  //               }),
  //               new TextRun({
  //                 text: "\tMore text",
  //                 bold: true,
  //               }),
  //             ],
  //           }),
  //         ],
  //       },
  //     ],
  //   });
  //   Packer.toBlob(docx).then((blob) => {
  //     saveAs(blob, "example.docx");
  //     console.log("Word document created successfully");
  //   });
  // },

  addToHistory({ title: "Reporting", url: location });

  return (
    <>
      <Typography sx={{ mt: 2, mb: 3 }}>AE Reports</Typography>
      <Typography sx={{ mb: 3, fontWeight: "bold" }}>
        Serious AE Report examples:
      </Typography>
      The following are examples of reports that can be generated from our data
      into various formats. These examples show how to make Microsoft Word
      files, although we can also make PDF files and Excel files if required.
      <Stack
        spacing={2}
        direction="column"
        justifyContent="flex-start"
        alignItems={"flex-start"}
        sx={{ mt: 3 }}
      >
        <Item>
          {seriousResults && seriousResults.data.length > 0 && (
            <Tooltip title="Produce a Microsoft Word document containing a Serious Adverse Event (SAE) report for each SAE in the study (Example 1).">
              <Box>
                <IconButton
                  sx={{
                    mt: 1,
                    mb: 1,
                    backgroundColor: "primary.light",
                    "&:hover": { backgroundColor: "secondary.light" },
                  }}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    saeReports(seriousResults, studyDatabase, study, {
                      cm: showCm,
                      mh: showMh,
                    });
                  }}
                >
                  <Badge color="primary">
                    <HealthAndSafety color="action" />
                  </Badge>
                </IconButton>
                &nbsp;<b>Serious AE Report (type 1)</b>
              </Box>
            </Tooltip>
          )}
        </Item>
        <Item>
          {seriousResults && seriousResults.data.length > 0 && (
            <Tooltip title="Produce a Microsoft Word document containing a Serious Adverse Event (SAE) report for each SAE in the study (Example 2).">
              <Box>
                <IconButton
                  sx={{
                    mt: 1,
                    mb: 1,
                    backgroundColor: "primary.light",
                    "&:hover": { backgroundColor: "secondary.light" },
                  }}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    saeReports2(seriousResults, studyDatabase, study, {
                      cm: showCm,
                      mh: showMh,
                    });
                  }}
                >
                  <Badge color="primary">
                    <HealthAndSafety color="action" />
                  </Badge>
                </IconButton>
                &nbsp;<b>Serious AE Report (type 2)</b>
              </Box>
            </Tooltip>
          )}
        </Item>
        <Item>
          {seriousResults && seriousResults.data.length > 0 && (
            <Tooltip
              title="Produce a Microsoft Word document containing a Council for
            International Organization of Medical Sciences (CIOMS) report for each
            SAE in the study."
            >
              <Box>
                <IconButton
                  sx={{
                    mt: 1,
                    mb: 1,
                    backgroundColor: "primary.light",
                    "&:hover": { backgroundColor: "secondary.light" },
                  }}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    ciomsReports1(seriousResults, studyDatabase, study, {
                      cm: showCm,
                      mh: showMh,
                    });
                  }}
                >
                  <Badge color="primary">
                    <Mail color="action" />
                  </Badge>
                </IconButton>
                &nbsp;<b>CIOMS</b>
              </Box>
            </Tooltip>
          )}
        </Item>
      </Stack>
    </>
  );
};
