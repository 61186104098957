import React, { useState } from "react";
import Mermaid from "./Mermaid";
import { Button, TextField } from "@mui/material";

export default function Diagrams() {
  const [chart, setChart] = useState(`flowchart TB
  c1-->a2
  subgraph one
  a1-->a2
  end
  subgraph two
  b1-->b2
  end
  subgraph three
  c1-->c2
  end        `),
    z = `flowchart LR\na --> b & c--> d\n`,
    a = `flowchart TB
        c1-->a2
        subgraph one
        a1-->a2
        end
        subgraph two
        b1-->b2
        end
        subgraph three
        c1-->c2
        end`,
    b = `flowchart LR
        A --o B
        B --x C`,
    c = `sequenceDiagram
        participant Alice
        participant John
        links Alice: {"Dashboard": "https://dashboard.contoso.com/alice", "Wiki": "https://wiki.contoso.com/alice"}
        links John: {"Dashboard": "https://dashboard.contoso.com/john", "Wiki": "https://wiki.contoso.com/john"}
        Alice->>John: Hello John, how are you?
        John-->>Alice: Great!
        Alice-)John: See you later!`,
    d = `erDiagram
        CAR ||--o{ NAMED-DRIVER : allows
        CAR {
            string allowedDriver FK "The license of the allowed driver"
            string registrationNumber
            string make
            string model
        }
        PERSON ||--o{ NAMED-DRIVER : is
        PERSON {
            string driversLicense PK "The license #"
            string firstName
            string lastName
            int age
        }`,
    e = `gantt
        title A Gantt Diagram
        dateFormat  YYYY-MM-DD
        section Section
        A task           :a1, 2014-01-01, 30d
        Another task     :after a1  , 20d
        section Another
        Task in sec      :2014-01-12  , 12d
        another task      : 24d`,
    f = `pie showData
        title Key elements in Product X
        "Calcium" : 42.96
        "Potassium" : 50.05
        "Magnesium" : 10.01
        "Iron" :  5`;

  // if (chart) { setChart("graph TD; A-->B;"); }
  // return (<Mermaid chart={chart} />)
  return (
    <>
      <Button onClick={() => setChart(z)}>Example 0</Button>
      <Button onClick={() => setChart(a)}>Example 1</Button>
      <Button onClick={() => setChart(b)}>Example 2</Button>
      <Button onClick={() => setChart(c)}>Example 3</Button>
      <Button onClick={() => setChart(d)}>Example 4</Button>
      <Button onClick={() => setChart(e)}>Example 5</Button>
      <Button onClick={() => setChart(f)}>Example 6</Button>
      {/* <MermaidReact
            id='test'
            mmd={diagram}
            onClick={(e) => console.log('test Click', e)}
        /> */}
      <Mermaid chart={chart} useMaxWidth={false} />
      <TextField
        id="standard-textarea"
        label="Diagram Code"
        placeholder="Placeholder"
        multiline
        fullWidth
        variant="standard"
        value={chart}
      />
    </>
  );
}
