import axios from "axios";
import moment from "moment";
import Handsontable from "handsontable";

const $ = require("jquery"),
  serverUrl = window.location.host.startsWith("localhost")
    ? "http://localhost:3001"
    : window.location.host.startsWith("192.168.1.120")
    ? "http://192.168.1.120:3001"
    : window.location.host.startsWith("192.168.")
    ? "http://localhost:3001"
    : "http://134.122.110.66";

// used in AdverseEventsGraph.jsx
export function dataToBarOptions(data, category, variables, contrastingColor) {
  // console.log('variables', variables);
  const series = variables.map((variable) => {
    return {
      name: variable,
      data: data.map((row) => {
        return {
          name: variable,
          y: row[variable],
          drilldown: true,
        };
      }),
    };
  });
  const categories = data.map((row) => {
    let val = row[category];
    if (val === null) {
      val = "Unknown";
    }
    return val;
  });
  return {
    series: series,
    // drilldown: {series: []},
    xAxis: {
      categories: categories,
      labels: {
        style: {
          color: contrastingColor,
        },
      },
    },
  };
}
export const addToHistory = (historyItem, notitle) => {
  // get history & if it's a new page then add to history
  let history = JSON.parse(sessionStorage.getItem("history")) || [];
  if (history.length === 0) history.push(historyItem);
  else {
    const lastItem = history[history.length - 1];
    if (lastItem.url.pathname !== historyItem.url.pathname) {
      // console.log('addToHistory - history', history, 'historyItem', historyItem);
      history.push(historyItem);
    }
  }
  sessionStorage.setItem("history", JSON.stringify(history));
  if (!notitle) document.title = "Xploratum " + historyItem.title;
};

export const round = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

export const lightenDarkenColor = (col, amt) => {
  var usePound = false;
  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

export function dataToStreamOptions(
  data,
  category,
  minDay,
  maxDay,
  colorLookup,
  indexLookup,
  invert,
  theme,
  force3Severities = false
) {
  // console.log('data', data, 'category', category, 'minDay', minDay, 'maxDay', maxDay, 'colorLookup', colorLookup, 'indexLookup', indexLookup, 'invert', invert, 'theme', theme);
  const categoryValues = data.map((row) => row[category]);
  let categories = [...new Set([...categoryValues])];
  // console.log('categories', categories);
  if (force3Severities) {
    if (
      categories.includes("MILD") ||
      categories.includes("MODERATE") ||
      categories.includes("SEVERE")
    ) {
      categories = ["MILD", "MODERATE", "SEVERE"]; // we always want these 3 categories even if there is no data for them
    } else {
      categories = ["T1", "T2", "T3", "T4", "T5"]; // we always want these 5 categories even if there is no data for them
    }
  }

  const invertFactor = invert ? -1 : 1,
    series = categories.map((categoryValue) => {
      // console.log('categoryValue', categoryValue);
      const subseries = data.filter((row) => row[category] === categoryValue);
      // console.log('subseries', subseries, 'categories', categories)
      // modify color based on invert
      let colorToUse = colorLookup
        ? categoryValue in colorLookup
          ? colorLookup[categoryValue]
          : "gray"
        : "gray";
      // colorToUse = invert
      //     ? lightenDarkenColor(colorToUse, 20)
      //     : colorToUse;
      // console.log('colorToUse', colorToUse);
      const tempSeries = Array.from({ length: maxDay - minDay }, (_, i) => {
        return { name: categoryValue, y: 0 };
      });
      subseries.forEach((_, i) => {
        const index = _.day;
        tempSeries[index] = { name: categoryValue, y: invertFactor * _.count };
      });
      // console.log('tempSeries', tempSeries)
      if (colorLookup && indexLookup)
        return {
          name: categoryValue,
          id: categoryValue,
          data: tempSeries,
          color: colorToUse,
          index: indexLookup
            ? categoryValue in indexLookup
              ? indexLookup[categoryValue]
              : 0
            : 0,
        };
      else
        return {
          name: categoryValue,
          data: tempSeries,
        };
    }),
    xAxisValues = [],
    contrastingColor =
      theme && theme.palette && theme.palette.mode === "dark"
        ? "white"
        : "black";
  for (let i = minDay; i < maxDay + 1; i++) {
    xAxisValues.push(i.toString());
  }
  // console.log('xAxisValues', xAxisValues, 'minDay', minDay, 'maxDay', maxDay, 'series', series);

  return {
    series: series,
    // drilldown: {series: []},
    xAxis: {
      maxPadding: 0,
      type: "category",
      crosshair: true,
      categories: xAxisValues,
      labels: {
        align: "left",
        reserveSpace: true,
        rotation: 270,
        y: 25,
        style: { color: contrastingColor },
      },
      lineWidth: 0,
      // margin: 20,
      tickWidth: 0,
      max: maxDay,
      title: { text: "Day of treatment", margin: 15 },
    },
  };
}

// run SQL against sqlite database
export function sql(db, sqlCode) {
  // const useDb = db ? db : 'sdtm';
  // console.log('db', db, 'sqlCode', sqlCode);
  if (db === null || db === undefined)
    return Promise.resolve({
      message: "fail",
      data: [],
      info: "No database selected",
    });
  // console.log('length', sqlCode.length);
  if (sqlCode.length > 2048) return sqlpost(db, sqlCode);
  else
    return axios
      .get(serverUrl + "/sql/" + db + "/" + sqlCode)
      .then((response) => {
        // console.log(response);
        return response.data;
      })
      .catch((error) => {
        console.error("error", error, serverUrl + "/sql/" + db + "/" + sqlCode);
        return {
          message: "fail",
          data: [],
          info:
            error && error.response && error.response.data
              ? error.response.data.error
              : error,
        };
      });
}

// run SQL against sqlite database
export function sqlpost(db, sqlCode) {
  // console.log('sqlpost - db', db, 'sqlCode', sqlCode);
  if (db === null || db === undefined)
    return Promise.resolve({
      message: "fail",
      data: [],
      info: "No database selected",
    });
  const payload = { sql: sqlCode },
    headers = { headers: { "content-type": "application/json" } };
  // console.log('about to call axios.post', 'db', db, 'sqlCode', sqlCode, serverUrl + '/sqlpost/' + db, payload, headers);
  return axios
    .post(serverUrl + "/sqlpost/" + db, payload, headers)
    .then((response) => {
      // console.log(response);
      return response.data;
    })
    .catch((error) => {
      console.error("error", error);
      return {
        message: "fail",
        data: [],
        info:
          error && error.response && error.response.data
            ? error.response.data.error
            : error,
      };
    });
}

// run SQL against url-notes sqlite database
//TODO: add study to database so we can have different notes for the same screen but different studies
export function note(sqlCode) {
  return axios.get(serverUrl + "/note/" + sqlCode).then((response) => {
    // console.log(response.data);
    return response.data;
  });
}
// used in Table component to apply styles to cells based on their content
export function createdCell(td, cellData, rowData, row, col) {
  const colName = Object.keys(rowData)[col];
  switch (colName) {
    case "aesev":
      switch (cellData) {
        case "SEVERE":
          $(td).css("background-color", "#ffcccc");
          break;
        case "MODERATE":
          $(td).css("background-color", "#eeee00");
          break;
        default:
          break;
      }
      break;
    case "visit":
      if (Number(cellData) < 50) {
        $(td).css("background-color", "#e6fff2");
      }
      break;
    case "col0":
    case "col2":
      $(td).css("background-color", "#e6ffe6");
      break;
    case "col1":
      $(td).css("background-color", "#efffef");
      break;
    case "col3":
    case "col5":
    case "col7":
    case "col9":
    case "col11":
    case "col13":
    case "col15":
    case "col17":
    case "col19":
    case "col21":
    case "col23":
    case "col25":
    case "col27":
    case "col29":
    case "col31":
    case "col33":
    case "col35":
    case "col37":
    case "col39":
      $(td).css("background-color", "#cceeff");
      break;
    case "col4":
      $(td).css("background-color", "#e6f7ff");
      break;
    default:
      if (row % 2 === 1) {
        $(td).css("background-color", "#f2f2f2");
      } else {
        $(td).css("background-color", "#ffffff");
      }
  }
  // AEs start with 4 non-breakable spaces in the key value
  if (
    typeof rowData["key"] === "string" &&
    rowData["key"].startsWith("&nbsp;&nbsp;&nbsp;&nbsp;") &&
    cellData &&
    col !== 0
  ) {
    // console.log('td', td, 'cellData', cellData, 'rowData', rowData, 'row', row, 'col', col)
    $(td).css("background-color", "#ffff80");
    if (cellData.includes("data-colspan")) {
      const [, days] = cellData.match(/"((?:\\.|[^"\\])*)"/);
      // console.log('days', days)
      $(td).attr("colspan", days);
    }
  }
  //  use data-colspan to span multiple columns
  if (
    typeof rowData["key"] === "string" &&
    rowData["key"].startsWith("&nbsp;") &&
    cellData &&
    col !== 0 &&
    typeof cellData === "string" &&
    cellData.includes("data-colspan")
  ) {
    const [, cols] = cellData.match(/"((?:\\.|[^"\\])*)"/);
    $(td).attr("colspan", cols);
  }
  // highlight cells which have a key starting with underline (used for labs)
  if (
    typeof rowData["key"] === "string" &&
    rowData["key"].startsWith("<u>") &&
    typeof cellData === "string" &&
    col !== 0 &&
    cellData.includes("data-background")
  ) {
    const [, color] = cellData.match(/"((?:\\.|[^"\\])*)"/);
    $(td).css("background-color", color);
  }
}
export function formatVisits(td, cellData, rowData, row, col) {
  // console.log('td', td, 'cellData', cellData, 'rowData', rowData, 'row', row, 'col', col)
  if (row % 2 === 1) {
    $(td).css("background-color", "#f2f2f2");
  } else {
    $(td).css("background-color", "#ffffff");
  }
  // const colName = Object.keys(rowData)[col];
  if (col % 2 === 1) {
    $(td).css("background-color", "#b3ffd7");
  } else {
    $(td).css("background-color", "#e6ffe6");
  }
}
export const globalFilter = (selectedOptions) => {
  if (selectedOptions) {
    const keys = Object.keys(selectedOptions);
    return keys
      .map((key, index) => {
        return (
          key +
          " in (" +
          selectedOptions[key]
            .map((item, i) => {
              return "'" + item.value + "'";
            })
            .join(",") +
          ")"
        );
      })
      .join(" and ");
  } else return null;
};
// make SQL statement from selected options
export function makeSqlStatement(selectedOptions, varToTable) {
  const keys = Object.keys(selectedOptions);
  // console.log('keys', keys)
  let table = "vd"; // default table
  const tempWhereClause = keys
    .map((key, index) => {
      table = varToTable[key];
      // console.log(key, 'uses', table, 'selectedOptions[key]', selectedOptions[key])
      return (
        key +
        " in (" +
        selectedOptions[key]
          .map((item, i) => {
            return "'" + item.value + "'";
          })
          .join(",") +
        ")"
      );
    })
    .join(" and ");
  // console.log('tempWhereClause', tempWhereClause)
  const whereClause = tempWhereClause === "" ? "" : " where " + tempWhereClause;
  const tables = [];
  keys.forEach((key, index) => {
    table = varToTable[key];
    if (!tables.includes(table)) {
      tables.push(table);
    }
  });
  // console.log('tables', tables)
  const sqlStatements =
    tables && tables.length > 0
      ? tables.map((table, index) => {
          if (index === 0) {
            return `select distinct ${table}.SUBJID from ${table}`;
          } else {
            return `inner join ${table} on ${tables[0]}.SUBJID=${table}.SUBJID`;
          }
        })
      : [];
  // console.log('sqlStatements', sqlStatements)
  const sqlStatement =
    sqlStatements.length > 0
      ? sqlStatements.join(" ") + " " + whereClause
      : "select distinct SUBJID from vd";
  // console.log('sqlStatement', sqlStatement)
  return [sqlStatement];
}
// apply filters to get list of subjects
export function getSubjects(studyDatabase, filters, varToTable) {
  const sqlStatement = makeSqlStatement(filters, varToTable);
  // console.log('studyDatabase', studyDatabase, 'sqlStatement', sqlStatement, 'filters', filters, 'varToTable', varToTable)
  return sql(studyDatabase, sqlStatement).then((res) => {
    const subjects = res.data.map((row) => {
      return row.SUBJID;
    });
    // console.log('subjects', subjects)
    return subjects;
  });
}
// convert a number in a range to a heatmap color
// export function heatmapColor(value, min, max) {
//     const r = Math.round(255 * (value - min) / (max - min));
//     const g = Math.round(255 * (max - value) / (max - min));
//     return 'rgb(' + r + ',' + g + ',0)';
// }
export function heatMapColorforValue(value) {
  var h = (1.0 - value) * 240;
  return "hsl(" + h + ", 100%, 50%)";
}

export const arrayMin = (arr) => {
  if (arr.length === 0) {
    return undefined;
  } else {
    return arr.reduce(function (p, v) {
      return p < v ? p : v;
    });
  }
};
export const arrayMax = (arr) => {
  if (arr.length === 0) {
    return undefined;
  } else {
    const arr2 = arr.filter((item) => !Number.isNaN(item));
    // const arr2 = arr.map((item) => +item)
    // console.log('arr2', arr2)
    return arr2.reduce(function (p, v) {
      return p > v ? p : v;
    });
  }
};

export const sumByKey = (arr, key, value) => {
  const map = new Map();
  for (const obj of arr) {
    const currSum = map.get(obj[key]) || 0;
    map.set(obj[key], currSum + obj[value]);
  }
  const res = Array.from(map, ([k, v]) => ({ [key]: k, [value]: v }));
  return res;
};

const AEweight = (aesev) => {
  if (aesev === "SEVERE") {
    return 3;
  } else if (aesev === "MODERATE") {
    return 2;
  } else if (aesev === "MILD") {
    return 1;
  } else if (aesev === "T1") {
    return 1;
  } else if (aesev === "T2") {
    return 2;
  } else if (aesev === "T3") {
    return 3;
  } else if (aesev === "T4") {
    return 4;
  } else if (aesev === "T5") {
    return 5;
  } else return +aesev;
};

export function makeAEBOTArray(
  studyDatabase,
  classVar,
  whereSubjects,
  drillDownLevel,
  drillDownPath,
  drillVars
) {
  let tempWhereClause = whereSubjects ? whereSubjects : "where 1";
  for (let i = 0; i < drillDownLevel; i++) {
    tempWhereClause += " and " + drillDownPath[i] + '="' + drillVars[i] + '"';
  }
  const sqlCode = `
    SELECT *
        FROM (
                 SELECT SUBJID AS aesub,
                        aebodsys,
                        aesev,
                        AEENDTC,
                        AESTDTC
                   FROM ae
                   ${tempWhereClause}
             )
             LEFT JOIN
             (
                 SELECT SUBJID AS vdsub,
                        vddate
                   FROM vd
                  WHERE visit in ('DAY 1','BASELINE','CYCLE 01 DAY 01')
             )
             ON aesub = vdsub
             LEFT JOIN
             (
                 SELECT SUBJID AS dmsub,
                        armcd
                   FROM dm
             )
             ON aesub = dmsub;
      `;

  return sql(studyDatabase, sqlCode).then((res) => {
    if (res.message === "success") {
      // console.log(sqlCode, res.data);
      res.data.forEach((element) => {
        const aeendtc = new Date(element.AEENDTC + "Z"),
          aestdtc = new Date(element.AESTDTC + "Z"),
          firstVisitDate = moment(element.vddate, "YYYYMMDD").toDate(),
          days = Math.round((aeendtc - aestdtc) / (1000 * 60 * 60 * 24)),
          firstDayOfAE =
            1 + Math.round((aestdtc - firstVisitDate) / (1000 * 60 * 60 * 24)),
          lastDayOfAE = firstDayOfAE + days - 1;
        element.firstDayOfAE = parseInt(firstDayOfAE);
        element.days = days < 1 ? 1 : days;
        element.lastDayOfAE =
          lastDayOfAE < element.firstDayOfAE
            ? element.firstDayOfAE
            : lastDayOfAE;
        // console.log(
        //   "aestdtc",
        //   aestdtc,
        //   "firstVisitDate",
        //   firstVisitDate,
        //   element,
        //   "firstDayOfAE",
        //   firstDayOfAE,
        //   "lastDayOfAE",
        //   lastDayOfAE,
        //   "days",
        //   days
        // );
      });
      const dataToUse = res.data.filter((row) => {
        return row.firstDayOfAE >= 0;
      }); // only use data after treatment
      // console.log('dataToUse', dataToUse);
      const from = dataToUse.map((element) => element.firstDayOfAE),
        to = dataToUse.map((element) => element.lastDayOfAE);
      // console.log('from: ', from, 'to: ', to);
      const min = arrayMin(from),
        max = arrayMax(to),
        minWeek = Math.floor(min / 7) + 1,
        maxWeek = Math.floor(max / 7) + 1,
        aeData = {},
        range = max - min + 1,
        rangeWeek = maxWeek - minWeek + 1;
      //   console.log('range: ', range,'rangeWeek',rangeWeek, 'min: ', min, 'max: ', max);

      // count up the weighted sum of AEs for each day for each arm
      dataToUse.forEach((element) => {
        // console.log('element', element, 'element.armcd', element.armcd, 'classVar', classVar, 'aedata', aeData);
        if (!(element.armcd in aeData)) aeData[element.armcd] = {};
        // console.log('aeData[element.armcd]', aeData[element.armcd], 'element[classVar]', element[classVar], 'range', range)
        // if (!(element[classVar] in aeData[element.armcd]))
        if (!aeData[element.armcd].hasOwnProperty(element[classVar]))
          aeData[element.armcd][element[classVar]] = Array(range).fill(0);
        for (let i = element.firstDayOfAE; i <= element.lastDayOfAE; i++) {
          aeData[element.armcd][element[classVar]][i - min] =
            aeData[element.armcd][element[classVar]][i - min] +
            AEweight(element.aesev);
        }
      });
      // console.log('aeData: ', aeData);

      // count the patients per day per arm
      const sqlNumberOfPatients = `
                SELECT *
                FROM (
                         SELECT SUBJID,
                                min(date(vddate) ) AS min,
                                max(date(vddate) ) AS max
                           FROM vd
                           ${tempWhereClause}
                          GROUP BY SUBJID
                     )
                     LEFT JOIN
                     (
                         SELECT SUBJID AS dmsub,
                                armcd
                           FROM dm
                     )
                     ON SUBJID = dmsub
                     LEFT JOIN
                     (
                         SELECT SUBJID AS vdsub,
                                vddate as firstVisit
                           FROM vd
                          WHERE visit in ('DAY 1','BASELINE','CYCLE 01 DAY 01')
                     )
                     ON SUBJID = vdsub;               
            `,
        patientsInArm = {},
        patientsInArmWeek = {};
      return sql(studyDatabase, sqlNumberOfPatients).then((res) => {
        // console.log("res", res);
        res.data.forEach((element) => {
          // console.log('element', element);
          const firstVisitDate = moment(
              element.firstVisit,
              "DDMMMYYYY"
            ).toDate(),
            // from = new Date(element.min + "Z"),
            to = new Date(element.max + "Z"),
            // totalDays = Math.round((to - from) / (1000 * 60 * 60 * 24)) + 1, // includes before first visit
            firstDayOfTreatment = 1,
            lastDayOfTreatment =
              Math.round((to - firstVisitDate) / (1000 * 60 * 60 * 24)) + 1, // works out the last day of treatment
            firstWeekOfTreatment = 1,
            lastWeekOfTreatment = 1 + Math.floor((lastDayOfTreatment - 1) / 7);
          //   console.log('element', element, 'min', min, 'firstvisitdate', firstVisitDate, 'firstDayOfTreatment', firstDayOfTreatment, 'lastDayOfTreatment', lastDayOfTreatment);
          //   console.log('range', range, 'rangeWeek',rangeWeek, 'patientsInArm', patientsInArm)
          if (!(element.armcd in patientsInArm))
            patientsInArm[element.armcd] = Array(range).fill(0);
          for (let i = firstDayOfTreatment; i <= lastDayOfTreatment; i++) {
            patientsInArm[element.armcd][i - min] =
              patientsInArm[element.armcd][i - min] + 1;
          }
          //   console.log(element,patientsInArmWeek,rangeWeek)
          if (!(element.armcd in patientsInArmWeek))
            patientsInArmWeek[element.armcd] = Array(rangeWeek).fill(0);
          //   console.log(element.armcd,'firstWeekOfTreatment', firstWeekOfTreatment, 'lastWeekOfTreatment', lastWeekOfTreatment, 'rangeWeek', rangeWeek, 'minWeek', minWeek);
          for (let i = firstWeekOfTreatment; i <= lastWeekOfTreatment; i++) {
            patientsInArmWeek[element.armcd][i - minWeek] =
              patientsInArmWeek[element.armcd][i - minWeek] + 1;
          }
        });
        // console.log('patientsInArm: ', patientsInArm,'patientsInArmWeek: ',patientsInArmWeek, 'min: ', min, 'max: ', max, 'minWeek: ', minWeek, 'maxWeek: ', maxWeek,);

        const valuesToReturn = {};
        Object.keys(aeData).forEach((armcd) => {
          const values = [];
          Object.keys(aeData[armcd]).forEach((key) => {
            aeData[armcd][key].forEach((element, index) => {
              const patients = patientsInArm[armcd][index]
                  ? patientsInArm[armcd][index]
                  : 0,
                weekIndex = 1 + Math.floor((index - 1) / 7),
                patientsInWeek = patientsInArmWeek[armcd][weekIndex]
                  ? patientsInArmWeek[armcd][weekIndex]
                  : 0,
                ratio = patients === 0 ? 0 : element / patients; // get number as a percentage figure where 100 = 100%
              const value = {
                day: index,
                week: weekIndex,
                count: ratio,
                patients: patients,
                patientsInWeek: patientsInWeek,
                numberOfAEs: element,
                combined: key + ":" + weekIndex,
              };
              value[classVar] = key;
              if (ratio > 0) values.push(value);
            });
          });
          valuesToReturn[armcd] = values;
        });
        // calculate the ratio for each week
        const weekValuesToReturn = {};
        Object.keys(valuesToReturn).forEach((armcd) => {
          weekValuesToReturn[armcd] = sumByKey(
            valuesToReturn[armcd],
            "combined",
            "numberOfAEs"
          );
          //     armcd.forEach((element) => {
          //         if (!(element[aesev] in weekValuesToReturn[armcd]))
          //     })
        });
        console.log("weekValuesToReturn: ", weekValuesToReturn);
        console.log("valuesToReturn: ", valuesToReturn);
        return {
          min: min,
          max: max,
          values: valuesToReturn,
          weekValues: weekValuesToReturn,
          tempWhereClause: tempWhereClause,
        };
      });
    }
  });
}

// Labs BoTh array
export function makeLBBOTArray(
  studyDatabase,
  classVar,
  whereSubjects,
  drillDownLevel,
  drillDownPath,
  drillVars
) {
  let tempWhereClause = whereSubjects ? whereSubjects : "where 1";
  let tempExtraWhereClause = "where 1";
  for (let i = 0; i < drillDownLevel; i++) {
    tempExtraWhereClause +=
      " and " + drillDownPath[i] + '="' + drillVars[i] + '"';
  }
  // console.log('tempWhereClause: ', tempWhereClause);
  const sqlCode =
    `SELECT *  FROM ` +
    `( SELECT * FROM (  SELECT SUBJID AS lbsub, LBDTC,  lbtestcd, lbtest, CAST (lbstresn AS DECIMAL) AS result FROM lb ${tempWhereClause} ) LEFT JOIN ` +
    `( SELECT code,  low,  high,  ctcae FROM ctcae ) ON lbtestcd = code AND result > low AND result < high WHERE ctcae NOT NULL  )  LEFT JOIN  ` +
    `(  SELECT SUBJID AS vdsub, vddate  FROM vd WHERE visit in ('DAY 1','BASELINE','CYCLE 01 DAY 01')  )  ON lbsub = vdsub  LEFT JOIN  ` +
    `(  SELECT SUBJID AS dmsub, armcd  FROM dm  )  ON lbsub = dmsub  LEFT JOIN  ` +
    `(  SELECT code AS catcode, category  FROM labcategories  )  ON lbtestcd = catcode  ` +
    `${tempExtraWhereClause}`;

  return sql(studyDatabase, sqlCode).then((res) => {
    // console.log('res', res)
    if (res.message === "success") {
      res.data.forEach((element) => {
        const start = moment(element.LBDTC, "YYYYMMDD").toDate(),
          firstVisitDate = moment(element.vddate, "YYYYMMDD").toDate(),
          days = 1,
          firstDayOfLB = Math.round(
            (start - firstVisitDate) / (1000 * 60 * 60 * 24)
          ),
          lastDayOfLB = firstDayOfLB + days;
        element.firstDayOfLB = parseInt(firstDayOfLB);
        element.days = days;
        element.lastDayOfLB = lastDayOfLB;
      });
      const dataToUse = res.data.filter((row) => {
        return row.firstDayOfLB >= 0;
      }); // only use data after treatment
      // console.log('makeLBBOTArray - dataToUse', dataToUse);
      const from = dataToUse.map((element) => element.firstDayOfLB),
        to = dataToUse.map((element) => element.lastDayOfLB);
      // console.log('from: ', from, 'to: ', to);
      const min = arrayMin(from),
        max = arrayMax(to),
        lbData = {},
        range = max - min + 1;
      // console.log('range: ', range, 'min: ', min, 'max: ', max);

      // count up the weighted sum of LBs for each day for each arm
      dataToUse.forEach((element) => {
        // if (element.firstDayOfLB < -30) console.log('element', element);
        if (!(element.armcd in lbData)) lbData[element.armcd] = {}; // add arm to object if it doesn't exist
        // console.log('element', element, 'classVar', classVar, 'lbData', lbData, 'range', range);
        if (!(element[classVar] in lbData[element.armcd]))
          lbData[element.armcd][element[classVar]] = Array(range).fill(0); // initialise range of values if it is the first time
        for (let i = element.firstDayOfLB; i <= element.lastDayOfLB; i++) {
          lbData[element.armcd][element[classVar]][i - min] =
            lbData[element.armcd][element[classVar]][i - min] + element.ctcae;
        }
      });
      // console.log('lbData: ', lbData);

      // count the patients per day per arm
      const sqlNumberOfPatients =
          `SELECT * FROM ( SELECT SUBJID, min(date(vddate ) ) AS min, ` +
          `max(date(vddate ) ) AS max ` +
          `FROM vd ${tempWhereClause} GROUP BY SUBJID ) ` +
          `LEFT JOIN (SELECT SUBJID AS dmsub, armcd FROM dm ) ON SUBJID = dmsub ` +
          `LEFT JOIN ( SELECT SUBJID AS vdsub, vddate as firstVisit FROM vd WHERE visit in ('DAY 1','BASELINE','CYCLE 01 DAY 01') ) ON SUBJID = vdsub ` +
          `LEFT JOIN ( SELECT SUBJID AS lbsub, min(LBDTC) AS firstLabVisit FROM lb WHERE LBDTC <> '' GROUP BY lbsub) ON SUBJID = lbsub `,
        patientsInArm = {};
      return sql(studyDatabase, sqlNumberOfPatients).then((res) => {
        // console.log('res', res);
        res.data.forEach((element) => {
          const firstVisitDate = moment(
              element.firstVisit,
              "DDMMMYYYY"
            ).toDate(),
            // from = new Date(element.min + "Z"),
            to = new Date(element.max + "Z"),
            // totalDays = Math.round((to - from) / (1000 * 60 * 60 * 24)) + 1, // includes before first visit
            // firstDayOfTreatment = 1,
            firstDateOfLabs = new Date(element.firstLabVisit + "Z"),
            firstDayOfLabs = Math.round(
              (firstDateOfLabs - firstVisitDate) / (1000 * 60 * 60 * 24)
            ),
            lastDayOfTreatment =
              Math.round((to - firstVisitDate) / (1000 * 60 * 60 * 24)) + 1; // works out the last day of treatment
          // console.log('element', element, 'firstvisitdate', firstVisitDate,
          //     'firstDateOfLabs', firstDateOfLabs, 'firstDayOfLabs', firstDayOfLabs,
          //     'from', from, 'to', to, 'firstDayOfTreatment', firstDayOfTreatment, 'lastDayOfTreatment', lastDayOfTreatment);
          if (!(element.armcd in patientsInArm))
            patientsInArm[element.armcd] = Array(range).fill(0);
          for (let i = firstDayOfLabs; i <= lastDayOfTreatment; i++) {
            patientsInArm[element.armcd][i - min] =
              patientsInArm[element.armcd][i - min] + 1;
          }
        });
        // console.log('patientsInArm: ', patientsInArm);

        const valuesToReturn = {};
        Object.keys(lbData).forEach((armcd) => {
          const values = [];
          Object.keys(lbData[armcd]).forEach((key) => {
            lbData[armcd][key].forEach((element, index) => {
              const patients = patientsInArm[armcd][index],
                ratio = patients === 0 ? 0 : element / patients; // get number as a percentage figure where 100 = 100%
              const value = {
                day: index,
                count: ratio,
                patients: patients,
                numberOfAEs: element,
              };
              value[classVar] = key;
              values.push(value);
            });
          });
          valuesToReturn[armcd] = values;
        });
        // console.log('valuesToReturn: ', valuesToReturn);
        return {
          min: min,
          max: max,
          values: valuesToReturn,
          tempWhereClause: tempWhereClause,
        };
      });
    }
  });
}

export function makeAEOnDayArray(
  studyDatabase,
  classVar,
  whereSubjects,
  drillDownLevel,
  drillDownPath,
  drillVars
) {
  let tempWhereClause = whereSubjects ? whereSubjects : "where 1";
  for (let i = 0; i < drillDownLevel; i++) {
    tempWhereClause += " and " + drillDownPath[i] + '="' + drillVars[i] + '"';
  }
  // console.log('classVar', classVar);
  const sqlCode =
    `select * from (select SUBJID as aesub, ${classVar}, aesev, AEENDTC, AESTDTC from ae ${tempWhereClause} ) ` +
    `left join ` +
    `(select SUBJID as vdsub, vddate from vd where visit in ('DAY 1','BASELINE','CYCLE 01 DAY 01')) ` +
    `on aesub=vdsub`;
  // console.log('sqlCode', sqlCode);
  return sql(studyDatabase, sqlCode).then((res) => {
    if (res.message === "success") {
      res.data.forEach((element) => {
        // console.log('element', element);
        const aeendtc =
            element.AEENDTC > "" && element.AEENDTC.length > 4
              ? moment(element.AEENDTC, "YYYYMMDD").toDate()
              : null,
          aestdtc = element.AESTDTC
            ? moment(element.AESTDTC, "YYYYMMDD").toDate()
            : null,
          firstVisitDate = moment(element.vddate, "YYYYMMDD").toDate(),
          days =
            aeendtc && aestdtc
              ? 1 + Math.round((aeendtc - aestdtc) / (1000 * 60 * 60 * 24))
              : null,
          firstDayOfAE =
            aestdtc && firstVisitDate
              ? Math.round((aestdtc - firstVisitDate) / (1000 * 60 * 60 * 24)) +
                1
              : null,
          lastDayOfAE = firstDayOfAE && days ? firstDayOfAE + days : null;
        element.firstDayOfAE = parseInt(firstDayOfAE);
        element.days = days;
        element.lastDayOfAE = lastDayOfAE;
        if (firstDayOfAE < -500) console.log("element", element);
      });
      const dataToUse = res.data.filter((row) => {
        return row.firstDayOfAE >= 0;
      }); // only use data after treatment
      // console.log('dataToUse', dataToUse);
      const from = dataToUse.map((element) => element.firstDayOfAE),
        to = dataToUse.map((element) => element.lastDayOfAE);
      // console.log('from: ', from, 'to: ', to);
      const min = arrayMin(from),
        max = arrayMax(to),
        mydata = {},
        range = max - min + 1;
      // console.log('range: ', range, 'min: ', min, 'max: ', max);
      // define array for each classVar value to show the AE count on each day
      dataToUse.forEach((element) => {
        // console.log('element', element, 'classVar', classVar, 'mydata', mydata, 'range', range);
        if (!(element[classVar] in mydata))
          mydata[element[classVar]] = Array(range).fill(0);
        for (let i = element.firstDayOfAE; i <= element.lastDayOfAE; i++) {
          mydata[element[classVar]][i - min] =
            mydata[element[classVar]][i - min] + 1;
        }
      });
      // console.log('mydata: ', mydata);
      const values = [];
      Object.keys(mydata).forEach((key) => {
        mydata[key].forEach((element, index) => {
          const value = { day: index, count: element };
          value[classVar] = key;
          values.push(value);
        });
      });
      // console.log('values: ', values);
      return {
        min: min,
        max: max,
        values: values,
        tempWhereClause: tempWhereClause,
      };
    }
  });
}

export const TickCross = (props) => {
  // <HotColumn>
  //     <TickCross hot-renderer />
  // </HotColumn>
  const { value } = props;
  if (value === "Y") {
    return (
      <>
        <b>
          <span style={{ color: "green" }}>&#10004;</span>
        </b>
      </>
    );
  } else if (value === "N") {
    return (
      <>
        <b>
          <span style={{ color: "red" }}>&#10007;</span>
        </b>
      </>
    );
  }
  if (value === "Yes") {
    return (
      <>
        <b>
          <span style={{ color: "green" }}>&#10004;</span>
        </b>
      </>
    );
  } else if (value === "No") {
    return (
      <>
        <b>
          <span style={{ color: "red" }}>&#10007;</span>
        </b>
      </>
    );
  }
  return (
    <>
      <b>
        <span style={{ color: "gray" }}>{value}</span>
      </b>
    </>
  );
};

export const heatmapColor = (value, min, max, rMax, gMax, bMax, opacity) => {
  const range = max - min,
    proportion = (value - min) / range;
  const r =
      rMax === 1
        ? Math.floor(255 * proportion)
        : rMax === -1
        ? Math.floor(255 * (1 - proportion))
        : 0,
    g =
      gMax === 1
        ? Math.floor(255 * proportion)
        : gMax === -1
        ? Math.floor(255 * (1 - proportion))
        : 0,
    b =
      bMax === 1
        ? Math.floor(255 * proportion)
        : bMax === -1
        ? Math.floor(255 * (1 - proportion))
        : 0,
    backgroundColor = `rgba(${r},${g},${b},${opacity})`;
  return backgroundColor;
};
export const Heatmap = (props) => {
  // <HotColumn>
  //     <Heatmap hot-renderer
  //         min={1}
  //         max={10}
  //         rMax={-1}
  //         gMax={1}
  //         bMax={0}
  //         opacity={0.5} />
  // </HotColumn>
  // rMax, gMax & bMax - if 1 then 255 is top, if -1 then 0 is top, otherwise value is 0 - allows specifying varying ranges
  const { value, min, max, rMax, gMax, bMax, opacity } = props;
  const backgroundColor = heatmapColor(
    value,
    min,
    max,
    rMax,
    gMax,
    bMax,
    opacity
  );
  return (
    <>
      <div style={{ backgroundColor }}>{value}</div>
    </>
  );
};
export const addClassWhenNeeded = (props) => {
  const className = props.cellProperties.className;
  if (className !== void 0) {
    Handsontable.dom.addClass(props.TD, className);
  }
};
export function ProgressBarRenderer(props) {
  // <HotColumn>
  //    <ProgressBarRenderer
  //         hot-renderer
  //         min={min}
  //         max={max}
  //         cellWidth={100}
  //         color='rgba(0,255,0,0.75)' />
  // </HotColumn>
  const { value, min, max, cellWidth, color } = props;
  addClassWhenNeeded(props);
  const range = max - min;
  const width = Math.round((cellWidth * (value - min)) / range);
  return (
    <>
      <div
        className="htLeft"
        style={{ backgroundColor: color, width: `${width}px` }}
      >
        {value}
      </div>
    </>
  );
}
// bar length depends on value, and colour depends on value too
export function ProgressBarHeatmapRenderer(props) {
  // <HotColumn>
  //    <ProgressBarHeatmapRenderer
  //         hot-renderer
  //         min={min}
  //         max={max}
  //         cellWidth={100}
  //         rMax={-1}
  //         gMax={1}
  //         bMax={0}
  //         opacity={0.5}  />
  // </HotColumn>
  const { value, min, max, cellWidth, rMax, gMax, bMax, opacity } = props;
  addClassWhenNeeded(props);
  const range = max - min;
  const width = Math.round((cellWidth * (value - min)) / range);
  const backgroundColor = heatmapColor(
    value,
    min,
    max,
    rMax,
    gMax,
    bMax,
    opacity
  );
  return (
    <>
      <div
        className="htLeft"
        style={{ backgroundColor: backgroundColor, width: `${width}px` }}
      >
        {value}
      </div>
    </>
  );
}
export function ProgressBarLevelsRenderer(props) {
  const { value, min, max, cellWidth, colorLevels } = props;
  addClassWhenNeeded(props);
  const range = max - min;
  const width = Math.round((cellWidth * (value - min)) / range);
  // console.log('value: ', value);
  const backgroundColor = colorLevels.reduce(
    (total, currentValue, currentIndex, arr) => {
      // console.log('value', value, 'currentValue: ', currentValue, 'currentIndex: ', currentIndex, 'total: ', total);
      return value >= currentValue.from && value <= currentValue.to
        ? currentValue.color
        : total;
    },
    null
  );
  // console.log('backgroundColor: ', backgroundColor);
  return (
    <>
      <div
        className="htLeft"
        style={{ backgroundColor: backgroundColor, width: `${width}px` }}
      >
        {value}
      </div>
    </>
  );
}
export function PatientProfileRenderer(props) {
  const { value } = props;
  return (
    <>
      <a
        href={`/#/patientprofile/${props.studyDatabase}/${value}`}
        target="_blank"
        rel="noreferrer"
        style={{ color: "blue", style: "bold", textDecoration: "underline" }}
      >
        {value}
      </a>
    </>
  );
}
export function openSaveFileDialog(data, filename, mimetype) {
  if (!data) return;

  var blob =
    data.constructor !== Blob
      ? new Blob([data], { type: mimetype || "application/octet-stream" })
      : data;

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
    return;
  }

  var lnk = document.createElement("a"),
    url = window.URL,
    objectURL;

  if (mimetype) {
    lnk.type = mimetype;
  }

  lnk.download = filename || "untitled";
  lnk.href = objectURL = url.createObjectURL(blob);
  lnk.dispatchEvent(new MouseEvent("click"));
  setTimeout(url.revokeObjectURL.bind(url, objectURL));
}
export const Colormap = (props) => {
  // console.log('props',props)
  const { value, prop, colors } = props;
  const backgroundColor = colors[prop][value];
  return (
    <>
      <div style={{ backgroundColor }}>{value}</div>
    </>
  );
};
export const sortByKey = (array, key) => {
  return array.sort(function (a, b) {
    const value1 = a[key],
      value2 = b[key];
    return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
  });
};
export const sortBy2Keys = (array, key1, key2) => {
  return array.sort(function (a, b) {
    const value1 = a[key1],
      value2 = b[key1],
      value3 = a[key2],
      value4 = b[key2];
    if (value1 === value2)
      return value3 < value4 ? -1 : value3 > value4 ? 1 : 0;
    else return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
  });
};
export function TestRenderer(props) {
  const { value, row, dividerRows, subdividerRows } = props;
  const backgroundColor = dividerRows.includes(row)
    ? "darkgray"
    : subdividerRows.includes(row)
    ? "lightgray"
    : null;
  const fontWeight = "bold";
  // ,textAlign = 'left'
  if (dividerRows.includes(row) || subdividerRows.includes(row))
    return <div style={{ backgroundColor, fontWeight }}>{value}</div>;
  else return <div>{value}</div>;
}
export const Heatmap2 = (value, min, max, rMax, gMax, bMax, opacity) => {
  // rMax, gMax & bMax - if 1 then 255 is top, if -1 then 0 is top, otherwise value is 0 - allows specifying varying ranges
  // const { value, min, max, rMax, gMax, bMax, opacity } = props;
  const backgroundColor = heatmapColor(
      value,
      min,
      max,
      rMax,
      gMax,
      bMax,
      opacity
    ),
    flex = 1;
  // console.log('backgroundColor', backgroundColor, value, min, max, rMax, gMax, bMax, opacity)
  return <div style={{ backgroundColor, flex }}>{value}</div>;
};
export const getWidth = () => {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
};

export const getHeight = () => {
  const { clientHeight } = document.documentElement;
  // { scrollHeight, offsetHeight, clientHeight } = document.documentElement,
  //     { scrollHeight: bodyScrollHeight, offsetHeight: bodyOffsetHeight, clientHeight: bodyClientHeight } = document.body,
  // maxHeight = Math.max(
  //     document.body.scrollHeight,
  //     document.documentElement.scrollHeight,
  //     document.body.offsetHeight,
  //     document.documentElement.offsetHeight,
  //     document.documentElement.clientHeight
  // );
  // console.log('maxHeight', maxHeight, 'scrollHeight', scrollHeight, 'offsetHeight', offsetHeight, 'clientHeight', clientHeight,
  //     'bodyScrollHeight', bodyScrollHeight, 'bodyOffsetHeight', bodyOffsetHeight, 'bodyClientHeight', bodyClientHeight)
  return clientHeight;
  // return height;
};
export function Prog(props) {
  // <HotColumn>
  //    <Prog
  //         hot-renderer
  //         min={min}
  //         max={max}
  //         cellWidth={100}
  //         color='rgba(0,255,0,0.75)' />
  // </HotColumn>
  const { value, min, max, cellWidth, color } = props;
  addClassWhenNeeded(props);
  const range = max - min;
  const width = Math.round((cellWidth * (value - min)) / range);
  return (
    <>
      <div
        className="htLeft"
        style={{ backgroundColor: color, width: `${width}px` }}
      >
        {value}
      </div>
    </>
  );
}

export const checkLabAlerts = (
  alerts,
  setWaiting,
  studyDatabase,
  labCategories,
  setAlertResults
) => {
  // console.log('alerts', alerts)
  if (Object.keys(alerts).length === 0) return;
  const sqlStatements = [];
  Object.keys(alerts).forEach((test, index1) => {
    Object.keys(alerts[test]).forEach((level, index2) => {
      const comparison =
          level === "low"
            ? `and lbstresn < ${alerts[test][level]}`
            : level === "high"
            ? `and lbstresn > ${alerts[test][level]}`
            : null,
        // sqlStatement = `SELECT subjid, lbtestcd, '${level}' as level, count(*) as count, max(LBDTC) as lastdate, min(lbstresn) as min, max(lbstresn) as max FROM lb  WHERE lbtestcd='${test}' ${comparison} GROUP BY subjid,lbtestcd`
        // sqlStatement = `SELECT subjid, lbtestcd, '${level}' as level, LBDTC, LBSTRESN FROM lb  WHERE visitdy='14' and lbtestcd='${test}' ${comparison} ORDER BY subjid,lbtestcd`
        sqlStatement =
          `SELECT distinct * FROM ` +
          `(SELECT SUBJID, LBTESTCD, '${level}' as LEVEL, LBDTC, LBSTRESN FROM lb ` +
          `WHERE lbtestcd='${test}' ${comparison} ) a ` +
          `LEFT JOIN ( SELECT subjid, testcd, date, 1 as checked FROM lbchecked ) b  ` +
          `ON a.subjid = b.subjid AND a.lbtestcd = b.testcd AND a.lbdtc = b.date`;
      if (comparison !== null) sqlStatements.push(sqlStatement);
    });
  });
  setWaiting(true);
  // console.log('sqlStatements', sqlStatements)
  Promise.all(
    sqlStatements.map((sqlStatement) =>
      sql(studyDatabase, sqlStatement).then((res) => {
        // console.log('res', res)
        return res.data.map((rowFromCheck, rowIndex) => {
          const row = {
            id: rowFromCheck.LBTESTCD + rowFromCheck.level + rowIndex,
            test: rowFromCheck.LBTESTCD,
            level: rowFromCheck.LEVEL,
            subjid: rowFromCheck.SUBJID,
            value: rowFromCheck.LBSTRESN,
            date: rowFromCheck.LBDTC,
            labCategory:
              rowFromCheck.LBTESTCD in labCategories
                ? labCategories[rowFromCheck.LBTESTCD]
                : null,
            lastdate: rowFromCheck.lastdate,
            checked: Boolean(rowFromCheck.checked),
          };
          return row;
        });
      })
    )
  ).then((res) => {
    // console.log('res', res)
    setAlertResults(res);
    setWaiting(false);
  });
};

export const checkAdverseEventsAlerts = (
  setWaiting,
  studyDatabase,
  setAlertResults
) => {
  // console.log(studyDatabase)
  setWaiting(true);
  const sqlStatement = `SELECT * FROM ae WHERE aeser='Y'`;
  sql(studyDatabase, sqlStatement).then((res) => {
    // console.log('res', res)
    setAlertResults(res);
    setWaiting(false);
  });
};

// get directory listing
export function dir(folder) {
  return axios.get(serverUrl + "/dir/" + folder).then((response) => {
    // console.log(response.data);
    return response.data;
  });
}

// download a file
export function getafile(folder, filename) {
  // console.log('getafile', folder, filename)
  return axios
    .get(serverUrl + "/getafile/" + folder + "/" + filename, {
      responseType: "blob",
    })
    .then((response) => {
      // console.log(response.data);
      return response.data;
    });
}

// get stats for boxplot
export function getStats(arr) {
  // console.log("arr", arr);
  // if (arr.length === 1) return null;
  // sort array ascending
  const asc = (arr) => arr.sort((a, b) => a - b),
    sum = (arr) => arr.reduce((a, b) => a + b, 0),
    mean = (arr) => sum(arr) / arr.length,
    std = (arr) => {
      // sample standard deviation
      const mu = mean(arr);
      const diffArr = arr.map((a) => (a - mu) ** 2);
      return Math.sqrt(sum(diffArr) / (arr.length - 1));
    },
    sorted = asc(arr),
    quantile = (arr, q) => {
      const pos = (sorted.length - 1) * q;
      const base = Math.floor(pos);
      const rest = pos - base;
      if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
      } else {
        return sorted[base];
      }
    };
  return {
    // x: 1,
    low: sorted[0],
    q1: quantile(arr, 0.25),
    median: quantile(arr, 0.5),
    q3: quantile(arr, 0.75),
    high: sorted[arr.length - 1],
    std: std(arr),
    mean: mean(arr),
    // name: "Point2",
    // color: "#00FF00"
  };
}

// run an open ai query
export function getAnswer(question, setAnswer) {
  const payload = { question: question },
    headers = { headers: { "content-type": "application/json" } };
  console.log("serverUrl", serverUrl, window.location.host);
  return axios
    .post(serverUrl + "/getAnswer", payload, headers)
    .then((response) => {
      console.log("response", response);
      setAnswer(response.data.message);
      return response.data;
    })
    .catch((error) => {
      console.error("error", error);
      setAnswer(error);
      return {
        message: "fail",
        data: [],
        info:
          error && error.response && error.response.data
            ? error.response.data.error
            : error,
      };
    });
}
