import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { sql, globalFilter } from "../../apis/utility";
// import ReactFC from "react-fusioncharts";
// import FusionCharts from "fusioncharts";
// import excelexport from "fusioncharts/fusioncharts.excelexport";
// import Charts from "fusioncharts/fusioncharts.charts";
// import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel';
// ReactFC.fcRoot(FusionCharts, Charts, GammelTheme, excelexport);
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import AnnotationsModule from "highcharts/modules/annotations";
AnnotationsModule(Highcharts);

export const Donut = (props) => {
  const [subtitle, setSubtitle] = useState(null),
    [dataSource1, setDataSource1] = useState(null),
    [dataSource2, setDataSource2] = useState(null),
    [dataSource3, setDataSource3] = useState(null),
    { study, studyDatabase, selectedOptions, selectedSubjects, screenHeight } =
      props,
    whereSubjects =
      props.selectedSubjects && props.selectedSubjects.length > 0
        ? 'where SUBJID in ("' + selectedSubjects.join('","') + '")'
        : "";

  // addToHistory({ title: "Donut", url: location })

  useEffect(() => {
    const getData = async () => {
      setSubtitle(globalFilter(selectedOptions));
      const res = await sql(
        studyDatabase,
        `SELECT count(CASE WHEN aeser = 'Y' THEN 1 END) AS serious,
            count(CASE WHEN aeser = 'N' THEN 1 END) AS notserious,
            count(CASE WHEN aesev = 'MODERATE' or aesev = 'T2' or aesev='T3' THEN 1 END) AS moderate,
            count(CASE WHEN aesev = 'SEVERE' or aesev = 'T5' or aesev='T4' THEN 1 END) AS severe,
            count(CASE WHEN aesev = 'MILD' or aesev = 'T1' THEN 1 END) AS mild,
            count(CASE WHEN aerel = 'POSSIBLE' THEN 1 END) AS possible,
            count(CASE WHEN aerel = 'PROBABLE' or aerel = 'SUSPECTED' THEN 1 END) AS probable,
            count(CASE WHEN aerel = 'NOT RELATED'or aerel='NOT SUSPECTED' THEN 1 END) AS unrelated,
            count(*) as total FROM ae ${whereSubjects}`
      );
      // console.log('ae res', res)
      if (res.message === "success") {
        const z = res.data[0];
        setDataSource1({
          chart: {
            backgroundColor: "transparent",
            plotBorderWidth: 0,
            plotShadow: false,
            marginBottom: 10,
            height: screenHeight * 0.25,
          },
          credits: {
            enabled: false
          },
          title: {
            text: "Seriousness",
            align: "center",
            verticalAlign: "top",
            y: 10,
            style: {
                 fontWeight: 'bold',
                 "fontSize": "14px"
            }
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          plotOptions: {
            pie: {
              colors: ["#000000", "#f4decd"],
              dataLabels: {
                enabled: true,
              },
              startAngle: 45,
              endAngle: 45,
              center: ["50%", "60%"],
              size: "100%",
            },
          },
          series: [
            {
              type: "pie",
              name: "AE Share",
              innerSize: "60%",
              data: [
                ["Serious", z.serious],
                ["Not Serious", z.notserious],
              ],
            },
          ],
        });
        setDataSource2({
          chart: {
            backgroundColor: "transparent",
            plotBorderWidth: 0,
            plotShadow: false,
            marginBottom: 10,
            height: screenHeight * 0.25,
          },
          credits: {
            enabled: false
          },
          title: {
            text: "Severity" ,
            align: "center",
            verticalAlign: "top",
            y: 10,
            style: {
                 fontWeight: 'bold',
                 "fontSize": "14px"
            }
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          plotOptions: {
            pie: {
              colors: ["#ac011d", "#e4b293", "#f4decd"],
              dataLabels: {
                enabled: true,
              },
              startAngle: 45,
              endAngle: 45,
              center: ["50%", "60%"],
              size: "100%",
            },
          },
          series: [
            {
              type: "pie",
              name: "AE Share",
              innerSize: "60%",
              data: [
                ["Severe", z.severe],
                ["Moderate", z.moderate],
                ["Mild", z.mild],
              ],
            },
          ],
        });
        setDataSource3({
          chart: {
            backgroundColor: "transparent",
            plotBorderWidth: 0,
            plotShadow: false,
            marginBottom: 10,
            height: screenHeight * 0.25,
          },
          credits: {
            enabled: false
          },
          title: {
            text: "Relatedness",
            align: "center",
            verticalAlign: "top",
            y: 10,
            style: {
                 fontWeight: 'bold',
                 "fontSize": "14px"
            }
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
        //   legend: { enabled: true },
          plotOptions: {
            pie: {
              colors: ["#ac011d", "#e4b293", "#f4decd"],
              dataLabels: {
                enabled: true,
              },
              startAngle: 45,
              endAngle: 45,
              center: ["50%", "60%"],
              size: "100%",
            },
          },
          series: [
            {
              type: "pie",
              name: "AE Share",
              innerSize: "60%",
              data: [
                ["Probable", z.probable],
                ["Possible", z.possible],
                ["Unrelated", z.unrelated],
              ],
            },
          ],
        });
      }
    };
    if (study && studyDatabase) getData();
  }, [study, studyDatabase, selectedOptions, subtitle, whereSubjects, screenHeight]);

  return (
    <Grid container>
      <Grid item xs={4}>
        {dataSource1 ? (
          <HighchartsReact highcharts={Highcharts} options={dataSource1} />
        ) : (
          "loading ..."
        )}
      </Grid>
      <Grid item xs={4}>
        {dataSource2 ? (
          <HighchartsReact highcharts={Highcharts} options={dataSource2} />
        ) : (
          "loading ..."
        )}
      </Grid>
      <Grid item xs={4}>
        {dataSource3 ? (
          <HighchartsReact highcharts={Highcharts} options={dataSource3} />
        ) : (
          "loading ..."
        )}
      </Grid>
    </Grid>
  );
};
