import React, { useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import { Tooltip, Fab, Snackbar } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SubjectTable from "../SubjectTable";
import {
  makeAEOnDayArray,
  dataToStreamOptions,
  globalFilter,
  addToHistory,
  sql,
} from "../../apis/utility";
import addStreamgraphModule from "highcharts/modules/streamgraph";
import addSeriesLabel from "highcharts/modules/series-label";
import { useLocation } from "react-router-dom";
import { LookBelow } from "../layout/LookBelow";
HC_exporting(Highcharts);

export default function AEStreamGraph(props) {
  addStreamgraphModule(Highcharts);
  addSeriesLabel(Highcharts);
  const location = useLocation();
  addToHistory({ title: "AE Stream", url: location });

  const contrastingColor =
      props.theme.palette.mode === "dark" ? "white" : "black",
    [showSubjects, setShowSubjects] = useState(false),
    [dataForChart, setDataForChart] = useState(null),
    [drillVars, setDrillVars] = useState([]),
    [subjectsDrilledTo, setSubjectsDrilledTo] = useState([]),
    [whereClause, setWhereClause] = useState(1),
    [snackBarOpen, setSnackBarOpen] = useState(false),
    drillDownPath = props.drillDownPath,
    [drillDownLevel, setDrillDownLevel] = useState(0), // define the drilldown level here
    [newOptions, setNewOptions] = useState(null),
    [chartOptions, setChartOptions] = useState(null),
    [minX, setMinX] = useState(null),
    [maxX, setMaxX] = useState(null),
    // classVar = drillDownPath[drillDownLevel],
    whereSubjects =
      props.selectedSubjects.length > 0
        ? 'where SUBJID in ("' + props.selectedSubjects.join('","') + '")'
        : "",
    drillUp = () => {
      setDrillVars(drillVars.slice(0, drillDownLevel - 1));
      setDrillDownLevel(drillDownLevel - 1);
      setShowSubjects(false);
    },
    top = () => {
      setDrillVars(drillVars.slice(0, 0));
      setDrillDownLevel(0);
      setShowSubjects(false);
    },
    whatHaveIDrilledDownTo = () => {
      const tempWhereClause = [];
      for (let i = 0; i < drillDownLevel; i++) {
        const catVar = drillDownPath[i],
          drillDownLevelLabel =
            catVar in props.variableLabels.ae
              ? props.variableLabels.ae[catVar]
              : catVar;
        // console.log('props', props, catVar, drillDownLevelLabel)
        tempWhereClause.push(drillDownLevelLabel + '="' + drillVars[i] + '"');
      }
      return tempWhereClause.join(" and ");
    };

  useEffect(() => {
    const classVar = drillDownPath[drillDownLevel];
    // console.log('drillDownLevel', drillDownLevel, 'drillDownPath', drillDownPath, 'classVar', classVar)
    makeAEOnDayArray(
      props.studyDatabase,
      classVar,
      whereSubjects,
      drillDownLevel,
      drillDownPath,
      drillVars
    ).then((res) => {
      if (res) {
        // console.log('res: ', res);
        setDataForChart(res.values);
        setMinX(res.min);
        setMaxX(res.max);
        setWhereClause(res.tempWhereClause);
      }
    });
    // eslint-disable-next-line
  }, [
    props.studyDatabase,
    whereSubjects,
    drillDownLevel,
    drillDownPath,
    drillVars,
  ]);

  useEffect(() => {
    // console.log('dataForChart', dataForChart);
    let streamOptions = null;
    if (dataForChart) {
      const classVar = drillDownPath[drillDownLevel];
      streamOptions = dataToStreamOptions(
        dataForChart,
        classVar,
        minX,
        maxX,
        null,
        null,
        null,
        props.theme
      );
      setNewOptions(streamOptions);
    }
    // eslint-disable-next-line
  }, [
    dataForChart,
    drillDownPath,
    drillDownLevel,
    minX,
    maxX,
    contrastingColor,
  ]);

  useEffect(() => {
    // if (props.variableLabels &&props.variableLabels.ae) return ;
    // console.log('props', props)
    const subtitle = globalFilter(props.selectedOptions),
      catVar = drillDownPath[drillDownLevel],
      drillDownLevelLabel =
        "ae" in props.variableLabels && catVar in props.variableLabels.ae
          ? props.variableLabels.ae[catVar]
          : catVar;
    const commonOptions = {
      chart: {
        type: "streamgraph",
        marginBottom: 30,
        height: props.screenHeight * 0.75,
        zoomType: "xy",
        backgroundColor: "transparent",
      },
      // colors: ['#ffc2b3', '#e6e6ff'],
      title: {
        text: `Adverse Events by ${drillDownLevelLabel} (${props.study})`,
        style: { color: contrastingColor },
      },
      subtitle: {
        text: subtitle + "<br/>" + whatHaveIDrilledDownTo(),
        style: { color: contrastingColor },
      },
      xAxis: { labels: { style: { color: contrastingColor } } },
      plotOptions: {
        series: {
          label: {
            minFontSize: 5,
            maxFontSize: 15,
            style: {
              color: contrastingColor,
            },
          },
          accessibility: {
            exposeAsGroupOnly: true,
          },
          // dataLabels: { enabled: true },
          point: {
            events: {
              click: function (e) {
                console.log(
                  "clicked",
                  this.options.name,
                  drillDownLevel,
                  drillDownPath
                );
                if (drillDownLevel < drillDownPath.length - 1) {
                  // drilling down to next level
                  setDrillDownLevel(drillDownLevel + 1);
                  setDrillVars([...drillVars, this.options.name]);
                } else {
                  // drilling down beyond last level to subjects with AE
                  setSnackBarOpen(true);
                  setShowSubjects(true);
                  // const tableWhereClause = whereClause + ' and ' +
                  //     drillDownPath.slice(-1)[0] + '="' + this.options.name + '"'
                  sql(
                    props.studyDatabase,
                    `select distinct SUBJID from ae ${whereClause} and ${
                      drillDownPath.slice(-1)[0]
                    }="${this.options.name}"`
                  ).then((res) => {
                    if (res.message === "success") {
                      const subjectData = res.data.map((d) => d.SUBJID);
                      setSubjectsDrilledTo(subjectData);
                    }
                  });
                }
              },
            },
          },
        },
      },
      yAxis: {
        visible: false,
        startOnTick: false,
        endOnTick: false,
      },
      legend: {
        enabled: false,
      },
      // caption: {text: caption},
      tooltip: {
        formatter: function () {
          return (
            "<b>" +
            this.series.name +
            ",</b><br/> " +
            "Day = " +
            this.point.category +
            "<br/>" +
            "Number of AEs: " +
            Highcharts.numberFormat(Math.abs(this.point.y), 0)
          );
        },
      },
      credits: { enabled: false },
    };
    const updatedOptions = { ...commonOptions, ...newOptions };
    setChartOptions(updatedOptions);
    // console.log("updatedOptions", updatedOptions);
    // eslint-disable-next-line
  }, [drillDownLevel, newOptions, contrastingColor]);
  // console.log('chartOptions', chartOptions, 'newOptions', newOptions)

  return (
    <>
      {chartOptions && newOptions && (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      )}
      {drillDownLevel > 0 ? (
        <span style={{ position: "fixed", bottom: "40px", right: "40px" }}>
          <Tooltip title="Level we have drilled down to">
            <Fab
              size="small"
              variant="extended"
              color="info"
              sx={{ margin: 1 }}
              onClick={drillUp}
            >
              Level {drillDownLevel}
            </Fab>
          </Tooltip>
          <Tooltip title="Drill up">
            <Fab size="small" color="info" sx={{ margin: 1 }} onClick={drillUp}>
              <ArrowDropUpIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Go to top level">
            <Fab size="small" color="info" sx={{ margin: 1 }} onClick={top}>
              <ArrowUpwardIcon />
            </Fab>
          </Tooltip>
        </span>
      ) : null}
      {showSubjects && (
        <LookBelow
          label="Subject Table"
          tooltip="Click to scroll to subject table appears below"
          mt={2}
          ml={4}
          mr={0}
          mb={0}
        />
      )}
      {showSubjects && (
        <SubjectTable
          selectedSubjects={subjectsDrilledTo}
          screenHeight={400}
          study={props.study}
          studyDatabase={props.studyDatabase}
          selectedOptions={props.selectedOptions}
        />
      )}
      <Snackbar
        message={"Can't drill any lower"}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackBarOpen(false)}
      />
    </>
  );
}
