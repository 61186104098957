import React, { useState, useEffect, useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {
  Tooltip,
  Fab,
  Snackbar,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SubjectTable from "../SubjectTable";
import {
  dataToStreamOptions,
  globalFilter,
  sql,
  makeAEBOTArray,
  addToHistory,
  round,
} from "../../apis/utility";
import addStreamgraphModule from "highcharts/modules/streamgraph";
import addSeriesLabel from "highcharts/modules/series-label";
import addAccessibility from "highcharts/modules/accessibility";
import { useLocation } from "react-router-dom";
import { LookBelow } from "../layout/LookBelow";

export default function AEBoth(props) {
  const location = useLocation();
  addToHistory({ title: "AE BoTh", url: location }, props.notitle);
  addStreamgraphModule(Highcharts);
  addSeriesLabel(Highcharts);
  addAccessibility(Highcharts);
  // console.log('props', props);

  const topChartRef = useRef(null),
    bottomChartRef = useRef(null),
    contrastingColor = props.theme.palette.mode === "dark" ? "white" : "black",
    [showSubjects, setShowSubjects] = useState(false),
    [dataForChart, setDataForChart] = useState(null),
    [drillVars, setDrillVars] = useState([]),
    [subjectsDrilledTo, setSubjectsDrilledTo] = useState([]),
    [whereClause, setWhereClause] = useState(1),
    [snackBarOpen, setSnackBarOpen] = useState(false),
    [drillDownPath] = useState(["aesev"]), // define the drilldown path here
    [drillDownLevel, setDrillDownLevel] = useState(0), // define the drilldown level here
    [newOptions, setNewOptions] = useState(null),
    [chartOptions, setChartOptions] = useState(null),
    [minX, setMinX] = useState(null),
    [maxX, setMaxX] = useState(null),
    [, setMinY] = useState(null),
    [maxY, setMaxY] = useState(null),
    [sumY, setSumY] = useState(null),
    [arms, setArms] = useState([]),
    classVar = "aesev",
    whereSubjects =
      props.selectedSubjects.length > 0
        ? 'where SUBJID in ("' + props.selectedSubjects.join('","') + '")'
        : "",
    drillUp = () => {
      setDrillVars(drillVars.slice(0, drillDownLevel - 1));
      setDrillDownLevel(drillDownLevel - 1);
      setShowSubjects(false);
    },
    top = () => {
      setDrillVars(drillVars.slice(0, 0));
      setDrillDownLevel(0);
      setShowSubjects(false);
    },
    whatHaveIDrilledDownTo = () => {
      const tempWhereClause = [];
      for (let i = 0; i < drillDownLevel; i++) {
        tempWhereClause.push(drillDownPath[i] + '="' + drillVars[i] + '"');
      }
      return tempWhereClause.join(" and ");
    },
    [lowerSelectedArm, setLowerSelectedArm] = useState(null),
    [upperSelectedArm, setUpperSelectedArm] = useState("PCBO");

  useEffect(() => {
    if (props.studyDatabase)
      makeAEBOTArray(
        props.studyDatabase,
        classVar,
        whereSubjects,
        drillDownLevel,
        drillDownPath,
        drillVars
      ).then((res) => {
        // console.log("res: ", res);
        if (res && res.min) setMinX(res.min);
        else setMinX(0);
        if (res && res.max) setMaxX(res.max);
        setWhereClause(res.tempWhereClause);
        const orderedArms = Object.keys(res.values),
          position = orderedArms.indexOf(upperSelectedArm),
          to = 0;
        // console.log('position: ', position)
        orderedArms.splice(to, 0, orderedArms.splice(position, 1)[0]); // put the default one at the top of the list
        // setArms(orderedArms)
        setArms(orderedArms);
        if (!("PCBO" in orderedArms)) setUpperSelectedArm(orderedArms[0]);
        setLowerSelectedArm(orderedArms[1]);
        // setDataForChart(res.values[upperSelectedArm]);
        setDataForChart(res.values);
      });
    // eslint-disable-next-line
  }, [classVar, whereSubjects, props.studyDatabase]);

  useEffect(() => {
    if (dataForChart) {
      // console.log("dataForChart", dataForChart);
      let streamOptions = {};
      Object.keys(dataForChart).forEach((arm) => {
        // console.log('arm', arm, 'classVar', classVar, 'dataForChart[arm]', dataForChart[arm], 'minX', minX, 'maxX', maxX);
        streamOptions[arm] = dataToStreamOptions(
          dataForChart[arm],
          classVar,
          minX,
          maxX,
          {
            MILD: "#99ccff",
            MODERATE: "#ffb84d",
            SEVERE: "#ff4d4d",
            T1: "#99ccff",
            T2: "#DC9492",
            T3: "#ffb84d",
            T4: "#AC011D",
            T5: "#000000",
          }, // specify colors to use for values of category variable
          {
            MILD: 2,
            MODERATE: 1,
            SEVERE: 0,
            T1: 4,
            T2: 3,
            T3: 2,
            T4: 1,
            T5: 0,
          }, // specify index to use for values of category variable, 0 is top subgroup
          false, // specify whether to invert values or not
          props.theme,
          true
        ); // force using 3 severities even if there are less
      });
      // console.log('streamOptions', streamOptions);
      setNewOptions(streamOptions);
      // work out the min and max values for the y axis
      const tempMin = {},
        tempMax = {},
        tempSum = {};
      Object.keys(streamOptions).forEach((arm) => {
        const armObject = streamOptions[arm];
        // console.log(arm, 'armObject', armObject)
        const values = armObject.series.map((item) =>
          item.data.map((obj) => obj.y)
        );
        // add values for each day
        const numberOfCategories = values.length,
          numberOfCategoryValues = Math.max(
            values[0].length,
            values[1].length,
            values[2].length
          ), // max of mild, moderate, severe
          totalsByCategory = [];
        for (let i = 0; i < numberOfCategories; i++) {
          for (let j = 0; j < numberOfCategoryValues; j++) {
            // console.log('i', i, 'j', j, 'values[i][j]', values[i][j])
            totalsByCategory[j] = values[i][j]
              ? totalsByCategory[j]
                ? totalsByCategory[j] + values[i][j] || 0
                : values[i][j] || 0
              : totalsByCategory[j] || 0;
          }
        }
        // console.log('arm', arm, 'totalsByCategory', totalsByCategory)
        // const merged = Array.prototype.concat.apply([], values).map((item) => item || 0)
        // console.log('arm', arm, 'values', values, 'merged', merged)
        tempMin[arm] = Math.ceil(Math.min(...totalsByCategory) * 10) / 10;
        tempMax[arm] = Math.ceil(Math.max(...totalsByCategory) * 10) / 10;
        tempSum[arm] =
          Math.ceil(
            totalsByCategory.reduce((partialSum, a) => partialSum + a, 0) * 10
          ) / 10;
      });
      // console.log('tempMin', tempMin, 'tempMax', tempMax, 'tempSum', tempSum);
      setMinY(tempMin);
      setMaxY(tempMax);
      setSumY(tempSum);
    }
    // eslint-disable-next-line
  }, [dataForChart, classVar, minX, maxX]);

  useEffect(() => {
    const subtitle = globalFilter(props.selectedOptions),
      commonOptions = (arm) => {
        return {
          chart: {
            type: "column",
            marginBottom: 25,
            height: props.screenHeight * 0.35,
            zoomType: "xy",
            backgroundColor: "transparent",
            divlinedashed: "1",
            divlinedashlen: "4",
            divlinedashgap: "2",
            divlinealpha: "60",
            divlinecolor: "#ACACAC",
            // "numvdivlines": "5",
            // "showalternatevgridcolor": "1",
            // "alternatevgridcolor": "#dfdfdf",
            // "alternatevgridalpha": "30",
          },
          // colors: ['#ffc2b3', '#e6e6ff'],
          title: {
            text: `Adverse Events (${props.study})`,
            style: { color: contrastingColor },
          },
          subtitle: {
            text: subtitle + "<br/>" + whatHaveIDrilledDownTo(),
            style: { color: contrastingColor },
          },
          plotOptions: {
            column: {
              groupPadding: 0,
              pointPadding: 0,
            },
            series: {
              stacking: "normal",
              label: {
                minFontSize: 5,
                maxFontSize: 15,
                style: {
                  color: contrastingColor,
                },
              },
              accessibility: {
                exposeAsGroupOnly: true,
              },
              point: {
                events: {
                  click: function (e) {
                    // console.log(
                    //   "e",
                    //   e,
                    //   "this.category",
                    //   this.category,
                    //   "this.name",
                    //   this.name
                    // );
                    setShowSubjects(true);
                    const enhancedWhereClause =
                      whereClause.replace("SUBJID", "ae.SUBJID") +
                      " and " +
                      drillDownPath.slice(-1)[0] +
                      '="' +
                      this.options.name +
                      '" and armcd="' +
                      arm +
                      '"';
                    // TODO: enhance the drill down to work out day of treatment and only return subjects for the day clicked on
                    sql(
                      props.studyDatabase,
                      `select distinct ae.SUBJID from ae left join dm on ae.subjid=dm.subjid ${enhancedWhereClause}`
                    ).then((res) => {
                      if (res.message === "success") {
                        const subjectData = res.data.map((d) => d.SUBJID);
                        setSubjectsDrilledTo(subjectData);
                      }
                    });
                    // }
                  },
                },
              },
              marker: { enabled: false },
              events: {
                legendItemClick: function (event) {
                  // when clicking the legend for bottom chart, hide the series in the top chart
                  const { index, visible } = event.target.userOptions;
                  topChartRef.current.chart.series[index].setVisible(
                    visible === undefined ? false : !visible
                  );
                },
              },
            },
          },
          yAxis: {
            visible: true,
            startOnTick: false,
            endOnTick: false,
            title: {
              text: "Toxicity Units",
              style: { color: contrastingColor },
            },
            min: 0,
            max:
              maxY && maxY[upperSelectedArm] && maxY[lowerSelectedArm]
                ? Math.max(maxY[upperSelectedArm], maxY[lowerSelectedArm])
                : undefined,
            labels: { style: { color: contrastingColor } },
          },
          legend: {
            enabled: true,
            floating: true,
          },
          tooltip: {
            formatter: function () {
              return (
                "<b>" +
                this.series.name +
                ",</b><br/> " +
                "Day = " +
                this.point.category +
                "<br/>" +
                "Weighted % of Patients with AEs: " +
                Highcharts.numberFormat(round(this.point.y, 1), 1)
              );
            },
          },
          credits: { enabled: false },
        };
      },
      updatedOptions = {},
      upperColors = {
        MILD: "#f4decd",
        MODERATE: "#e4b293",
        SEVERE: "#ac011d",
        T1: "#f4decd",
        T2: "#DC9492",
        T3: "#e4b293",
        T4: "#AC011D",
        T5: "#000000",
      },
      lowerColors = {
        MILD: "#f4decd",
        MODERATE: "#e4b293",
        SEVERE: "#ac011d",
        T1: "#f4decd",
        T2: "#DC9492",
        T3: "#e4b293",
        T4: "#AC011D",
        T5: "#000000",
      };
    // lowerColors = { MILD: '#ccebff', MODERATE: '#0099ff', SEVERE: '#0000ff' }

    // console.log('newOptions', newOptions);
    if (newOptions) {
      Object.keys(newOptions).forEach((arm) => {
        updatedOptions[arm] = { ...commonOptions(arm), ...newOptions[arm] };
        updatedOptions[arm].title = {
          text: `Adverse Events (${arm}) (${props.study})`,
          style: { color: contrastingColor },
        };
        if (arm !== upperSelectedArm) {
          updatedOptions[arm].series.forEach((series) => {
            series.color = lowerColors[series.name];
          });
          const titlemods = {
            verticalAlign: "bottom",
            y: -10,
            floating: true,
          };
          const chartmods = { marginBottom: 75 },
            xAxismods = {
              title: { text: null },
              enabled: false,
              visible: false,
              labels: { enabled: false },
            },
            yAxismods = {
              reversed: true,
              min: 0,
              max: Math.max(maxY[upperSelectedArm], maxY[lowerSelectedArm]),
            };
          updatedOptions[arm].title = {
            ...updatedOptions[arm].title,
            ...titlemods,
          };
          updatedOptions[arm].chart = {
            ...updatedOptions[arm].chart,
            ...chartmods,
          };
          updatedOptions[arm].xAxis = {
            ...updatedOptions[arm].xAxis,
            ...xAxismods,
          };
          updatedOptions[arm].yAxis = {
            ...updatedOptions[arm].yAxis,
            ...yAxismods,
          };
        } else {
          updatedOptions[arm].series.forEach((series) => {
            series.color = upperColors[series.name];
          });
          updatedOptions[arm].legend = { enabled: false };
        }
      });
      setChartOptions(updatedOptions);
      console.log(
        "chartOptions",
        updatedOptions,
        "lowerSelectedArm",
        lowerSelectedArm,
        "upperSelectedArm",
        upperSelectedArm
      );
    }
    // eslint-disable-next-line
  }, [newOptions, lowerSelectedArm, upperSelectedArm, contrastingColor]);

  const internalChart = {},
    afterChartCreated = (chart, whichOne) => {
      // console.log('chart', chart, whichOne)
      // Highcharts creates a separate chart instance during export
      if (!chart.options.chart.forExport) {
        internalChart[whichOne] = chart;
        // console.log('internalChart', internalChart)
      }
    };

  return (
    <>
      {chartOptions && chartOptions[upperSelectedArm] && (
        <Grid container spacing={3}>
          <Grid item xs={12} key={"upper"}>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions[upperSelectedArm]}
              immutable={true}
              callback={(e) => afterChartCreated(e, "upper")}
              ref={topChartRef}
            />
          </Grid>
        </Grid>
      )}
      {chartOptions && chartOptions[lowerSelectedArm] && (
        <Grid container spacing={3}>
          <Grid item xs={12} key={"lower"}>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions[lowerSelectedArm]}
              immutable={true}
              callback={(e) => afterChartCreated(e, "lower")}
              ref={bottomChartRef}
            />
          </Grid>
        </Grid>
      )}
      <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
        {arms && arms.length > 0 && upperSelectedArm && sumY && (
          <TextField
            id="outlined-read-only-input"
            label="Total Burden"
            value={sumY[upperSelectedArm]}
            InputProps={{
              readOnly: true,
            }}
          />
        )}
      </FormControl>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
        <InputLabel id="upper-arm-label">Upper Arm</InputLabel>
        {arms && arms.length > 0 && upperSelectedArm && (
          <Select
            labelId="upper-arm-label"
            id="upper-arm-select"
            value={upperSelectedArm ? upperSelectedArm : ""}
            label="Upper Arm"
            onChange={(event) => {
              setUpperSelectedArm(event.target.value);
            }}
          >
            {arms &&
              arms.length > 0 &&
              arms.map((arm, i) => (
                <MenuItem key={"up" + i} value={arm}>
                  {arm}
                </MenuItem>
              ))}
          </Select>
        )}
      </FormControl>
      {arms && arms.length > 1 && (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
          <InputLabel id="lower-arm-label">Lower Arm</InputLabel>
          {arms && arms.length > 0 && lowerSelectedArm && (
            <Select
              labelId="lower-arm-label"
              id="lower-arm-select"
              value={lowerSelectedArm ? lowerSelectedArm : ""}
              label="Lower Arm"
              onChange={(event) => {
                setLowerSelectedArm(event.target.value);
              }}
            >
              {arms &&
                arms.length > 0 &&
                arms.map((arm, i) => (
                  <MenuItem key={"low" + i} value={arm}>
                    {arm}
                  </MenuItem>
                ))}
            </Select>
          )}
        </FormControl>
      )}
      {arms && arms.length > 1 && (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
          {arms &&
            arms.length > 0 &&
            lowerSelectedArm &&
            sumY &&
            sumY[lowerSelectedArm] && (
              <TextField
                id="outlined-read-only-input"
                label="Total Burden"
                value={sumY[lowerSelectedArm]}
                InputProps={{
                  readOnly: true,
                }}
              />
            )}
        </FormControl>
      )}
      <Box sx={{ mb: 5 }}> </Box>
      {showSubjects && (
        <LookBelow
          label="Subject Table"
          tooltip="Click to scroll to subject table appears below"
          mt={2}
          ml={4}
          mr={0}
          mb={0}
        />
      )}
      {drillDownLevel > 0 ? (
        <span style={{ position: "fixed", bottom: "100px", right: "40px" }}>
          <Tooltip title="Level we have drilled down to">
            <Fab
              size="small"
              variant="extended"
              color="info"
              sx={{ margin: 1 }}
              onClick={drillUp}
            >
              Level {drillDownLevel}
            </Fab>
          </Tooltip>
          <Tooltip title="Drill up">
            <Fab size="small" color="info" sx={{ margin: 1 }} onClick={drillUp}>
              <ArrowDropUpIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Go to top level">
            <Fab size="small" color="info" sx={{ margin: 1 }} onClick={top}>
              <ArrowUpwardIcon />
            </Fab>
          </Tooltip>
        </span>
      ) : null}
      {showSubjects && (
        <SubjectTable
          selectedSubjects={subjectsDrilledTo}
          study={props.study}
          studyDatabase={props.studyDatabase}
          selectedOptions={props.selectedOptions}
          screenHeight={props.screenHeight}
        />
      )}
      <Snackbar
        message={"Can't drill any lower"}
        open={snackBarOpen}
        onClose={() => setSnackBarOpen(false)}
      />
    </>
  );
}
