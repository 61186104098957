import React, { useState, useEffect } from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import SubjectTable from "../SubjectTable";
import ReactFC from "react-fusioncharts";
import excelexport from "fusioncharts/fusioncharts.excelexport";
import { sql, globalFilter, addToHistory } from "../../apis/utility";
import { useLocation } from "react-router-dom";
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel';
import { LookBelow } from "../layout/LookBelow";
ReactFC.fcRoot(FusionCharts, TimeSeries, GammelTheme, excelexport);

export default function VDInfo(props) {
    const location = useLocation(),
        [subjectsDrilledTo, setSubjectsDrilledTo] = useState(null),
        whereSubjects = props.selectedSubjects.length > 0
            ? 'where SUBJID in ("' + props.selectedSubjects.join('","') + '")'
            : '',
        sqlStatement = `
    SELECT date(vddate) AS date,
           CASE WHEN substr(visit,1,9) = 'SCREENING' THEN 'Screening Visits' WHEN visit = 'UNSCHEDULED' THEN 'Unscheduled Visits' ELSE 'Scheduled Visits' END AS type,
           count(DISTINCT SUBJID) AS n
      FROM vd
      ${whereSubjects}
      GROUP BY 1,
               2;
    `,
        subtitle = globalFilter(props.selectedOptions),
        [data, setData] = useState(null),
        [timeseriesDs, setTimeseriesDs] = useState(null),
        config = {
            type: "timeseries",
            renderAt: "container",
            width: "100%",
            height: props.screenHeight * 0.75,
            dataSource: {
                chart: { theme: "gammel", "exportEnabled": "1" },
                caption: { text: "Visits by Calendar Day (" + props.study + ")" },
                subcaption: { text: subtitle },
                series: "Type",
                yaxis: [
                    {
                        plot: { value: "Visits", type: 'column' },
                        title: "Visits",
                        type: "number"
                    }
                ],
                data: null
            },
            events: {
                dataPlotClick: function (e) {
                    // console.log(e.data)
                    const drillSql = `select distinct SUBJID, case when substr(VISIT,1,4)='DAY ' then 'Scheduled Visits'
                    when VISIT='UNSCHEDULED' then 'Unscheduled Visits'
                    when substr(visit,1,9) = 'SCREENING' then 'Screening Visits' else VISIT end as V,
                    date(vddate) as date 
                    from vd where V='${e.data.seriesValue}' and date='${e.data.startText}'`
                    sql(props.studyDatabase, drillSql)
                        .then((res) => {
                            // console.log('res', res)
                            if (res.message === "success") {
                                setSubjectsDrilledTo(res.data.map(d => d.SUBJID));
                            }
                        })
                }
            }
        }
    addToHistory({ title: "VD Info", url: location })
    useEffect(() => {
        sql(props.studyDatabase, sqlStatement)
            .then((res) => {
                const tempData = res.data.map((item) => [item.date, item.type, item.n])
                setData(tempData)
            })
    }, [props.studyDatabase, sqlStatement])

    useEffect(() => {
        const schema = [{
            "name": "Time",
            "type": "date",
            "format": "%Y-%m-%d"
        }, {
            "name": "Type",
            "type": "string"
        }, {
            "name": "Visits",
            "type": "number"
        }]
        if (data) {
            const fusionTable = new FusionCharts.DataStore().createDataTable(
                data, schema);
            const temp = { ...config }
            temp.dataSource.data = fusionTable;
            setTimeseriesDs({ ...temp });
        }
        // eslint-disable-next-line
    }, [data])
    // console.log('timeseriesDs', timeseriesDs)

    return (<>
        {timeseriesDs
            ? <ReactFC {...timeseriesDs} />
            : "loading"}
        {subjectsDrilledTo &&
            <LookBelow label="Subject Table" tooltip="Click to scroll to subject table appears below" mt={2} ml={4} mr={0} mb={0} />
        }
        {subjectsDrilledTo &&
            <SubjectTable
                screenHeight={props.screenHeight}
                selectedSubjects={subjectsDrilledTo}
                study={props.study}
                studyDatabase={props.studyDatabase}
                selectedOptions={props.selectedOptions} />}
    </>)
}