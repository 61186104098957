import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton, Box } from "@mui/material";
import Cloud from "@mui/icons-material/Cloud";

export default function OverviewLink() {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 0, p: 1 }}>
      <Tooltip title="Programme Overview">
        <IconButton onClick={() => navigate("/")} sx={{ p: 0 }}>
          <Cloud
            sx={{ color: "text.primary", fontSize: 24, fontWeight: "medium" }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
