import * as React from "react";
import { Typography, Button, Divider } from "@mui/material";
import { useLocation } from "react-router-dom";
import { addToHistory } from "../../apis/utility";
import pdfmake from "pdfmake/build/pdfmake";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";

export const Reporting = (props) => {
  const location = useLocation(),
    serverUrl = window.location.host.startsWith("localhost")
      ? "http://localhost:3001"
      : "http://134.122.110.66",
    openUrl = (url) => {
      window.open(url, "_blank");
    },
    fonts = {
      Courier: {
        normal: "Courier",
        bold: "Courier-Bold",
        italics: "Courier-Oblique",
        bolditalics: "Courier-BoldOblique",
      },
      Helvetica: {
        normal: "Helvetica",
        bold: "Helvetica-Bold",
        italics: "Helvetica-Oblique",
        bolditalics: "Helvetica-BoldOblique",
      },
      Roboto: {
        normal:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
    },
    makePdf = () => {
      const docDefinition = {
        content: [
          { text: "Example Report", style: "header" },
          "Text would go here.",
          { text: "More text", style: "anotherStyle" },
          {
            text: "Multiple styles applied",
            style: ["header", "anotherStyle"],
          },
        ],
        styles: {
          header: {
            fontSize: 22,
            bold: true,
          },
          anotherStyle: {
            italics: true,
            alignment: "right",
          },
        },
      };
      pdfmake.createPdf(docDefinition, null, fonts).download();
    },
    makeDocx = () => {
      const docx = new Document({
        sections: [
          {
            properties: {},
            children: [
              new Paragraph({
                children: [
                  new TextRun("Example Report. "),
                  new TextRun({
                    text: "Text goes here.",
                    bold: true,
                  }),
                  new TextRun({
                    text: "\tMore text",
                    bold: true,
                  }),
                ],
              }),
            ],
          },
        ],
      });
      Packer.toBlob(docx).then((blob) => {
        saveAs(blob, "example.docx");
        console.log("Word document created successfully");
      });
    };

  addToHistory({ title: "Reporting", url: location });

  return (
    <>
      <Typography sx={{ mt: 2, mb: 3 }}>
        Reporting Screen (experimental)
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ mr: 2, mb: 2 }}
        onClick={() => openUrl(serverUrl + "/r-pdf/plot1")}
      >
        Sample R report
      </Button>
      <Button
        variant="contained"
        color="primary"
        sx={{ mr: 2, mb: 2 }}
        onClick={() => openUrl(serverUrl + "/python/test")}
      >
        Sample Python output
      </Button>
      <Button
        variant="contained"
        color="primary"
        sx={{ mr: 2, mb: 2 }}
        onClick={() => makePdf()}
      >
        Sample generated PDF
      </Button>
      <Button
        variant="contained"
        color="primary"
        sx={{ mr: 2, mb: 2 }}
        onClick={() => makeDocx()}
      >
        Sample generated Microsoft Word
      </Button>
      <Divider />
      <h5>Useful links</h5>
      <ul>
        <li>
          <a
            href="http://pdfmake.org/playground.html"
            rel="noreferrer"
            target="_blank"
          >
            PDF Playground
          </a>
        </li>
        <li>
          <a
            href="https://pdfmake.github.io/docs/0.1/document-definition-object/"
            rel="noreferrer"
            target="_blank"
          >
            PDF Documentation
          </a>
        </li>
      </ul>
    </>
  );
};
