import React, { useState, useEffect } from "react";
import { Typography, Button, Tooltip } from '@mui/material';
import { useLocation, useParams } from "react-router-dom";
import { sql, addToHistory } from '../../apis/utility';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const TestProfile = (props) => {
    const
        { labCategories, study } = props,
        params = useParams()
    // console.log('params', params,'props', props)
    const
        location = useLocation(),
        { studyDatabase, subjid, category } = params,
        [matchingTests, setMatchingTests] = useState([]),
        [gridData, setGridData] = useState(null),
        columns = [
            { width: 100, headerName: 'Code', field: 'LBTESTCD' },
            { width: 80, headerName: 'Subject', field: 'SUBJID' },
            { width: 120, headerName: 'Date', field: 'LBDTC' },
            { width: 200, headerName: 'Test', field: 'LBTEST' },
            { width: 60, headerName: 'Visit', field: 'VISIT' },
            { width: 50, headerName: 'Visit Day', field: 'VISITDY' },
            { width: 150, headerName: 'Result (Orig.)', field: 'originalResult' },
            { width: 150, headerName: 'Result (Std.)', field: 'standardizedResult' },
        ],
        [mode, setMode] = useState('*'),
        [testNames, setTestNames] = useState([]),
        [title, setTitle] = useState(category + ' for Subject ' + subjid + ' in ' + study)

    addToHistory({ title: "Test Profile", url: location })

    useEffect(() => {
        if (!labCategories) return
        const testKeys = Object.keys(labCategories),
            tempMatchingTests = []
        // console.log('testKeys', testKeys)
        testKeys.forEach(key => {
            // console.log('testKeys', testKeys, 'labCategories[key]', labCategories[key], 'params.category', params.category)
            if (labCategories[key] === params.category) tempMatchingTests.push(key)
        })
        // console.log('params', params, 'props', props, 'tempMatchingTests', tempMatchingTests, 'labCategories', labCategories)
        const sqlStatement = mode === '*'
            ? `SELECT * FROM lb WHERE subjid='${subjid}' and lbtestcd in ('${tempMatchingTests.join("','")}')`
            : `SELECT * FROM lb WHERE lbtestcd="${mode}"`
        setTitle(mode === '*'
            ? category + ' for Subject ' + subjid + ' in ' + study
            : mode + ' for all Subjects in ' + study)
        setMatchingTests(tempMatchingTests)
        sql(studyDatabase, sqlStatement)
            .then(res => {
                // console.log('sqlStatement', sqlStatement, 'res', res)
                setGridData(res.data.map((rowFromCheck, rowIndex) => {
                    const LBSTRES = rowFromCheck.LBSTRESC ? rowFromCheck.LBSTRESC : rowFromCheck.LBSTRESN,
                        originalResult = rowFromCheck.LBORRES + ' ' + rowFromCheck.LBORRESU,
                        standardizedResult = LBSTRES + ' ' + rowFromCheck.LBSTRESU
                    return {
                        ...rowFromCheck,
                        id: rowIndex,
                        originalResult: originalResult,
                        standardizedResult: standardizedResult,
                    }
                }))
            })
        // eslint-disable-next-line
    }, [subjid, studyDatabase, labCategories, mode])

    useEffect(() => {
        sql(studyDatabase, 'select distinct lbtest, lbtestcd from lb')
            .then(res => {
                const tempTestNames = {}
                res.data.forEach(row => {
                    // console.log(row)
                    tempTestNames[row.LBTESTCD] = row.LBTEST
                })
                setTestNames(tempTestNames)
                // console.log('res', res, 'tempTestNames', tempTestNames)
            })
    }, [studyDatabase])

    return (<>
        <Typography variant="h5">
            {title}
        </Typography>
        <Button
            onClick={() => setMode('*')}
            size='small'
            sx={{ color: 'blue', border: 1, "&:hover": { backgroundColor: 'primary.light' } }}
            key={'reset'}>
            Subject {subjid}
        </Button>
        {matchingTests.map((test, index) => <Tooltip title={`Look at all subjects results for ${test}`}>
            <Button
                onClick={() => setMode(test)}
                size='small'
                sx={{ color: 'primary', ml: 1, border: 0.5, "&:hover": { backgroundColor: 'primary.light' } }}
                key={'butt' + index}>
                {test in testNames ? testNames[test] : test}
            </Button>
        </Tooltip>)}
        {gridData && columns
            ? <div style={{ height: props.screenHeight * 0.8, width: "100%" }}>
                <DataGridPro
                    initialState={{
                        sorting: {
                            sortModel: [
                                { field: 'LBTESTCD', sort: 'asc' },
                                { field: 'SUBJID', sort: 'asc' },
                                { field: 'LBDTC', sort: 'asc' }
                            ],
                        },
                    }}
                    rows={gridData}
                    columns={columns}
                    density={"compact"}
                    rowHeight={30}
                    sx={{ fontSize: '0.7em' }}
                />
            </div>
            : null
        }
    </>
    );
};