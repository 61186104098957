import React, { useState, useEffect } from "react";
import { IconButton, Button, Box, Switch, FormGroup, FormControlLabel, CircularProgress, Tooltip } from "@mui/material";
import { sql, globalFilter, openSaveFileDialog, addToHistory, Heatmap2, arrayMax, arrayMin } from '../../apis/utility';
import { useParams } from "react-router-dom";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { usePapaParse } from 'react-papaparse';
import { useLocation } from "react-router-dom";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function ViewData(props) {
    // console.log('props', props)
    let params = useParams();
    const location = useLocation()
    addToHistory({ title: "View " + params.table, url: location })
    const [gridData, setGridData] = useState(null),
        [columns, setColumns] = useState(null),
        [currentParameter, setCurrentParameter] = useState(null),
        [checked, setChecked] = useState(true),
        handleChange = (event) => {
            setChecked(event.target.checked);
        },
        { selectedOptions } = props,
        // define what special renderers to use for certain columns
        renderOptions = {
            age: Heatmap2
        },
        subtitle = globalFilter(props.selectedOptions),
        { jsonToCSV } = usePapaParse(),
        exportData = () => {
            openSaveFileDialog(jsonToCSV(gridData), params.table, 'text/csv')
        },
        [tableInfo, setTableInfo] = useState(null),
        [waiting, setWaiting] = useState(false)

    useEffect(() => {
        sql('global', 'select * from tableinfo')
            .then(res => {
                if (res.message === 'success') {
                    const tempTableInfo = {}
                    res.data.forEach(row => {
                        tempTableInfo[row.name] = { short: row.short, long: row.long }
                    })
                    // console.log('tempTableInfo', tempTableInfo)
                    setTableInfo(tempTableInfo)
                }
            })
    }, [])

    useEffect(() => {
        setWaiting(true)
        let whereClause = ''
        sql(props.studyDatabase, `SELECT sql FROM sqlite_schema WHERE name = '${params.table}'`)
            .then(res => {
                const vars = res.data.length > 0
                    ? res.data[0]['sql'].replace(/ /g, ',').replace(/\(/g, '').replace(/\)/g, '').split(",")
                    : []
                if (vars.includes('SUBJID')) whereClause = 'SUBJID in (' +
                    props.selectedSubjects.map((item) => `'${item}'`) +
                    ')';

                let subjectsWhere = ''
                const subclauses = Object.keys(selectedOptions).map((variable) => {
                    // check if variables chosen match the table and use them if they do
                    let subclause = null
                    if (variable.startsWith(params.table.toUpperCase())) {
                        const variableValues = selectedOptions[variable]
                        const valueList = variableValues.map((value) => {
                            if (typeof value.value === 'string') return '"' + value.value + '"'
                            else return value.value
                        }).join(',')
                        subclause = variable + ' in (' + valueList + ')'
                    }
                    return subclause
                })

                const subclausesNotNull = subclauses.filter(n => n),
                    specificWhere = subclausesNotNull.length > 0
                        ? 'where ' + subclausesNotNull.join(' and ')
                        : ''
                if (props.selectedSubjects.length > 0 && whereClause.length > 0) subjectsWhere = 'where ' + whereClause

                const where = checked
                    ? subjectsWhere
                    : specificWhere
                sql(props.studyDatabase, `SELECT * FROM ${params.table} ` + where)
                    .then(res => {
                        if (res.data.length > 0) {
                            const tempData = res.data.map((row, id) => { return { ...row, id: id } })
                            // console.log('tempData', tempData);
                            setGridData(tempData);
                            const keys = Object.keys(res.data[0]),
                                columns = [],
                                ages = res.data.map((item) => item.age),
                                ageMin = arrayMin(ages),
                                ageMax = arrayMax(ages),
                                variableLabels = props.variableLabels ? props.variableLabels[params.table] : null
                            keys.forEach((key, index) => {
                                const headerName = variableLabels ? variableLabels[key] : key
                                columns.push({
                                    headerName: headerName,
                                    field: key,
                                    renderCell: (cellValues) => {
                                        const { value, field } = cellValues
                                        if (field in renderOptions) {
                                            const func = renderOptions[field]
                                            return func(value, ageMin, ageMax, 0, -1, 1, .5)
                                        } else if (field === 'SUBJID') {
                                            const target = `${window.location.protocol}//${window.location.host}/#/patientprofile/${props.studyDatabase}/${value}`
                                            return <Button onClick={() => { window.open(target) }
                                            }
                                            >{value}</Button>
                                        }
                                        else if (field === 'sex') {
                                            const color = value === 'F' ? 'red' : 'blue'
                                            return <b><span style={{ color: color }}>{value}</span></b>
                                        }
                                        return value
                                    }
                                })
                            })
                            setColumns(columns)
                            setWaiting(false)
                        }
                    })
            })
        // }
        // eslint-disable-next-line
    }, [props.selectedSubjects, params.table, checked])

    if (currentParameter !== params.table) {
        setCurrentParameter(params.table)
    }

    return (<>
        {waiting && <CircularProgress />}
        {!waiting && <>
            <Box sx={{ mt: 1, display: 'flex' }}>
                <Box sx={{ color: 'primary', alignContent: 'left', justifyContent: 'start' }}>
                    <Tooltip title="Download table">
                        <IconButton color="primary" onClick={exportData}>
                            <FileDownloadIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box sx={{ flexGrow: 1, color: 'primary' }}><b>
                    {tableInfo && params.table in tableInfo
                        ? tableInfo[params.table].short
                        : params.table}</b> ({props.study})
                </Box>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={checked} onChange={handleChange} />}
                        label="Subjects"
                        sx={{ color: 'primary' }} />
                </FormGroup>
            </Box>
            <Box sx={{ display: 'flex', bgcolor: 'background.paper' }}>
                <Box sx={{ flexGrow: 1, justifyContent: 'center', fontSize: 10 }}>{subtitle}</Box>
            </Box>
            {/* </h4> */}
            {gridData && columns
                ? <div style={{ height: props.screenHeight * 0.8, width: "100%" }}>
                    <DataGridPro
                        rows={gridData}
                        columns={columns}
                        density={"compact"}
                        rowHeight={30}
                        sx={{ fontSize: '0.7em' }}
                    // getCellClassName={(params) => {
                    //     if (params.field === 'age') {
                    //         console.log('params', params)
                    //         return 'red'
                    //     }
                    //     else return ''
                    // }}
                    />
                </div>
                : "Loading..."
            }
        </>}
    </>)
}