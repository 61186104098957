import React, { useState, useEffect } from "react";
import { sql, globalFilter, addToHistory } from "../../apis/utility";
import { useLocation } from "react-router-dom";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import excelexport from "fusioncharts/fusioncharts.excelexport";
import Charts from "fusioncharts/fusioncharts.charts";
import SubjectTable from "../SubjectTable";
// import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel';
import { LookBelow } from "../layout/LookBelow";
ReactFC.fcRoot(FusionCharts, Charts, GammelTheme, excelexport);

// const [open, setOpen] = React.useState(false);

export const AESunburst = (props) => {
    const
        location = useLocation(),
        [subtitle, setSubtitle] = useState(null),
        [dataSource, setDataSource] = useState(null),
        [showSubjects, setShowSubjects] = useState(false),
        [subjectsDrilledTo, setSubjectsDrilledTo] = useState([]),
        drillDownPath = props.drillDownPath,
        whereSubjects = props.selectedSubjects && props.selectedSubjects.length > 0
            ? 'where SUBJID in ("' + props.selectedSubjects.join('","') + '")'
            : '',
        sqlStatement1 = `SELECT ${drillDownPath[0]}, count( * ) AS count FROM ae ${whereSubjects} GROUP BY ${drillDownPath[0]}`,
        sqlStatement2 = `SELECT ${drillDownPath[0]}, ${drillDownPath[1]}, count( * ) AS count FROM ae ${whereSubjects} GROUP BY ${drillDownPath[0]}, ${drillDownPath[1]}`,
        sqlStatement3 = `SELECT ${drillDownPath[1]}, ${drillDownPath[2]}, count( * ) AS count FROM ae ${whereSubjects} GROUP BY ${drillDownPath[1]}, ${drillDownPath[2]}`
    // console.log('props', props)

    addToHistory({ title: "AE Sunburst", url: location })

    useEffect(() => {
        setSubtitle(globalFilter(props.selectedOptions))
        if (props.study && props.studyDatabase && drillDownPath && sqlStatement1 && sqlStatement2 && sqlStatement3)
            sql(props.studyDatabase, sqlStatement1)
                .then((res1) => {
                    if (res1.message === "success") {
                        // console.log('res1.data', res1.data)
                        const data1 = res1.data.map((row) => {
                            return {
                                parent: "AEs",
                                id: '1' + drillDownPath[0] + row[drillDownPath[0]],
                                label: row[drillDownPath[0]],
                                value: row.count
                            }
                        })
                        // console.log('data1', data1)
                        sql(props.studyDatabase, sqlStatement2)
                            .then((res2) => {
                                if (res2.message === "success") {
                                    // console.log('res2.data', res2.data)
                                    const data2 = res2.data.map((row) => {
                                        return {
                                            parent: '1' + drillDownPath[0] + row[drillDownPath[0]],
                                            id: '2' + drillDownPath[1] + row[drillDownPath[1]],
                                            label: row[drillDownPath[1]],
                                            value: row.count
                                        }
                                    })
                                    // console.log('data2', data2)
                                    sql(props.studyDatabase, sqlStatement3)
                                        .then((res3) => {
                                            if (res3.message === "success") {
                                                // console.log('res3.data', res3.data)
                                                const data3 = res3.data.map((row) => {
                                                    return {
                                                        parent: '2' + drillDownPath[1] + row[drillDownPath[1]],
                                                        id: '3' + row[drillDownPath[2]],
                                                        label: row[drillDownPath[2]],
                                                        value: row.count
                                                    }
                                                })
                                                // console.log('data3', data3)
                                                setDataSource({
                                                    "chart": {
                                                        caption: "Adverse Events (" + props.study + ")",
                                                        subcaption: subtitle,
                                                        // theme: "gammel",
                                                        "bgColor": "EEEEEE,CCCCCC",
                                                        "bgratio": "60,40",
                                                        "bgAlpha": "70,80",
                                                        "bgAngle": "180",
                                                        "exportEnabled": "1"
                                                    },
                                                    "data": [{
                                                        id: 'AEs',
                                                        parent: "",
                                                        label: 'AEs',
                                                        value: ''
                                                    }, ...data1, ...data2, ...data3]
                                                })
                                            }
                                        })
                                }
                            })
                    }
                })
    }, [props.selectedSubjects, props.study, props.studyDatabase, drillDownPath, props.selectedOptions, subtitle, sqlStatement1, sqlStatement2, sqlStatement3])

    // console.log('dataSource', dataSource)
    return (<>
        {dataSource
            ? <ReactFC
                type="sunburst"
                width="100%"
                height={props.screenHeight * 0.75}
                dataFormat="JSON"
                dataSource={dataSource}
                events={{
                    dataPlotClick: function (e) {
                        const { nodeId, label } = e.data,
                            level = e.data.nodeId.substring(0, 1),
                            variable = drillDownPath[level - 1]
                        // console.log('level', level, 'nodeId', nodeId, 'label', label, 'variable', variable)
                        if (nodeId === 'AEs') setShowSubjects(false)
                        else sql(props.studyDatabase, `select distinct SUBJID from ae where ${variable} = '${label}'`)
                            .then((res) => {
                                // console.log('res', res)
                                if (res.message === "success") {
                                    setShowSubjects(true);
                                    setSubjectsDrilledTo(res.data.map(d => d.SUBJID));
                                    // console.log('res.data', res.data)
                                } else setShowSubjects(false);
                            })
                    }
                }}
            />
            : "loading"}
        {showSubjects &&
            <LookBelow label="Subject Table" tooltip="Click to scroll to subject table appears below" mt={2} ml={4} mr={0} mb={0} />
        }
        {showSubjects &&
            <SubjectTable
                screenHeight={props.screenHeight}
                selectedSubjects={subjectsDrilledTo}
                study={props.study}
                studyDatabase={props.studyDatabase}
                selectedOptions={props.selectedOptions} />}
    </>

    );
};