import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
// Material UI
import { Container, Grid } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// apis
import {
  getWidth,
  getHeight,
  sql,
  getSubjects,
  checkLabAlerts,
  checkAdverseEventsAlerts,
} from "./apis/utility";
import { isLoggedIn } from "./apis/authService";
// components
import SubjectTable from "./components/SubjectTable";
//layout
import { TopMenu } from "./components/layout/TopMenu";
import { History } from "./components/layout/History";
// forms
import LoginPage from "./components/forms/LoginPage";
//pages
import GetDataFromUrl from "./components/pages/GetDataFromUrl";
import About from "./components/pages/About";
import NotFound from "./components/pages/NotFound";
import ViewData from "./components/pages/ViewData";
import BoxPlot from "./components/pages/BoxPlot";
import PatientProfile from "./components/pages/PatientProfile";
import Diagrams from "./components/pages/Diagrams";
// import { Pivot } from './components/pages/Pivot';
import { ViewSpark } from "./components/pages/ViewSpark";
import Home from "./components/pages/Home";
import Overview from "./components/pages/Overview";
import AEDrilldown from "./components/pages/AEDrilldown";
import AEStreamGraph from "./components/pages/AEStreamGraph";
import AdverseEventsGraph from "./components/pages/AdverseEventsGraph";
import TestGraph from "./components/pages/TestGraph";
import AEBoth from "./components/pages/AEBoth";
import DMInfo from "./components/pages/DMInfo";
import DSInfo from "./components/pages/DSInfo";
import DSSankey from "./components/pages/DSSankey";
import LBBoxplot from "./components/pages/LBBoxplot";
// import LBHysLaw from './components/pages/LBHysLaw';
import HysLaw from "./components/pages/HysLaw";
import VSBloodPressure from "./components/pages/VSBloodPressure";
import VSPulse from "./components/pages/VSPulse";
import VSOBloodPressure from "./components/pages/VSOBloodPressure";
import VSOPulse from "./components/pages/VSOPulse";
import VSListing from "./components/pages/VSListing";
import VDInfo from "./components/pages/VDInfo";
import Ecrf from "./components/pages/Ecrf";
import LBBoth from "./components/pages/LBBoth";
import Preferences from "./components/pages/Preferences";
import { MakeNotes } from "./components/pages/MakeNotes";
import { TestTable } from "./components/pages/TestTable";
import { TestNew } from "./components/pages/TestNew";
import { LBTable } from "./components/pages/LBTable";
import { InfoModal } from "./components/pages/InfoModal";
import { MakeNotesModal } from "./components/pages/MakeNotesModal";
import { ViewNotesModal } from "./components/pages/ViewNotesModal";
import { Openai } from "./components/pages/Openai";
import { Query } from "./components/pages/Query";
import { AEReports } from "./components/pages/AEReports";
import { Reporting } from "./components/pages/Reporting";
import { ViewNotes } from "./components/pages/ViewNotes";
import { TestProfile } from "./components/pages/TestProfile";
import { TestDayProfile } from "./components/pages/TestDayProfile";
import { AESunburst } from "./components/pages/AESunburst";
import { MHSunburst } from "./components/pages/MHSunburst";
import { Tools } from "./components/pages/Tools";
// python
import Pythondemo from "./components/python/Pythondemo";
// r
import Rdemo from "./components/r/Rdemo";
import { Files } from "./components/pages/Files";
// icons
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import StreamIcon from "@mui/icons-material/Stream";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TableViewIcon from "@mui/icons-material/TableView";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import MedicationLiquidIcon from "@mui/icons-material/MedicationLiquid";
import MasksIcon from "@mui/icons-material/Masks";
import InfoIcon from "@mui/icons-material/Info";
import TestHighChart from "./components/pages/TestHighChart";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export default function App() {
  LicenseInfo.setLicenseKey(
    "369a1eb75b405178b0ae6c2b51263cacTz03MTMzMCxFPTE3MjE3NDE5NDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  );
  const [study, setStudy] = useState(null),
    [studyDatabase, setStudyDatabase] = useState(null),
    [showAe, setShowAe] = useState("Y"),
    [showCm, setShowCm] = useState("Y"),
    [showDm, setShowDm] = useState("Y"),
    [showDs, setShowDs] = useState("Y"),
    [showLb, setShowLb] = useState("Y"),
    [showMh, setShowMh] = useState("Y"),
    [showVd, setShowVd] = useState("Y"),
    [showVs, setShowVs] = useState("Y"),
    [roles, setRoles] = useState(null),
    [listOfStudies, setListOfStudies] = React.useState(null),
    [selectedOptions, setSelectedOptions] = useState({}),
    [selectedSubjects, setSelectedSubjects] = useState([]),
    [optionsForSelection, setOptionsForSelection] = useState([]),
    [alertResults, setAlertResults] = useState(null),
    [seriousResults, setSeriousResults] = useState(null),
    [varToTable, setVarToTable] = useState(null),
    [listOfTables, setListOfTables] = useState(null),
    // define variables to be used to create filters
    [optionsForSelectionToBeProcessed, setOptionsForSelectionToBeProcessed] =
      useState(null),
    [variableLabels, setVariableLabels] = useState({}),
    [labCategories, setLabCategories] = useState(null),
    [mode, setMode] = React.useState("light"),
    colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
        },
      }),
      []
    ),
    [screenWidth, setScreenWidth] = useState(getWidth()),
    [screenHeight, setScreenHeight] = useState(getHeight()),
    [rowCounts, setRowCounts] = useState(null),
    theme = React.useMemo(
      () =>
        createTheme({
          typography: {
            fontFamily: [
              '"Helvetica Neue"',
              "Arial",
              "sans-serif",
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(","),
          },
          components: {
            MuiContainer: {
              styleOverrides: {
                root: {
                  width: screenWidth + "px",
                  height: "100%",
                  minHeight: "100%",
                  // },
                  // contained: {
                  background:
                    mode === "dark"
                      ? "linear-gradient(0deg, #505050, #C0C0C0)"
                      : "linear-gradient(0deg, #f2f2f2, #d9d9d9)",
                },
              },
            },
          },
          palette: {
            mode,
            // background: { default: '#B0B0B0' } // override background color
          },
          primary: {
            main: "#3f51b5",
          },
          secondary: {
            main: "#f50057",
          },
        }),
      [mode, screenWidth]
    ),
    links = [
      {
        area: "AE",
        label: "Adverse Events  Table",
        url: "/view/ae",
        icon: <TableViewIcon />,
      },
      {
        area: "AE",
        label: "Burden Of Toxicity (BoTh)",
        url: "/aeboth",
        icon: <CoronavirusIcon />,
      },
      {
        area: "AE",
        label: "Stream",
        url: "/aestreamgraph",
        icon: <StreamIcon />,
      },
      {
        area: "AE",
        label: "Drilldown",
        url: "/drill",
        icon: <FileDownloadIcon />,
      },
      {
        area: "AE",
        label: "Sunburst",
        url: "/aesunburst",
        icon: <WbSunnyIcon />,
      },
      {
        area: "DM",
        label: "Demography Table",
        url: "/view/dm",
        icon: <TableViewIcon />,
      },
      {
        area: "DM",
        label: "Demography Info",
        url: "/dminfo",
        icon: <AccessibilityNewIcon />,
      },
      {
        area: "DS",
        label: "Disposition   Table",
        url: "/view/ds",
        icon: <TableViewIcon />,
      },
      {
        area: "DS",
        label: "Disposition by Calendar Day",
        url: "/dsinfo",
        icon: <TableViewIcon />,
      },
      {
        area: "DS",
        label: "Sankey",
        url: "/dssankey",
        icon: <AccountTreeIcon />,
      },
      {
        area: "LB",
        label: "Labs Table",
        url: "/view/lb",
        icon: <TableViewIcon />,
      },
      {
        area: "LB",
        label: "Burden Of Toxicity (BoTh)",
        url: "/lbboth",
        icon: <CoronavirusIcon />,
      },
      // { area: 'LB', label: 'Old Box Plot', url: '/lbbox', icon: <CandlestickChartIcon /> },
      {
        area: "LB",
        label: "Labs Box Plot",
        url: "/lbboxplot",
        icon: <CandlestickChartIcon />,
      },
      {
        area: "LB",
        label: "Biochemistry",
        url: "/lbtable",
        icon: <MedicationLiquidIcon />,
      },
      // { area: 'LB', label: 'Hys Law 1', url: '/lbhyslaw', icon: <MasksIcon /> },
      { area: "LB", label: "Hys Law", url: "/hyslaw", icon: <MasksIcon /> },
      {
        area: "MH",
        label: "Sunburst",
        url: "/mhsunburst",
        icon: <WbSunnyIcon />,
      },
      {
        area: "VD",
        label: "Visit Data Table",
        url: "/view/vd",
        icon: <TableViewIcon />,
      },
      { area: "VD", label: "Visit by Day", url: "/vdinfo", icon: <InfoIcon /> },
      {
        area: "VS",
        label: "Vital Signs Table",
        url: "/view/vs",
        icon: <TableViewIcon />,
      },
      // { area: 'VS', label: 'Blood Pressure', url: '/vsbp', icon: <MedicationLiquidIcon /> },
      // { area: 'VS', label: 'Pulse', url: '/vspulse', icon: <CandlestickChartIcon /> },
      {
        area: "VS",
        label: "Vital Signs Box Plot",
        url: "/vsboxplot",
        icon: <CandlestickChartIcon />,
      },
      // { area: 'VS', label: 'Orthostatic Blood Pressure', url: '/vsobp', icon: <MedicationLiquidIcon /> },
      // { area: 'VS', label: 'Orthostatic Pulse', url: '/vsopulse', icon: <CandlestickChartIcon /> },
      {
        area: "VS",
        label: "Vital Signs Listing",
        url: "/vslisting",
        icon: <CandlestickChartIcon />,
      },
      {
        area: "Reporting",
        label: "AE Reports",
        url: "/aereports",
        icon: <TableViewIcon />,
      },
      {
        area: "Reporting",
        label: "Standard Reports",
        url: "/reporting",
        icon: <TableViewIcon />,
      },
      {
        area: "Reporting",
        label: "View Notes",
        url: "/viewnotes",
        icon: <TableViewIcon />,
      },
      {
        area: "Reporting",
        label: "Diagrams",
        url: "/diagrams",
        icon: <TableViewIcon />,
      },
      // { area: 'Reporting', label: 'Pivot', url: '/pivot', icon: <TableViewIcon /> },
      {
        area: "Reporting",
        label: "Files",
        url: "/files",
        icon: <TableViewIcon />,
      },
      {
        area: "Reporting",
        label: "View Sparklines",
        url: "/viewspark",
        icon: <TableViewIcon />,
      },
    ],
    [normalRanges, setNormalRanges] = useState({
      PULSE: { low: 60, high: 100 },
      SYSBP: { low: 80, high: 120 },
      DIABP: { low: 60, high: 90 },
      OPULSE: { low: 60, high: 100 },
      OSYSBP: { low: 80, high: 120 },
      ODIABP: { low: 60, high: 90 },
    }),
    [alerts, setAlerts] = useState({}),
    [drillDownPath, setDrillDownPath] = useState([
      "AEBODSYS",
      "AEHLT",
      "AELLT",
    ]),
    [drillDownPathMH, setDrillDownPathMH] = useState([
      "MHBODSYS",
      "MHCAT",
      "MHDECOD",
    ]),
    [openInfoModal, setOpenInfoModal] = React.useState(false),
    [openMakeNotesModal, setOpenMakeNotesModal] = React.useState(false),
    [openViewNotesModal, setOpenViewNotesModal] = React.useState(false),
    [loggedIn, setLoggedIn] = useState(false),
    [userName, setUserName] = useState(""),
    [userType, setUserType] = useState(null),
    [lastDatabaseChecked, setLastDatabaseChecked] = useState(null),
    [, setWaiting] = useState(false),
    navigate = useNavigate();

  if (!listOfStudies) {
    sql("global", `SELECT * FROM studys`).then((res) => {
      setListOfStudies(res.data);
      setStudy(res.data[0].STUDYID);
      setStudyDatabase(res.data[0].filename);
      // console.log('res.data[0].STUDYID', res.data[0].STUDYID, 'study', study, 'listOfStudies', listOfStudies);
      sql(
        res.data[0].filename,
        `SELECT distinct lbcat as category, lbtestcd as code, lbtest as test, min(lbstnrlo) as low, max(lbstnrhi) as high from lb where lbstresn>0 and  lbstnrhi>' ' group by lbcat, lbtestcd`
      ).then((res) => {
        // console.log('res', res);
        const tempAlerts = {};
        res.data.forEach((row) => {
          // console.log('labCategories',labCategories,'row',row);
          // use our own categories if available, otherwise use the ones from the database
          // const category = labCategories[row.code]
          // ? labCategories[row.code]
          // : row.category;
          tempAlerts[row.code] = {
            high: row.high,
            test: row.test,
            category: row.category,
          };
          if (row.low > 0) tempAlerts[row.code].low = row.low;
        });
        setAlerts(tempAlerts);
      });
    });
    sql("global", `SELECT * FROM roles`).then((res) => {
      // console.log('res', res);
      setRoles(res.data);
    });
  }

  useEffect(() => {
    if (isLoggedIn()) {
      setUserName(localStorage.getItem("username"));
      setUserType(localStorage.getItem("usertype"));
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [loggedIn]);

  useEffect(() => {
    if (studyDatabase && varToTable)
      sql(
        studyDatabase,
        `SELECT name FROM sqlite_schema WHERE type='table' ORDER BY name`
      ).then((res) => {
        // console.log('sqlite_schema - res', res, 'studyDatabase', studyDatabase)
        if (studyDatabase && res.data.length > 0) {
          const listOfTableNames = res.data.map((item) => item.name); // get list of tables
          setListOfTables(listOfTableNames);
          let tempRowCounts = {};
          listOfTableNames.forEach((t) => {
            sql(studyDatabase, `SELECT count(1) as ${t} FROM ${t}`).then(
              (res2) => {
                const item = res2.data[0];
                // console.log(item);
                tempRowCounts = { ...tempRowCounts, ...item };
                // console.log("tempRowCounts", tempRowCounts);
                setRowCounts(tempRowCounts);
              }
            );
          });
          setSelectedOptions({}); // reset selections
          getSubjects(studyDatabase, {}, varToTable).then((res) => {
            setSelectedSubjects(res);
          });
        }
      });
    // eslint-disable-next-line
  }, [studyDatabase, varToTable]);

  // create filter lists
  useEffect(() => {
    // console.log('optionsForSelectionToBeProcessed', optionsForSelectionToBeProcessed)
    if (optionsForSelectionToBeProcessed && listOfTables)
      optionsForSelectionToBeProcessed.forEach((opt) => {
        // console.log('opt', opt);
        Object.keys(opt.options).forEach((key) => {
          const table = opt.options[key].table;
          // const gotOptions = 'options' in opt.options[key]
          // console.log('key', key, 'table', table, 'gotOptions', gotOptions)
          // if we dont already have the options, then we go and get them
          // if (!gotOptions) {
          // check if key is in table
          const sqlStatement = `SELECT sql FROM sqlite_schema WHERE name = '${table}'`;
          sql(studyDatabase, sqlStatement).then((res) => {
            // console.log(sqlStatement, res.data, 'listOfTables', listOfTables, 'table', table)
            const vars =
              res.data.length > 0
                ? res.data[0]["sql"]
                    .replace(/ /g, ",")
                    .replace(/\(/g, "")
                    .replace(/\)/g, "")
                    .split(",")
                : [];
            if (listOfTables.includes(table) && vars.includes(key)) {
              // console.log('table', table, 'vars', vars, 'key', key)
              sql(
                studyDatabase,
                `SELECT DISTINCT ${key} FROM ${table} ORDER BY ${key}`
              ).then((res) => {
                // console.log('key', key, 'res', res)
                if (res.message === "success") {
                  opt.options[key].options = res.data.map((r) => {
                    // console.log('r', r)
                    return { value: r[key], label: r[key] };
                  });
                } else {
                  opt.options[key].options = [];
                }
              });
            } else {
              opt.options[key].options = [];
            }
          });
          // }
        });
        setOptionsForSelection(optionsForSelectionToBeProcessed);
      });
    // eslint-disable-next-line
  }, [studyDatabase, optionsForSelectionToBeProcessed, listOfTables]);

  // remove keys with empty content
  useEffect(() => {
    Object.keys(selectedOptions).forEach((key) => {
      if (selectedOptions[key].length === 0) {
        delete selectedOptions[key];
      }
    });
  }, [selectedOptions]);

  // check for lab alerts
  useEffect(() => {
    // console.log('alerts', alerts,'studyDatabase', studyDatabase,'alertResults', alertResults)
    if (alerts && studyDatabase && labCategories && !alertResults)
      checkLabAlerts(
        alerts,
        setWaiting,
        studyDatabase,
        labCategories,
        setAlertResults
      );
    // eslint-disable-next-line
  }, [alerts, studyDatabase, labCategories]);

  //check for serious adverse events
  useEffect(() => {
    if (studyDatabase && lastDatabaseChecked !== studyDatabase) {
      // get serious adverse events if we haven't already
      checkAdverseEventsAlerts(setWaiting, studyDatabase, setSeriousResults);
      setLastDatabaseChecked(studyDatabase);
    }
    // eslint-disable-next-line
  }, [studyDatabase]);

  // get labCategories
  useEffect(() => {
    sql(studyDatabase, `SELECT * FROM labcategories`).then((res) => {
      // console.log(res)
      if (res.message === "success") {
        const tempLabCategories = {};
        res.data.forEach((row) => (tempLabCategories[row.code] = row.category));
        // console.log('tempLabCategories', tempLabCategories)
        setLabCategories(tempLabCategories);
      }
    });
  }, [studyDatabase]);

  // get normalRanges
  useEffect(() => {
    sql(
      studyDatabase,
      `SELECT * FROM vitalsignslimits union all select * from lablimits`
    ).then((res) => {
      // console.log(res)
      if (res.message === "success") {
        const tempNormalRanges = {};
        res.data.forEach((row) => {
          if (!(row.code in tempNormalRanges)) tempNormalRanges[row.code] = {};
          tempNormalRanges[row.code].low = row.LLN;
          tempNormalRanges[row.code].high = row.ULN;
        });
        // console.log('tempNormalRanges', tempNormalRanges)
        setNormalRanges(tempNormalRanges);
      }
    });
  }, [studyDatabase]);

  // get variableLabels
  useEffect(() => {
    sql(studyDatabase, `SELECT * FROM variablelabels`).then((res) => {
      // console.log(res)
      if (res.message === "success") {
        const tempVariableLabels = {};
        res.data.forEach((row) => {
          if (!(row.table in tempVariableLabels))
            tempVariableLabels[row.table] = {};
          tempVariableLabels[row.table][row.variable] = row.label;
        });
        // console.log('tempVariableLabels', tempVariableLabels)
        setVariableLabels(tempVariableLabels);
      }
    });
  }, [studyDatabase]);

  // get optionsForSelectionToBeProcessed
  useEffect(() => {
    sql(studyDatabase, `SELECT * FROM optionsForSelectionToBeProcessed`).then(
      (res) => {
        // console.log(res)
        if (res.message === "success") {
          const tempVarToTable = {};
          let tempOptionsForSelectionToBeProcessed = [];
          let next = {};
          res.data.forEach((item) => {
            if (item.group !== next.label) {
              tempOptionsForSelectionToBeProcessed.push(next);
              next = { label: item.group, options: {} };
            }
            next.options[item.key] = { label: item.label, table: item.table };
            // console.log('next', next)
          });
          tempOptionsForSelectionToBeProcessed.push(next);
          tempOptionsForSelectionToBeProcessed =
            tempOptionsForSelectionToBeProcessed.slice(1);
          // console.log('tempOptionsForSelectionToBeProcessed', tempOptionsForSelectionToBeProcessed)
          tempOptionsForSelectionToBeProcessed.forEach((opt) => {
            Object.keys(opt.options).forEach((key) => {
              tempVarToTable[key] = opt.options[key].table;
            });
          });
          // console.log('study', study, 'listOfStudies', listOfStudies);
          // console.log('tempVarToTable', tempVarToTable)
          setVarToTable(tempVarToTable);
          setOptionsForSelectionToBeProcessed(
            tempOptionsForSelectionToBeProcessed
          );
        }
      }
    );
    // eslint-disable-next-line
  }, [studyDatabase]);

  // xs, extra-small: 0px
  // sm, small: 600px
  // md, medium: 900px
  // lg, large: 1200px
  // xl, extra-large: 1536px

  return loggedIn ? (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Container
          maxWidth={false}
          sx={{
            display: "flex",
            "& .MuiContainer-maxWidthLg": {
              maxWidth: "100%",
            },
            maxWidth: "100%",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "background.default",
            color: "text.primary",
            borderRadius: 1,
            p: 3,
          }}
        >
          <div className="App">
            <TopMenu
              study={study}
              alerts={alerts}
              alertResults={alertResults}
              setAlertResults={setAlertResults}
              labCategories={labCategories}
              setStudy={setStudy}
              setStudyDatabase={setStudyDatabase}
              setShowAe={setShowAe}
              setShowCm={setShowCm}
              setShowDm={setShowDm}
              setShowDs={setShowDs}
              setShowLb={setShowLb}
              setShowMh={setShowMh}
              setShowVd={setShowVd}
              setShowVs={setShowVs}
              showAe={showAe}
              showDm={showDm}
              showCm={showCm}
              showDs={showDs}
              showLb={showLb}
              showMh={showMh}
              showVd={showVd}
              showVs={showVs}
              setDrillDownPath={setDrillDownPath}
              setDrillDownPathMH={setDrillDownPathMH}
              studyDatabase={studyDatabase}
              selectedOptions={selectedOptions}
              listOfTables={listOfTables}
              setSelectedOptions={setSelectedOptions}
              optionsForSelection={optionsForSelection}
              varToTable={varToTable}
              setSelectedSubjects={setSelectedSubjects}
              selectedSubjects={selectedSubjects}
              listOfStudies={listOfStudies}
              theme={theme}
              links={links}
              screenHeight={screenHeight}
              userName={userName}
              userType={userType}
              openInfoModal={openInfoModal}
              setOpenInfoModal={setOpenInfoModal}
              openMakeNotesModal={openMakeNotesModal}
              setOpenMakeNotesModal={setOpenMakeNotesModal}
              openViewNotesModal={openViewNotesModal}
              setOpenViewNotesModal={setOpenViewNotesModal}
              setTheme={colorMode.toggleColorMode}
            />
            <Grid container spacing={2}>
              <Grid item xs={2} xl={1}>
                <Tools
                  screenHeight={screenHeight}
                  theme={theme}
                  mode={mode}
                  showAe={showAe}
                  showCm={showCm}
                  showDm={showDm}
                  showDs={showDs}
                  showLb={showLb}
                  showMh={showMh}
                  showVd={showVd}
                  showVs={showVs}
                />
              </Grid>
              <Grid item xs={10} xl={11} sx={{ mb: 1 }}>
                <Routes>
                  {/* General */}
                  <Route
                    path="/"
                    element={
                      <Overview
                        study={study}
                        listOfTables={listOfTables}
                        listOfStudies={listOfStudies}
                        studyDatabase={studyDatabase}
                        links={links}
                        theme={theme}
                        alerts={alerts}
                        rowCounts={rowCounts}
                        seriousResults={seriousResults}
                        screenHeight={screenHeight}
                        screenWidth={screenWidth}
                        setSelectedSubjects={setSelectedSubjects}
                        setSelectedOptions={setSelectedOptions}
                        varToTable={varToTable}
                        labCategories={labCategories}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                        setStudy={setStudy}
                        setStudyDatabase={setStudyDatabase}
                        setDrillDownPath={setDrillDownPath}
                        setDrillDownPathMH={setDrillDownPathMH}
                        setShowAe={setShowAe}
                        setShowCm={setShowCm}
                        setShowDm={setShowDm}
                        setShowDs={setShowDs}
                        setShowLb={setShowLb}
                        setShowMh={setShowMh}
                        setShowVd={setShowVd}
                        setShowVs={setShowVs}
                        showAe={showAe}
                        showCm={showCm}
                        showDm={showDm}
                        showDs={showDs}
                        showLb={showLb}
                        showMh={showMh}
                        showVd={showVd}
                        showVs={showVs}
                      />
                    }
                  />
                  <Route
                    path="/home"
                    element={
                      <Home
                        study={study}
                        listOfTables={listOfTables}
                        studyDatabase={studyDatabase}
                        rowCounts={rowCounts}
                        links={links}
                        theme={theme}
                        alerts={alerts}
                        seriousResults={seriousResults}
                        screenHeight={screenHeight}
                        screenWidth={screenWidth}
                        setSelectedSubjects={setSelectedSubjects}
                        setSelectedOptions={setSelectedOptions}
                        varToTable={varToTable}
                        labCategories={labCategories}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                        showAe={showAe}
                        showCm={showCm}
                        showDm={showDm}
                        showDs={showDs}
                        showLb={showLb}
                        showMh={showMh}
                        showVd={showVd}
                        showVs={showVs}
                      />
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <LoginPage
                        loggedIn={loggedIn}
                        setLoggedIn={setLoggedIn}
                        userName={userName}
                        setUserName={setUserName}
                        userType={userType}
                        setUserType={setUserType}
                      />
                    }
                  />
                  <Route
                    path="/subjects"
                    element={
                      <SubjectTable
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                        detail={true}
                      />
                    }
                  />
                  <Route
                    path="/tools/:id"
                    element={
                      <Tools
                        screenHeight={screenHeight}
                        study={study}
                        studyDatabase={studyDatabase}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="testprofile/:studyDatabase/:category/:test/:subjid"
                    element={
                      <TestProfile
                        study={study}
                        studyDatabase={studyDatabase}
                        labCategories={labCategories}
                        theme={theme}
                        screenHeight={screenHeight}
                      />
                    }
                  />
                  <Route
                    path="testdayprofile/:studyDatabase/:date/:test/:subjid"
                    element={
                      <TestDayProfile
                        study={study}
                        studyDatabase={studyDatabase}
                        // date={date}
                        theme={theme}
                        screenHeight={screenHeight}
                      />
                    }
                  />
                  <Route
                    path="patientprofile/:studyDatabase/:id"
                    element={
                      <PatientProfile
                        study={study}
                        studyDatabase={studyDatabase}
                        theme={theme}
                        screenHeight={screenHeight}
                        screenWidth={screenWidth}
                      />
                    }
                  />
                  <Route
                    path="/patient-profiles/#/patientprofile/:studyDatabase/:id"
                    element={
                      <PatientProfile
                        study={study}
                        studyDatabase={studyDatabase}
                        theme={theme}
                        screenHeight={screenHeight}
                        screenWidth={screenWidth}
                      />
                    }
                  />
                  {/* <Route path="patientprofile"
                element={<PatientProfile />} /> */}
                  <Route
                    path="view/:table"
                    element={
                      <ViewData
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        optionsForSelection={optionsForSelection}
                        variableLabels={variableLabels}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="preferences"
                    element={
                      <Preferences
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        setScreenHeight={setScreenHeight}
                        setScreenWidth={setScreenWidth}
                        screenWidth={screenWidth}
                        normalRanges={normalRanges}
                        setNormalRanges={setNormalRanges}
                        alerts={alerts}
                        variableLabels={variableLabels}
                        setAlerts={setAlerts}
                        drillDownPath={drillDownPath}
                        setDrillDownPath={setDrillDownPath}
                        setDrillDownPathMH={setDrillDownPathMH}
                      />
                    }
                  />
                  <Route
                    path="/viewnotes"
                    element={<ViewNotes screenHeight={screenHeight} />}
                  />

                  {/* LB */}

                  <Route
                    path="/lbbox"
                    element={
                      <LBBoxplot
                        theme={theme}
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/lbboxplot"
                    element={
                      <BoxPlot
                        study={study}
                        studyDatabase={studyDatabase}
                        normalRanges={normalRanges}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                        labCategories={labCategories}
                        parms={{
                          table: "lb",
                          categoryVariable: "LBTESTCD",
                          categoryVariableLabel: "LBTEST",
                          xCategoryValues: ["ALT", "AST"],
                          codeOptions: [
                            { value: "ALT", label: "ALT" },
                            { value: "AST", label: "AST" },
                          ],
                          dmOptions: [
                            { value: "ARMCD", label: "Arm Code" },
                            { value: "SEX", label: "Sex" },
                            { value: "SUBJID", label: "Subject ID" },
                            { value: "AGE", label: "Age" },
                            { value: "RACE", label: "Race" },
                            { value: "ETHNIC", label: "Ethnicity" },
                            { value: "SITEID", label: "Site" },
                          ],
                          widthOptions: [
                            { value: "12", label: "Full" },
                            { value: "6", label: "1/2" },
                            { value: "4", label: "1/3" },
                            { value: "3", label: "1/4" },
                            { value: "2", label: "1/6" },
                            { value: "1", label: "1/12" },
                          ],
                          xVariable: "LBDY",
                          yVariable: "LBSTRESN",
                        }}
                      />
                    }
                  />
                  <Route
                    path="/lbtable"
                    element={
                      <LBTable
                        theme={theme}
                        study={study}
                        labCategories={labCategories}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/lbboth"
                    element={
                      <LBBoth
                        theme={theme}
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  {/* <Route path="/lbhyslaw"
                      element={<LBHysLaw
                        theme={theme}
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects} />} /> */}
                  <Route
                    path="/hyslaw"
                    element={
                      <HysLaw
                        theme={theme}
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />

                  {/* VS */}

                  <Route
                    path="/vsboxplot"
                    element={
                      <BoxPlot
                        study={study}
                        studyDatabase={studyDatabase}
                        normalRanges={normalRanges}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                        parms={{
                          table: "vs",
                          categoryVariable: "VSTESTCD",
                          categoryVariableLabel: "VSTEST",
                          xCategoryValues: ["PULSE"],
                          codeOptions: [
                            { value: "PULSE", label: "PULSE" },
                            { value: "SYSBP", label: "SYSBP" },
                            { value: "DIABP", label: "DIABP" },
                          ],
                          dmOptions: [
                            { value: "ARMCD", label: "Arm Code" },
                            { value: "SEX", label: "Sex" },
                            { value: "SUBJID", label: "Subject ID" },
                            { value: "AGE", label: "Age" },
                            { value: "RACE", label: "Race" },
                            { value: "ETHNIC", label: "Ethnicity" },
                            { value: "SITEID", label: "Site" },
                          ],
                          widthOptions: [
                            { value: "12", label: "Full" },
                            { value: "6", label: "1/2" },
                            { value: "4", label: "1/3" },
                            { value: "3", label: "1/4" },
                            { value: "2", label: "1/6" },
                            { value: "1", label: "1/12" },
                          ],
                          xVariable: "VSDY",
                          yVariable: "VSSTRESN",
                        }}
                      />
                    }
                  />
                  <Route
                    path="/vsbp"
                    element={
                      <VSBloodPressure
                        study={study}
                        studyDatabase={studyDatabase}
                        normalRanges={normalRanges}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/vsobp"
                    element={
                      <VSOBloodPressure
                        study={study}
                        studyDatabase={studyDatabase}
                        normalRanges={normalRanges}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/vspulse"
                    element={
                      <VSPulse
                        study={study}
                        studyDatabase={studyDatabase}
                        normalRanges={normalRanges}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/vsopulse"
                    element={
                      <VSOPulse
                        study={study}
                        studyDatabase={studyDatabase}
                        normalRanges={normalRanges}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/vslisting"
                    element={
                      <VSListing
                        study={study}
                        studyDatabase={studyDatabase}
                        normalRanges={normalRanges}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />

                  {/* DM */}

                  <Route
                    path="/dminfo"
                    element={
                      <DMInfo
                        height1={400}
                        height2={160}
                        detail={true}
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />

                  {/* VD */}

                  <Route
                    path="/vdinfo"
                    element={
                      <VDInfo
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />

                  {/* DS */}

                  <Route
                    path="/dsinfo"
                    element={
                      <DSInfo
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/dssankey"
                    element={
                      <DSSankey
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />

                  {/* AE */}

                  <Route
                    path="/aeboth"
                    element={
                      <AEBoth
                        theme={theme}
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/ae"
                    element={
                      <AdverseEventsGraph
                        theme={theme}
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/aestreamgraph"
                    element={
                      <AEStreamGraph
                        theme={theme}
                        study={study}
                        variableLabels={variableLabels}
                        studyDatabase={studyDatabase}
                        drillDownPath={drillDownPath}
                        screenHeight={screenHeight}
                        selectedOptions={selectedOptions}
                        setSelectedSubjects={setSelectedSubjects}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/drill"
                    element={
                      <AEDrilldown
                        theme={theme}
                        study={study}
                        variableLabels={variableLabels}
                        studyDatabase={studyDatabase}
                        drillDownPath={drillDownPath}
                        screenHeight={screenHeight}
                        screenWidth={screenWidth}
                        selectedOptions={selectedOptions}
                        setSelectedSubjects={setSelectedSubjects}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/aesunburst"
                    element={
                      <AESunburst
                        theme={theme}
                        study={study}
                        drillDownPath={drillDownPath}
                        variableLabels={variableLabels}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        screenWidth={screenWidth}
                        selectedOptions={selectedOptions}
                        setSelectedSubjects={setSelectedSubjects}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/mhsunburst"
                    element={
                      <MHSunburst
                        theme={theme}
                        study={study}
                        drillDownPathMH={drillDownPathMH}
                        variableLabels={variableLabels}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                        screenWidth={screenWidth}
                        selectedOptions={selectedOptions}
                        setSelectedSubjects={setSelectedSubjects}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />

                  {/* R & D */}

                  <Route path="/testnew" element={<TestNew />} />
                  <Route
                    path="/testgraph"
                    element={
                      <TestGraph
                        selectedOptions={selectedOptions}
                        screenHeight={screenHeight}
                        setSelectedSubjects={setSelectedSubjects}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/testhighchart"
                    element={
                      <TestHighChart
                        selectedOptions={selectedOptions}
                        screenHeight={screenHeight}
                        setSelectedSubjects={setSelectedSubjects}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route
                    path="/testtable"
                    element={
                      <TestTable
                        selectedOptions={selectedOptions}
                        screenHeight={screenHeight}
                        setSelectedSubjects={setSelectedSubjects}
                        selectedSubjects={selectedSubjects}
                      />
                    }
                  />
                  <Route path="diagrams" element={<Diagrams />} />
                  {/* <Route path="pivot" element={<Pivot />} /> */}
                  <Route path="viewspark" element={<ViewSpark />} />
                  <Route path="/openai" element={<Openai />} />
                  <Route
                    path="/query"
                    element={
                      <Query
                        study={study}
                        studyDatabase={studyDatabase}
                        screenHeight={screenHeight}
                      />
                    }
                  />
                  <Route
                    path="/aereports"
                    element={
                      <AEReports
                        study={study}
                        listOfTables={listOfTables}
                        studyDatabase={studyDatabase}
                        links={links}
                        theme={theme}
                        alerts={alerts}
                        seriousResults={seriousResults}
                        screenHeight={screenHeight}
                        screenWidth={screenWidth}
                        setSelectedSubjects={setSelectedSubjects}
                        setSelectedOptions={setSelectedOptions}
                        varToTable={varToTable}
                        // labCategories={labCategories}
                        selectedOptions={selectedOptions}
                        selectedSubjects={selectedSubjects}
                        showAe={showAe}
                        showCm={showCm}
                        showDm={showDm}
                        showDs={showDs}
                        showLb={showLb}
                        showMh={showMh}
                        showVd={showVd}
                        showVs={showVs}
                      />
                    }
                  />
                  <Route path="/reporting" element={<Reporting />} />
                  <Route
                    path="/files"
                    element={<Files screenHeight={screenHeight} />}
                  />
                  <Route path="/:id" element={<GetDataFromUrl />} />
                  <Route path="about" element={<About />} />
                  <Route path="/ecrf" element={<Ecrf />} />
                  <Route path="/rdemo" element={<Rdemo />} />
                  <Route path="/pythondemo" element={<Pythondemo />} />
                  <Route path="*" element={<NotFound />} />
                  {/* </Route> */}
                </Routes>
              </Grid>
            </Grid>
            <MakeNotes
              setOpenViewNotesModal={setOpenViewNotesModal}
              screenWidth={screenWidth}
              userName={userName}
              studyDatabase={studyDatabase}
            />
            <History className={"stickToBottom"} />
          </div>
          <InfoModal
            openInfoModal={openInfoModal}
            setOpenInfoModal={setOpenInfoModal}
            screenHeight={screenHeight}
            screenWidth={screenWidth}
          />
          <ViewNotesModal
            openViewNotesModal={openViewNotesModal}
            setOpenViewNotesModal={setOpenViewNotesModal}
            screenHeight={screenHeight}
            screenWidth={screenWidth}
          />
          <MakeNotesModal
            openMakeNotesModal={openMakeNotesModal}
            setOpenMakeNotesModal={setOpenMakeNotesModal}
            userName={userName}
            screenHeight={screenHeight}
            screenWidth={screenWidth}
          />
          {/* <Modal open={waiting || false} sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: screenWidth * 0.2,
            height: screenHeight * 0.2,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
            <>
              <Typography sx={{color:'white'}}>Loading Alerts</Typography>
              <p/>
              <CircularProgress />
            </>
          </Modal> */}
        </Container>
      </ThemeProvider>
    </ColorModeContext.Provider>
  ) : (
    <LoginPage
      loggedIn={loggedIn}
      setLoggedIn={setLoggedIn}
      userName={userName}
      setUserName={setUserName}
      userType={userType}
      setUserType={setUserType}
      roles={roles}
    />
  );
}
