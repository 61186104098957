import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, IconButton, Grid, Tooltip } from '@mui/material';
import { useLocation } from "react-router-dom";
import { note, openSaveFileDialog } from "../../apis/utility";
import { DataGridPro } from '@mui/x-data-grid-pro';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import { usePapaParse } from 'react-papaparse';

export const ViewNotesModal = (props) => {
    const { setOpenViewNotesModal, openViewNotesModal, screenHeight, screenWidth } = props,
        location = useLocation(),
        handleClose = () => setOpenViewNotesModal(false),
        style = {
            position: 'absolute',
            top: '20%',
            left: '20%',
            transform: 'translate(-10%, -10%)',
            width: screenWidth * 0.8,
            height: screenHeight * 0.8,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        },
        [noteData, setNoteData] = useState(null),
        [noteColumns, setNoteColumns] = useState(null),
        { jsonToCSV } = usePapaParse(),
        exportData = () => {
            if (!noteData || noteData.length === 0) {
                console.log('nothing to save')
                //TODO: show snackbar
            } else {
                openSaveFileDialog(jsonToCSV(noteData), 'notes for ' + location.pathname, 'text/csv')
            }
        }

    useEffect(() => {
        if (!openViewNotesModal) return
        const pathname = location.pathname.replace(/\//g, "%2F")
        note(`SELECT * from notes where url='${pathname}'`)
            .then((res) => {
                if (res.message === "success") {
                    const tempData = res.data.map((d, id) => {
                        return { ...d, id: id }
                    })
                    setNoteData(tempData)
                    // console.log('tempData', tempData)
                    if (res.data.length > 0) {
                        setNoteColumns(Object.keys(res.data[0]).map(d => {
                            let flex = null
                            if (d === 'note') flex = 1
                            return ({ headerName: d, field: d, flex: flex })
                        }
                        ))
                    }
                }
            })
    }, [location, openViewNotesModal])

    return (
        <Modal open={openViewNotesModal} onClose={handleClose}>
            <Box sx={style}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Tooltip title="Download table">
                            <IconButton size='small' sx={{ mr: 1 }} color="primary" onClick={exportData}>
                                <FileDownloadIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Close">
                            <IconButton size='small' variant="contained" color="primary" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            View notes for screen: {location.pathname}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ height: screenHeight * .75 }}>
                        {noteData && noteColumns
                            ? <DataGridPro
                                rows={noteData}
                                columns={noteColumns}
                                density={"compact"}
                                rowHeight={30}
                                sx={{ fontSize: '0.7em' }}
                            />
                            : <span>No notes yet</span>}
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};