import React, { useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import AnnotationsModule from "highcharts/modules/annotations";
import { Box } from "@mui/material";
AnnotationsModule(Highcharts);

export default function TestHighChart(props) {
  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    setChartOptions({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: "Browser<br>shares<br>January<br>2022",
        align: "center",
        verticalAlign: "middle",
        y: 60,
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: "bold",
              color: "white",
            },
          },
          startAngle: 0,
          endAngle: 0,
          center: ["50%", "60%"],
          size: "50%",
        },
      },
      series: [
        {
          type: "pie",
          name: "Browser share",
          innerSize: "50%",
          data: [
            ["Chrome", 73.86],
            ["Edge", 11.97],
            ["Firefox", 5.52],
            ["Safari", 2.98],
            ["Internet Explorer", 1.9],
            {
              name: "Other",
              y: 3.77,
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
      ],
    });
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Box>
  );
}
