import React from "react";
import { Fab, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
// import { useLocalstorageState } from "rooks";
import { getSubjects } from '../../apis/utility';

export default function Clear(props) {
    const { varToTable, setSelectedSubjects, setSelectedOptions } = props
    const clear = () => {
        setSelectedOptions({});
        getSubjects(props.studyDatabase, {}, varToTable)
            .then(res => {
                // console.log('clear - res', res)
                setSelectedSubjects(res)
            })
    };

    return (<>
        <Tooltip title="Clear Global Filter">
            <Fab
                size="small"
                onClick={clear}
                color={Object.keys(props.selectedOptions).length > 0 ? "secondary" : "primary"}
                sx={{ margin: 1 }}>
                <ClearIcon />
            </Fab>
        </Tooltip>
    </>)
}