import React from "react";
import { Fab, Tooltip, Modal, Button, Box } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useLocalstorageState } from "rooks";
import { getSubjects } from '../../apis/utility';

export default function Load(props) {
    const { varToTable, setSelectedSubjects, setSelectedOptions, setStudy, setStudyDatabase } = props,
        [userSelections1] = useLocalstorageState('userSelections1', {}),
        [userSelections2] = useLocalstorageState('userSelections2', {}),
        [userSelections3] = useLocalstorageState('userSelections3', {}),
        [userSelections4] = useLocalstorageState('userSelections4', {}),
        [userSelections5] = useLocalstorageState('userSelections5', {}),
        [userSelections6] = useLocalstorageState('userSelections6', {}),
        [userSelections7] = useLocalstorageState('userSelections7', {}),
        [userSelections8] = useLocalstorageState('userSelections8', {}),
        [userSelections9] = useLocalstorageState('userSelections9', {}),
        [openModal, setOpenModal] = React.useState(false),
        handleClose = () => { setOpenModal(false) },
        handleOpen = () => { setOpenModal(true) },
        modalStyle = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        },
        load = (slot) => {
            let userSelections;
            switch (slot) {
                case 1: userSelections = userSelections1
                    break;
                case 2: userSelections = userSelections2
                    break;
                case 3: userSelections = userSelections3
                    break;
                case 4: userSelections = userSelections4
                    break;
                case 5: userSelections = userSelections5
                    break;
                case 6: userSelections = userSelections6
                    break;
                case 7: userSelections = userSelections7
                    break;
                case 8: userSelections = userSelections8
                    break;
                case 9: userSelections = userSelections9
                    break;
                default:
            }
            if (userSelections.study) {
                setSelectedOptions(userSelections.selectedOptions);
                setStudy(userSelections.study);
                setStudyDatabase(userSelections.studyDatabase);
                getSubjects(userSelections.studyDatabase, userSelections.selectedOptions, varToTable)
                    .then(res => {
                        setSelectedSubjects(res)
                    })
            }
            handleClose();
        }

    return (<>
        <Tooltip title="Load Global Filter (from local storage)">
            <Fab size="small" onClick={handleOpen} color="primary" sx={{ margin: 0 }}>
                <CloudDownloadIcon />
            </Fab>
        </Tooltip>
        <Modal open={openModal} onClose={handleClose}>
            <Box sx={modalStyle} >
                <Button onClick={() => load(1)}>Load Slot 1</Button>
                <Button onClick={() => load(2)}>Load Slot 2</Button>
                <Button onClick={() => load(3)}>Load Slot 3</Button>
                <Button onClick={() => load(4)}>Load Slot 4</Button>
                <Button onClick={() => load(5)}>Load Slot 5</Button>
                <Button onClick={() => load(6)}>Load Slot 6</Button>
                <Button onClick={() => load(7)}>Load Slot 7</Button>
                <Button onClick={() => load(8)}>Load Slot 8</Button>
                <Button onClick={() => load(9)}>Load Slot 9</Button>
            </Box>
        </Modal>
    </>)
}