import React, { useState, useEffect } from "react";
import { Typography, IconButton, Grid, Tooltip, Box, Button } from '@mui/material';
import { useLocation } from "react-router-dom";
import { note, openSaveFileDialog } from "../../apis/utility";
import { DataGridPro } from '@mui/x-data-grid-pro';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import { usePapaParse } from 'react-papaparse';

export const ViewNotes = (props) => {
    const { screenHeight } = props,
        location = useLocation(),
        [noteData, setNoteData] = useState(null),
        [noteColumns, setNoteColumns] = useState(null),
        { jsonToCSV } = usePapaParse(),
        exportData = () => {
            if (!noteData || noteData.length === 0) {
                console.log('nothing to save')
                //TODO: show snackbar
            } else {
                openSaveFileDialog(jsonToCSV(noteData), 'notes for ' + location.pathname, 'text/csv')
            }
        }

    useEffect(() => {
        // const pathname = location.pathname.replace(/\//g, "%2F")
        note(`SELECT * from notes`)
            .then((res) => {
                if (res.message === "success") {
                    const tempData = res.data.map((d, id) => {
                        return { ...d, id: id }
                    })
                    setNoteData(tempData)
                    // console.log('tempData', tempData)
                    if (res.data.length > 0) {
                        setNoteColumns(Object.keys(res.data[0]).map(d => {
                            let flex = null
                            if (d === 'note') flex = 1
                            return ({
                                headerName: d, field: d, flex: flex,
                                renderCell: (cellValues) => {
                                    // console.log('cellValues', cellValues)
                                    const { value, field } = cellValues
                                    if (field === 'url') {
                                        const target = `${window.location.protocol}//${window.location.host}/#${value}`
                                        return <Button onClick={() => { window.open(target) }
                                        }
                                        >{value}</Button>
                                    }
                                    else return value
                                }
                            })
                        }
                        ))
                    }
                }
            })
    }, [location])

    return (
        <Grid container spacing={2} >
            <Grid item xs={2}>
                <Tooltip title="Download table">
                    <IconButton size='small' sx={{ mr: 1 }} color="primary" onClick={exportData}>
                        <FileDownloadIcon />
                    </IconButton>
                </Tooltip>
                {props.showClose && <Tooltip title="Close">
                    <IconButton size='small' variant="contained" color="primary" onClick={props.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>}
            </Grid>
            <Grid item xs={10}>
                <Typography id="title" variant="h6" component="h2">
                    View notes for screen: {location.pathname}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {noteData && noteColumns
                    ? <Box sx={{ height: screenHeight * .8 }}>
                        <DataGridPro
                            rows={noteData}
                            columns={noteColumns}
                            density={"compact"}
                            rowHeight={30}
                            sx={{ fontSize: '0.7em' }}
                        />
                    </Box>
                    : <span>No notes yet</span>}
            </Grid>
        </Grid>
    );
};