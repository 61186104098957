import React, { useEffect, useState } from "react";
import { Typography, Button, Divider, TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import { addToHistory } from "../../apis/utility";
import { getAnswer } from "../../apis/utility";

export const Openai = (props) => {
  const location = useLocation(),
    // serverUrl = window.location.host.startsWith("localhost")
    //   ? "http://localhost:3001"
    //   : "http://134.122.110.66,
    askOpenai = (chat) => {
      // console.log("asking openai:", chat);
      getAnswer(chat, setAnswer);
    },
    [question, setQuestion] = useState(""),
    // [tokens, setTokens] = useState(100),
    [answer, setAnswer] = useState("");

  addToHistory({ title: "Openai", url: location });

  useEffect(() => {
    // console.log("answer", answer);
  }, [answer]);

  return (
    <>
      <Typography sx={{ mt: 2, mb: 3 }}>A.I. (experimental)</Typography>
      <TextField
        label="Question"
        value={question}
        onChange={(event) => setQuestion(event.target.value)}
        fullWidth
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ ml: 2, mt: 1, mb: 2 }}
        onClick={() => {
          askOpenai(question);
        }}
      >
        Ask OpenAI
      </Button>
      <Divider />
      <TextField
        label="Answer"
        value={answer}
        fullWidth
        multiline={true}
        maxRows={40}
      />
    </>
  );
};
