import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  Tooltip,
  Button,
  Modal,
  Box,
  TextField,
} from "@mui/material";
import { sql } from "../../apis/utility";
import moment from "moment";
import { DataGridPro } from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import AEBoth from "./AEBoth";
import { LookBelow } from "../layout/LookBelow";

const getColumnKey = (data, dateKey, date, returnKey) => {
  const matched = data
    ? data.find((item) => {
        return (
          item[dateKey] >= moment(date).set({ hour: 0, minute: 0, second: 0 })
        );
      })
    : null;
  // console.log(
  //   "data",
  //   data,
  //   "dateKey",
  //   dateKey,
  //   "date",
  //   date,
  //   "returnKey",
  //   returnKey,
  //   "matched",
  //   matched
  // );
  return matched ? matched[returnKey] : null;
};

export default function PatientProfile(props) {
  let params = useParams();
  const studyDatabase = params.studyDatabase
      ? params.studyDatabase
      : props.studyDatabase,
    [study, setStudy] = useState(null),
    [aeData, setAeData] = useState(null), // AE
    [fullAeData, setFullAeData] = useState(null),
    [aeColumnDefs, setAeColumnDefs] = useState(null),
    // navigate = useNavigate(),
    aeStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
      height: 700,
      "& .red": {
        textAlign: "center",
        backgroundColor: "#ff9999",
        color: "black",
      },
      "& .orange": {
        textAlign: "center",
        backgroundColor: "#ffd699",
        color: "black",
      },
      "& .yellow": {
        textAlign: "center",
        backgroundColor: "#ffffb3",
        color: "black",
      },
      "& .green": {
        textAlign: "center",
        backgroundColor: "#d9ffb3",
        color: "black",
      },
    },
    [openAe, setOpenAe] = React.useState(false),
    handleOpenAe = () => setOpenAe(true),
    handleCloseAe = () => setOpenAe(false),
    [dmData, setDmData] = useState(null), // DM
    [vdData, setVdData] = useState(null), // VD
    [fullVdData, setFullVdData] = useState(null),
    [vsData, setVsData] = useState(null), // VS
    [lbData, setLbData] = useState(null), // LB
    [lbSummaryData, setLbSummaryData] = useState(null),
    // [fullLbData, setFullLbData] = useState(null),
    [splitLbData, setSplitLbData] = useState(null),
    // [lbColumnDefs, setLbColumnDefs] = useState(null),
    lbStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
      height: 700,
      "& .red": {
        textAlign: "center",
        backgroundColor: "#ff9999",
        color: "black",
      },
      "& .orange": {
        textAlign: "center",
        backgroundColor: "#ffd699",
        color: "black",
      },
      "& .yellow": {
        textAlign: "center",
        backgroundColor: "#ffff99",
        color: "black",
      },
      "& .blue": {
        textAlign: "center",
        backgroundColor: "#ccd9ff",
        color: "black",
      },
    },
    [openLb, setOpenLb] = React.useState(false),
    [lbModalArea, setLbModalArea] = React.useState(null),
    handleOpenLb = (area) => {
      setLbModalArea(area);
      // console.log('area', area)
      setOpenLb(true);
    },
    handleCloseLb = () => setOpenLb(false),
    [lbModalColumnDefs, setLbModalColumnDefs] = useState(null),
    [dsData, setDsData] = useState(null), // DS
    [cmData, setCmData] = useState(null), // CM data
    [cmDataCols, setCmDataCols] = useState(null), // CM columns
    [mhData, setMhData] = useState(null), // MH data
    [mhDataCols, setMhDataCols] = useState(null), // MH columns
    tableStyle = {
      // height: props.screenHeight * 0.64,
      mb: 3,
      width: "100%",
      "& .red": {
        textAlign: "center",
        backgroundColor: "#ac011d",
        color: "black",
      },
      "& .orange": {
        textAlign: "center",
        backgroundColor: "#e4b293",
        color: "black",
      },
      "& .yellow": {
        textAlign: "center",
        backgroundColor: "#f4decd",
        color: "black",
      },
      "& .green": {
        textAlign: "center",
        backgroundColor: "green",
        color: "black",
      },
      "& .gray": {
        textAlign: "center",
        backgroundColor: "gray",
        color: "black",
      },
    },
    [tableData, setTableData] = useState(null),
    [tableColumnDefs, setTableColumnDefs] = useState(null),
    [dateFirstTreatment, setDateFirstTreatment] = useState(null),
    [dateOfVisit, setDateOfVisit] = useState(null),
    vsSortOrder = {
      RESP: 1,
      TEMP: 2,
      BMI: 3,
      WEIGHT: 4,
      HEIGHT: 5,
      OSYSBP: 6,
      ODIABP: 7,
      OPULSE: 8,
      SYSBP: 9,
      DIABP: 10,
      PULSE: 11,
    },
    splitValue = (value) => {
      if (typeof value === "string" && value.startsWith("[")) {
        return value.match(/\[(.*?)\]/g).map(function (val) {
          return val.replace(/[[\]]/g, "");
        });
      } else return [];
    },
    //TODO: change labTitles to come from a query to actual data
    labsTitle = [
      "CHEMISTRY",
      "BIOCHEMISTRY",
      "HEMATOLOGY",
      "LIPID",
      "OTHER",
      "URINALYSIS",
    ],
    contrastingColor =
      props.theme && props.theme.palette && props.theme.palette.mode === "dark"
        ? "white"
        : "black",
    KeyRenderer = (props) => {
      const { field, value } = props,
        values = splitValue(value);
      if (field === "key") {
        if (values[0] === "lab")
          return (
            <Button
              variant="text"
              onClick={() => {
                switch (values[1]) {
                  case labsTitle[0]:
                    handleOpenLb(0);
                    break;
                  case labsTitle[1]:
                    handleOpenLb(1);
                    break;
                  case labsTitle[2]:
                    handleOpenLb(2);
                    break;
                  case labsTitle[3]:
                    handleOpenLb(3);
                    break;
                  case labsTitle[4]:
                    handleOpenLb(4);
                    break;
                  case labsTitle[5]:
                    handleOpenLb(5);
                    break;
                  default:
                  // code block
                }
              }}
              sx={{
                ml: 2,
                justifyContent: "left",
                color: "text.primary",
                fontSize: 10,
                p: 0,
              }}
            >
              <u>{values[1]}</u>
            </Button>
          );
        else if (values[0] === "section") {
          if (values.length > 2 && values[2] === "ae")
            return (
              <Button
                variant="text"
                onClick={handleOpenAe}
                sx={{ color: "text.primary", fontSize: 15, p: 0 }}
              >
                <u>{values[1]}</u>
              </Button>
            );
          // AE modal
          else
            return (
              <Typography sx={{ color: "primary", fontSize: 15, p: 0 }}>
                <b>{values[1].toUpperCase()}</b>
              </Typography>
            );
        } else if (values[0] === "indent")
          return (
            <Typography
              sx={{ ml: 2, color: "text.primary", fontSize: 10, p: 0 }}
            >
              {values[1]}
            </Typography>
          );
        return <span>{value}</span>;
      } else return <span>{value}</span>;
    },
    ValueRenderer = (props) => {
      const { value } = props;
      if (
        typeof value === "string" &&
        (value.startsWith("[ae]") || value.startsWith("[lb]"))
      ) {
        const values = splitValue(value),
          valueForCell = values[values.length - 1];
        // console.log('values', values)
        if (values[0] === "ae") {
          let tooltip = `
                                ${values[2]},
                                ${
                                  values[3] === "POSSIBLE"
                                    ? "Possibly Related"
                                    : values[3]
                                },
                                ${values[4]},
                                ${
                                  values[5] === "Y" ? "Serious" : "Not Serious"
                                },
                                ${values[6]},
                                ${values[7]}`;
          return (
            <Tooltip title={tooltip}>
              <span>{valueForCell}</span>
            </Tooltip>
          );
        } else if (typeof value === "string" && values[0] === "lb") {
          if (values[1] === "good")
            return <CheckCircleIcon sx={{ color: "green" }} />;
          else if (values[1] === "verybad")
            return (
              <Tooltip title="Some values over twice Upper Limit of Normal">
                <CancelIcon sx={{ color: "red" }} />
              </Tooltip>
            );
          else if (values[1] === "bad")
            return (
              <Tooltip title="Some values over Upper Limit of Normal">
                <CancelIcon sx={{ color: "orange" }} />
              </Tooltip>
            );
          else if (values[1] === "bad2")
            return (
              <Tooltip title="Some values below Lower Limit of Normal">
                <CancelIcon sx={{ color: "blue" }} />
              </Tooltip>
            );
          return <span>{values[1]}</span>;
        } else return <span>{value}</span>;
      } else return <span>{value}</span>;
    },
    pad = (num, size) => {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    };

  console.log("study", study);

  useEffect(() => {
    // VD - Visit Data
    sql(
      studyDatabase,
      `select visit, date(vddate ) AS Date  from vd where SUBJID='${params.id}'`
    ).then((res) => {
      // console.log('res', res);
      if (res.message === "success") {
        const tempTableRow = {},
          tempDateOfVisit = { key: "Date of  Visit" };
        res.data.forEach((row, i) => {
          row.start = moment(row.Date).toDate();
          row.columnNumber = i;
          row.columnName = "col" + pad(i, 4);
          tempTableRow[row.columnName] = row.VISIT;
          tempDateOfVisit[row.columnName] = moment(row.start).format("dMMMYY");
          if (["DAY 1", "BASELINE", "CYCLE 01 DAY 01"].includes(row.VISIT))
            setDateFirstTreatment(row.start);
          // console.log('row', row)
        });
        setDateOfVisit(tempDateOfVisit);
        // console.log('tempTableRow', tempTableRow, 'res.data', res.data, 'tempDateOfVisit', tempDateOfVisit)
        setVdData(tempTableRow);
        setFullVdData(res.data);
        const slotColumnCommonFields = {
            sortable: false,
            filterable: false,
            pinnable: false,
            hideable: false,
            minWidth: 50,
            cellClassName: (params) => params.value,
            colSpan: ({ row, field, value }) => {
              let colSpan = 1;
              if (typeof value === "string" && value.startsWith("[ae]"))
                colSpan = Number(splitValue(value)[1]);
              return colSpan;
            },
          },
          tempTableColumnDefs = Object.keys(tempTableRow).map((key) => {
            return {
              title: tempTableRow[key],
              data: key,
              headerName: tempTableRow[key],
              field: key,
              width: 50,
              renderCell: ValueRenderer,
              ...slotColumnCommonFields,
            };
          });
        setTableColumnDefs([
          {
            title: "",
            data: "key",
            headerName: "",
            field: "key",
            renderCell: KeyRenderer,
            width: 160,
          },
          ...tempTableColumnDefs,
        ]);
      }
    });
    // eslint-disable-next-line
  }, [params.id]);

  useEffect(() => {
    // console.log('props', props)
    // DM - Demography
    sql(
      studyDatabase,
      `SELECT SUBJID AS Subject,
                    DMDTC AS Date,
                    AGE AS Age,
                    SEX AS Sex,
                    RACE AS Race,
                    ETHNIC AS Ethicity,
                    ARMCD AS Arm,
                    EXPOSED AS Exposed,
                    RANDOMIZED AS Randomized,
                    SAF AS Safety_Population,
                    PKSET AS PK_Set,
                    PDSET AS PD_Set
            FROM dm where SUBJID='${params.id}'`
    ).then((res) => {
      if (res.message === "success") {
        const temp = [],
          patient = res.data[0];
        for (const key in patient) {
          const tempRow = { key: key, value: patient[key], id: key };
          temp.push(tempRow);
        }
        // console.log('studyDatabase', studyDatabase, 'temp', temp)
        setDmData(temp);
      }
    });
    // DS - Disposition
    sql(
      studyDatabase,
      `select DATE as Completed, DSDECOD as Status, DSOTHSP as Other_Reason from ds where SUBJID='${params.id}'`
    ).then((res) => {
      if (res.message === "success") {
        const temp = [],
          patient = res.data[0];
        for (const key in patient) {
          const tempRow = { key: key, value: patient[key], id: key };
          temp.push(tempRow);
        }
        setDsData(temp);
      }
    });
    // CM - Concommitent Medications
    sql(
      studyDatabase,
      `select * from cm where SUBJID='${params.id}' order by cmstdtc, cmendtc`
    ).then((res) => {
      if (res.message === "success") {
        setCmDataCols([
          { headerName: "Treatment", field: "CMTRT", width: 150 },
          { headerName: "Dose", field: "CMDOSE", width: 75 },
          { headerName: "Unit", field: "CMDOSU", width: 75 },
          { headerName: "Route", field: "CMROUTE", width: 75 },
          { headerName: "Freq.", field: "CMDOSFRQ", width: 75 },
          { headerName: "Ongoing", field: "CMONGO", width: 75 },
          { headerName: "Start", field: "CMSTDTC", width: 100 },
          { headerName: "End", field: "CMENDTC", width: 100 },
        ]);
        const tempCmData = res.data.map((r, id) => {
          return { id: id, ...r };
        });
        // console.log(tempCmData);
        setCmData(tempCmData);
      }
    });
    // MH - Medical History
    sql(
      studyDatabase,
      `select * from mh where SUBJID='${params.id}' order by MHSTDTC,MHDTC`
    ).then((res) => {
      if (res.message === "success") {
        setMhDataCols([
          { headerName: "Condition", field: "MHDECOD", width: 250 },
          { headerName: "Start", field: "MHSTDTC", width: 150 },
          { headerName: "End", field: "MHDTC", width: 150 },
          { headerName: "Ongoing", field: "MHONGO", width: 75 },
        ]);
        const tempMhData = res.data.map((r, id) => {
          return { id: id, ...r };
        });
        // console.log(tempMhData);
        setMhData(tempMhData);
      }
    });
    // VS - Vital Signs
    if (fullVdData)
      sql(
        studyDatabase,
        `select VSCAT,VSTESTCD,VSPOS,VSSTRESN,VSSTRESU,VSDY
                                    from vs where SUBJID='${params.id}' order by VSCAT,VSTESTCD,VSPOS`
      ).then((res) => {
        // console.log('res', res)
        if (res.message === "success") {
          let defaultTableRow = {};
          tableColumnDefs.forEach((columnDef) => {
            defaultTableRow[columnDef.data] = null;
          });
          let lastCat = null,
            lastCD = null,
            vsTableData = [],
            tableRow = { ...defaultTableRow };
          // console.log('VS res.data', res.data)
          res.data.forEach((row, i) => {
            // console.log('dateFirstTreatment', dateFirstTreatment,'moment(dateFirstTreatment)',moment(dateFirstTreatment))
            row.date = moment(dateFirstTreatment)
              .add(parseInt(row.VSDY), "days")
              .toDate();
            if (lastCat && lastCD && lastCD !== row.VSTESTCD) {
              tableRow["category"] = lastCat;
              vsTableData.push(tableRow);
              tableRow = { ...defaultTableRow };
            }
            tableRow["key"] = row.VSPOS
              ? "[indent][" + row.VSTESTCD + ", " + row.VSPOS + "]"
              : "[indent][" + row.VSTESTCD + "]";
            const usekey = tableRow["key"]
              .replace(/\[/g, ",")
              .replace(/\]/g, ",")
              .split(",");
            // console.log('tableRow', tableRow, 'usekey', usekey)
            tableRow["vsSortOrder"] = vsSortOrder[usekey[3]]; // lookup the order for each test code
            // work out what column to put the data in using vd info
            const columnName = getColumnKey(
              fullVdData,
              "start",
              row.date,
              "columnName"
            );
            const newValue = row.VSSTRESN ? Number(row.VSSTRESN) : null;
            tableRow[columnName] = tableRow[columnName]
              ? Math.max(tableRow[columnName], newValue)
              : newValue;
            lastCat = row.VSCAT;
            lastCD = row.VSTESTCD;
          });
          vsTableData.push(tableRow);
          vsTableData.sort((a, b) => a.vsSortOrder - b.vsSortOrder); // put vital signs in the right order
          // console.log('vsTableData', vsTableData)
          setVsData(vsTableData);
        }
      });
    // LB - Labs
    if (fullVdData)
      sql(
        studyDatabase,
        `select VISITDY, LBCAT, LBTESTCD, LBSTNRLO, LBSTNRHI, LBNRIND, LBSTNRLO, LBSTNRHI, LBTEST, LBSTRESN, LBSTRESC, LBSTRESU from lb where SUBJID='${params.id}' order by LBCAT, LBTEST`
      ).then((res) => {
        if (res.message === "success") {
          // console.log('res.data', res.data)
          let defaultTableRow = {};
          tableColumnDefs.forEach((columnDef) => {
            defaultTableRow[columnDef.data] = null;
          });
          let lastCat = null,
            lastTEST = null,
            lbTableData = [],
            tempFullLbData = [],
            categoryRow = {},
            lbTableSummary = [],
            tableRow = { ...defaultTableRow },
            currentCategoryStatus = { ...defaultTableRow };
          res.data.forEach((row, id) => {
            if (lastCat !== row.LBCAT) {
              tableRow = { ...defaultTableRow };
              tableRow["key"] = `[lab][` + row.LBCAT + "]";
              lbTableData.push(tableRow);
              tableRow = { ...defaultTableRow };
            }
            row.date = moment(dateFirstTreatment)
              .add(parseInt(row.VISITDY), "days")
              .toDate();
            if (lastCat && lastTEST && lastTEST !== row.LBTEST) {
              tableRow["category"] = lastCat;
              categoryRow["category"] = lastCat;
              lbTableData.push(tableRow);
              tempFullLbData.push(categoryRow);
              tableRow = { ...defaultTableRow };
              categoryRow = {};
            }
            tableRow["key"] = row.LBSTRESU
              ? "[indent][" + row.LBTEST + " (" + row.LBSTRESU + ")]"
              : "[indent][" + row.LBTEST + "]";
            categoryRow["id"] = id;
            categoryRow["key"] = row.LBTEST;
            categoryRow["visit"] = row.VISITDY;
            categoryRow["unit"] = row.LBSTRESU;
            const columnName = getColumnKey(
              fullVdData,
              "start",
              row.date,
              "columnName"
            ); // work out what column to put the data in using vd info
            const newValue = row.LBSTRESN ? Number(row.LBSTRESN) : row.LBSTRESC;
            // console.log('tableRow', tableRow, 'columnName', columnName, 'newValue', newValue)
            const newRowValue =
              typeof newValue === "number"
                ? Math.max(tableRow[columnName], newValue)
                : newValue;
            // console.log('newRowValue', newRowValue)
            tableRow[columnName] = newRowValue;
            categoryRow["lower"] = row.LBSTNRLO ? Number(row.LBSTNRLO) : null;
            categoryRow["upper"] = row.LBSTNRHI ? Number(row.LBSTNRHI) : null;
            categoryRow[columnName + "- value"] = newRowValue;
            categoryRow[columnName + "-date"] = row.date;
            categoryRow[columnName + "-status"] =
              categoryRow["upper"] &&
              categoryRow[columnName + "- value"] >= categoryRow["upper"] * 2
                ? "HIGH"
                : categoryRow["upper"] &&
                  categoryRow[columnName + "- value"] >= categoryRow["upper"]
                ? "MID"
                : categoryRow["lower"] &&
                  categoryRow[columnName + "- value"] <= categoryRow["lower"]
                ? "LOW"
                : row.LBNRIND;
            if (lastCat === null) {
              currentCategoryStatus = { ...defaultTableRow };
              currentCategoryStatus["key"] = `[lab][` + row.LBCAT + "]";
            }

            if (
              (lastCat && lastCat !== row.LBCAT) ||
              res.data.length === id + 1
            ) {
              // console.log('currentCategoryStatus', currentCategoryStatus)
              Object.keys(currentCategoryStatus).forEach((colKey) => {
                if (colKey !== "key") {
                  if (currentCategoryStatus[colKey] === "verybad")
                    currentCategoryStatus[colKey] = `[lb][verybad]`;
                  else if (currentCategoryStatus[colKey] === "bad")
                    currentCategoryStatus[colKey] = `[lb][bad]`;
                  else if (currentCategoryStatus[colKey] === "bad2")
                    currentCategoryStatus[colKey] = `[lb][bad2]`;
                  else if (currentCategoryStatus[colKey] === "good")
                    currentCategoryStatus[colKey] = `[lb][good]`;
                }
              });
              lbTableSummary.push(currentCategoryStatus);
              currentCategoryStatus = { ...defaultTableRow };
              currentCategoryStatus["key"] = `[lab][` + row.LBCAT + "]";
            }

            if (currentCategoryStatus[columnName] !== "verybad") {
              if (categoryRow[columnName + "-status"] === "HIGH")
                currentCategoryStatus[columnName] = "verybad";
              else if (currentCategoryStatus[columnName] !== "bad") {
                if (categoryRow[columnName + "-status"] === "MID")
                  currentCategoryStatus[columnName] = "bad";
                else if (categoryRow[columnName + "-status"] === "LOW")
                  currentCategoryStatus[columnName] = "bad2";
                else currentCategoryStatus[columnName] = "good";
              }
            }

            lastCat = row.LBCAT;
            lastTEST = row.LBTEST;
          });
          lbTableData.push(tableRow);
          tempFullLbData.push(categoryRow);
          const lastRow = { ...categoryRow }; // need a row to get keys from to set dates for each column
          // date = dateOfVisit[key.substr(0, 7)]
          const actualDates = {};
          Object.keys(lastRow).forEach((k) => {
            // console.log('k', k, 'categoryRow', categoryRow)
            if (k.includes("- value"))
              actualDates[k] = dateOfVisit[k.substring(0, 7)];
          });
          categoryRow = {
            category: labsTitle[0],
            key: "Date",
            id: "date",
            ...actualDates,
          };
          tempFullLbData.unshift(categoryRow);
          categoryRow = {
            category: labsTitle[1],
            key: "Date",
            id: "date",
            ...actualDates,
          };
          tempFullLbData.unshift(categoryRow);
          categoryRow = {
            category: labsTitle[2],
            key: "Date",
            id: "date",
            ...actualDates,
          };
          tempFullLbData.unshift(categoryRow);
          categoryRow = {
            category: labsTitle[3],
            key: "Date",
            id: "date",
            ...actualDates,
          };
          tempFullLbData.unshift(categoryRow);
          categoryRow = {
            category: labsTitle[4],
            key: "Date",
            id: "date",
            ...actualDates,
          };
          tempFullLbData.unshift(categoryRow);
          categoryRow = {
            category: labsTitle[5],
            key: "Date",
            id: "date",
            ...actualDates,
          };
          tempFullLbData.unshift(categoryRow);
          setLbSummaryData(lbTableSummary);
          setLbData(lbTableData);
          // console.log('lbTableData', lbTableData)
          // split data into array elements
          const splitLbData = [];
          splitLbData.push(
            tempFullLbData.filter((r) => r.category === labsTitle[0])
          );
          splitLbData.push(
            tempFullLbData.filter((r) => r.category === labsTitle[1])
          );
          splitLbData.push(
            tempFullLbData.filter((r) => r.category === labsTitle[2])
          );
          splitLbData.push(
            tempFullLbData.filter((r) => r.category === labsTitle[3])
          );
          splitLbData.push(
            tempFullLbData.filter((r) => r.category === labsTitle[4])
          );
          splitLbData.push(
            tempFullLbData.filter((r) => r.category === labsTitle[5])
          );
          setSplitLbData(splitLbData);
          // column def for lb modal
          const uniqueKeys = [];
          tempFullLbData.forEach((obj) => {
            Object.keys(obj).forEach((key) => {
              if (!key.startsWith("null") && !uniqueKeys.includes(key))
                uniqueKeys.push(key);
            });
          });
          uniqueKeys.sort();
          const colPart = uniqueKeys
            .map((key) => {
              const visit = vdData[key.substr(0, 7)];
              if (key.includes("- value"))
                return {
                  headerName: visit,
                  field: key,
                  width: 75,
                  renderCell: ValueRenderer,
                };
              else return null;
            })
            .filter((item) => item !== null);
          const tempModalColumnDefs = [
            { headerName: "Parameter", field: "key", width: 200 },
            { headerName: "Result Unit", field: "unit", width: 80 },
            { headerName: "Lower Limit", field: "lower", width: 80 },
            { headerName: "Upper Limit", field: "upper", width: 80 },
            ...colPart,
            // { headerName: 'Comments', field: 'key' },
          ];
          // console.log('dateOfVisit', dateOfVisit, 'vdData', vdData, 'lbTableSummary', lbTableSummary, 'tempFullLbData', tempFullLbData, 'tempModalColumnDefs', tempModalColumnDefs, 'splitLbData', splitLbData)
          setLbModalColumnDefs(tempModalColumnDefs);
        }
      });
    // AE - Adverse Events
    sql(
      studyDatabase,
      `SELECT aebodsys,aept, aellt, aedur, aeser, aesev, aerel, aeout, aeacn, aete, AESTDTC, AEENDTC 
                    FROM ae where SUBJID='${params.id}' order by AESTDTC`
    ).then((res) => {
      // console.log('res', res)
      if (res.message === "success") {
        const keyDescriptions = {
          AEBODSYS: "Body System",
          AEPT: "Preferred Term",
          AELLT: "Lower Level Term",
          AEDUR: "Duration",
          AESER: "Serious",
          AESEV: "Severity",
          AEREL: "Related",
          AEOUT: "Outcome",
          AEACN: "Changed",
          AETE: "TE",
          AESTDTC: "Start",
          AEENDTC: "End",
        };
        const blankRow = { ...dateOfVisit };
        Object.keys(blankRow).forEach(
          (columnName) => (blankRow[columnName] = null)
        );
        const temp = [],
          tempModal = [];
        const keysForModal =
          res.data.length > 0 ? Object.keys(res.data[0]) : [];
        res.data.forEach((row, i) => {
          row.AESTDTC = moment(row.AESTDTC).toDate();
          row.AEENDTC = moment(row.AEENDTC).toDate();
          const columnNameStart = getColumnKey(
              fullVdData,
              "start",
              row.AESTDTC,
              "columnName"
            ),
            // columnNameEnd = getColumnKey(
            //   fullVdData,
            //   "start",
            //   row.AEENDTC,
            //   "columnName"
            // ),
            columnNumberStart = getColumnKey(
              fullVdData,
              "start",
              row.AESTDTC,
              "columnNumber"
            ),
            columnNumberEnd = getColumnKey(
              fullVdData,
              "start",
              moment(row.AEENDTC).subtract({ hours: 1 }).toDate(),
              "columnNumber"
            );
          // console.log(
          //   "AE:-",
          //   "row",
          //   row,
          //   columnNameStart,
          //   // columnNameEnd,
          //   columnNumberStart,
          //   columnNumberEnd
          // );
          const tempRow = { ...blankRow };
          tempRow["key"] = "[indent][" + row.AEPT + "]";
          const days = columnNumberEnd - columnNumberStart + 1;
          // console.log(
          //   "columnNumberStart",
          //   columnNumberStart,
          //   "columnNumberEnd",
          //   columnNumberEnd,
          //   "days",
          //   days
          // );
          tempRow[columnNameStart] = `[ae][${days < 1 ? 1 : days}][${
            row.AESEV
          }][${row.AEREL}][${row.AEOUT}][${row.AESER}][${row.AEDUR}][${
            row.AEACN
          }][${row.AESEV}]`;
          temp.push(tempRow);
          // add data for modal
          // console.log('keysForModal', keysForModal)
          tempModal.push({ key: "", id: "title" + i });
          keysForModal.forEach((k, id) => {
            const tempModalRow = {};
            tempModalRow["key"] = keyDescriptions[k];
            tempModalRow["value"] = row[k];
            tempModalRow["id"] = "x" + i + "y" + id;
            tempModal.push(tempModalRow);
          });
        });
        // console.log('tempModal', tempModal)
        // console.log("temp", temp);
        setAeData(temp);
        setFullAeData(tempModal);
        setAeColumnDefs([
          { headerName: "Variable", field: "key" },
          { headerName: "Value", field: "value", width: 500 },
        ]);
      }
    });
    // eslint-disable-next-line
  }, [fullVdData]);

  useEffect(() => {
    sql(
      "global",
      `select studyid from studys where filename='${studyDatabase}'`
    ).then((res) => {
      if (res.message === "success") {
        const tempStudy = res.data[0].STUDYID;
        console.log("tempStudy", tempStudy);
        setStudy(tempStudy);
      } else {
        setStudy(params.study ? params.study : props.study);
      }
    });
  }, [params.study, props.study, studyDatabase]);

  useEffect(() => {
    // add all the main data for the table
    const tempTableData = [];
    if (aeData) {
      const title = { ...dateOfVisit };
      Object.keys(title).forEach((columnName) => (title[columnName] = null));
      title.key = `[section][Adverse Events][ae]`;
      tempTableData.push(title);
      tempTableData.push(...aeData);
    }
    if (vsData) {
      const title = { ...dateOfVisit };
      Object.keys(title).forEach((columnName) => (title[columnName] = null));
      title.key = "[section][Vital Signs]";
      tempTableData.push(title);
      tempTableData.push(...vsData);
    }
    if (lbSummaryData) {
      const title = { ...dateOfVisit };
      Object.keys(title).forEach((columnName) => (title[columnName] = null));
      title.key = "[section][Labs]";
      tempTableData.push(title);
      tempTableData.push(...lbSummaryData);
    }
    if (vsData) {
      // add unique id
      const withId = tempTableData.map((row, i) => {
        row.id = i;
        return row;
      });
      setTableData(withId);
      // console.log('tempTableData', tempTableData)
    }
    // work out the column headers
    const tempColHeaders = [];
    if (vdData) {
      Object.keys(vdData).forEach((key) => {
        tempColHeaders.push(vdData[key]);
      });
    }
    document.title = "Xploratum " + params.id;
    // eslint-disable-next-line
  }, [dmData, vdData, aeData, vsData, lbData, dsData, dateFirstTreatment]);

  const Demog = (props) => {
    if (dmData && dsData)
      return (
        <React.Fragment key={props.childKey}>
          {dmData.map((item) =>
            item.value ? (
              <React.Fragment key={"dm" + item.id}>
                <TextField
                  id={"dm" + item.id}
                  label={item.id}
                  size="small"
                  value={item.value}
                  sx={{
                    width: Math.max(item.value.length * 15, 80),
                    mb: 1,
                    mr: 1,
                    fontSize: "0.7rem",
                  }}
                />
              </React.Fragment>
            ) : null
          )}
          {dsData.map((item) =>
            item.value ? (
              <React.Fragment key={"dm" + item.id}>
                <TextField
                  id={"ds" + item.id}
                  label={item.id}
                  size="small"
                  value={item.value}
                  sx={{
                    width: Math.max(item.value.length * 15, 70),
                    mb: 1,
                    mr: 1,
                  }}
                />
              </React.Fragment>
            ) : null
          )}
          {aeData && aeData.length > 0 && props.lookbelow ? (
            <LookBelow
              label="AE BoTh below"
              tooltip="Click to scroll down to Adverse Event BoTh graph"
              mt={0.5}
            />
          ) : null}
        </React.Fragment>
      );
  };

  return (
    <Container maxWidth={false}>
      <Typography
        align="center"
        sx={{ mt: 2, mb: 3, fontWeight: "bold", fontSize: 14 }}
      >
        {study} - Patient Profile
      </Typography>
      <Grid container spacing={1} sx={{ mb: 12 }}>
        <Demog childKey="main" lookbelow={true} />
        {tableData && tableColumnDefs && (
          <Grid sx={tableStyle}>
            <DataGridPro
              rows={tableData}
              columns={tableColumnDefs}
              rowHeight={30}
              autoHeight={true}
              density={"compact"}
              initialState={{ pinnedColumns: { left: ["key"] } }}
              disableColumnMenu={true}
              hideFooter={true}
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  textOverflow: "clip",
                  whiteSpace: "break-spaces",
                  color: contrastingColor,
                  lineHeight: 1,
                },
                "& .MuiDataGrid-pinnedColumns": {
                  // backgroundColor: 'black',
                  color: contrastingColor,
                  // opacity: 0.5,
                },
                "& .MuiDataGrid-pinnedColumnHeaders": {
                  // backgroundColor: 'black',
                  color: contrastingColor,
                  // opacity: 0.5,
                },
                fontSize: "0.7em",
                mt: 3,
                mb: 3,
              }}
              getCellClassName={(params) => {
                const values = splitValue(params.value),
                  aeSeverity = values.length >= 2 ? values[2] : null;
                if (
                  aeSeverity &&
                  typeof params.value === "string" &&
                  params.value.startsWith("[ae]")
                ) {
                  // console.log('params', params)
                  return aeSeverity === "SEVERE"
                    ? "red"
                    : aeSeverity === "MODERATE"
                    ? "orange"
                    : "yellow";
                }
                return "";
              }}
            />
          </Grid>
        )}
        <AEBoth
          theme={props.theme}
          study={study}
          studyDatabase={studyDatabase}
          screenHeight={props.screenHeight}
          selectedOptions={{ SUBJID: [{ label: params.id, value: params.id }] }}
          selectedSubjects={[params.id]}
          notitle={true}
        />
      </Grid>
      {cmData && cmData.length > 0 ? (
        <Box
          sx={{
            width: props.screenWidth - 100,
            // height: props.screenHeight - 500,
          }}
        >
          <b>Concomitent Medications</b>
          <DataGridPro
            rows={cmData}
            columns={cmDataCols}
            autoHeight={true}
            rowHeight={30}
            density={"compact"}
            sx={{ fontSize: "0.7em" }}
          />
        </Box>
      ) : null}
      {mhData && mhData.length > 0 ? (
        <Box
          sx={{
            width: props.screenWidth - 100,
            // height: props.screenHeight - 500,
          }}
        >
          <b>Medical History</b>
          <DataGridPro
            rows={mhData}
            columns={mhDataCols}
            autoHeight={true}
            rowHeight={30}
            density={"compact"}
            sx={{ fontSize: "0.7em" }}
          />
        </Box>
      ) : null}
      <Modal open={openAe} onClose={handleCloseAe}>
        <Box sx={aeStyle}>
          {fullAeData && aeColumnDefs && (
            <span>
              <Typography variant="subtitle1" sx={{ color: "primary.main" }}>
                {study} - Patient Profile
              </Typography>
              <Demog childKey="aeModal" lookbelow={false} />
              <Typography variant="h6" sx={{ color: "primary.main" }}>
                Adverse Events
              </Typography>
              <DataGridPro
                rows={fullAeData}
                columns={aeColumnDefs}
                rowHeight={30}
                density={"compact"}
                initialState={{ pinnedColumns: { left: ["key"] } }}
                disableColumnMenu={true}
                hideFooter={true}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1,
                  },
                  fontSize: "0.7em",
                  height: 500,
                }}
                getCellClassName={(params) => {
                  const { value, row } = params;
                  if (row && typeof row.key === "string")
                    switch (row.key) {
                      case "Severity":
                        return (value === "SEVERE") |
                          (value === "T4") |
                          (value === "T5")
                          ? "red"
                          : (value === "MODERATE") |
                            (value === "T2") |
                            (value === "T3")
                          ? "orange"
                          : (value === "MILD") | (value === "T1")
                          ? "yellow"
                          : "";

                      case "Serious":
                        return value === "Y" ? "red" : "";
                      case "Related":
                        return value === "PROBABLE"
                          ? "red"
                          : value === "POSSIBLE"
                          ? "orange"
                          : "";
                      default:
                        return "";
                    }
                  return "";
                }}
              />
            </span>
          )}
        </Box>
      </Modal>
      <Modal open={openLb} onClose={handleCloseLb}>
        <span>
          {splitLbData && lbModalArea >= 0 && (
            <Box sx={lbStyle}>
              <Typography variant="subtitle1" sx={{ color: "primary.main" }}>
                {study} - Patient Profile
              </Typography>
              <Demog childKey="lbModal" lookbelow={false} />
              <Typography variant="h6" sx={{ color: "primary.main" }}>
                Labs - {labsTitle[lbModalArea]}
              </Typography>
              <DataGridPro
                rows={splitLbData[lbModalArea]}
                columns={lbModalColumnDefs}
                rowHeight={30}
                density={"compact"}
                initialState={{ pinnedColumns: { left: ["key"] } }}
                disableColumnMenu={true}
                hideFooter={true}
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1,
                  },
                  fontSize: "0.7em",
                  height: 500,
                }}
                getCellClassName={(params) => {
                  const { value, field, row } = params;
                  const col = field.substring(0, 7),
                    status = row[col + "-status"];
                  if (typeof value !== "object") {
                    // console.log('value', value, 'field', field, 'row', row, 'col', col, 'status', status)
                    return status === "HIGH"
                      ? "red"
                      : status === "MID"
                      ? "orange"
                      : status === "LOW"
                      ? "blue"
                      : "";
                  }
                  return "";
                }}
              />
            </Box>
          )}
        </span>
      </Modal>
    </Container>
  );
}
