import * as React from "react";
import {
  Tooltip,
  MenuItem,
  Menu,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

export default function MainMenu() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const links = [
    { label: "About", url: "/about" },
    { label: "Preferences", url: "/preferences" },
    { label: "Change User", url: "/login" },
    { label: "Future", url: "Divider" },
    { label: "Query Builder", url: "/query", disabled: true },
    { label: "Dashboard Builder", url: "/dashboard", disabled: true },
    { label: "Load  study", url: "/load", disabled: true },
    { label: "Export Data", url: "/export", disabled: true },
    { label: "Help", url: "/help", disabled: true },
    { label: "R & D", url: "Divider" },
    { label: "Query", url: "/query" },
    { label: "A.I.", url: "/openai" },
    { label: "Report Builder", url: "/reporting" },
    { label: "Diagrams", url: "/diagrams" },
    { label: "Files", url: "/files" },
    { label: "R", url: "/rdemo", disabled: true },
    { label: "Python", url: "/pythondemo", disabled: true },
    { label: "Test Graph", url: "/testgraph" },
    { label: "Test Highchart", url: "/testhighchart" },
    { label: "Test Table", url: "/testtable", disabled: true },
    { label: "New Test", url: "/testnew", disabled: false },
    { label: "Pivot Tables", url: "/pivot", disabled: true },
    { label: "View Sparklines", url: "/viewspark", disabled: true },
    // { label: 'SQL API', url: 'http://localhost:3001/sql/select%20*%20from%20ae' },
  ];

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = (url) => {
    setAnchorElUser(null);
  };
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Main Menu">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <MenuIcon sx={{ fontSize: 24, fontWeight: "medium" }} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {links.map((link, index) => {
          if (link.url === "Divider")
            return (
              <Divider
                key={index}
                sx={{
                  color: "primary.contrastText",
                  backgroundColor: "primary.light",
                }}
              >
                {link.label}
              </Divider>
            );
          else
            return (
              <MenuItem
                key={index}
                disabled={link.disabled ? true : false}
                sx={{ backgroundColor: "primary.light" }}
              >
                <Link to={link.url} onClick={handleCloseUserMenu}>
                  {link.label}
                </Link>
              </MenuItem>
            );
        })}
      </Menu>
    </Box>
  );
}
