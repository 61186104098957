import React from "react";
import { GenerateSelects } from "../forms/GenerateSelects";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Fab, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

export const Filter = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (<>
        <Tooltip title='Make Global Filter'>
            <Fab size="small" onClick={handleClickOpen} color="primary">
                <FilterAltIcon />
            </Fab>
        </Tooltip>
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">
                {"Global Filter"}
            </DialogTitle>
            <DialogContent>
                <GenerateSelects
                    selectedOptions={props.selectedOptions}
                    studyDatabase={props.studyDatabase}
                    setSelectedOptions={props.setSelectedOptions}
                    setSelectedSubjects={props.setSelectedSubjects}
                    optionsForSelection={props.optionsForSelection}
                    varToTable={props.varToTable} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </>
    );
}