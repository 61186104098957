import React, { useState } from "react";
import { Modal, Box, Typography, TextField, InputAdornment, Button, Snackbar } from '@mui/material';
import { useLocation } from "react-router-dom";
import { note } from "../../apis/utility";
import moment from "moment";
import BorderColorIcon from '@mui/icons-material/BorderColor';

//TODO: need to write to notes to each separate database

export const MakeNotesModal = (props) => {
    const { setOpenMakeNotesModal, userName, openMakeNotesModal, screenHeight, screenWidth } = props,
        location = useLocation(),
        handleClose = () => setOpenMakeNotesModal(false),
        style = {
            position: 'absolute',
            top: '20%',
            left: '20%',
            transform: 'translate(-10%, -10%)',
            width: screenWidth * 0.8,
            height: screenHeight * 0.8,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        },
        [tempNote, setTempNote] = useState(''),
        [snackMessage, setSnackMessage] = useState(''),
        [openSnackbar, setOpenSnackbar] = useState(false),
        handleOpenSnackbar = (message) => {
            setSnackMessage(message)
            setOpenSnackbar(true)
        },
        handleCloseSnackbar = () => setOpenSnackbar(false),
        addNewNote = () => {
            const url = encodeURIComponent(location.pathname),
                userid = userName,
                created = moment().format('YYYY-MM-DD HH:mm:ss')
            note(`insert into notes (url,note,userid,created) values ('${url}', '${tempNote}', '${userid}', '${created}')`)
                .then((res) => {
                    // console.log('res', res)
                    handleOpenSnackbar(res.message)
                    setTempNote('')
                })
        }

    return (
        <Modal open={openMakeNotesModal || false} onClose={handleClose}>
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Make notes - {location.pathname}
                </Typography>
                <TextField id="new-note"
                    label="New Note"
                    variant="outlined"
                    multiline
                    rows={6}
                    maxRows={4}
                    onChange={(event) => { setTempNote(event.target.value) }}
                    value={tempNote}
                    sx={{ m: 1, minWidth: 600 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <BorderColorIcon />
                            </InputAdornment>
                        )
                    }} />
                &nbsp;&nbsp;
                <Button variant="contained" color="primary" onClick={addNewNote} size='small'>Add Note</Button>
                <Button variant="contained" color="primary" onClick={handleClose} size='small' sx={{ ml: 2 }}>Close</Button>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    message={snackMessage}
                />
            </Box>
        </Modal>
    );
};