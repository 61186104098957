import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton, Box } from "@mui/material";
import Home from "@mui/icons-material/Home";

export default function HomeIconLink() {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 0, p: 1 }}>
      <Tooltip title="Study Overview">
        <IconButton onClick={() => navigate("/home")} sx={{ p: 0 }}>
          <Home
            sx={{ color: "text.primary", fontSize: 24, fontWeight: "medium" }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
