export const doLogIn = (username,type) => {
    localStorage.setItem("username", username);
    localStorage.setItem("usertype", type);
    localStorage.setItem("isLoggedIn", true);
    console.log("logged in - ", username);
};

export const isLoggedIn = () => {
    // console.log("isLoggedIn - ", localStorage.getItem("isLoggedIn"));
    // console.log("username - ", localStorage.getItem("username"));
    return Boolean(localStorage.getItem("isLoggedIn"));
};

export const logOut = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("usertype");
    localStorage.removeItem("isLoggedIn");
    // props.history.push("/login");
    console.log("logged out");
};