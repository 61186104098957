import React, { useState, useEffect } from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts";
import excelexport from "fusioncharts/fusioncharts.excelexport";
import SubjectTable from "../SubjectTable";
import { sql, globalFilter, addToHistory } from "../../apis/utility";
import { useLocation } from "react-router-dom";
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel';
import moment from "moment";

ReactFC.fcRoot(FusionCharts, TimeSeries, GammelTheme, excelexport);

export default function DSInfo(props) {
    const location = useLocation();
    addToHistory({ title: "DS Info", url: location })
    const
        whereSubjects = props.selectedSubjects.length > 0
            ? 'where SUBJID in ("' + props.selectedSubjects.join('","') + '")'
            : '',
        [subjectsDrilledTo, setSubjectsDrilledTo] = useState(null),
        sqlStatement = `SELECT date(date) as date,
            CASE dsdecod WHEN 'COMPLETED' THEN 'Study Completed' WHEN 'ADVERSE EVENT' THEN 'Withdrew due to Adverse Event' WHEN 'WITHDREW CONSENT (NOT RELATED TO AE)' THEN 'Withdrew Consent (not related to AE)' when 'OTHER' then dsothsp ELSE dsdecod END AS type,
                 count( * ) AS n
            FROM ds
            ${whereSubjects}
            GROUP BY date,
                     dsdecod;`,
        subtitle = globalFilter(props.selectedOptions),
        [data, setData] = useState(null),
        [timeseriesDs, setTimeseriesDs] = useState(null),
        config = {
            type: "timeseries",
            renderAt: "container",
            width: "100%",
            height: props.screenHeight * 0.8,
            dataSource: {
                chart: { theme: "gammel", "exportEnabled": "1" },
                caption: { text: "Disposition Events and Protocol Milestones by Calendar Day (" + props.study + ")" },
                subcaption: { text: subtitle },
                series: "Type",
                yaxis: [
                    {
                        plot: { value: "Visits", type: 'column' },
                        title: "Visits",
                        type: "number"
                    }
                ],
                data: null
            },
            events: {
                dataPlotClick: function (e) {
                    // console.log(e.data)
                    const start = moment(e.data.startText, 'YYYY-MM-DD'),
                        end = moment(e.data.endText, 'YYYY-MM-DD')
                    const dateRange = []
                    for (let m = moment(start); m < moment(end); m.add(1, 'days')) {
                        const date = m.format('YYYY-MM-DD')
                        dateRange.push(date)
                    }
                    sql(props.studyDatabase, `select distinct SUBJID from ds    
                        where dsdecod='${e.data.seriesValue}'                     
                            and date in ('${dateRange.join("','")}')`)
                        .then((res) => {
                            // console.log('res', res)
                            if (res.message === "success") {
                                setSubjectsDrilledTo(res.data.map(d => d.SUBJID));
                            }
                        })
                }
            }
        }

    useEffect(() => {
        sql(props.studyDatabase, sqlStatement)
            .then((res) => {
                const tempData = res.data.map((item) => [item.date, item.type, item.n])
                setData(tempData)
            })
    }, [props.studyDatabase, sqlStatement])

    useEffect(() => {
        const schema = [{
            "name": "Time",
            "type": "date",
            "format": "%Y-%m-%d"
        }, {
            "name": "Type",
            "type": "string"
        }, {
            "name": "Visits",
            "type": "number"
        }]
        if (data) {
            const fusionTable = new FusionCharts.DataStore().createDataTable(
                data, schema);
            const temp = { ...config }
            temp.dataSource.data = fusionTable;
            setTimeseriesDs({ ...temp });
        }
        // eslint-disable-next-line
    }, [data])

    return (<>
        {data && timeseriesDs
            ? <ReactFC {...timeseriesDs} />
            : "loading"}
        {subjectsDrilledTo &&
            <SubjectTable
                screenHeight={props.screenHeight}
                selectedSubjects={subjectsDrilledTo}
                study={props.study}
                studyDatabase={props.studyDatabase}
                selectedOptions={props.selectedOptions} />}
    </>)
}