import React, { useState, useEffect } from "react";
import { Divider, Tooltip, TextField, Container, FormControl, InputLabel, Select, MenuItem, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { sql } from "../../apis/utility";

export default function Preferences(props) {
    const
        { setDrillDownPath, drillDownPath } = props,
        setNormalRanges = props.setNormalRanges,
        setAlerts = props.setAlerts,
        alerts = props.alerts,
        SYSBP = props.normalRanges.SYSBP,
        DIABP = props.normalRanges.DIABP,
        PULSE = props.normalRanges.PULSE,
        [screenWidth, setScreenWidth] = useState(props.screenWidth),
        [screenHeight, setScreenHeight] = useState(props.screenHeight),
        [lowSys, setLowSys] = useState(SYSBP.low),
        [highSys, setHighSys] = useState(SYSBP.high),
        [lowDia, setLowDia] = useState(DIABP.low),
        [highDia, setHighDia] = useState(DIABP.high),
        [lowPulse, setLowPulse] = useState(PULSE.low),
        [highPulse, setHighPulse] = useState(PULSE.high),
        [dp0, setDp0] = useState(drillDownPath[0]),
        [dp1, setDp1] = useState(drillDownPath[1]),
        [dp2, setDp2] = useState(drillDownPath[2]),
        [aeVars, setAeVars] = useState(null),
        handleChange = (e, variable, index) => {
            if (variable === 'screenWidth') setScreenWidth(e.target.value)
            else if (variable === 'screenHeight') setScreenHeight(e.target.value)
            else if (variable === 'lowSys') setLowSys(e.target.value)
            else if (variable === 'highSys') setHighSys(e.target.value)
            else if (variable === 'lowDia') setLowDia(e.target.value)
            else if (variable === 'highDia') setHighDia(e.target.value)
            else if (variable === 'lowPulse') setLowPulse(e.target.value)
            else if (variable === 'highPulse') setHighPulse(e.target.value)
            else if (variable === 'path') {
                // console.log('e.target.value', e.target.value, 'variable', variable, 'index', index)
                if (index === 0) setDp0(e.target.value)
                else if (index === 1) setDp1(e.target.value)
                else if (index === 2) setDp2(e.target.value)
            }
        },
        handleChangeAlert = (e, test, level) => {
            // console.log('e.target.value', e.target.value, 'test', test, 'level', level)
            const changedAlerts = { ...alerts }
            changedAlerts[test][level] = Number(e.target.value)
            setAlerts(changedAlerts)
        },
        [tabValue, setTabValue] = useState('1')

    useEffect(() => {
        sql(props.studyDatabase, 'select * from ae')
            .then(res => {
                if (res.message === 'success') setAeVars(Object.keys(res.data[0]))
            })
    }, [props.studyDatabase])

    useEffect(() => {
        props.setScreenWidth(screenWidth)
        // eslint-disable-next-line
    }, [screenWidth])

    useEffect(() => {
        props.setScreenHeight(screenHeight)
        // eslint-disable-next-line
    }, [screenHeight])

    useEffect(() => {
        setNormalRanges({
            PULSE: { low: lowPulse, high: highPulse },
            SYSBP: { low: lowSys, high: highSys },
            DIABP: { low: lowDia, high: highDia }
        })
    }, [lowPulse, highPulse, lowSys, highSys, lowDia, highDia, setNormalRanges])

    useEffect(() => {
        setDrillDownPath([dp0, dp1, dp2])
    }, [setDrillDownPath, dp0, dp1, dp2])
    let lastCategory = ''
    // console.log('alerts', alerts)

    return (<Container sx={{ height: props.screenHeight * .8 }}>
        <h4>Preferences</h4>
        <TabContext value={tabValue}>
            <TabList onChange={(e, v) => setTabValue(v)}>
                <Tab label="Lab Limits" value='1' />
                <Tab label="Screen Size" value='2' />
                <Tab label="Vital Signs Limits" value='3' />
                <Tab label="Adverse Events Drill Down Path" value='4' />
            </TabList>
            <Divider />
            <TabPanel value={'1'} index={0}>
                <h5>Check Lab results using these normal ranges</h5>

                {alerts && Object.keys(alerts).map((test, index1) => {
                    return Object.keys(alerts[test]).map((level, index2) => {
                        const flag = lastCategory !== alerts[test].category
                        lastCategory = alerts[test].category
                        if (!['test', 'category'].includes(level)) {
                            return <>
                                {flag ? <h5 key={'h5-' + index1 + '-' + index2}>{alerts[test].category}</h5> : null}
                                <Tooltip key={'labtest-' + index1 + '-' + index2} title={alerts[test].category + ' - ' + level + ' - ' + alerts[test].test}>
                                    <TextField
                                        margin="dense"
                                        size='small'
                                        label={level + ' - ' + test}
                                        value={alerts[test][level]}
                                        onChange={(e) => handleChangeAlert(e, test, level)}
                                        sx={{ width: 140 }}
                                        key={'textfield-' + index1 + '-' + index2}
                                    />
                                </Tooltip>
                            </>
                        }
                        else return null
                    })
                })}
                {/* <TextField
            id="highAst"
            name='highAst'
            label="AST more than"
            value={highAst}
            size="small"
            onChange={(e) => handleChange(e, 'highAst')}
        /> */}
            </TabPanel>
            <TabPanel value='2' index={1}>
                <h5>Settings</h5>
                <TextField
                    id="screenWidth"
                    name='screenWidth'
                    label="Screen Width"
                    value={screenWidth}
                    size="small"
                    onChange={(e) => handleChange(e, 'screenWidth')}
                />
                <TextField
                    id="screenHeight"
                    name='screenHeight'
                    label="Screen Height"
                    value={screenHeight}
                    size="small"
                    onChange={(e) => handleChange(e, 'screenHeight')}
                />
            </TabPanel>
            <TabPanel value={'3'} index={2}>
                <h5>Vital Signs (Limits of Normal)</h5>
                <TextField
                    id="sysbp-low"
                    name='sysbp-low'
                    label="Low Systolic Blood Pressure"
                    value={lowSys}
                    size="small"
                    onChange={(e) => handleChange(e, 'lowSys')}
                />
                <TextField
                    id="sysbp-high"
                    name='sysbp-high'
                    label="High Systolic Blood Pressure"
                    value={highSys}
                    size="small"
                    onChange={(e) => handleChange(e, 'highSys')}
                />
                <p />
                <TextField
                    id="diabp-low"
                    name='diabp-low'
                    label="Low Diastolic Blood Pressure"
                    value={lowDia}
                    size="small"
                    onChange={(e) => handleChange(e, 'lowDia')}
                />
                <TextField
                    id="diabp-high"
                    name='diabp-high'
                    label="High Diastolic Blood Pressure"
                    value={highDia}
                    size="small"
                    onChange={(e) => handleChange(e, 'highDia')}
                />
                <p />
                <TextField
                    id="pulse-low"
                    name='pulse-low'
                    label="Low Pulse"
                    value={lowPulse}
                    size="small"
                    onChange={(e) => handleChange(e, 'lowPulse')}
                />
                <TextField
                    id="pulse-high"
                    name='pulse-high'
                    label="High Pulse"
                    value={highPulse}
                    size="small"
                    onChange={(e) => handleChange(e, 'highPulse')}
                />
                <p />
            </TabPanel>
            <TabPanel value={'4'} index={3}>
                <h5>Adverse Events - Drill Path</h5>
                {aeVars && drillDownPath.map((item, index) => {
                    return <FormControl key={'form-' + index} sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>{"Level-" + index}</InputLabel>
                        <Select
                            value={item}
                            label={"Level " + index}
                            onChange={(e) => handleChange(e, 'path', index)}
                            key={'select-' + index}
                        >
                            {aeVars.map((item, index2) => {
                                const label = props.variableLabels.ae[item]
                                    ? props.variableLabels.ae[item]
                                    : item
                                return <MenuItem key={'MenuItem-' + index + '-' + index2} value={item}>{label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                }
                )}
            </TabPanel>
        </TabContext>

        <p>&nbsp;</p>
    </Container>)
}