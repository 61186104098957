import React, { useState } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  Snackbar,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { note } from "../../apis/utility";
import moment from "moment";
// import BorderColorIcon from "@mui/icons-material/BorderColor";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import SaveIcon from "@mui/icons-material/Save";
import { BorderColor, Visibility, Save, Expand } from "@mui/icons-material";

export const MakeNotes = (props) => {
  const { userName, setOpenViewNotesModal, screenWidth, studyDatabase } = props,
    location = useLocation(),
    viewNote = () => {
      setOpenViewNotesModal(true);
    },
    style = {
      bottom: 30,
      height: 70,
      // border: '2px solid #000',
      p: 0.5,
      position: "fixed",
      left: 100,
      right: 100,
      // color: 'white',
      backgroundColor: "transparent",
      "&:hover": {
        border: "2px solid #00FF00",
        boxShadow: 24,
        color: "gray",
        backgroundColor: "white",
      },
    },
    altStyle = {
      top: 80,
      height: 70,
      // border: '2px solid #000',
      p: 0.5,
      position: "fixed",
      left: 100,
      right: 100,
      // color: 'white',
      backgroundColor: "transparent",
      "&:hover": {
        border: "2px solid #00FF00",
        boxShadow: 24,
        color: "gray",
        backgroundColor: "white",
      },
    },
    [tempNote, setTempNote] = useState(""),
    [snackMessage, setSnackMessage] = useState(""),
    [openSnackbar, setOpenSnackbar] = useState(false),
    [topNotes, setTopNotes] = useState(false),
    handleOpenSnackbar = (message) => {
      setSnackMessage(message);
      setOpenSnackbar(true);
    },
    handleCloseSnackbar = () => setOpenSnackbar(false),
    addNewNote = () => {
      const url = encodeURIComponent(location.pathname),
        userid = userName,
        created = moment().format("YYYY-MM-DD HH:mm:ss");
      note(
        `insert into notes (url,note,userid,created,database) values ('${url}', '${tempNote}', '${userid}', '${created}', '${studyDatabase}')`
      ).then((res) => {
        // console.log('res', res)
        handleOpenSnackbar(res.message);
        setTempNote("");
      });
    };

  return (
    <Box sx={topNotes ? altStyle : style}>
      <Grid container spacing={1}>
        <Grid item xs={10} md={10}>
          <TextField
            id="new-note"
            label="New Note"
            variant="outlined"
            multiline
            maxRows={8}
            onChange={(event) => {
              setTempNote(event.target.value);
            }}
            value={tempNote}
            sx={{ m: 1, minWidth: (screenWidth * 10) / 12 - 200 }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ color: "lightgray", "&:hover": { color: "black" } }}
                >
                  <BorderColor />
                </InputAdornment>
              ),
              style: { fontSize: 12 },
            }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            // helperText={"Add a note for the screen you are currently looking at."}
          />
        </Grid>
        <Grid
          item
          container
          xs={2}
          md={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Tooltip title="Save note">
            <IconButton
              onClick={addNewNote}
              size="small"
              sx={{ color: "lightgray", "&:hover": { color: "black" } }}
            >
              <Save />
            </IconButton>
          </Tooltip>
          <Tooltip title="View notes for this screen">
            <IconButton
              onClick={viewNote}
              size="small"
              sx={{ color: "lightgray", "&:hover": { color: "black" } }}
            >
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip title="Toggle between top & bottom">
            <IconButton
              onClick={() => {
                setTopNotes(!topNotes);
              }}
              size="small"
              sx={{ color: "lightgray", "&:hover": { color: "black" } }}
            >
              <Expand />
            </IconButton>
          </Tooltip>
        </Grid>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackMessage}
        />
      </Grid>
    </Box>
  );
};
