import React, { useEffect, useState } from "react";
import {
  Avatar,
  Checkbox,
  Grid,
  Modal,
  TextField,
  FormControlLabel,
  Paper,
  Button,
  Typography,
  Link,
  Box,
} from "@mui/material";
import { doLogIn, isLoggedIn, logOut } from "../../apis/authService";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";

const LoginPage = (props) => {
  const navigate = useNavigate(),
    Copyright = () => {
      return (
        <Typography variant="body2" color="textSecondary" align="center">
          {"Copyright © "}
          <Link
            color="inherit"
            href="https://both-analytics.com/index.php/both_home.html"
            target="_blank"
          >
            BoTh analytics
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      );
    },
    [showSignup, setShowSignup] = useState(false),
    modalStyle = {
      position: "absolute",
      top: "10%",
      left: "10%",
      // transform: 'translate(-10%, -10%)',
      width: "80%",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
      height: props.screenHeight * 0.8,
      // width: 'props.screenWidth*.8',
    },
    defaultRoles = [{ userName: "test", password: "test", type: "Tester" }],
    [password, setPassword] = useState(""),
    {
      loggedIn,
      setLoggedIn,
      userName,
      setUserName,
      userType,
      setUserType,
      roles,
    } = props,
    // handleAccount = (property, event) => {
    //   const accountCopy = { ...account };
    //   accountCopy[property] = event.target.value;
    //   console.log('accountCopy',accountCopy);
    //   setAccount(accountCopy);
    // },
    isVerifiedUser = (userName, password, rolesToUse) => {
      return true;
      // return rolesToUse.find(
      //   (user) => user.USERNAME === userName && user.PASSWORD === password
      // );
    },
    handelLogin = () => {
      let useTheseRoles = roles;
      if (roles === undefined || roles.length === 0) {
        useTheseRoles = defaultRoles;
      }
      const user = isVerifiedUser(userName, password, useTheseRoles);
      console.log("user", user, "roles", roles);
      if (user) {
        doLogIn(userName, user.USERTYPE);
        setLoggedIn(true);
        setUserName(userName);
        setPassword(password);
        setUserType(user.USERTYPE);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        alert("Invalid userName or password");
      }
    },
    handelLogout = () => {
      logOut();
      setUserName("");
      setPassword("");
      setUserType(null);
      setLoggedIn(false);
    };

  console.log("roles", roles, props);

  useEffect(() => {
    if (isLoggedIn()) {
      // navigate("/home");
      setLoggedIn(true);
      // console.log("isLoggedIn");
    } else {
      setLoggedIn(false);
      // console.log("is not logged in");
    }
  }, [setLoggedIn]);

  //TODO: add a sign up page

  return (
    <div style={{ padding: 30 }}>
      <Grid
        container
        spacing={3}
        direction={"column"}
        justify={"center"}
        alignItems={"center"}
        component={Paper}
        elevation={1}
        square
      >
        <Avatar sx={{ bgcolor: loggedIn ? "green" : "red" }}>
          <LockOutlinedIcon sx={{ bgcolor: loggedIn ? "green" : "red" }} />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form noValidate>
          <TextField
            onChange={(event) => setUserName(event.target.value)}
            value={userName}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="userName"
            label="userName"
            name="userName"
            autoFocus
          />
          <TextField
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handelLogin}
          >
            Sign In
          </Button>
          {userType ? (
            <Typography sx={{ mt: 3, mb: 2 }}>
              You are logged in as a {userType}
            </Typography>
          ) : null}
          {userType ? (
            <Typography sx={{ mb: 2 }}>
              Forwarding you to the home page.
            </Typography>
          ) : null}
          <Button
            variant="contained"
            color="error"
            onClick={handelLogout}
            sx={{ mt: 3 }}
          >
            Sign Out
          </Button>
          <Grid container>
            <Grid item>
              <Button variant="body2" onClick={() => setShowSignup(true)}>
                {"Don't have an account? Sign Up"}
              </Button>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </form>
      </Grid>
      {showSignup && (
        <Modal open={showSignup} onClose={() => setShowSignup(false)}>
          <Box sx={modalStyle}>Signup form will go here.</Box>
        </Modal>
      )}
    </div>
  );
};

export default LoginPage;
