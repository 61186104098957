import { Box, Container } from "@mui/material";

const Ecrf = (props) => {
  return (
    <Container>
      <Box sx={{ height: props.screenHeight }}>
        <h1>Links to documents for currently selected study</h1>
        <li>Protocol</li>
        <li>CRF</li>
        <li>SAP</li>
        <li>Protocol</li>
      </Box>
    </Container>
  );
};
export default Ecrf;
