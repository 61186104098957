import React, { useState, useEffect } from "react";
import axios from 'axios';
const Pythondemo = () => {
    const [data, setData] = useState(null);
    useEffect(() => {
        axios.get('http://localhost:3001/python/test')
            .then((response) => {
                console.log(response);
                if (response.statusText === 'OK') {
                    setData(response.data);
                }
            });
    }, [])
    return (
        <div >
            <h1>Python demo</h1>
            <b>The following was returned by running a Python program.</b>
            <p>{data}</p>
        </div>
    );
}
export default Pythondemo;