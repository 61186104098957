import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton, Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';

export default function SubjectIconLink() {
    const navigate = useNavigate()
    return (
        <Box sx={{ flexGrow: 0, p: 1 }}>
            <Tooltip title="Subject Table">
                <IconButton onClick={() => navigate('/subjects')} sx={{ p: 0 }}>
                    <PeopleIcon sx={{ color: 'text.primary', fontSize: 24, fontWeight: 'medium' }} />
                </IconButton>
            </Tooltip>
        </Box >)
}