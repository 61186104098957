import React, { useState, useEffect } from "react";
import { sql, globalFilter, addToHistory } from "../../apis/utility";
import { useLocation } from "react-router-dom";

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import excelexport from "fusioncharts/fusioncharts.excelexport";
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel';
ReactFC.fcRoot(FusionCharts, Charts, PowerCharts, GammelTheme, excelexport);

export default function VSBloodPressure(props) {
    const location = useLocation();
    addToHistory({ title: "VS Ortho Blood Pressure", url: location })
    const
        whereSubjects = props.selectedSubjects.length > 0
            ? 'and SUBJID in ("' + props.selectedSubjects.join('","') + '")'
            : '',
        sqlStatement = `select VSDY, VSSTRESN, VSTESTCD from vs where VSTESTCD in ('OSYSBP','ODIABP') ${whereSubjects}`,
        [subtitle, setSubtitle] = useState(null),
        [dataSource, setDataSource] = useState(null),
        lowSys = props.normalRanges.VSSYSBP.low,
        highSys = props.normalRanges.VSSYSBP.high,
        lowDia = props.normalRanges.VSDIABP.low,
        highDia = props.normalRanges.VSDIABP.high

    useEffect(() => {
        setSubtitle(globalFilter(props.selectedOptions))
        sql(props.studyDatabase, sqlStatement)
            .then((res) => {
                if (res.message === "success") {
                    // console.log('res.data', res.data)
                    const categoryValues = [...new Set(res.data
                        .filter((row) => ('OSYSBP', 'ODIABP').includes(row.VSTESTCD))
                        .map((row) => Number(row.VSDY)))]
                        .sort((a, b) => a - b),
                        categories = [{
                            category:
                                categoryValues.map((item) => { return { label: item.toString() } })
                        }],
                        osysbpSeries = {
                            seriesname: 'OSYSBP', data:
                                categories[0].category.map((category) => {
                                    const bpData = res.data
                                        .filter((row) => row.VSTESTCD === 'OSYSBP' && row.VSDY === category.label)
                                    if (bpData.length > 2) { return { value: bpData.map((item) => Number(item.VSSTRESN)).join(',') } }
                                    else {
                                        const values = bpData.map((item) => Number(item.VSSTRESN)).join(',');
                                        return { "value": values + ',' + values + ',' + values }
                                    }
                                })
                        },
                        odiabpSeries = {
                            seriesname: 'ODIABP', data:
                                categories[0].category.map((category) => {
                                    const bpData = res.data
                                        .filter((row) => row.VSTESTCD === 'ODIABP' && row.VSDY === category.label)
                                    if (bpData.length > 2) { return { value: bpData.map((item) => Number(item.VSSTRESN)).join(',') } }
                                    else {
                                        const values = bpData.map((item) => Number(item.VSSTRESN)).join(',');
                                        return { "value": values + ',' + values + ',' + values }
                                    }
                                })
                        },
                        dataSource = {
                            chart: {
                                "caption": "Orthostatic Blood Pressure (" + props.study + ')',
                                "subcaption": subtitle,
                                "yaxisname": "BP",
                                "xaxisname": "Day",
                                // theme: "gammel",
                                "bgColor": "EEEEEE,CCCCCC",
                                "bgratio": "60,40",
                                "bgAlpha": "70,80",
                                "bgAngle": "180",
                                "plotspacepercent": "25",
                                "numVisiblePlot": "222",
                                "showalloutliers": "1",
                                "exportEnabled": "1"
                            },
                            categories: categories,
                            dataset: [
                                osysbpSeries,
                                odiabpSeries
                            ],
                            "trendlines": [{
                                "line": [{
                                    "isTrendZone": "0",
                                    // "startvalue": 0,
                                    // "endValue": lowSys,
                                    value: lowSys,
                                    "color": "#ff0000",
                                    "valueOnRight": "1",
                                    "alpha": "100",
                                    "tooltext": "Low Systolic",
                                    "displayvalue": "Systolic"
                                }, {
                                    "isTrendZone": "0",
                                    // "startvalue": highSys,
                                    // "endValue": 250,
                                    value: highSys,
                                    "color": "#ff0000",
                                    "valueOnRight": "1",
                                    "alpha": "100",
                                    "tooltext": "High Systolic",
                                    "displayvalue": "Systolic"
                                },
                                {
                                    "isTrendZone": "0",
                                    // "startvalue": 0,
                                    // "endValue": lowDia,
                                    value: lowDia,
                                    "color": "#0000ff",
                                    "valueOnRight": "1",
                                    "alpha": "100",
                                    "tooltext": "Low Diastolic",
                                    "displayvalue": "Diastolic"
                                }, {
                                    "isTrendZone": "0",
                                    "value": highDia,
                                    // "startvalue": highDia,
                                    // "endValue": 250,
                                    "color": "#0000ff",
                                    "valueOnRight": "1",
                                    "alpha": "100",
                                    "tooltext": "High Diastolic",
                                    "displayvalue": "Diastolic"
                                }]
                            }]
                        }
                    // console.log('dataSource', dataSource)
                    setDataSource(dataSource)
                }
            });
    }, [props.selectedSubjects, props.study, props.selectedOptions, props.studyDatabase, subtitle, sqlStatement, lowSys, highSys, lowDia, highDia])

    return (<>
        {dataSource
            ? <ReactFC
                type="boxandwhisker2d"
                width="100%"
                height={props.screenHeight * 0.8}
                dataFormat="JSON"
                dataSource={dataSource}
            />
            : "loading"}
    </>)
}