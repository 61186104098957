import React, { useState, useEffect } from "react";
import { sql, globalFilter, addToHistory } from "../../apis/utility";
import { useLocation } from "react-router-dom";

import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import excelexport from "fusioncharts/fusioncharts.excelexport";
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel';
ReactFC.fcRoot(FusionCharts, Charts, PowerCharts, GammelTheme, excelexport);

export default function LBBoxplot(props) {
    const location = useLocation();
    addToHistory({ title: "Lab Box-plot", url: location })
    const
        whereSubjects = props.selectedSubjects.length > 0
            ? 'and SUBJID in ("' + props.selectedSubjects.join('","') + '")'
            : '',
        sqlStatement = `select LBDY, LBSTRESN, LBTESTCD from lb where LBTESTCD in ('ALKP','ALT','AST','ALB') ${whereSubjects}`,
        [subtitle, setSubtitle] = useState(null),
        [dataSource, setDataSource] = useState(null)

    useEffect(() => {
        setSubtitle(globalFilter(props.selectedOptions))
        sql(props.studyDatabase, sqlStatement)
            .then((res) => {
                if (res.message === "success") {
                    // console.log('res.data', res.data)
                    const outlierThreshold = 2
                    const categoryValues = [...new Set(res.data
                        .filter((row) => ('ALKP', 'ALT', 'AST', 'ALB').includes(row.LBTESTCD))
                        .map((row) => Number(row.LBDY)))]
                        .sort((a, b) => a - b),
                        categories = [{
                            category:
                                categoryValues.map((item) => { return { label: item.toString() } })
                        }],
                        ALKPSeries = {
                            seriesname: 'ALKP', data:
                                categories[0].category.map((category) => {
                                    const tempData = res.data
                                        .filter((row) => row.LBTESTCD === 'ALKP' && row.LBDY === category.label)
                                    if (tempData.length > outlierThreshold) { return { "value": tempData.map((item) => Number(item.LBSTRESN)).join(',') } }
                                    else {
                                        const values = tempData.map((item) => Number(item.LBSTRESN)).join(',');
                                        return { "value": values + ',' + values + ',' + values }
                                    }
                                })
                        },
                        ALTSeries = {
                            seriesname: 'ALT', data:
                                categories[0].category.map((category) => {
                                    const tempData = res.data
                                        .filter((row) => row.LBTESTCD === 'ALT' && row.LBDY === category.label)
                                    if (tempData.length > outlierThreshold) { return { "value": tempData.map((item) => Number(item.LBSTRESN)).join(',') } }
                                    else {
                                        const values = tempData.map((item) => Number(item.LBSTRESN)).join(',');
                                        return { "value": values + ',' + values + ',' + values }
                                    }
                                })
                        },
                        ASTSeries = {
                            seriesname: 'AST', data:
                                categories[0].category.map((category) => {
                                    const tempData = res.data
                                        .filter((row) => row.LBTESTCD === 'AST' && row.LBDY === category.label)
                                    if (tempData.length > outlierThreshold) { return { "value": tempData.map((item) => Number(item.LBSTRESN)).join(',') } }
                                    else {
                                        const values = tempData.map((item) => Number(item.LBSTRESN)).join(',');
                                        return { "value": values + ',' + values + ',' + values }
                                    }
                                })
                        },
                        ALBSeries = {
                            seriesname: 'ALB', data:
                                categories[0].category.map((category) => {
                                    const tempData = res.data
                                        .filter((row) => row.LBTESTCD === 'ALB' && row.LBDY === category.label)
                                    if (tempData.length > outlierThreshold) { return { "value": tempData.map((item) => Number(item.LBSTRESN)).join(',') } }
                                    else {
                                        const values = tempData.map((item) => Number(item.LBSTRESN)).join(',');
                                        return { "value": values + ',' + values + ',' + values }
                                    }
                                })
                        },
                        dataSource = {
                            chart: {
                                "caption": "Lab Results (" + props.study + ')',
                                "subcaption": subtitle,
                                "yaxisname": "Units",
                                "xaxisname": "Day",
                                // theme: "gammel",
                                "plotspacepercent": "10",
                                "showalloutliers": "1",
                                "transposeAxis": "1",
                                "bgColor": "EEEEEE,CCCCCC",
                                "bgratio": "60,40",
                                "bgAlpha": "70,80",
                                "bgAngle": "180",
                                "showMean": "0",
                                "numVisiblePlot": "222",
                                "exportEnabled": "1"
                            },
                            categories: categories,
                            dataset: [
                                ALKPSeries,
                                ALTSeries,
                                ASTSeries,
                                ALBSeries
                            ]
                        }
                    // console.log('dataSource', dataSource)
                    setDataSource(dataSource)
                }
            });
    }, [props.selectedSubjects, props.study, props.selectedOptions, props.studyDatabase, subtitle, sqlStatement])

    return (<>
        {dataSource
            ? <ReactFC
                type="boxandwhisker2d"
                width="100%"
                height={props.screenHeight * 0.8}
                dataFormat="JSON"
                dataSource={dataSource}
                events={{
                    dataPlotClick: function (e) {
                        // console.log(e.data)
                        // sql(`select distinct SUBJID from ds 
                        //     where dsdecod='${e.data.seriesValue}' and
                        //         date>='${e.data.startText}' and
                        //         date<'${e.data.endText}'`)
                        //     .then((res) => {
                        //         console.log('res', res)
                        //         if (res.message === "success") {
                        //             setSubjectsDrilledTo(res.data.map(d => d.SUBJID));
                        //         }
                        //     })
                    }
                }}
            />
            : "loading"}
    </>)
}